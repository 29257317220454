@import "../../../../styles/variables";

.companyBaseInfoInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding: 0 20px 0 10px;
  &-row {
    display: flex;
    justify-content: flex-end;
  }
  &-grid {
    display: grid;
    grid-template-columns: 1fr auto;
    @include gapBig;

    @include _1024 {
      padding-bottom: 0;
    }
    @include _768 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-title {
    display: inline-block;
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    color: #04488e;
  }
  &-avatar {
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _768 {
      width: 100%;
      align-items: center;
    }
  }
}
