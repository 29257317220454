@import "/src/styles/variables";

.tooltipContent {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  @include borderRadius;
  background-color: #fff;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.2s;
  &-header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    // border: 1px solid red;
    h5 {
      @include FSbaseBold;
      width: 100%;
      text-align: center;
    }
    span {
      @include FSbaseReg;
      color: $grey-dark;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      display: flex;
      gap: 10px;
      span {
        @include FSbaseReg;
      }
      span:last-child {
        @include FSbaseBold;
      }
      &-text.overdueTask {
        color: red !important;
      }
    }
  }
}
