@import "./src/styles/variables";

.profileRowModal {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $blue-m-bg;
  display: flex;
  flex-direction: column;

  padding: 20px 0 0 0;
  &-wrapper {
    @include gapBig;
    display: grid;
    grid-template-columns: 1fr 160px;
    @include _900 {
      display: flex;
      flex-direction: column;
    }
  }
  &-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, minmax(150px, 1fr));
    @include gapBig;
    @include _1024 {
      grid-template-columns: repeat(3, minmax(150px, 1fr));
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
  }
  &-btns {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-span {
      @include FSbaseBold;
      color: #fff;
    }
    &-list {
      display: flex;
      @include gapBig;
    }
  }
  &-btn {
    height: 45px;
    width: 45px;
    @include borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include _2000 {
      height: 40px;
      width: 40px;
    }
    @include _1700 {
      height: 35px;
      width: 35px;
    }
  }
  &-btn.red {
    background-color: $red;
    @include HoverBtnRed;
    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
  &-btn.grey {
    background-color: $blue-m-text;
    @include HoverBtnDarkGrey;
  }

  &-btn.dark {
    background-color: $blue-dark-btn;
    @include HoverBtnDark;
  }

  &-btn.dark-light {
    background-color: $blue-dark-btn;
    @include HoverBtnDark;
  }

  &-btn.blue {
    background-color: $main-color;
    @include HoverBtnBlue;
  }
}
