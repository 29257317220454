@import "src/styles/variables";

.docMenuFolderItemMobile {
  width: 160px;
  padding: 10px 5px 10px 10px;
  @include borderRadius;
  border: 1px solid $grey;
  @include borderRadius;
  height: 40px;
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  span {
    @include FSbaseBoldM;
    color: $blue;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.docMenuFolderItemMobile.active {
  background-color: $blue;

  border: 1px solid $blue;
  > span {
    color: white;
  }
  svg {
    fill: #ffffff;
  }
}
