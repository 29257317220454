@import "../../styles/variables";

.authPage {
  &-auth {
    &-form {
      &-title {
        text-align: center;
      }
      &-inputs-input-input {
        height: 45px;
        @include _2000 {
          padding: 8px 30px 8px 10px;
          height: 40px;
        }
        @include _1700 {
          padding: 6px 30px 6px 10px;
          height: 35px;
        }
      }
    }
  }
}
