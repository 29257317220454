@import "/src/styles/variables";

.taskListHeader {
  display: grid;
  align-items: center;
  border-top-left-radius: 5px 5px;
  border-top: 1px solid $grey;
  border-left: 1px solid $grey;
  background-color: #fff;
  grid-template-columns: 60px 250px 40px 0 60px;
  align-items: center;
  transition: all 0.5s;
  &-showTaskSection {
    grid-template-columns: 60px 250px 40px 1fr 60px;
  }
  @include _2000 {
    grid-template-columns: 60px 150px 40px 0 60px;
    transition: all 0.5s;
    &-showTaskSection {
      grid-template-columns: 60px 150px 40px 1fr 60px;
    }
  }
  @include _1700 {
    grid-template-columns: 60px 1fr 40px 0 60px;
    transition: all 0.5s;
    &-showTaskSection {
      grid-template-columns: 60px 1fr 40px 1fr 60px;
    }
  }
  span {
    @include FSbaseBold;
    color: $blue;
    width: 100%;
    text-align: center;
  }
  &-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 10px;
    // border-right: 1px solid $grey;
    border-bottom: 1px solid$grey;
    // &-name,
    // &-number {
    //   border-right: 1px solid $main-color;
    //   border-bottom: 1px solid $main-color;
    // }
    // &-startDate,
    // &-endDate {
    //   border-bottom: 1px solid $main-color;
    // }
    // &-endDate {
    //   border-right: 1px solid $main-color;
    // }
    &-deadlineDeviation {
      transform: rotate(0deg);
      transition: transform 0.3s linear;
      &-open {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
      }
    }

    &-showSection {
      display: grid;
      grid-template-columns: 100px 100px 60px 60px;
      @include _2000 {
        grid-template-columns: 100px 100px 40px 40px;
      }
      @include _1700 {
        grid-template-columns: 80px 80px 40px 40px;
      }
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s linear;
      &-open {
        opacity: 1;
        transition: opacity 0.3s linear;
      }
    }
  }
}
