@import "src/styles/_variables.scss";

.modalAddParticipantMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @include FSbaseBoldM;
    &-check {
      display: flex;
      gap: 10px;
      align-items: center;
    }
  }
  &-grid {
    @include cardColumnGridMobile;
  }
  &-btns {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}
