@import "../../styles/variables";

.textarea {
  display: flex;
  flex-direction: column;
  @include gapMin;
  width: 100%;
  min-height: 45px;
  position: relative;
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-input {
    textarea {
      resize: none;
      width: 100%;
      font-family: "Montserrat";
      @include FSbaseReg;
      padding: 12px 10px;
      color: black;
      // background: white;
      @include borderRadius;
      // @include _1024 {
      border: 1px solid $blue-m-bg;
      background-color: $blue-m-bg;
      &::placeholder {
        color: $blue-m-text;
      }
      // }
    }
  }
  &-validate {
    position: absolute;
    bottom: -12px;
    span {
      color: red;
      @include FSxsReg;
    }
  }
  &-title {
    display: flex;
    color: $blue;
    gap: 3px;
    &-red {
      color: red !important;
    }
  }
}

.textarea.error {
  textarea {
    border: 1px solid #ff0606;
  }
}
