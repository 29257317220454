@import "src/styles/variables";

.accessInfoEditMobileCard {
  display: flex;
  flex-direction: column;
  @include gapMin;
  border-top: 1px solid $blue-m-bg;

  &-header {
    display: flex;
    padding: 10px 0 0 0;
    justify-content: space-between;
    &-name {
      @include FSbaseBoldM;
    }
  }
  &-content {
    display: grid;
    @include gapMin;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  }
}
