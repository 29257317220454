@import "./src/styles/_variables.scss";

.authPageAccount {
  @include flexGap(5px, col);
  display: flex;
  font-family: "Montserrat";
  background-color: #ffffff;
  border: 1px solid $grey;
  padding: 8px 10px;
  justify-content: space-between;
  align-items: center;
  @include borderRadius;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-auth {
    padding: 8px 10px;
    color: white;
    background-color: $main-color;
    border-radius: 10px;
    width: 100%;
    text-align: center;
    cursor: pointer;
  }
}
