@import "../../styles/variables";
@include animate_pulse;

.inputTime {
  display: flex;
  flex-direction: column;
  @include gapMin;
  position: relative;
  &-title {
    color: $blue;
    @include FSbaseBold;
  }
  &-input {
    font-family: "Montserrat";
    width: 100%;
    height: 45px;
    border: 1px solid $grey;
    @include borderRadius;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 11px 10px;
    cursor: pointer;
    position: relative;

    @include _2000 {
      padding: 8px 10px;
      height: 40px;
    }
    @include _1700 {
      padding: 6px 8px;
      height: 35px;
    }
    &-icon {
      position: absolute;
      bottom: 27%;
      right: 10px;
    }
    // @include _1024 {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    &::placeholder {
      // color: $blue-m-text;
      @include FSbaseBold;
    }
    // }
    input[type="time"] {
      border: 1px solid $blue-m-bg;
      background-color: $blue-m-bg;
      width: 100%;
      @include FSbaseBold;
      cursor: pointer;

      &::-webkit-calendar-picker-indicator {
        z-index: 10;
        width: 26px;
        height: 26px;
        filter: invert(80%) sepia(2%) saturate(1763%) hue-rotate(161deg) brightness(80%)
          contrast(92%);
        @include _2000 {
          width: 22px;
          height: 22px;
        }
        @include _1700 {
          width: 20px;
          height: 20px;
        }

        cursor: pointer;
        position: absolute;
        right: 8px;
        top: 50%;
        transform: translateY(-50%);
        transition: stroke 0.3s;
        &:hover {
          filter: invert(44%) sepia(35%) saturate(5274%) hue-rotate(177deg) brightness(98%)
            contrast(101%);
        }
        &:active {
          border-radius: 50%;
          animation: animate_pulse 0.3s;
        }
      }
    }
  }
}
