@import "../../../../styles/_variables.scss";

.structuresList {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  padding: 0 20px 0 10px;
  overflow: hidden;
  @include borderRadius;
  min-height: 330px;
  max-height: calc(100vh - 220px);
  // height: 70vh;
  @include _1024 {
    padding: 0 5px 0 0;
    width: calc(100% + 5px); // для корректного отображения скролла
  }
  &-rows {
    margin-top: -10px;
    padding-top: 30px;
    list-style-type: none;
    overflow: hidden;
    @include _1024 {
      padding-top: 20px;
    }
    &-last {
      margin-top: -10px;
      padding-top: 30px;
      list-style-type: none;
      // overflow: hidden;
      z-index: 1;
      @include _1024 {
        padding-top: 20px;
        overflow: hidden;
      }
    }
  }
}
