@import "/src/styles/variables";

.meetingMinutes {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-cards {
    display: flex;
    gap: 10px;
    flex-direction: column;
    padding: 0 20px 0 10px;
    &-header {
      display: grid;
      gap: 10px;
      grid-template-columns: 150px 1fr 3fr 0.5fr minmax(100px, 1fr);

      @include headerWrapper;
      padding-left: 20px;
      &-item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        span {
          font-family: "Montserrat";
          @include FSbaseBold;
          color: $blue;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-item.number {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
      }
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }
}
