@import "../../styles/variables";

.filter {
  position: relative;
  z-index: 100;

  &-btn {
    cursor: pointer;
    width: 70px;
    height: 50px;
    background-color: white;
    border-radius: 50px 0 0 50px;

    @include _2000 {
      border-radius: 45px 0 0 45px;
      height: 45px;
    }
    @include _1700 {
      border-radius: 35px 0 0 35px;
      height: 35px;
    }
    @include _1024 {
      width: 60px;
      background-color: inherit;
    }
    &-content {
      > svg {
        fill: black;
        transition: fill 0.5s;
        @include SFilterPanelIcon;
        &:hover {
          fill: $main-color;
        }
      }
      &.filterBadge {
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 20%;
          right: 30%;
          background-color: $main-color;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          display: block;
          border-radius: 50%;
          animation: animate_reduce 2s linear;
          animation-iteration-count: 5;
        }
      }
    }
  }
}
.filter-btn-mobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 40px;
  @include borderRadius;
  display: flex;
  align-items: center;
  justify-content: center;
}

.filter-btn-content.show {
  padding: 13px 20px 13px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    @include SFilterPanelIcon;
  }
  @include _1700 {
    padding: 10px 10px 10px 10px;
  }
}

.filter-btn-content.hide {
  padding: 13px 20px 13px 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    @include SFilterPanelIcon;
  }
}
.filter-owerflow {
  opacity: 0;
}
.filter-owerflow.active {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 1;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(2px);
}
