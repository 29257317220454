@import "../../../styles/_variables.scss";

.employees {
  display: flex;
  height: 100%;
  width: 100%;
  &-leftInfo {
    @include flexGap(10px, row);
    align-items: center;
    width: 100%;
    &-inputSearch {
      width: 100%;
      margin-right: 20px;
      max-width: 500px;
    }
  }
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include _1700 {
      padding-top: 10px;
    }
    &__filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }
    &__section {
      width: 100%;
      // max-height: calc(100vh - 222px);
      @include flexGap(20px, col);
      display: flex;
      flex-direction: column;
      @include gapBig;
      .pagination {
        margin-bottom: 20px;
        @include _1700 {
          margin-bottom: 10px;
        }
      }
    }
  }
}
