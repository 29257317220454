@import "../../styles/variables";

.searchPanel {
  background: #ffffff;
  // border: 1px solid #000000;
  @include borderRadius;
  display: flex;
  gap: 10px;
  padding: 10px 10px;
  display: flex;
  align-items: center;
  min-height: 50px;
  @include _2000 {
    padding: 10px;
  }

  &-input {
    background-color: $blue-m-bg;
    @include borderRadius;
    position: relative;
    width: 100%;
    input {
      background-color: $blue-m-bg;
      @include borderRadius;
      padding: 9px 40px 9px 10px;
      font-family: "Montserrat";
      @include FSbaseReg;
      width: 100%;
      height: 45px;

      &::placeholder {
        color: $blue-m-text;
      }
      @include _2000 {
        padding: 7px 40px 7px 10px;
        height: 40px;
      }
      @include _1700 {
        height: 30px;
      }
    }
    &-icon-search {
      position: absolute;
      right: 10px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      > img {
        @include SmdBtn;
      }

      // @include HoverIcon;
    }
    &-icon-close {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 40px;
      cursor: pointer;
      opacity: 0;
      transition: all 0.4s;
      > div {
        > svg {
          width: 18px;
          height: 18px;
        }
      }
      @include _1700 {
        > div {
          > svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      @include _1300 {
        > div {
          > svg {
            width: 14px;
            height: 14px;
          }
        }
      }
      &-active {
        opacity: 1;
        // @include HoverIcon;
      }
    }
  }
  &-btns {
    display: flex;
    gap: 10px;
    &-btn {
      background: $main-color;
      @include borderRadius;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      gap: 5px;
      padding: 8px 10px;
      width: 100%;
      height: 45px;
      min-width: 180px;
      cursor: pointer;
      @include HoverBtnBlue;
      // &-icon {
      //   @include SbtnIcon;
      // }
      &-title {
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbtn;
        color: white;
        white-space: nowrap;
      }
      @include _2000 {
        padding: 6px 10px;
        min-width: 150px;
        height: 40px;
      }
      @include _1700 {
        height: 30px;
      }
      @include _1300 {
        padding: 4px 10px;
      }
    }
  }
  &-navigationLeft {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    &-btn {
      background-color: #ffffff;
      padding: 8px 10px;
      width: 100%;
      max-height: 34px;
      min-width: 180px;
      @include borderRadius;
      border: 2px solid $blue;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      @include HoverBtnSearch;
      &-title {
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbtn;
        color: $blue;
        white-space: nowrap;
      }
      &-active {
        background-color: $blue;
        background: linear-gradient($blue, $blue);
        border: 2px solid $blue;
        > span {
          color: white;
        }
      }
      @include _2000 {
        min-width: 150px;
      }
      @include _2000 {
        padding: 6px 10px;
        min-width: 150px;
        height: 40px;
      }
      @include _1700 {
        height: 30px;
      }
      @include _1300 {
        padding: 4px 10px;
      }
    }
  }
  &-navigationLeft-select {
    min-width: 240px;
    position: relative;
    &-btn {
      position: relative;
      background-color: $blue;
      padding: 6px 10px;
      width: 100%;
      height: 34px;
      @include borderRadius;
      border: 1px solid $grey;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      &-title {
        @include FSbtn;
        color: #fff;
        font-family: "Montserrat";
      }
      &-arrow {
        position: absolute;
        // top: 50%;
        right: 10px;
        transform: rotate(0deg), translate(-50%, -50%);
        transition: transform 0.3s linear;
        &-open {
          transform: rotate(180deg);
          transition: transform 0.3s linear;
        }
        > svg {
          fill: #fff;
        }
      }
      @include HoverBtnDark;
      &:hover {
        div {
          svg {
            fill: $main-color;
          }
        }
      }
    }
    &-list {
      padding: 10px;
      background-color: #fff;
      @include borderRadius;
      border: 1px solid $grey;
      position: absolute;
      // bottom: -70px;
      width: 100%;
      z-index: 10;
      display: none;
      flex-direction: column;
      gap: 10px;
      &-active {
        display: flex;
      }
      &-option {
        @include FSbtn;
        cursor: pointer;
        font-family: "Montserrat";
        // color: $blue;
      }
      @include HoverSearchPanelText;
    }
  }
}
