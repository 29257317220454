@import "/src/styles/variables";

.modalAddGroupMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  row-gap: 20px;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    row-gap: 20px;
    &-info {
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include min_600 {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
