@import "/src/styles/variables";

.groupMobile {
  display: flex;
  flex-direction: column;
  gap: 10;
}

.groupInfoMobile {
  display: flex;
  flex-direction: column;
  padding: 10px 0 60px 0;
  gap: 20px;
  &-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-item {
      display: flex;
      gap: 10px;
      flex-direction: column;
      @include FSbaseBoldM;
      @include min_600 {
        flex-direction: row;
      }
    }
    &-title {
      color: $blue;
    }
  }
}
.groupEditMobile {
  display: flex;
  flex-direction: column;
  padding: 10px 0 60px 0;
  gap: 20px;
  &-info {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 10px;
    }
  }
}
