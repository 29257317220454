@import "./src/styles/variables";
.cardGridHeader {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  &-info {
    display: flex;
    gap: 5px;

    &-title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      max-width: 90%;
      color: $blue;
    }
    &-text {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      max-width: 90%;
    }
  }
  &-btn {
    margin-right: -5px;
  }
}
