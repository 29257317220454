@import "/src/styles/variables";

.modalStage {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-main {
    @include flexGap(20px, col);
    .inputDate {
      width: 100%;
      max-width: 440px;
    }
  }
}
