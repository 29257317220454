@import "../../styles/variables";

@keyframes animate_overflow {
  0% {
    background-color: rgba(0, 0, 0, 0);
  }
  10% {
    background-color: rgba(0, 0, 0, 0);
  }
  100% {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.modalWrapperMobile {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  transform: translateY(500%);
  opacity: 0;
  z-index: 999;
  margin: 0 auto;
  height: auto;
  width: 100%;
  // border-top-left-radius: 5px 5px;
  // border-top-right-radius: 5px 5px;
  transition: opacity 0.4s ease-out;

  &.active {
    left: -10px;
    right: -10px;
    bottom: 0;
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.4s ease-out;
    // padding-top: 70px;
    // display: grid;
    // grid-template-columns: 1fr;
    // grid-template-rows: 60px 1fr;
    display: flex;
    flex-direction: column;
    padding-top: 60px;
    justify-content: space-between;
    top: 0px;
    height: 100dvh;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(1px);
    animation: 500ms cubic-bezier(0, 0, 0.2, 1) 0s 1 normal forwards running;
    // transition: height 0.4s ease-out;
  }
  &-modal {
    padding: 10px 10px 10px 10px;
    padding-bottom: calc(20px + env(safe-area-inset-bottom));
    background-color: #fff;
    // position: fixed;
    // transform: translateY(500%);
    position: relative;
    opacity: 1;
    z-index: 1000 !important;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    border-top-left-radius: 10px 10px;
    border-top-right-radius: 10px 10px;
    z-index: 1000;
    // bottom: 60px;
    // top: 70px;
    // left: 5px;
    // right: 5px;
    // bottom: 0;
    display: flex;
    flex-direction: column;
    // position: absolute;
    // max-height: 100%;
    // height: 100%;
    // display: grid;
    // /* grid-template-columns: 1fr; */
    // grid-template-rows: auto 1fr minMax(60px, 120px);
    &.btns {
      // padding-bottom: 80px;
    }
    &-header {
      display: flex;
      align-items: center;
      position: relative;
      padding: 0px 0 10px 0;
      border-bottom: 1px solid $grey;
      min-height: 40px;
      display: grid;
      grid-template-columns: 1fr 60px;
      &-title {
        @include FStitleM;
        color: black;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-btns {
        display: flex;
        gap: 20px;
        justify-content: flex-end;
        width: 100%;
        &-btn {
          // position: absolute;
          // top: -5px;
          // right: -10px;
          background-color: #fff;
          // height: 40px;
          // width: 40px;
        }
      }
    }
    &-content {
      padding: 10px;
      margin: 0 -10px 0 -10px;
      height: 100%;
      position: relative;
    }
    &-btns {
      // position: fixed;
      padding-top: 10px;
      // bottom: 10px;
      // // width: 100%;
      // left: 10px;
      // right: 10px;
      // display: flex;
      // flex-direction: column;
      // justify-content: flex-start;
    }
    &-container {
      padding: 20px;
      position: relative;
      &-draftNotification {
        position: fixed;
        bottom: 80px;
        right: 20px;
        padding: 14px 12px;
        border: 1px solid $blue-m-bg;
        background-color: $blue-m-bg;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        &-btns {
          display: flex;
          flex-direction: row;
          gap: 10px;
        }
      }
    }
  }
}
