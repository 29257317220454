@import "../../../../../../../../styles/variables";

.structuresGridCardContact {
  display: grid;
  grid-template-columns: 1fr 4fr 20px;
  &-contact {
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-self: flex-start;
  }
  &-image {
    margin-right: 7px;
    margin-top: 2px;
    @include XSavatar;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }
  &-title {
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 10px;
    line-height: 14px;
    @include FSmdSemiBold;
  }
  &-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
    line-height: 14px;
    @include FSxsReg;
  }
}
