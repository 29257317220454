@import "./src/styles/variables";

.employeeBaseInfo {
  height: 100%;
  padding: 0 20px 0 10px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding-bottom: 20px;
  a,
  span {
    font-family: "Montserrat";
  }
  a {
    text-decoration: none;
    @include HoverLink;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 14px;
    color: #000000;
  }
  &-wrapper {
    display: flex;
    justify-content: space-between;
    @include gapBig;
  }
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-list {
    list-style-type: none;
    display: grid;
    @include gapBig;
    grid-template-columns: repeat(4, minmax(125px, 1fr));
    width: 100%;
    height: fit-content;
  }
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-title {
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
    }
    &-text {
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      color: #000000;
      @include nowrap;
      max-width: 99%;
    }
    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &-list {
      display: flex;
      gap: 10px;
    }
  }
  &-avatar {
    display: flex;
    flex-direction: column;
    &-title {
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
      margin-bottom: 10px;
    }
    &-img {
      @include ProfileAvatar;
      // width: 326px;
      // height: 326px;
      // object-fit: cover;
      // @include borderRadius;
      // @include _1700 {
      //   width: 250px;
      //   height: 250px;
      // }
      // @include _1300 {
      //   width: 150px;
      //   height: 150px;
      // }
    }
  }
  &-porfiles {
    display: flex;
    flex-direction: column;
    @include gapBig;
    // margin-right: 180px;
  }
}
