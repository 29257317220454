@import "./src/styles/variables";

.cardColumnTaskUser {
  display: flex;
  flex-direction: column;
  @include gapMin;

  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    color: $blue;
  }
  &-wrapper {
    display: flex;
    gap: 5px;
    overflow: hidden;
    overflow: hidden;
    text-overflow: ellipsis;
    &-avatar {
      @include XSavatar;
      flex-shrink: 0;
    }
    &-info {
      display: flex;
      flex-direction: column;
      gap: 2px;
      width: 90%;
      flex-shrink: 1;
    }
    &-name {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      max-width: 99%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-position {
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 16px;
      max-width: 99%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
