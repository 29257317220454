@import "../../../../../../../../styles/variables";

@keyframes down {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-15px);
    opacity: 0.2;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.structuresGridCardFooter {
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  &-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 5px;
  }
  &-arrow {
    margin-right: 3px;
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    &-active {
      transform: rotate(180deg);
    }
  }
  &-title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 10px;
    color: $main-color;
    @include FSxsReg;
  }
  &-avatars {
    display: flex;
  }
  &-avatar {
    border-radius: 50%;
    object-fit: cover;
    border: 1px solid #fff;
    &:not(:first-child) {
      margin-left: -7px;
    }
    @include XSavatar;
  }
  &-other {
    height: 26px;
    width: 26px;
    background: #c0c0c0;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: -7px;
    border: 1px solid #fff;
    @include XSavatar;
    > span {
      font-family: "Montserrat";
      font-style: normal;
      @include FSxsReg;
      line-height: 16px;
      color: #ffffff;
    }
  }
  &-wrapper {
    margin-bottom: 6px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  &-text {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 10px;
  }
  &-list {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 5px;
    padding-right: 3px;
    margin-right: -7px;
    animation: down 0.2s;
    min-height: 35px;
    max-height: 200px;
  }
}
