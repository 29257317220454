@import "./src/styles/variables";

.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background: #ffffff;
  box-shadow: 0px 0px 19px rgba(0, 0, 0, 0.1);
  height: 50px;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 10;
  @include _2000 {
    z-index: 55;
    height: 40px;
  }
  @include _1300 {
    position: fixed;
    padding: 5px 20px;
    height: 40px;
  }
  span {
    @include FSxsBold;
  }
  &-title {
    font-family: "Montserrat" !important;
  }
  &-date {
    font-family: "Montserrat";
    text-align: center;
    @include flexGap(1px, col);
  }
}
