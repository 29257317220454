@import "src/styles/variables";

.deliveryWorkRequestsMobile {
  width: 100%;
  padding-bottom: 60px;
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 20px 0 10px 0;
    span {
      @include FSbaseBoldM;
    }
  }
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
}
