@import "../../styles/variables";
.owerflow-modal {
  padding-top: 60px;
  padding-bottom: 60px;
  position: relative;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modalWrapperDesctop {
  position: absolute;
  background-color: #fff;
  @include borderRadius;
  position: absolute;
  top: 50%;
  left: 50%;
  // margin-top: 60px;
  // margin-bottom: 60px;
  transform: translate(-50%, -48%);
  width: 100%;
  max-width: 1600px;
  min-height: 120px;
  max-height: 80vh;
  z-index: 102;
  display: grid;
  grid-template-rows: 45px 1fr;
  @include _1900 {
    max-width: 1400px;
  }
  @include _1700 {
    max-width: 1100px;
  }
  @include _1300 {
    grid-template-rows: 40px 1fr;
    max-width: calc(100vw - 100px);
  }

  &-fullScreen {
    max-width: 95vw;
    max-height: 95vh;
    transform: translate(-50%, -49%);
    // @include _1300 {
    //   max-width: 1400px;
    // }
  }
  &-header {
    border-radius: 6px 6px 0 0;
    background-color: $main-color;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    @include _2000 {
      height: 45px;
    }
    @include _1300 {
      height: 40px;
    }
  }
  &-btns {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: flex-end;
  }
  &-btn {
    background-color: inherit;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #ffffff;
  }
  &-container {
    padding: 20px;
    position: relative;
    // max-height: calc(60vh - 120px);
    // overflow-y: scroll;
    @include _1700 {
      padding: 10px;
    }
    &-draftNotification {
      position: fixed;
      bottom: 20px;
      right: 20px;
      padding: 14px 12px;
      border: 1px solid $blue-m-bg;
      background-color: $blue-m-bg;
      @include borderRadius;
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-btns {
        display: flex;
        flex-direction: row;
        gap: 10px;
      }
    }
  }
}

.modalWrapperContainer {
  padding: 17px 0px 0px 0px !important;
}
