@import "./src/styles/variables";

.profilePassword {
  font-family: "Montserrat";
  // font-style: normal;
  // padding: 20px 0 20px 0;
  // margin: 0 20px 0 10px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-title {
    @include FSsubtitle;
    line-height: 18px;
    // margin-bottom: 20px;
  }
  &-form {
    display: flex;
    flex-direction: column;
    // max-width: 26%;
    @include gapBig;
    // margin-bottom: 20px;

    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    &-grid {
      display: grid;
      @include gapMin;
      grid-template-columns: repeat(3, 1fr);

      @include _1024 {
        grid-template-columns: repeat(3, 1fr);
      }
      @include _900 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
  }

  padding-bottom: 20px;
}
