@import "../../styles/variables";

.input {
  display: flex;
  flex-direction: column;
  @include gapMin;
  position: relative;

  span {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    color: $blue;
  }
  &-title {
    display: flex;
    color: $blue;
    gap: 3px;
    @include FSbaseBold;
    &-red {
      color: red !important;
    }
  }
  input {
    border: 1px solid $grey;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseBold;
    padding: 12px 10px;
    color: black;
    // background: white;
    @include borderRadius;
    height: 45px;
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    &::placeholder {
      color: $blue-m-text;
      @include FSbaseReg;
    }
    @include _2000 {
      padding: 8px 10px;
      height: 40px;
    }
    @include _1700 {
      padding: 6px 8px;
      height: 35px;
    }
    &::placeholder {
      @include FSbaseReg;
    }
    // @include _1024 {

    // }
  }
  &-icon {
    position: absolute;
    bottom: 10px;
    right: 10px;
    > img {
      @include SmdBtn;
    }
    @include _1700 {
      bottom: 12px;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  &-validate {
    position: absolute;
    bottom: -12px;
    span {
      color: red;
      @include FSxsReg;
    }
  }
}

.input.min {
  width: 66px;
  input {
    filter: none;
    border: 1px solid $blue-m-bg;
    height: 45px;
    text-align: center;
    background-color: $blue-m-bg;
    padding: 11px 0px !important;
    @include _2000 {
      padding: 8px 10px;
      height: 40px;
    }
    @include _1700 {
      padding: 6px 8px;
      height: 35px;
    }
  }
}

.input.error {
  input {
    border: 1px solid #ff0606;
  }
}

.input.disabled {
  input {
    cursor: not-allowed;
    border: 1px solid $grey-light;
    background-color: $grey-light;
  }
}
