@import "../../styles/variables";

.modalAddToFolder {
  display: flex;
  flex-direction: column;
  @include gapBig;
  span {
    font-family: "Montserrat";
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include gapBig;
      width: 100%;
      &-choose {
        display: flex;
        flex-direction: column;
        @include gapBig;
        width: 100%;
      }
      &-list {
        list-style-type: none;
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        @include gapBig;
      }
    }
    &-btn-wrapper {
      display: flex;
      justify-content: center;
    }
  }
}
