@import "../../styles/variables";

.button {
  @include borderRadius;
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  min-height: 45px;
  height: fit-content;
  span {
    font-family: "Montserrat";
    font-weight: 700;
    @include FSbtn;
    color: white;
    white-space: nowrap;
    line-height: 18px;
  }
  // &-icon {
  //   display: flex;
  //   align-items: center;
  //   @include SbtnIcon;
  // }
  @include _2000 {
    max-width: 150px !important;
    padding: 6px;
    min-height: 40px;
  }
  @include _1700 {
    min-height: 35px;
  }
  @include _1300 {
    min-height: 35px;
  }
  // @include _1024 {
  //   min-height: 40px;
  //   max-width: 100% !important;
  // }
}

.button.red {
  background-color: $red;
  @include HoverBtnRed;
}
.button.grey {
  background-color: $grey-dark;
  @include HoverBtnDarkGrey;
}

.button.dark {
  background-color: $blue-dark-btn;
  @include HoverBtnDark;
}

.button.dark-light {
  background-color: $blue-dark-btn;
  @include HoverBtnDark;
}

.button.blue {
  background-color: $main-color;
  @include HoverBtnBlue;
}

.button.purple {
  background-color: $purple;
  @include HoverBtnPurple;
}

.button.yellow {
  background-color: $yellow;
  @include HoverBtnYellow;
}

.button.green {
  background-color: $light-green;
  @include HoverBtnGreen;
}

.button.disabled {
  background-color: $grey !important;
  background: $grey !important;
  &:hover {
    background-color: $grey !important;
  }
}

.button-mobile {
  @include borderRadius;
  width: 100%;
  padding: 8px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  min-height: 38px;
  span {
    font-family: "Montserrat";
    @include FSbaseBoldM;
    color: white;
    white-space: nowrap;
    line-height: 18px;
  }
  svg {
    fill: #fff;
  }
  // &-icon {
  //   display: flex;
  //   align-items: center;
  //   @include SbtnIcon;
  // }

  // @include min_480 {
  //   width: 300px;
  // }
  @include min_768 {
    width: 150px;
  }
}
.button-mobile.red {
  background-color: $red;
}
.button-mobile.grey {
  background-color: $grey;
}

.button-mobile.dark {
  background-color: $blue-dark-btn;
}

.button-mobile.dark-light {
  background-color: $blue-dark-btn;
}
.button-mobile.dark-light {
  background-color: $blue-dark-btn;
}

.button-mobile.blue {
  background-color: $main-color;
}

.button-mobile.disabled {
  background-color: $grey !important;
  background: $grey !important;
}
.button-mobile.green {
  background-color: $light-green;
}

.button {
  &:hover {
    svg {
      fill: #fff;
    }
  }
}
