@import "/src/styles/variables";

.profileNotification {
  font-family: "Montserrat";
  font-style: normal;
  // padding: 20px 0 20px 0;
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    margin: 0;
  }

  &-title {
    @include FSsubtitle;
    line-height: 18px;
    @include _1024 {
      @include FSbaseBold;
    }
  }
  &__form {
    display: flex;
    flex-direction: column;
    max-width: 432px;
    @include gapBig;
    // margin-bottom: 20px;
  }
  &__list {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include gapMin;
    @include _1455 {
      margin-right: 0;
    }
    @include _900 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
  }
  // border-bottom: 2px solid $main-color-light;
}
