@import "../../styles/variables";

.docItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include borderRadius;
  background-color: white;
  padding: 7px 10px;
  border: 1px solid $grey;
  width: 100%;
  // overflow: hidden;

  @include _1024 {
    padding: 4px 5px;
  }
  span {
    font-family: "Montserrat";
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    @include _1024 {
      gap: 2px;
    }

    display: flex;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    &-name {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &-full {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        max-width: 100%;
        font-size: 14px;
        @include FSbaseReg;
      }
    }
    &-size {
      color: #c4c4c4;
      @include FSmdReg;
    }
  }
  &-btns {
    display: flex;
    align-items: center;
    justify-self: end;
    @include gapMin;
    overflow: visible;
    img {
      cursor: pointer;
      @include SmdBtn;
    }
  }
  &-download {
    width: 100%;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .docItem-info-name-full {
      color: black;
    }
    &-content {
      display: grid;
      grid-template-columns: 20px 1fr;
      gap: 5px;
      align-items: center;
      width: 100%;
    }
  }
  &-content {
    display: grid;
    grid-template-columns: 20px 1fr 20px;
    gap: 5px;
    align-items: center;
    width: 100%;
  }
}
.taskInfo-documents-item-typeIcon {
  > img {
    @include SxlBtn;
  }
}
