.sunburst {
  height: 300px;
  width: 300px;
  > div {
    > div {
      > svg {
        > g {
          > g {
            path:nth-child(7) {
              fill: #db1f35;
            }
            path:nth-child(9) {
              fill: #db1f35;
            }
            path:nth-child(11) {
              fill: #db1f35;
            }
            path:nth-child(13) {
              fill: #db1f35;
            }
            path:nth-child(15) {
              fill: #db1f35;
            }
          }
        }
      }
    }
  }
}
