@import "../../../../styles/variables";

.filter-modal {
  background: white;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  @include borderRadius;
  padding: 20px;
  width: 330px;
  position: absolute;
  top: 0px;
  right: 10px;
  display: flex;
  flex-direction: column;
  @include gapMin;
  @include _1700 {
    padding: 10px;
  }
  @include _1024 {
    padding: 0;
    width: 100%;
    position: relative;
    box-shadow: 0px 0px 0 0;
    top: 0px;
    right: 0px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-title {
    font-family: "Montserrat";
    @include FSnav;
    color: black;
  }
  &-btns {
    display: flex;
    gap: 18px;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    overflow: visible;
    div {
      display: flex;
      align-items: center;
      justify-content: center;
      @include borderRadius;
      padding: 8px 10px;
      width: 100%;
      cursor: pointer;
      span {
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbtn;
        color: white;
      }
    }
    &-accept {
      background: $main-color;
      @include HoverBtnBlue;
    }
    &-clear {
      background: $blue-dark-btn;
      @include HoverBtnDark;
    }
  }
  &-fields {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-row {
      display: flex;
      align-items: center;
      @include gapMin;
      &-title {
        display: flex;
        color: $blue;
        gap: 3px;
        @include FSbaseBold;
      }
    }
  }
}
.filter-modal-mobile {
  padding: 10px;
  background-color: #fff;
  position: fixed;
  transform: translateY(500%);
  opacity: 0;
  z-index: 999;
  width: calc(100% - 20px);
  margin: 0 auto;
  height: auto;
  border-top-left-radius: 5px 5px;
  border-top-right-radius: 5px 5px;
  transition: transform 0.4s ease-out;
  &-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 0 10px 0;
    span {
      @include FStitleM;
      color: black;
    }
  }
  &-fields {
    display: flex;
    flex-direction: column;
    @include gapBig;
    padding: 20px 0;
    max-height: calc(100% - 30px);
    overflow-y: visible;
    overflow-y: auto;
    /* Necessary: some browsers (e.g. IE9) can't set the with of the block */
    width: auto;
    // max-height: 14em;
    max-height: calc(100% - 100px);
    height: 99%;
  }
  &-active {
    top: 70px;
    left: 5px;
    right: 5px;
    bottom: 0;
    transform: translateY(0);
    opacity: 1;
    transition: transform 0.4s ease-out;
  }
  &-btns {
    position: fixed;
    width: calc(100% - 20px);
    bottom: 0;
    display: flex;
    justify-content: center;
    @include gapBig;
    // padding: 10px 0;
    // height: 60px;
    padding-bottom: 20px;
    background-color: #fff;
  }
}
