@import "../../styles/variables";

.searchModal {
  display: flex;
  border: 1px solid $blue-m-bg;
  background-color: $blue-m-bg;
  @include borderRadius;
  position: relative;
  width: 100%;
  height: 45px;
  padding: 12px 10px;
  @include _2000 {
    padding: 8px 10px;
    height: 40px;
  }
  @include _1700 {
    padding: 6px 8px;
    height: 30px;
  }
  @include FSbaseBold;
  &::placeholder {
    color: $blue-m-text;
    @include FSbaseReg;
  }
  &-icon-search {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
    @include FSbaseBold;
    cursor: pointer;
    > div {
      > svg {
        width: 22px;
        height: 22px;
      }
    }
    @include _1700 {
      > div {
        > svg {
          width: 18px;
          height: 18px;
        }
      }
    }
    @include _1300 {
      top: 8px;
      > div {
        > svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    @include _1300 {
      top: 7px;
    }
    // @include HoverIcon;
  }
  &-icon-close {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: all 0.4s;
    &-active {
      opacity: 1;
      // @include HoverIcon;
    }
    > div {
      > svg {
        width: 18px;
        height: 18px;
      }
    }
    @include _1700 {
      > div {
        > svg {
          width: 14px;
          height: 14px;
        }
      }
    }
    @include _1300 {
      top: 50%;
      > div {
        > svg {
          width: 14px;
          height: 14px;
        }
      }
    }
  }
  input {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    @include borderRadius;
    // padding: 9px 40px 9px 10px;
    font-family: "Montserrat";
    @include FSbaseBold;
    width: 100%;
    &::placeholder {
      @include FSbaseReg;
    }
  }
}
