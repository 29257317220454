@import "../../../../../styles/variables";

.modalAddDepartment {
  &__form {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-content {
    &:nth-child(1) {
      border-bottom: 2px solid #7bc7f1;
      padding-bottom: 20px;
    }
    &:last-child {
      padding-top: 20px;
      padding-bottom: 0px;
    }
    &-select {
      margin-top: 20px;
      width: 32%;
    }
  }
  // &__btn-wrapper {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  // }
}
