@import "src/styles/_variables.scss";

.layersMobile {
  width: 100%;
  padding-bottom: 60px;
  &-rows {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
  &-container {
    display: grid;
    grid-template-columns: 1fr 300px;
    gap: 10px;
    &-plan-zone {
      overflow-x: hidden;
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;

      // height: 70vh;
    }
    @include _768 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}
