@import "./src/styles/variables.scss";

.addedCatalogWork {
  display: flex;
  flex-direction: column;
  @include gapBig;

  &-title {
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
  }
  &-content {
    border: 1px solid $blue-m-bg;
    @include borderRadius;
    background-color: $blue-m-bg;
    height: calc(100dvh - 250px);
  }
  &-grid {
    padding: 10px;
    height: fit-content;
    @include cardColumnGrid;
    gap: 5px;
    margin-bottom: 0;
    @include _1024 {
      @include cardColumnGridMobile;
    }
  }
  &-empty {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      @include FSh1;
      margin-bottom: 20px;

      color: $blue;
      opacity: 0.5;
      @include _1024 {
        @include FStitleM;
      }
    }
  }
}
