@import "src/styles/variables";

.windowChatfooter {
  display: flex;
  flex-direction: column;
  padding: 10px 10px;
  border-top: 1px solid $grey;

  @include _1024 {
    padding: 10px;
  }
  &-bottom {
    width: 100%;
    display: flex;
    gap: 10px;
    @include _1024 {
      width: 100%;
      flex-direction: column;
    }
    &-btns {
      display: flex;
      justify-content: flex-end;
      gap: 10px;
      height: 38px;
    }
  }
  img {
    cursor: pointer;
    @include SmdBtn;
  }
  .input {
    // filter: none;
    // border: 1px solid #e8e8e8;
    // overflow-y: auto;
    width: 100%;
    input {
      // height: 90px;
      font-weight: normal;
      display: flex;
      align-items: flex-start;
    }
  }
  input[type="file"] {
    display: none;
  }
  &-files {
    left: 20px;
    bottom: 100px;
    width: 100%;
    &-list {
      padding: 2px;
      max-height: 190px;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(270px, 1fr));
      margin-bottom: 10px;
      gap: 10px;
      .docItem {
        width: 100%;
        max-width: none;
      }
    }
  }
  &-forward {
    position: relative;
    &-count {
      position: absolute;
      top: -15px;
      left: -7px;
      width: 18px;
      height: 18px;
      background-color: #0094e7;
      border-radius: 50%;
      @include flexAllCenter();
      span {
        @include FSxsBoldM;
        color: white;
      }
    }
  }
  &-submit-btn {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include _2000 {
      height: 40px;
      width: 40px;
    }
    @include _1700 {
      height: 35px;
      width: 35px;
    }
    @include HoverBtnBlue;
    @include borderRadius;
  }
  .textarea-input {
    height: 90px;
    textarea {
      height: 100%;
    }
  }
}
