@import "src/styles/_variables.scss";

.meetingMinutesMobile {
  width: 100%;
  padding-bottom: 60px;
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    @include FSbaseBoldM;
    padding: 10px 0 10px 0;
  }
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
  &-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
