@import "../../styles/variables";
.layout {
  // width: 100%;
  // height: 100vh;
  // background-color: $background-color-content;
  // display: flex;
  // flex-direction: column;
  display: flex;
  background-color: $background-color;
  height: 100%;
  align-items: center;
  position: relative;
  padding-top: 20px;
  @include _1700 {
    padding-top: 10px;
  }
  &-content {
    // @include flexGap(20px, col);
    display: flex;
    flex-direction: column;
    background-color: $background-color-content;
    width: 100%;
    border-radius: 10px 0px 0px 0px;
    height: 100%;
    overflow: hidden;
    position: relative;
    // padding-bottom: 50px;
    &-main {
      @include flexGap(20px, col);
      height: 100%;
    }
  }
}
.main {
  width: 100%;
  height: auto; //!!!
  background-color: $background-color-content;
  padding: 60px 0;
  position: relative;
}
