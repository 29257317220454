@import "../../../../../styles/variables";

.companies {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-row {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-cards {
    display: flex;
    gap: 10px;
    flex-direction: column;
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0 20px 0 10px;
      @include gapMin;
    }
    &-header {
      font-family: "Montserrat";
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 5px;
      grid-template-columns: 
      // 80px
        3fr 2fr 1fr 2fr 2fr 2fr 1fr 20px;
      @include headerWrapper;
      &-item {
        display: flex;
        text-align: center;
        align-items: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-item.number {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;
      }
    }
  }

  &-cardsColumns {
    padding: 0 20px 0 10px;
    @include cardColumnGrid;
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }
}
