@import "../../../../../../../styles/variables";

.profileLogoCompany {
  font-family: "Montserrat";
  font-style: normal;
  padding: 20px 0 20px 0;
  margin: 0 20px 0 10px;
  &-title {
    @include FSsubtitle;
    line-height: 18px;
    margin-bottom: 20px;
    @include _1700 {
      margin-bottom: 10px;
    }
  }
}
