@import "/src/styles/variables";

.projectWorkScheduleColListContent {
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    padding: 0 10px;
    display: grid;
    @include gapMin;
    justify-content: space-between;

    grid-template-columns:
      40px
      3fr
      // minmax(auto, 200px)
      1fr
      1fr
      1fr
      1fr
      1fr
      100px;
    @include _1024 {
      grid-template-columns:
        40px
        3fr
        // minmax(auto, 200px)
        1fr
        1fr
        1fr
        1fr
        1fr
        20px;
    }
    span {
      color: $blue;
      text-align: center;
      @include FSbaseBold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &-number {
      display: flex;
    }
    &-btns-checkbox {
      display: flex;
      padding-left: 50px;
    }
  }
}
