@import "src/styles/_variables.scss";

.notificationsMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 20px;
  &-header-btns {
    display: flex;
    @include gapBig;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.notificationsMobileItem {
  padding: 10px;
  border: 1px solid $grey;
  width: 100%;
  @include borderRadius;
  display: flex;
  align-items: center;
  gap: 10px;
  span {
    width: 100%;
    @include FSbaseRegM;
    display: inline-block;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.disabled {
    opacity: 0.5;
  }
  &-status {
    &-circle {
      background-color: #f3be03;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border-radius: 50%;
      animation: animate_reduce 2s linear;
      animation-iteration-count: 5;
      @include _2000 {
        top: 0px;
        right: 2px;
      }
    }
  }
}
