@import "/src/styles/variables";

.modalStageEdit {
  @include flexGap(20px, col);
  .inputDate {
    width: 100%;
    max-width: 440px;
    @include _1024 {
      max-width: 100%;
    }
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-row {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    @include _1024 {
      column-gap: 10px;
    }
    @include _768 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 10px;
      span {
        @include FSbaseBold;
      }
      @include _400 {
        display: flex;
      }
      &-title {
        color: $blue;
      }
      :last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
  }
  &-works {
    @include flexGap(10px, col);
    &-header {
      display: grid;
      grid-template-columns: 400px 160px 200px 260px 170px minmax(100px, 1fr);
      text-align: center;
      padding-left: 10px;
      &-name {
        text-align: left;
      }
    }
    &-item {
      display: grid;
      align-items: center;
      grid-template-columns: 400px 160px 200px 260px 170px minmax(100px, 1fr);
      text-align: center;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 13px 10px;
      &-name {
        text-align: left;
      }
      img {
        cursor: pointer;
      }
      &-btns {
        display: flex;
        align-items: center;
        gap: 15px;
        justify-self: flex-end;
      }
    }
  }
  &-select {
    width: 100%;
    max-width: 240px;
  }
  &-historyChanges {
    @include flexGap(10px, col);
    padding-bottom: 20px;
  }
  .bold.green {
    color: $light-green;
  }
  .bold.red {
    color: $red;
  }
  .bold.yellow {
    color: $orange;
  }
}
