@import "/src/styles/variables";

.modalViewDoc {
  width: 100%;
}

.myNoRenderer {
  &-text {
    @include FSbaseBold;
  }
}

.react-doc-viewer-modal {
  min-height: 400px;
  width: 100%;
  #header-bar {
    display: none;
  }
}
