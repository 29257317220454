@import "../../../../../styles/variables";

.modalEditAnswerDeliveryWork {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-grid {
    display: grid;
    @include gapBig;
    grid-template-columns: repeat(3, 1fr);
    @include _1300 {
      grid-template-columns: repeat(2, 1fr);
    }
  }

  &-form {
    width: 100%;
    &__list {
      list-style-type: none;
      display: flex;
      margin-bottom: 20px;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include gapBig;
    }
  }
  &__btns {
    width: 100%;
    padding-top: 20px;
    display: flex;
    justify-content: center;
    > div {
      margin: 0 10px 0 10px;
    }
  }
  .inputFile {
    max-width: 100%;
  }
  .inputFile-zone-list {
    max-width: 100%;
  }
  &-result {
    width: 100%;
    &-form {
      &__FileUploader {
        margin-bottom: 20px;
      }
      &__DocItem {
        margin-bottom: 20px;
        width: 100%;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          margin-bottom: 10px;
        }
        > div {
          margin-bottom: 10px;
          max-width: 48.5%;
        }
      }
    }
  }
}
.directive {
  margin-top: 20px;
  border-top: 2px solid #7bc7f1;
  padding-top: 20px;
  &__title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
    margin-bottom: 20px;
  }
  &__date {
    margin-top: 20px;
    width: 30%;
  }
}
