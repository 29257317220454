@import "../../styles/variables";

.profileTabsList {
  font-family: "Montserrat";
  font-style: normal;

  &-header {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    gap: 2px;
    &-item {
      background-color: $blue;
      display: flex;
      flex-direction: column;
      align-items: center;
      &__text {
        @include FSsubtitle;
        line-height: 18px;
        color: #fff;
        padding: 20px 0;
      }
      @include _1024 {
        &:not(:nth-child(1)) {
          display: flex;
          align-items: flex-start;
          justify-content: center;
          writing-mode: vertical-rl;
          transform: rotate(180deg);
        }
      }
      &-title {
        margin-right: 20px;
        width: 264px;
        background: #ffffff;
        box-shadow: 0px 4px 16px #04488e;
        flex-direction: row;
        padding-left: 10px;
        @include _1024 {
          box-shadow: none;
          width: 220px;
          margin-right: 10px;
        }
        > h2 {
          color: $blue;
        }
        > img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
      }
    }
  }
  &__list {
    list-style-type: none;
  }
}
