@import "src/styles/variables";

.accessEdit {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr) 40px;
    gap: 5px;

    &.file {
      grid-template-columns: 2fr repeat(5, 1fr) 40px;
    }

    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-title.name {
      text-align: start;
    }
    span {
      text-align: center;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;

    max-height: 120px;
  }
  &-addedSection {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 38px;
    @include gapMin;
    align-items: end;
    justify-content: space-between;
    &-btn {
      @include borderRadius;
      display: flex;
      align-items: center;
      justify-content: center;
      @include HoverBtnBlue;
      height: 38px;
      width: 38px;
      cursor: pointer;
      // @include _2000 {
      //   width: 32px;
      //   min-height: 32px;
      // }
      // @include _1300 {
      //   width: 30px;
      //   min-height: 30px;
      // }
    }
  }
}

.accessEditMobile {
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
