@import "../../../../styles/variables";

.layoutMobileFooter {
  width: 100%;
  background: rgba(255, 255, 255, 0.8);
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 997;
  bottom: 0;
  position: fixed;
  &-disabled {
    bottom: -60px;
  }
  &-btns {
    gap: 20px;
    position: relative;
    display: grid;
    height: 100%;
    grid-template-columns: repeat(4, 1fr);
    &-item {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
  }
  &-btn-circle {
    width: 40px;
    height: 40px;
    background-color: $main-color;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    // &-arrow {
    //   padding-right: 5px;
    // }
  }
  &-btn-rectangle {
    width: 60px;
    height: 40px;
    border: 1px solid $grey;
    background-color: #fff;
    @include borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-btn-filter {
    width: 60px;
    height: 40px;
    border: 1px solid $grey;
    background-color: #fff;
    @include borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-btn-plus {
    transform: rotate(45deg);
  }
}
