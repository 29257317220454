@import "../../../../styles/variables";

.cardRowProject {
  background: #ffffff;
  // border: 1px solid $grey;
  @include borderRadius;
  // padding: 14.5px 10px;
  align-items: center;
  @include cardRowWrapper;
  display: grid;
  gap: 10px;
  justify-content: space-between;
  grid-template-columns:
    // 80px

    3fr
    1.5fr
    2fr
    1.5fr
    1.5fr
    3fr
    1.5fr
    20px;
  @include _1700 {
    grid-template-columns:
      3fr
      1.5fr
      2fr
      1.5fr
      1.5fr
      3fr
      1.5fr
      20px;
  }
  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-item.taskCount span {
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      color: $blue-dark;
    }
  }
  &-item.fullname {
    color: $main-color-light;
    cursor: pointer;
    @include borderRadius;
    > span {
      display: inline-block;
      text-align: start;
      color: $blue-active;
      @include cardRowPaddingSpan;
    }
    @include HoverBtnRow;
  }
  &-item.dateStart {
    color: $light-green;
  }
  &-item.dateEnd {
    color: $red;
  }
  &-item.btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    overflow: visible;
    img {
      cursor: pointer;
    }
  }
  &-item.number {
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: flex-start;
  }
  &-item.fullname,
  &-item.address {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}
