@import "../../../styles/_variables.scss";

@keyframes bgImage {
  0% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1);
  }
  15% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
  }
  29% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1.1);
  }
  30% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1.1);
  }
  31% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
    transform: scale(1.1);
  }
  45% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
  }
  60% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
    transform: scale(1);
  }
  61% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
    transform: scale(1);
  }
  75% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
  }
  97% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
  }
}

.authPageMobile {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  background: linear-gradient(90deg, $blue-dark 44.92%, rgba(13, 89, 151, 0.8) 100%);
  @media (max-height: 540px) {
    height: auto;
  }
  &-owerflow {
    position: absolute;
    width: 100%;
    height: 100%;
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: -2;
    overflow: hidden;
    // overflow-y: scroll;
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      background-image: url("../../../assets/images/pages/auth/background-form.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: 5s;
      animation: bgImage 25s infinite;
      z-index: -1;
      overflow: hidden;
    }
  }

  &-container {
    max-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 20px;
    z-index: 2;
  }
  &-wrapper {
    max-width: 320px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    // min-height: 700px;
  }

  &-auth {
    // width: 100vw;
    // height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-repeat: no-repeat;
    position: relative;
    &__subtitle {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      color: #ffffff;
      font-size: 16px;
    }
    &-btns {
      display: flex;
      justify-content: center;
      gap: 20px;
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      @include borderRadius;
      //   min-width: 220px;
      height: 40px;
      &-text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #ffffff;
      }
    }
    &-logo {
      margin-top: 16px;
      display: flex;
      justify-content: center;
      > img {
        width: 167px;
        height: 98px;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      width: 100%;
      &-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 40px;
        color: white;
        text-align: center;
        font-size: 25px;
      }
      &-text {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: rgba(255, 255, 255, 0.7);
      }
      &-inputs {
        display: flex;
        flex-direction: column;
        gap: 20px;
        width: 100%;
        &-input {
          display: flex;
          flex-direction: column;
          gap: 10px;
          position: relative;
          &-label {
            color: white;
            font-family: "Montserrat";
            @include FSbaseBold;
          }
          &-input {
            background: white;
            @include borderRadius;
            padding: 10px 10px;
            color: black;
            font-size: 18px;
            font-family: "Montserrat";
            @include FSsubtitle;
            &::placeholder {
              @include FSsubtitle;
              font-family: "Montserrat";
            }
          }
          input {
            padding: 10px 10px;
          }
          &__icon {
            width: 24px;
            height: 24px;
            @include SmdBtn;
            opacity: 0.7;
            transition: opacity 0.2s;
            &:hover {
              opacity: 1;
            }
          }
        }
        i {
          position: absolute;
          top: 59%;
          right: 15px;
          cursor: pointer;
          @include min_768 {
            // top: 45px;
            top: 57%;
          }
        }
      }
      &-btnSub {
        color: white;
        background-color: $main-color;
        @include borderRadius;
        padding: 8px 10px;
        max-width: 231px;
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbtn;
      }
      &-btnResetPasw {
        font-family: "Montserrat";
        @include FSbaseReg;
        letter-spacing: 0.03em;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
      }
      &-confirm {
        display: flex;
        gap: 10px;
        .checkbox {
          &-input.check::after {
            content: url("../../../assets/icons/checkMarkCheckboxWhite.svg");
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            zoom: 1.1;
          }
        }
        &-text {
          font-family: "Montserrat";
          @include FSxsReg;
          color: rgba(255, 255, 255, 0.829);
          &-link {
            color: rgba(255, 255, 255, 0.829);
          }
        }
      }
    }
  }
  &-links {
    display: flex;
    justify-content: center;
    column-gap: 20px;
    row-gap: 10px;
    flex-wrap: wrap;
    max-width: 80%;
  }
  &-link {
    a {
      display: flex;
      align-items: center;
      gap: 10px;
      font-family: "Montserrat";
      @include FSxsReg;
      color: rgba(255, 255, 255, 0.829);
      :active {
        color: #fff;
      }
    }
  }
  &-copyrigh {
    display: flex;
    align-items: center;
    gap: 10px;
    font-family: "Montserrat";
    margin-top: 10%;
    &-text {
      color: #ffffff;
      padding-top: 3px;
    }
    &-logo {
      height: 25px;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    gap: 2px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: 20px;
    &-text {
      font-size: 10px;
      color: $grey;
    }
  }
}
