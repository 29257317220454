@import "./src/styles/variables";

.modalEventList {
  display: flex;
  flex-direction: column;

  @include gapBig;
  &-title {
    @include FSbaseBold;
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-item {
      display: flex;
      flex-direction: column;
      @include gapBig;
      @include borderRadius;
      border: 1px solid $grey;
      padding: 10px;
      &-title {
        @include FSbaseBold;
        color: $blue;
      }
      &-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        img {
          cursor: pointer;
        }
        &-name {
          @include FSbaseBold;
        }
        border-bottom: 1px solid $main-color;
      }
      &-person {
        display: flex;
        flex-direction: column;
        @include gapMin;
        &-title {
          @include FSbaseBold;
        }
        &-text {
          @include FSbaseReg;
        }
        &-logo {
          border-radius: 50%;
          background-size: cover;
          background-position: center;
          @include XSavatar;
        }
        &-info {
          display: flex;
          flex-direction: column;
          @include gapMin;
        }
      }
      &-particList {
        display: flex;
        @include gapMin;
        flex-wrap: wrap;
        @include borderRadius;
        border: 1px solid $grey;
        padding: 12px 10px;
      }
    }
  }
}
