@import "./src/styles/_variables.scss";

.tasksRowHeader {
  width: 100%;
  font-family: "Montserrat";
  list-style-type: none;
  width: 100%;
  margin: 0;
  display: grid;
  @include gapMin;
  @include headerRow;
  padding-right: 10px;
  position: relative;
  grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 40px;
  @include _1900 {
    grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
  }
  @include _1455 {
    grid-template-columns: 3fr 1fr 1fr 1.2fr 1.2fr 140px 1fr 40px;
  }
  @include _1300 {
    grid-template-columns: 3fr 1fr 1fr 1.2fr 1.2fr 60px 1fr 40px;
  }
  &.edit {
    grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 40px;
    @include _1900 {
      grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
    }
    @include _1455 {
      grid-template-columns: 20px 3fr 1fr 1fr 1.2fr 1.2fr 140px 1fr 40px;
    }
    @include _1300 {
      grid-template-columns: 20px 3fr 1fr 1fr 1.2fr 1.2fr 60px 1fr 40px;
    }
  }
  &-item {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
    > span {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
      vertical-align: middle;
      height: auto;
      display: inline-block;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-item.numder-item {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    @include gapBig;
  }
}
