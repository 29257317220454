@import "./src/styles/variables.scss";

.settingElementCard {
  @include borderRadius;
  padding: 6px;
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  @include _1024 {
    padding: 10px;
  }
  &-wrapper {
    display: flex;
    gap: 5px;
    align-items: center;
    text-overflow: ellipsis;
    display: flex;
    justify-content: space-between;
    max-width: 85%;
  }
  &-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    // width: 90%;
    @include FSbaseReg;
  }
  &-btns {
    display: flex;
    gap: 5px;
    @include _1024 {
      gap: 10px;
    }
  }
}
