@import "./src/styles/variables";

.modalInfoEvent {
  display: flex;
  flex-direction: column;
  @include gapBig;
  .select,
  .address-input,
  .selectMulti {
    width: 100%;
    max-width: 440px;
  }
  .address-input {
    // .input-icon {
    //   bottom: 5px;
    // }
  }
  .w-440 {
    width: 440px;
  }
  &-participants {
    > div {
      max-width: 100% !important; // временно
    }
  }
  &-btns {
    display: flex;
    justify-content: center;
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
    font-family: "Montserrat";
    &-box {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
    &-section-title {
      @include FSsubtitle;
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-bold {
      @include FSbaseBold;
    }
    &-text {
      @include FSbaseReg;
    }
    &-row {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
    &-grid {
      display: grid;

      @include gapBig;
      grid-template-columns: repeat(3, 1fr);
      @include _768 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _600 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _600 {
          display: flex;
          flex-direction: row;
        }
        @include _400 {
          &.date {
            justify-content: space-between;
          }
        }
      }
    }

    &-gridLists {
      display: grid;
      @include gapBig;
      display: flex;
      flex-direction: column;

      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
    }
    &-fileList {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-grid {
    @include gapBig;

    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  &-person {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-logo {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }
    &-info {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-participantsList {
    display: flex;
    flex-wrap: wrap;
    @include gapMin;
    padding: 12px 10px;
    border: 1px solid $grey;
    @include borderRadius;
    &-item {
      display: flex;
      @include gapMin;
      align-items: center;
      @include FSbaseReg;
      img {
        cursor: pointer;
      }
    }
  }

  &-file {
    @include flexGap(10px, col);
    width: 400px;
    &-fileList {
      padding: 2px;
      max-width: 440px;
      max-height: 190px;
      @include flexGap(5px, col);
      .docItem {
        width: 100%;
        max-width: none;
      }
    }
  }
  &-tasks {
    @include flexGap(10px, col);
    &-header {
      align-items: center;
      display: grid;
      padding: 0 10px 0 10px;
      gap: 5px;
      grid-template-columns:
        minmax(auto, 100px)
        minmax(auto, 382px)
        minmax(auto, 370px)
        minmax(auto, 200px)
        minmax(auto, 190px)
        minmax(auto, 158px)
        minmax(auto, 133px)
        minmax(67px, 1fr);
      &-item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        justify-content: center;

        span {
          font-family: "Montserrat";
          @include FSbaseBold;
          color: $blue;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-item.number {
        display: flex;
        align-items: center;
        gap: 25px;
        justify-content: flex-start;
      }
    }
    &-item {
      @include flexGap(10px, col);
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 8px 10px;
    }
    &-subitem {
      @include flexGap(10px, col);
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    &-btn {
      @include flexAllCenter();
      max-width: 44px;
      justify-content: center;
      background: #0094e7;
      @include borderRadius;
      padding: 5px 10px;
      height: 34px;
      cursor: pointer;
    }
    &-menuImport {
      position: absolute;
      bottom: -70px;
      right: 0;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 10px;
      @include flexGap(10px, col);
      span {
        @include FSmdMed;
        white-space: nowrap;
        cursor: pointer;
        &:hover {
          transition: all 0.2s;
          color: $main-color-light;
        }
      }
    }
  }
}
