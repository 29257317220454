@import "/src/styles/variables";

.skeletonGrid {
  padding: 0px 20px 0 10px;
  @include cardColumnGrid;
  @include _1024 {
    @include cardColumnGridMobile;
    padding: 10px 0 10px 0;
  }
  &-card {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 10px;
    height: 340px;
    border: 1px solid $main-color-ultra-light;
    @include borderRadius;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    &-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 10px;
      width: 100%;
    }
    &-header {
      width: 100%;
    }

    &-main,
    &-footer {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
    }
  }
}
