@import "../../../../../styles/_variables.scss";

.deliveryWork {
  &-requests {
    width: 100%;
    height: 100%;
    padding: 0 20px 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 20px;
    &__header {
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 5px;
      grid-template-columns:
        // 80px

        2fr
        2fr
        2fr
        2fr
        2fr
        1.5fr
        3fr
        90px;
      padding: 0 10px 0 10px;
    }
    &-header {
      padding-bottom: 10px;
      &__item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        align-items: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &-numder {
          justify-content: flex-start;
          > span {
            margin-left: 20px;
          }
        }
      }
    }
    &__list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &__pagination {
      display: flex;
      justify-content: center;
      padding-top: 20px;
      @include _1700 {
        padding-top: 10px;
      }
    }
  }
}
