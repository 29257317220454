@import "../../styles/variables";
.chatRow {
  display: flex;
  flex-direction: column;
  @include gapMin;
  background-color: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 10px;
  span {
    font-family: "Montserrat";
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
    display: flex;
    justify-content: space-between;
    &-content {
      display: flex;
      align-items: center;
      @include gapMin;
    }
    &-icon {
      &-rotate {
        transform: rotate(180deg);
      }
    }
  }
  &-list {
    list-style-type: none;
  }

  &-window {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-input {
      width: 100%;
    }
    &-messages {
      display: flex;
      flex-direction: column;
      // @include gapBig;
      overflow-y: scroll;
      height: fit-content;
      max-height: 300px;
      // background-color: $blue-m-bg;
      height: fit-content;
      padding: 10px 0 10px 0;
      // background-color: $main-color-ultra-light;
      border-radius: 6px;
      &-noMassages {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 20px;
        span {
          @include FSbaseReg;
          color: $grey;
        }
      }
    }
    &-textZone {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      @include gapMin;
      border-radius: 0 0 10px 10px;
      background-color: white;
      border-top: 2px solid #f6f6f6;
      width: 100%;
      padding-top: 10px;
      &-footer {
        width: 100%;
        display: flex;
        @include gapMin;
      }
      &-btns {
        display: flex;
        @include gapMin;
        overflow: visible;

        label {
          display: flex;
          align-items: center;
        }
        img {
          cursor: pointer;
        }
        // .button {
        //   width: 222px;
        // }
        &-submit-btn {
          height: 45px;
          width: 45px;
          display: flex;
          justify-content: center;
          align-items: center;
          @include _2000 {
            height: 40px;
            width: 40px;
          }
          @include _1700 {
            height: 35px;
            width: 35px;
          }
          @include HoverBtnBlue;
          @include borderRadius;
        }
      }
      &-files {
        width: 100%;
        max-height: 190px;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        @include gapMin;
        margin-bottom: 10px;
      }
    }
  }
  &-btn-comment {
    &.countComment {
      position: relative;
      overflow: visible;
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        content: attr(data-count);
        top: -8px;
        padding: 2px;
        left: -8px;
        // transform: translate(-50%);
        background-color: $yellow;
        border-radius: 50%;
        min-width: 16px;
        height: 16px;
        color: #fff;
      }
      &[data-count=""]::after {
        color: #fff;
      }
    }
  }
}
