@import "./src/styles/variables";

.cardLayers {
  @include gapMin;
  padding: 10px 8px;
  @include cardColumnWrapper;
  span {
    font-family: "Montserrat";
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    &-title {
      @include FSsubtitle;
      @include _1024 {
        @include FSbaseBoldM;
      }
    }
    img {
      width: 5px;
      cursor: pointer;
      @include SmdBtn;
    }
    &-events {
      position: absolute;
      top: 30px;
      width: 100%;
      display: flex;
      flex-direction: column;
      @include gapMin;
      padding: 10px;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      span {
        @include FSbaseMed;
        cursor: pointer;
      }
      @include HoverModalText;
    }
  }
  &-plan {
    @include borderRadius;
    background-size: cover;
    height: 190px;
    @include _2000 {
      height: 170px;
    }
    @include _1024 {
      height: 140px;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-name {
      // font-weight: 700px;
      // font-size: 18px;
      @include FSbaseBold;
      @include nowrap;
    }
    &-doc {
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include gapMin;
      width: 100%;
      padding: 10px;
      border: 1px solid $grey;
      @include borderRadius;
      &-name {
        @include FSbaseReg;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        cursor: pointer;
        @include SmdBtn;
      }
    }
    &-tasks {
      display: flex;
      gap: 60px;
      &-desc {
        display: flex;
        flex-direction: column;
        @include gapMin;
        span {
          font-family: "Montserrat";
        }
        &-title {
          @include FSbaseBold;
        }
        &-li {
          display: flex;
          align-items: center;
          gap: 5px;
          // cursor: pointer;
          &-circle {
            border-radius: 50%;
            width: 10px;
            height: 10px;
          }
          span {
            @include FSmdMed;
          }
        }
        &-li.completed {
          div {
            background-color: $completed;
          }
          span {
            color: $completed;
          }
        }
        &-li.atWork {
          div {
            background-color: $atWork;
          }
          span {
            color: $atWork;
          }
        }
        &-li.onVerification {
          div {
            background-color: $onVerification;
          }
          span {
            color: $onVerification;
          }
        }
        &-li.overdue {
          div {
            background-color: $overdue;
          }
          span {
            color: $overdue;
          }
        }
        &-li.rejected {
          div {
            background-color: $rejected;
          }
          span {
            color: $rejected;
          }
        }
        &-li.assigned {
          div {
            background-color: $assigned;
          }
          span {
            color: $assigned;
          }
        }
      }
    }
  }
}
