@import "src/styles/variables";

.deliveryWorkInfo {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
  }
  &-span {
    display: inline-block;
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    color: $blue;
  }
  &-text {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    line-height: 16px;
    &-description {
      font-weight: 400;
      line-height: 20px;
    }
    &-position {
      font-weight: 400;
    }
  }
  &-row {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _600 {
      display: flex;
      flex-direction: column;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-engineer {
        display: flex;
      }
      &__DocItem {
        > div {
          max-width: calc(50% - 20px);
          @include _600 {
            max-width: 100%;
          }
        }
      }
    }
  }
  &-author {
    @include gapMin;
    &-info {
      display: flex;
      @include gapMin;
    }

    &-avatar {
      @include XSavatar;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
    &-fio {
      @include FSbaseBold;
      font-family: "Montserrat";
    }
    &-position {
      @include FSbaseReg;
      font-family: "Montserrat";
    }
  }
}
.deliveryWorkEdit {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
