@import "src/styles/variables";

.windowChatHeader {
  padding: 10px;
  border-bottom: 1px solid $grey;
  display: grid;
  grid-template-columns: 1fr 90px;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  position: relative;
  min-height: 70px;
  &-info {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    display: grid;
    grid-template-columns: 45px 1fr;
    @include _1700 {
      grid-template-columns: 30px 1fr;
    }
    &-wrapper {
      width: 90%;
      width: 100%;
      @include nowrap;
    }
    &-title {
      width: 100%;
      @include FSbaseBold;
      position: relative;
      @include nowrap;
    }
    &-title.group {
      transform: translateX(-40px);
    }
    &-title.personal.online {
      &::after {
        content: "";
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: -20px;
        width: 11px;
        height: 11px;
        border-radius: 50%;
        background-color: $light-green;
      }
      @include _768 {
        &::after {
          display: none;
        }
      }
    }
    &-logo {
      &-list {
        display: flex;
        align-items: center;
        &-countMembers {
          height: 25px;
          width: 25px;
          border-radius: 50%;
          border: 1px solid #8c9ea5;
          background-color: #f5f9fb;
          @include flexAllCenter();
          margin-left: -10px;
          z-index: 1;
          span {
            @include FSxsReg;
            color: #8c9ea5;
          }
        }
      }
      &-img {
        @include XSavatar;
        border: 1px solid white;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        position: relative;
        // @include _768 {
        //   &::after {
        //     content: "";
        //     position: absolute;
        //     top: 5px;
        //     transform: translateY(-50%);
        //     left: 0px;
        //     width: 11px;
        //     height: 11px;
        //     border-radius: 50%;
        //     background-color: $light-green;
        //   }
        // }
      }
      &-img:not(:first-child) {
        margin-left: -10px;
      }
    }
    &-userList {
      z-index: 100;
      position: absolute;
      top: 60px;
      padding: 10px 5px 10px 10px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      max-height: 200px;
      overflow-y: scroll;
      @include borderRadius;
      border: 1px solid #c0c0c0;
      background-color: white;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      &-item {
        display: flex;
        width: 100%;
        background-color: #fff;
        height: 30px;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 10px;
        justify-content: space-between;
        align-items: center;
        margin-right: 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;

        > span {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 16px;
          color: #000000;
          margin-right: 10px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  &-menu {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 10px;
  }
}

.windowChatHeader-info.group {
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
  width: 100%;
  overflow: hidden;
  .windowChatHeader-info-logo-img {
    height: 25px;
    width: 25px;
  }
}
