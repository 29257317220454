@import "/src/styles/variables";

.deliveryWorkRequestsMobileCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $grey;
  @include borderRadius;
  background-color: #fff;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    margin: 0 10px;
    flex-grow: 1;
    &-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: top;
      &-check {
        display: flex;
        gap: 10px;
        align-items: center;
        span {
          @include FSbaseRegM;
        }
      }
      &-text {
        @include FSbaseRegM;
      }
    }
    &-img {
      @include AvatarM;
    }
    &-title {
      display: flex;

      align-items: center;
      height: 100%;
      @include FSbaseBoldM;
      text-align: center;
    }
    border-bottom: 1px solid $main-color;
  }
  &-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      display: flex;
      justify-content: space-between;
      &-title {
        white-space: nowrap;
        @include FSbaseRegM;
        margin-right: 5px;
      }
      &-text {
        @include FSbaseBoldM;
        text-align: end;
      }
    }
  }
}
