@import "/src/styles/variables";

.modalAddDoc {
  span {
    font-family: "Montserrat";
  }
  display: flex;
  flex-direction: column;
  @include gapBig;
  align-items: center;
  @include _1024 {
    padding-bottom: 60px;
  }
  &-main {
    display: flex;
    flex-direction: column;
    width: 100%;
    @include gapBig;
    @include _1024 {
      @include gapMin;
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
    .sc-bczRLJ.kiHXWb {
      max-width: none;
      width: 100%;
      height: 158px;
      .sc-dkzDqf.fVfcRr {
        display: flex;
        justify-content: center;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      @include gapBig;
      align-items: center;
      @include _1024 {
        @include gapMin;
      }

      &-choose {
        width: 100%;
        @include flexGap(20px, col);
      }
      &-file {
        width: 100%;
        display: flex;
        flex-direction: column;
        @include gapMin;
        &-title {
          @include FSbaseBold;
          color: $blue;
        }
        &-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          @include gapMin;
          @include _1024 {
            grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
          }
        }
      }
    }
  }
}
