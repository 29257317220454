@import "/src/styles/variables";

.canvasPlanWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  &-btns {
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    left: 20px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-scale {
      div {
        svg {
          fill: #fff;
        }
      }
      &.clear {
        div {
          svg {
            width: 16px;
            height: 16px;
            @include _2000 {
              width: 12px;
              height: 12px;
            }
            @include _1700 {
              width: 10px;
              height: 10px;
            }
          }
        }
      }
    }
  }
  &-draw {
    display: flex;
    position: relative;
  }

  // &-canvasPlan {
  //   margin-top: 10px;
  //   position: absolute;
  //   @include borderRadius;
  //   width: 100%;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   overflow: hidden;
  //   top: 0;
  // }
}
