@import "src/styles/variables";

.modalAddDeliveryWorkMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    @include min_600 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }
  }
}
