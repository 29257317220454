@import "src/styles/variables";

.analyticsInfoChart {
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    padding: 20px 0 60px 0;
  }
  &-searchPanel,
  &-reports,
  &-map,
  &-charts,
  &-list {
    padding: 0 20px 0 10px;
    @include _1024 {
      padding: 0;
    }
  }
  &-charts {
    display: grid;
    @include gapBig;
    grid-template-areas:
      "block1 block2"
      "block3 block3";
    grid-template-columns: 1fr 1fr;
    &-completedTasks {
      grid-area: block1;
    }
    &-completedTasksStages {
      grid-area: block2;
    }
    &-closedProjects {
      grid-area: block3;
    }
    @include _1300 {
      display: flex;
      flex-direction: column;
    }
  }
  &-filter {
    display: flex;
    justify-content: space-between;
  }
  //   &-addChart {
  //     display: flex;
  //     flex-direction: column;
  //     gap: 20px;
  //     &-content {
  //       width: 100%;
  //       display: grid;
  //       grid-template-columns: 250px 1fr;
  //       gap: 20px;
  //       padding: 0 10px;
  //       @include _1700 {
  //         display: flex;
  //         flex-direction: column;
  //       }
  //       &-panel {
  //         background: white;
  //         border: 1px solid $grey;
  //         @include borderRadius;
  //         padding: 20px 10px;
  //         display: flex;
  //         flex-direction: column;
  //         width: 100%;
  //         max-width: 264px;
  //         gap: 20px;
  //         height: fit-content;
  //         @include _1700 {
  //           // flex-direction: row;
  //           max-width: 100%;
  //         }
  //         &-title {
  //           font-family: "Montserrat";
  //           @include FSbaseBold;
  //           color: $blue;
  //           text-align: center;
  //         }
  //         &-list {
  //           display: flex;
  //           flex-direction: column;
  //           gap: 10px;
  //           @include _1700 {
  //             display: grid;
  //             grid-template-columns: repeat(8, 1fr);
  //             flex-direction: row;
  //             width: 100%;
  //           }
  //           &-item {
  //             display: flex;
  //             gap: 10px;
  //             background: white;
  //             align-items: center;
  //             padding: 13px 10px;
  //             border: 1px solid $grey;
  //             @include borderRadius;
  //             cursor: pointer;
  //             span {
  //               font-family: "Montserrat";
  //               @include FStitleRow;
  //               color: $blue;
  //             }
  //             @include HoverBtnAnal;
  //             @include _1700 {
  //               padding: 5px 10px;
  //             }
  //           }
  //           &-item.active {
  //             background-color: $blue;
  //             background: linear-gradient($blue, $blue);
  //             border: 1px solid $blue;
  //             > span {
  //               color: white;
  //             }
  //             svg {
  //               fill: white;
  //             }
  //           }
  //         }
  //       }
  //       &-form {
  //         width: 100%;
  //       }
  //     }
  //   }
}
