@import "src/styles/variables";

.accessInfoMobileCardElem {
  display: flex;
  @include gapMin;
  padding: 10px;
  @include borderRadius;
  border: 1px solid $blue-m-bg;
  align-items: center;
  display: grid;
  grid-template-columns: 15px 1fr;
  &.edit {
    grid-template-columns: 15px 1fr 35px;
  }

  &-title {
    @include FSbaseRegM;
  }
}
