@import "../../styles/variables";

.modalParticipant {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 2px;
    width: 100%;
  }
  &-main {
    // display: grid;
    // grid-template-columns: 1fr auto;
    // @include gapBig;
    // @in
    &-info {
      display: flex;
      flex-direction: column;
      @include gapBig;
      .hr.mw-920 {
        max-width: 920px;
      }
      &-form {
        display: flex;
        @include gapBig;
        &-col {
          width: 100%;
          display: flex;
          flex-direction: column;
          @include gapBig;
        }
      }
    }
    &-avatar {
      display: flex;
      flex-direction: column;
      @include gapMin;
      width: 100%;
      // max-width: 366px;
      .imageUpload__btns {
        background-color: white;
      }
      &-img {
        background-size: cover;
        background-position: center;
        @include borderRadius;
        overflow: hidden;
        width: 100%;
        // height: 326px;
        @include ProfileAvatar;
      }
      &-title {
        @include FSbaseBold;
        color: $blue;
      }
      .imageUpload-profileAvatar {
        margin-bottom: 0;
      }
      @include _768 {
        align-items: center;
      }
    }
    &-basicInfo {
      display: flex;
      flex-direction: column;
      @include gapBig;

      @include _768 {
        display: flex;
        flex-direction: column-reverse;
      }
      &-wrapper {
        display: grid;
        @include gapBig;
        grid-template-columns: 1fr 160px;
        width: 100%;
        @include gapBig;
        @include _768 {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      &-title {
        @include FSsubtitle;
      }
      &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        @include gapBig;
        @include _600 {
          display: flex;
          flex-direction: column;
        }
        &-col {
          display: flex;
          flex-direction: column;
          @include gapMin;
          @include _600 {
            flex-direction: row;
          }
          &-item {
            display: flex;
            flex-direction: column;
            @include gapMin;

            span {
              font-weight: 700;
            }
            &-title {
              @include FSbaseBold;
              color: $blue;
            }
            &-value {
              @include FSbaseBold;
            }
          }
        }
      }
    }
    &-projects {
      display: flex;
      flex-direction: column;
      @include gapBig;
      &-title {
        @include FSbaseBold;
      }
      &-cards {
        display: flex;
        flex-wrap: wrap;
        @include gapBig;
        &-card {
          display: flex;
          align-items: center;
          justify-content: space-between;
          @include gapMin;
          max-width: 350px;
          padding: 14.5px 10px;
          border: 1px solid $grey;
          @include borderRadius;
          max-width: 440px;
          width: 100%;
          span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          img {
            cursor: pointer;
          }
        }
      }
    }
  }
}
