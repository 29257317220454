@import "./src/styles/_variables.scss";

.structures {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  &-columns {
    padding: 20px 0;
    @include _1024 {
      padding: 0;
    }
  }
  &-noCompany {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &-title {
      @include FSsubtitle;
      color: $blue;
    }
  }
}
