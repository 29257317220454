@import "/src/styles/variables";

.tasksRowCard {
  display: flex;
  flex-direction: column;
  @include gapMin;
  justify-content: center;

  &-list {
    @include cardRowWrapper;
    list-style-type: none;
    @include borderRadius;
    @include gapMin;
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 40px;
    @include _1900 {
      grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
    }
    @include _1900 {
      grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
    }
    @include _1455 {
      grid-template-columns: 3fr 1fr 1fr 1.2fr 1.2fr 140px 1fr 40px;
    }
    @include _1300 {
      grid-template-columns: 3fr 1fr 1fr 1.2fr 1.2fr 70px 1fr 40px;
    }
    &.editMode {
      grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 40px;
      @include _1900 {
        grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
      }
      @include _1900 {
        grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 40px;
      }
      @include _1455 {
        grid-template-columns: 20px 3fr 1fr 1fr 1.2fr 1.2fr 140px 1fr 40px;
      }
      @include _1300 {
        grid-template-columns: 20px 3fr 1fr 1fr 1.2fr 1.2fr 60px 1fr 40px;
      }
    }
  }
  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-check {
      display: flex;
      @include gapBig;
      align-items: center;
      justify-content: flex-start;
    }
    // &-author {
    //   color: $blue-dark;
    // }
    &-task {
      @include borderRadius;
      overflow: hidden;
      align-self: center;
      cursor: pointer;
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-period {
      display: flex;
      justify-content: center;
      gap: 2px;
      span {
        @include FSbaseBold;
      }
    }
    // &-startDate {
    //   color: $light-green;
    // }
    &-nameProject {
      > span {
        text-align: start;
      }
    }
    // &-endDate {
    //   color: $red;
    // }
    &-day {
      width: 100%;

      &.atWork {
        background-color: #04498e19;

        align-items: center;
        @include borderRadius;
        span {
          color: $atWork;
          @include cardRowPaddingSpan;
        }
      }
      &.overdue {
        background-color: #db1f3516;

        align-items: center;
        @include borderRadius;
        span {
          color: $overdue;
          @include cardRowPaddingSpan;
        }
      }
    }
  }
  &-list.isImpotrant {
    .tasksRowCard-item-task {
      padding-left: 30px;
      &::before {
        content: url("../../assets/icons/fire.svg");
        position: absolute;
        width: 24px;
        height: 24px;
        top: 50%;
        transform: translateY(-40%);
        left: 10px;
        @include _1700 {
          width: 22px;
          height: 22px;
        }
      }
    }
  }
  &-list.commentsCount {
    .tasksRowCard-item-task {
      padding-left: 30px;
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        content: attr(data-count);
        top: 50%;
        padding: 2px;
        left: 20px;
        transform: translate(-50%, -50%);
        background-color: $main-color;
        border-radius: 50%;
        min-width: 16px;
        height: 16px;
        color: #fff;
        @include FSbaseReg;
        @include _1700 {
          min-width: 14px;
          height: 14px;
        }
      }
      &[data-count=""]::after {
        color: #fff;
      }
    }
  }
  &-list.isImpotrant.commentsCount {
    .tasksRowCard-item-task {
      padding-left: 50px;
      &::after {
        left: 40px;
      }
    }
  }
  &-btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    @include gapMin;
    overflow: visible;
  }
  &-btn {
    background-color: inherit;
    cursor: pointer;
  }
  &-subtasks {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include gapMin;
    // border-left: 2px solid $grey-blue-dark;
    border-radius: 1px;
    padding: 0 0 0 10px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0px;
      border-left: 2px solid #6e9cb4;
      width: 5px;
      height: 100%;
      border-bottom: 2px solid #6e9cb4;
      border-radius: 0 0 0 2px;
      -webkit-border-radius: 0 0 0 2px;
    }
  }
  .atWork {
    color: $atWork;
  }
  .rejected {
    color: $rejected;
  }
  .completed {
    color: $completed;
  }
  .onVerification {
    color: $onVerification;
  }
  .overdue {
    color: $overdue;
  }
  .assigned {
    color: $assigned;
  }
}
