@import "../../../../../styles/variables";

.employeesList {
  display: flex;
  flex-direction: column;

  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-content {
    padding-top: 20px;
    @include _1700 {
      padding-top: 10px;
    }
  }
  &-row {
    width: 100%;
    padding: 0 20px 0 10px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    @include gapBig;
    @include _2000 {
      height: calc(100vh - 290px);
    }
    @include _1700 {
      height: calc(100vh - 210px);
    }
    @include _1300 {
      height: calc(100vh - 200px);
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
      position: relative;
    }
    &-header {
      font-family: "Montserrat";
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 10px;
      align-items: center;
      padding: 0 10px;
      grid-template-columns:
        // 20px

        2fr
        1.5fr
        1.5fr
        2fr
        2fr
        // 1fr
        // 2fr
        0.8fr
        20px;

      // @include headerRow;
      @include _1700 {
        grid-template-columns:
          2fr
          1.5fr
          1.5fr
          2fr
          2fr
          0.8fr
          20px;
      }
      @include headerWrapper;
      padding-right: 10px;
    }
    &-header {
      &-item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-item.numder-item {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        @include gapBig;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    @include _1700 {
      padding-top: 10px;
    }
  }
  &-grid {
    height: calc(100vh - 300px);
    padding: 0 20px 0 10px;
    @include _1700 {
      height: calc(100vh - 210px);
    }
    @include _1300 {
      height: calc(100vh - 200px);
    }
    &-list {
      @include cardColumnGrid;
    }
  }
}
