@import "/src/styles/variables";

.modalDeleteItem {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > span {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      display: inline-block;
    }
    &-btns {
      display: flex;
      justify-content: center;
      @include gapMin;
      @include _1024 {
        gap: 10px;
      }
      @include _768 {
        flex-direction: column;
      }
    }
  }
  &-text {
    line-height: 20px !important;
  }
}
