@import "/src/styles/variables";

.ganttChart {
  display: flex;
  flex-direction: column;
  @include gapBig;
  overflow-y: hidden;
  &-taskList {
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    z-index: -100;
  }
  // &-content {
  //   display: grid;
  //   grid-template-columns: 0 minmax(20%, max-content);
  //   &-open {
  //     grid-template-columns: 0 minmax(20%, max-content);
  //   }

  //   @include _2000 {
  //     grid-template-columns: 0 minmax(20%, max-content);
  //     &-open {
  //       grid-template-columns: 0 minmax(20%, max-content);
  //     }
  //   }
  //   @include _1300 {
  //     grid-template-columns: 0 minmax(20%, max-content);
  //     &-open {
  //       grid-template-columns: 0 minmax(20%, max-content);
  //     }
  //   }
  // }
}
.ganttList {
  &-header {
    display: flex;
  }
}

// .grid {
//   scroll-snap-align: center;
._3_ygE {
  display: table;
  border-bottom: #e6e4e4 1px solid;
  border-top: #e6e4e4 1px solid;
  border-left: #e6e4e4 1px solid;
  border-top-right-radius: 5px 5px;
  border-top: 1px solid $grey;
  border-left: 1px solid $grey;
  div {
    // font-family: "Montserrat";
  }
}

._1nBOt {
  display: table-row;
  list-style: none;
}

._2eZzQ {
  border-right: 1px solid $main-color;
  opacity: 1;
  margin-left: -2px;
}

._WuQ0f {
  display: table-cell;
  vertical-align: -webkit-baseline-middle;
  vertical-align: middle;
  // font-family: "Montserrat";
  @include FSbaseBold;
}

._3ZbQT {
  display: table;
  border-bottom: $grey 1px solid;
  border-left: $grey 1px solid;
  border-bottom-left-radius: 5px $grey 1px solid;
}

._34SS0 {
  display: table-row;
  text-overflow: ellipsis;
  // font-family: "Montserrat";
  @include FSbaseReg;
}

._34SS0:nth-of-type(even) {
  background-color: #d9f2fa;
}

._3lLk3 {
  display: table-cell;
  vertical-align: middle;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  // font-family: "Montserrat";
  @include FSbaseReg;
}
._nI1Xw {
  display: flex;
}

.taskListExpander {
  color: $grey;
  font-size: 0.6rem;
  padding: 0.15rem 0.2rem 0rem 0.2rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  // // font-family: "Montserrat";
  @include FSbaseReg;
}
.taskListEmptyExpander {
  font-size: 0.6rem;
  padding-left: 1rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  // // font-family: "Montserrat";
  @include FSbaseReg;
}

._3T42e {
  background: #fff;
  padding: 12px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  font-family: "Montserrat" !important;
}

._29NTg {
  font-size: 12px;
  margin-bottom: 6px;
  color: #666;
  // font-family: "Montserrat";
}
.today {
  rect {
    fill: $blue-light-opacity;
  }
}

._25P-K {
  position: absolute;
  display: flex;
  flex-shrink: 0;
  pointer-events: none;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: "Montserrat" !important;
}

._3gVAq {
  visibility: hidden;
  position: absolute;
  display: flex;
  pointer-events: none;
}

._1eT-t {
  overflow: hidden auto;
  width: 1rem;
  flex-shrink: 0;
  /*firefox*/
  scrollbar-width: thin;
}
._1eT-t::-webkit-scrollbar {
  width: 1.1rem;
  height: 1.1rem;
}
._1eT-t::-webkit-scrollbar-corner {
  background: transparent;
}
._1eT-t::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: rgba(0, 0, 0, 0.2);
  background: var(--palette-black-alpha-20, rgba(0, 0, 0, 0.2));
  border-radius: 10px;
  background-clip: padding-box;
}
._1eT-t::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  background: rgba(0, 0, 0, 0.3);
  background: var(--palette-black-alpha-30, rgba(0, 0, 0, 0.3));
  background-clip: padding-box;
}

._2dZTy {
  fill: #fff;
}

._2dZTy:nth-child(even) {
  fill: $main-color-ultra-light;
}

._3rUKi {
  stroke: #ebeff2;
}

._RuwuK {
  stroke: #e6e4e4;
}

._9w8d5 {
  text-anchor: middle;
  fill: #333;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  // font-family: "Montserrat";
  @include FSbaseBold;
}

._1rLuZ {
  stroke: #e6e4e4;
}

._2q1Kt {
  text-anchor: middle;
  fill: #555;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  // font-family: "Montserrat";
  @include FSbaseBold;
}

._35nLX {
  fill: #ffffff;
  stroke: $grey;
  stroke-width: 2;
}

._KxSXS {
  cursor: pointer;
  outline: none;

  > g {
    rect:nth-child(2) {
      fill: $main-color;
    }
  }
}

._KxSXS:hover ._3w_5u {
  visibility: visible;
  opacity: 1;
}

._3w_5u {
  fill: #ddd;
  cursor: ew-resize;
  opacity: 0;
  visibility: hidden;
}

._31ERP {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  stroke-width: 0;
  // font-family: "Montserrat";
}

._RRr13 {
  cursor: pointer;
  outline: none;
}

._2P2B1 {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

._1KJ6x {
  cursor: pointer;
  outline: none;
  rect:nth-child(1) {
    fill: $blue;
  }
  rect:nth-child(2) {
    fill: $main-color;
  }
  rect:nth-child(3) {
    fill: $blue;
  }
  rect:nth-child(4) {
    fill: $blue;
  }
}

._2RbVy {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  opacity: 0.6;
}

._2pZMF {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  fill: $blue;
}

._3zRJQ {
  fill: #fff;
  text-anchor: middle;
  font-weight: lighter;
  dominant-baseline: central;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  // font-family: "Montserrat";
  @include FSbaseReg;
}

._3KcaM {
  fill: #555;
  text-anchor: start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none;
  // font-family: "Montserrat";
  @include FSbaseReg;
}

._CZjuD {
  overflow: hidden;
  font-size: 0;
  margin: 0;
  padding: 0;
}

._2B2zv {
  margin: 0;
  padding: 0;
  overflow: hidden;
}

._3eULf {
  display: flex;
  padding: 0;
  margin: 0;
  list-style: none;
  outline: none;
  position: relative;
  // border-top-right-radius: 5px 5px;
  // border-top: 1px solid $grey;
  // border-left: 1px solid $grey;
}

._2k9Ys {
  overflow: auto;
  max-width: 100%;
  /*firefox*/
  scrollbar-width: thin;
  /*iPad*/
  height: 1.2rem;
}
._2k9Ys::-webkit-scrollbar {
  width: 1.1rem;
  height: 1.1rem;
}
._2k9Ys::-webkit-scrollbar-corner {
  background: transparent;
}
._2k9Ys::-webkit-scrollbar-thumb {
  border: 6px solid transparent;
  background: #0094e7;
  background: var(--palette-black-alpha-20, #0094e7);
  border-radius: 10px;
  background-clip: padding-box;
  cursor: pointer;
}
._2k9Ys::-webkit-scrollbar-thumb:hover {
  border: 4px solid transparent;
  background: #0094e7;
  background: var(--palette-black-alpha-30, #0094e7);
  background-clip: padding-box;
}
@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
}
._19jgW {
  height: 1px;
}
.ganttChart-content-noOweflow {
  ._2B2zv {
    overflow: visible;
  }
}
