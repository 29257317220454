@import "src/styles/variables";

.accessInfoRow {
  width: 100%;
  display: grid;
  grid-template-columns: 2fr repeat(6, 1fr);
  padding: 10px 0 10px 5px;
  border-top: 1px solid $blue-m-bg;
  @include _1024 {
    grid-template-columns: 1.5fr repeat(6, 1fr);
  }
  &.file {
    grid-template-columns: 2fr repeat(5, 1fr);
    @include _1024 {
      grid-template-columns: 1.5fr repeat(5, 1fr);
    }
  }
  &:hover {
    background-color: $background-color-content;
    transition: background-color 0.5s ease;
  }
  &-name {
    @include FSbaseBold;
  }
  &-item {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
  }
  &-item.name {
    justify-content: start;
    > span {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      vertical-align: middle;
      height: auto;
      display: inline-block;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
