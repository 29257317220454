@import "src/styles/variables";

.chatList {
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 10px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  .title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
}
