@import "../../styles/variables";

.modalWrapper {
  position: absolute;
  background-color: #fff;
  @include borderRadius;
  // position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -59%);
  width: 100%;
  max-width: 1400px;
  min-height: 120px;
  z-index: 102;
  display: flex;
  flex-direction: column;
  @include _1455 {
    max-width: 95%;
  }

  &__header {
    border-radius: 10px 10px 0 0;
    background-color: $main-color-light;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    @include _2000 {
      height: 50px;
    }
    @include _1700 {
      height: 45px;
    }
  }
  &__close {
    background-color: inherit;
    > img {
      width: 20px;
      height: 20px;
    }
  }
  &__title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #ffffff;
  }
  &__container {
    padding: 20px;
    max-height: 70vh;
    // overflow-y: scroll;
  }
}

.modalWrapperContainer {
  padding: 17px 0px 0px 0px !important;
}
