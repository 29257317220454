@import "../../../styles/_variables.scss";

@keyframes bgImage {
  0% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1);
  }
  15% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
  }
  29% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1.1);
  }
  30% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form.jpg");
    transform: scale(1.1);
  }
  31% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
    transform: scale(1.1);
  }
  45% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
  }
  60% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form2.jpg");
    transform: scale(1);
  }
  61% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
    transform: scale(1);
  }
  75% {
    opacity: 1;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
  }
  97% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
    transform: scale(1.1);
  }
  100% {
    opacity: 0;
    background-image: url("../../../assets/images/pages/auth/background-form3.jpg");
  }
}
@include animate_bg_auth;
.authPage {
  height: 100%;
  background-image: url("../../../assets/images/pages/auth/background.svg");
  background-color: $blue-active;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  padding: 10px 10%;
  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &-auth {
    max-width: 400px;
    max-height: 730px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-repeat: no-repeat;
    position: relative;
    z-index: 2;
    overflow: hidden;
    @include _1024 {
      width: 100%;
      height: 100%;
    }
    &-swiper {
      &-wrapper {
        position: relative;
      }
      &-prev {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        left: 10px;
        z-index: 10;
      }
      &-next {
        position: absolute;
        top: 40%;
        transform: translateY(-50%);
        right: 10px;
        z-index: 10;
      }
    }
    &::after {
      content: "";
      position: absolute;
      top: 0px;
      right: 0px;
      bottom: 0px;
      width: 100%;
      height: 100%;
      border-radius: 50px;
      background-image: url("../../../assets/images/pages/auth/background-form.jpg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      transition: 5s;
      animation: bgImage 25s infinite;
      z-index: -1;
      overflow: hidden;
    }

    &-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      background: linear-gradient(90deg, $blue-dark 44.92%, rgba(13, 89, 151, 0.8) 100%);
      width: 100%;
      height: 100%;
      padding: 20px 60px 20px 60px;
      @include gapBig;
    }

    &__subtitle {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 20px;
      color: #ffffff;
      @include _2000 {
        font-size: 18px;
      }
      @include _1700 {
        font-size: 16px;
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
      @include gapBig;
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid #ffffff;
      @include borderRadius;
      min-width: 220px;
      height: 40px;
      &-text {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 20px;
        color: #ffffff;
        @include _1700 {
          font-size: 18px;
        }
        @include _1300 {
          font-size: 16px;
        }
      }
      @include _1300 {
        min-width: 190px;
      }
    }
    &-logo {
      margin-top: 16px;
      width: 110px;
      img {
        width: 100%;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      align-items: center;
      @include gapBig;
      width: 100%;
      &-title {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 30px;
        color: white;
        @include _2000 {
          font-size: 25px;
        }
        @include _1700 {
          font-size: 20px;
        }
      }
      &-text {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: rgba(255, 255, 255, 0.7);
      }
      &-inputs {
        display: flex;
        flex-direction: column;
        @include gapBig;
        width: 100%;
        &-input {
          display: flex;
          flex-direction: column;
          @include gapMin;
          position: relative;
          &-label {
            color: white;
            font-family: "Montserrat";
            @include FSbaseBold;
          }
          &-input {
            background: white;
            border-radius: 4px;
            padding: 16px 10px;
            color: black;
            font-size: 18px;
            @include FSsubtitle;
            font-family: "Montserrat";
            border: 1px solid $blue-m-bg;
            background-color: $blue-m-bg;
            @include borderRadius;
            @include _2000 {
              padding: 12px 10px;
            }
            @include _1700 {
              padding: 10px 10px;
            }
            &::placeholder {
              @include FSsubtitle;
              font-family: "Montserrat";
            }
          }
          &__icon {
            width: 24px;
            height: 24px;
            @include SmdBtn;
            opacity: 0.7;
            transition: opacity 0.2s;
            &:hover {
              opacity: 1;
            }
          }
        }
        // i {
        //   position: absolute;
        //   top: 45px;
        //   right: 15px;
        //   cursor: pointer;
        //   @include _1700 {
        //     top: 41px;
        //   }
        //   @include _1300 {
        //     top: 33px;
        //   }
        // }
      }
      &-btnSub {
        color: white;
        background-color: $main-color;
        @include borderRadius;
        padding: 8px 10px;
        max-width: 231px;
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbtn;
      }
      &-btnResetPasw {
        font-family: "Montserrat";
        @include FSbaseReg;
        letter-spacing: 0.03em;
        color: rgba(255, 255, 255, 0.7);
        cursor: pointer;
      }
      &-confirm {
        display: flex;
        @include gapMin;
        .checkbox {
          &-input.check::after {
            content: url("../../../assets/icons/checkMarkCheckbox.svg");
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            zoom: 1.1;
          }
        }
        &-text {
          font-family: "Montserrat";
          @include FSxxsReg;
          color: rgba(255, 255, 255, 0.829);
          &-link {
            color: rgba(255, 255, 255, 0.829);
          }
        }
      }
    }
  }
  &-links {
    display: flex;
    justify-content: center;
    @include gapBig;
    row-gap: 10px;
    flex-wrap: wrap;
  }
  &-link {
    a {
      display: flex;
      align-items: center;
      @include gapMin;
      font-family: "Montserrat";
      @include FSxsReg;
      color: rgba(255, 255, 255, 0.829);
    }
    @include HoverAuthLink;
  }
  &-copyrigh {
    display: flex;
    align-items: center;
    @include gapMin;
    font-family: "Montserrat";
    margin-top: 10%;
    &-text {
      padding-top: 1px;
      color: #ffffff;
      @include FSxsReg;
    }
    &-logo {
      height: 20px;
    }
  }
  &-accountList {
    @include flexGap(20px, col);
    width: 100%;
    &-title-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
      align-items: center;
    }
    &-item {
      @include flexGap(5px, col);
      display: flex;
      font-family: "Montserrat";
      background-color: #ffffff;
      border: 1px solid $grey;
      padding: 8px 10px;
      justify-content: space-between;
      align-items: center;
      @include borderRadius;

      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: space-between;
      padding: 5px;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-auth {
        padding: 8px 10px;
        color: white;
        background-color: $main-color;
        border-radius: 10px;
        width: 100%;
        text-align: center;
        cursor: pointer;
      }
    }
    &-footer {
      text-align: center;
    }
  }
  &-footer {
    display: flex;
    justify-content: center;
    gap: 2px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    left: 20px;
    &-text {
      font-size: 9px;
      color: $grey;
    }
  }
}
// .authPage-auth:hover::after {
//   transform: scale(1.15);
// }

.swiper-authPage {
  width: 100%;
  height: 100%;
  position: relative;
}

.swiper-slide-authPage {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide-authPage img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.swiper-pagination {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 22px;
  @include gapMin;
}
.swiper-pagination-bullet {
  width: 20px !important;
  height: 20px !important;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.829);
  opacity: 1;
  background: rgba(216, 213, 213, 0.2);
  @include FSbaseBold;
  @include borderRadius;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  color: #fff;
  background: #007aff;
}
