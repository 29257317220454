@import "../../../../styles/_variables.scss";

.structuresGrid {
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
  height: 100%;
  // height: 100%; // для драга и скролла
  // > div {
  //   height: 100%; // для драга и скролла
  //   position: relative;
  // }
  // &-content {
  //   width: 100%;
  //   height: 100%;
  //   > div {
  //     height: 100%; // для драга и скролла
  //   }
  // }
  overflow: hidden;
  &-wrapper-transform {
    width: 3000px;
    height: 100vh;
    @include _1024 {
      height: calc(100vh - 220px);
    }
  }
  &-wrapper {
    height: 100%;
    width: 100%;
    position: relative;
  }
  &-content {
    height: 100%;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
  }
  &-tools {
    position: absolute;
    bottom: 120px;
    right: 0;
    display: flex;
    align-items: center;
    gap: 10px;
    padding: 10px;
    @include _1024 {
      bottom: 140px;
    }
    &-btn {
      @include flexAllCenter();
      width: 40px;
      justify-content: center;
      background: #0094e7;
      @include borderRadius;
      height: 40px;
      cursor: pointer;
      img {
        @include SmdBtn;
      }
      @include HoverBtnBlue;
      @include _2000 {
        width: 35px;
        height: 35px;
      }
      &.reset {
        svg {
          transform: rotate(45deg);
        }
      }
    }
  }
}
.react-transform-wrapper {
  // width: 100vw !important;
  // height: 100vh !important;
  // @include _1024 {
  width: 100% !important;
  height: 100% !important;
  // }
}
