@import "./src/styles/_variables.scss";

.employeesMobile {
  &-content {
    height: 100%;
    &-strutures {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding-top: 10px;
      &-noCompany {
        display: flex;
        justify-content: center;
        &-title {
          @include FSbaseBoldM;
          color: $blue;
        }
      }
    }
  }
}
