@import "/src/styles/variables";

.employeesCardMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $grey;
  @include borderRadius;
  background-color: #fff;
  overflow: hidden;
  min-height: 330px;
  &.not-login {
    opacity: 0.5;
  }
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    margin: 0 10px;
    &-mobile {
      width: 100%;
    }
    &-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: top;
      &-text {
        @include FSbaseRegM;
        &-count {
          @include FSbaseBoldM;
        }
      }
    }
    &-img {
      @include AvatarM;
    }
    &-title {
      @include FSbaseBoldM;
      text-align: center;
    }
    border-bottom: 1px solid $main-color;
  }
  &-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-item {
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 3fr;
      &-title {
        @include FSbaseBoldM;
        color: $blue;
        margin-right: 5px;
      }
      &-text {
        @include FSbaseBoldM;

        text-align: end;
        @include nowrap;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    @include gapMin;
    padding: 10px;
    &-item {
      display: flex;
      gap: 5px;
      align-items: center;
      display: grid;
      grid-template-columns: 20px 3fr;
      &-text {
        @include FSbaseBoldM;
        color: #fff;
        display: inline-block;
        text-decoration: none;
        @include nowrap;
      }
    }
  }
}
