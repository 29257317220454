@import "/src/styles/variables";

.modalAddDellEmployees {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }
  &-tasks {
    display: flex;
    @include gapMin;
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-text {
      @include FSbaseBold;
    }
  }
  &-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
