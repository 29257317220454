@import "src/styles/variables";
.eventList {
  @include flexGap(10px, col);
  max-height: 450px;
  padding-right: 5px;
  @include _1024 {
    height: 180px;
    overflow: hidden;
  }
  @include _768 {
    height: 100%;
  }
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    height: 100%;
    @include _1024 {
      padding: 0px 10px 0 0;
      height: 200px;
    }
    @include _768 {
      height: auto;
    }
    &-title {
      max-width: 100%;
      display: flex;
      align-items: center;
      width: fit-content;
      padding: 5px;
      padding-left: 8px;
      background: #04488e;
      @include borderRadius;
      overflow: hidden;
      text-overflow: ellipsis;
      cursor: pointer;
      @include _1024 {
        max-width: 100%;
        width: 100%;
        height: 30px;
      }
      span {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        color: white;
        @include FSbaseBold;
        @include _768 {
          @include FSbaseRegM;
        }
      }
    }
    &-date {
      @include flexGap(10px, col);
      padding-left: 20px;
      span {
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 11px;
        color: #a1a1aa;
        position: relative;
        &::after {
          content: "";
          position: absolute;
          top: 50%;
          left: -12px;
          transform: translate(-50%, -50%);
          border-radius: 50%;
          width: 12px;
          height: 12px;
          background: #3b82f6;
          @include _1300 {
            width: 10px;
            height: 10px;
          }
        }
      }
    }
    &-participants {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-title,
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: $blue;
      }
      &-list {
        @include flexGap(0, col);
        max-height: 70px;

        span {
          font-weight: 400;
          color: black;
        }
      }
    }
    &-desc {
      @include flexGap(0, col);
      &-title {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: $blue;
      }
      &-text {
        @include FSbaseReg;
      }
    }
  }
}
.swiper-eventList {
  overflow: hidden;
  width: 100%;
  @include _768 {
    display: none;
  }
}
.swiper-eventList-item {
  width: fit-content;
  //   width: 220px;
  height: 100%;
}
