@import "../../../styles/_variables.scss";

.reference {
  display: flex;
  height: 100%;
  width: 100%;
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include _1700 {
      padding-top: 10px;
    }
    &-wrapper {
      height: calc(100vh - 300px);
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include _1300 {
        height: calc(100vh - 260px);
      }
    }
    &__filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }

    &__searchPanel {
      padding: 0 20px 0 10px;
    }
    &__wrapper {
      max-height: calc(100vh - 150px);
    }
  }
}
