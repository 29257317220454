@import "src/styles/variables";

.addressItem {
  display: flex;
  flex-direction: column;
  @include gapMin;
  padding: 10px 10px 6px 20px;
  clip-path: polygon(5% 0%, 100% 0%, 100% 100%, 5% 100%, 0% 50%);
  cursor: pointer;
  transition: all 0.2s;
  position: relative;
  @include _1024 {
    clip-path: none;
  }
  @include _768 {
    padding: 10px 10px 6px 10px;
  }
  &:hover {
    background-color: $blue-opacity;
    span {
      color: white;
    }
  }
  &-name {
    display: flex;
    @include gapMin;
    width: 100%;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      color: black;
    }
  }
  &-address {
    font-family: "Montserrat";
    @include FSbaseReg;
    color: black;
  }
}

.addressItem.active {
  span {
    color: white;
  }
  background-color: $blue;
}
