@import "../../styles/variables";

.modalInfoEditProfiles {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-list {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
}
