@import "/src/styles/variables";

.regularityForm {
  display: flex;
  flex-direction: column;
  @include gapMin;
  span {
    @include FSbaseBold;
  }
  &-text {
    display: flex;
    align-items: center;
    @include FSbaseBold;
  }
  &-description {
    background-color: $main-color-light;
    color: white;
    font-weight: 500 !important;
    padding: 10px;
    width: fit-content;
    @include borderRadius;
  }
  &-title {
    color: $blue;
    @include FSbaseBold;
  }

  &-repeatability {
    border-top: 2px solid $main-color-light;
    padding-top: 20px;
    @include _768 {
      padding-top: 10px;
    }
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-subtitle {
      @include FSsubtitle;
    }
    &-textBox {
      display: flex;
      @include gapMin;
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-text {
      @include FSbaseBold;
    }
    &-section {
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
    }
    &-choose {
      @include flexGap(10px, row);
      align-items: center;
      // &-item {
      //   @include borderRadius;
      //   padding: 5px 10px;
      //   cursor: pointer;
      // }
      // &-item.active {
      //   background-color: $blue;
      //   span {
      //     color: white;
      //   }
      // }
      @include _600 {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
      &-btns {
        display: flex;
        @include gapMin;
      }
      &-item {
        display: flex;
        flex-direction: column;
        @include gapBig;
        .inputTime,
        .inputDate {
          width: 100%;
        }
        &-wrapper {
          display: flex;
          @include gapMin;
          @include _1024 {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
          }
          @include _900 {
            grid-template-columns: repeat(2, 1fr);
          }
          @include _600 {
            display: flex;
            flex-direction: column;
          }
          &-item {
            display: flex;
            align-items: center;
            @include gapMin;
            @include _400 {
              flex-wrap: wrap;
            }
          }
          &-grid {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            @include gapMin;
          }
        }
        &-wrapperMonth {
          display: flex;
          @include gapMin;
          flex-wrap: wrap;
        }
        &-wrapperYear {
          display: flex;
          @include gapMin;
          flex-wrap: wrap;
        }
        &-week {
          display: flex;
          @include gapMin;
          align-items: center;
          @include _400 {
            gap: 5px;
          }
          &-day {
            display: flex;
            align-items: center;
            gap: 5px;
          }
        }
      }
      &-btn {
        &-item {
          @include borderRadius;
          padding: 5px 10px;
          cursor: pointer;
          @include _600 {
            padding: 10px;
          }
        }
        &-item.active {
          background-color: $blue;
          span {
            color: white;
          }
        }
      }
    }
    &-addTimeDate {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include gapBig;
      @include _1024 {
        @include gapMin;
      }
      @include _768 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _600 {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
    }
  }
  // span {
  //   white-space: nowrap;
  // }
  &-repeatUntil {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
