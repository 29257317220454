@import "/src/styles/variables";

.profilesList {
  font-family: "Montserrat";
  font-style: normal;
  padding: 20px 20px 20px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    padding: 20px 0 20px 0;
  }
  &-title {
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapBig;
    @include _900 {
      display: flex;
      width: 100%;
    }
  }
  &-wrapper {
    display: grid;
    grid-template-columns: 9fr 1fr;
    @include gapBig;
    @include _1024 {
      grid-template-columns: 9fr 158px;
    }
    @include _900 {
      display: flex;
      width: 100%;
    }
  }
}
