@import "../../styles/_variables";

.companyEmployees {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    @include headerWrapper;

    &-item {
      display: flex;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        vertical-align: middle;
        height: auto;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-item.numder-item {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      @include gapBig;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
