@import "src/styles/_variables.scss";

.basicInformationMobile {
  padding-top: 10px;
  display: flex;
  flex-direction: column-reverse;
  @include gapBig;
  padding-bottom: 60px;
  background-color: $background-color-content;
  @include min_768 {
    display: grid;
    grid-template-columns: 4fr 1fr;
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-item {
      display: flex;
      @include gapMin;
      @include min_600 {
        flex-direction: column;
      }
      &.description {
        flex-direction: column;
      }
    }
    &-specifications {
      display: flex;
      flex-direction: column;
      @include gapBig;
      @include min_600 {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
      }
    }
  }
  span {
    @include FSbaseBoldM;
  }
  &-title {
    color: $blue;
  }
  &-edit {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-forms {
      display: flex;
      flex-direction: column;
      @include gapBig;
      &-set {
        display: flex;
        flex-direction: column;
        @include gapBig;
        @include min_600 {
          display: grid;
          grid-template-columns: 1fr 1fr;
          @include gapMin;
        }
      }
    }
    &-btn {
      display: flex;
      flex-direction: column;
      @include gapBig;
      &-set {
        display: flex;
        flex-direction: column;
        @include gapBig;
        @include min_480 {
          flex-direction: row;
        }
      }
    }
  }
}
