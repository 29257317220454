@import "/src/styles/variables";

.regularityCheck {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-title {
    color: $blue;
    @include FSbaseBold;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    gap: 10px;
    &-text {
      @include FSbaseBold;
    }
  }
}
