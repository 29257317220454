@import "./src/styles/variables";

.donutForProjecCard {
  transition: all 0.2s;
  display: flex;
  justify-content: flex-end;
  &-graph {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    width: 120px;
    height: 120px;
  }
}
