@import "/src/styles/variables";

.modalWorkEdit {
  @include flexGap(20px, col);
  .inputDate {
    width: 100%;
    max-width: 440px;
    @include _1024 {
      max-width: 100%;
    }
  }
  &-select {
    width: 100%;
    max-width: 440px;
    @include _1024 {
      max-width: 100%;
    }
  }

  &-relationshipTasks {
    @include flexGap(10px, col);
    max-height: 205px;
    .p-5 {
      padding: 5px;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 12px 10px;
      align-items: center;
      border: 1px solid $grey;
      @include borderRadius;
      img {
        cursor: pointer;
      }
    }
  }
  &-historyChanges {
    @include flexGap(10px, col);
  }
  &-row {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
    @include _1024 {
      grid-template-columns: repeat(4, 1fr);
      column-gap: 10px;
    }
    @include _768 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: grid;
      grid-template-rows: auto 1fr;
      gap: 10px;
      @include _400 {
        display: flex;
      }
      span {
        @include FSbaseBold;
      }
      &-title {
        @include FSbaseBold;
        color: $blue;
      }
      :last-child {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      &-inputProcent {
        display: flex;
        align-items: center;
        gap: 10px;
        span.procent {
          margin-top: 15%;
          @include FSbaseBold;
          @include _1024 {
            margin-top: 30px;
          }
        }
      }
    }
  }
  &-select-row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    @include _1024 {
      column-gap: 10px;
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
  }
}
