@import "./src/styles/variables";

.modalEditDeliveryWork {
  &-form {
    width: 100%;
    display: flex;
    gap: 20px;
    flex-direction: column;
    &-wrapper {
      width: auto;
      display: grid;
      grid-template-columns: 3fr 440px;
      gap: 20px;
      &-info {
        display: flex;
        flex-direction: column;
        gap: 20px;
      }
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
    &-list {
      list-style-type: none;
      display: grid;
      gap: 20px;
      width: 100%;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      @include _768 {
        grid-template-columns: 1fr;
      }
    }
    &-item {
      .inputFile {
        max-width: 100%;
      }
    }
    &-item.inputFile {
      max-width: 100%;
      .inputFile-zone-list {
        max-width: 100%;
      }
    }
    &-DocItem {
      width: 100%;
      border: 1px solid red;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        margin-bottom: 10px;
      }
      > div {
        margin-bottom: 10px;
        max-width: 49%;
      }
    }
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
