@import "/src/styles/variables";

.infoTask {
  @include flexGap(20px, col);
  @include gapBig;
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include gapBig;
      @include _600 {
        grid-template-columns: 1fr;
      }
      &-employee {
        display: flex;
        flex-direction: column;
        @include gapBig;
        @include _1300 {
          display: grid;
          grid-template-columns: 1fr 1fr;
        }
        @include _768 {
          grid-template-columns: 1fr;
        }
      }
      .notification {
        display: flex;
        flex-direction: row;
      }
    }
    &-person {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-box {
        display: grid;
        grid-template-columns: 45px 1fr;
        @include gapMin;
        @include _1900 {
          grid-template-columns: 40px 1fr;
        }
        @include _1300 {
          grid-template-columns: 40px 1fr;
        }
      }
      &-logo {
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        flex-basis: 1;
        @include XSavatar;
      }
      &-info {
        display: flex;
        flex-direction: column;
        @include gapMin;
        &-text {
          @include FSbaseReg;
          // @include _768 {
          //   @include FSbaseBold;
          // }
        }
      }
    }
    &-date {
      display: grid;
      // flex-direction: column;
      grid-auto-flow: column;
      grid-template-columns: repeat(2, 1fr);
      grid-template-rows: repeat(2, 1fr);
      @include gapBig;

      &-elem {
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _600 {
          flex-direction: row;
          justify-content: space-between;
        }
      }
    }
    &-list {
      // @include flexGap(10px, row);
      // flex-wrap: wrap;
      // max-height: 70px;
      // overflow-y: scroll;
      // border: 1px solid $grey;
      // @include borderRadius;
      // padding: 12px 20px;
    }
    &-btns {
      @include flexGap(20px, row);
      @include gapBig;
    }
    &-select {
      width: 220px;
    }
    &-historyChanges {
      @include flexGap(10px, col);
      @include gapMin;
      padding-bottom: 20px;
    }
  }
  span {
    // @include FSbaseReg;
  }
  .title {
    color: $blue;
    font-weight: 700;
    @include FSbaseBold;
  }
  .bold {
    @include FSbaseBold;
  }
}
