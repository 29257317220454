@import "/src/styles/variables";

.modalChooseAddStepWork {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-main {
    @include flexGap(10px, col);
    &-title {
      padding-left: 40px;
      @include FSsubtitle;
    }
    &-card {
      @include flexGap(10px, col);
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 242px;
      padding: 20px;
      text-align: center;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      .number {
        font-weight: 700;
        font-size: 96px;
        color: $blue;
        @include _2000 {
          font-size: 76px;
        }
        @include _1700 {
          font-size: 56px;
        }
      }
      .hr {
        background-color: #7bc7f1;
        height: 2px;
        width: 199px;
        @include borderRadius;
        margin: 10px 0;
      }
      .title {
        @include FSsubtitle;
      }
    }
  }
}
