@import "../../../../../../../../../styles/variables";

.profileMobileTabsListItem {
  background-color: #fff;
  //   padding: 10px;
  position: relative;
  &-page {
    border: 1px solid $grey;
  }
  &-category {
    &:after {
      content: " ";
      position: absolute;
      border-top: 1px solid $main-color-light;
      top: 0;
      right: 10px;
      left: 10px;
      //   height: 30%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    padding: 10px;
    &-info {
      display: flex;
      gap: 10px;
    }
  }
  &-title {
    @include FSbaseRegM;
    &-page {
      @include FSbaseBoldM;
    }
    &-disabled {
      color: #c0c0c0;
    }
  }
  &-panel {
    background-color: #fff;
    &[aria-expanded="true"] {
      .profileMobileTabsListItem-inner-content {
        opacity: 1;
        display: block;
      }
      //   .referenceItem-panel__label_active {
      //     // coorect active
      //     color: #000000;
      //   }
    }
  }
  &-btn {
    background-color: #fff;
  }
  &-icon-active {
    transform: rotate(180deg);
  }
  &-inner {
    overflow: hidden;
    will-change: height;
    transition: height 0.4s cubic-bezier(0.65, 0.05, 0.36, 1);
    &-content {
      font-size: 16px;
      color: #000000;
      opacity: 0;
      transition: opacity 0.3s linear 0.18s;
      display: none;
      &__text {
        @include FSbaseReg;
        line-height: 16px;
      }
    }
  }
  &-permissionList {
    display: flex;
    flex-direction: column;
    gap: 2px;
    padding: 0 10px 10px 10px;
  }
}

.permissionRow {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  height: 30px;
  @include FSbaseRegM;
  &-left {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $blue;
    border: 1px solid $blue;
    color: #fff;
  }
  &-right {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $grey;
  }
}
