@import "../../../styles/_variables.scss";

.profile {
  display: flex;
  width: 100%;
  height: calc(100vh - 100px);
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include _1700 {
      padding-top: 10px;
    }
    &__wrapper {
      // max-height: calc(100vh - 150px);
    }
  }
}
