@import "/src/styles/variables";

.modalWork {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-main {
    @include flexGap(20px, col);
  }
}
