@import "./src/styles/variables";

.folderStructureItem {
  display: flex;
  flex-direction: column;
  gap: 5px;
  &-row {
    border: 1px solid #fff;
    display: flex;
    gap: 5px;
    background-color: #fff;
    width: fit-content;
    padding: 5px 10px 5px 5px;
    @include borderRadius;
    align-items: center;
    min-height: 35px;
    cursor: pointer;
    @include HoverSelectRow;
    &-arrow {
      transform: rotate(180deg);
    }
    &-arrow.active {
      transform: rotate(270deg);
    }
    &-name {
      @include FSbaseBold;
    }
    &-icon {
      width: 30px;
    }
    &-folder {
      svg {
        width: 26px;
        height: 26px;
        @include _2000 {
          width: 22px;
          height: 22px;
        }
        @include _1700 {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
  &-row.active {
    border: 1px solid $main-color;
  }
  &-children {
    margin-left: 20px;
  }
}
