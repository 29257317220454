@import "../../styles/variables";

.inputDate {
  display: flex;
  flex-direction: column;
  @include gapMin;
  position: relative;

  &-validate {
    position: absolute;
    bottom: -12px;
    span {
      color: red !important;
      font-weight: 400 !important;
      font-size: 12px !important;
      @include _2000 {
        font-size: 10px !important;
      }
    }
  }

  &-input {
    background: white;
    border: 1px solid $grey;
    @include borderRadius;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 12px 10px;
    width: 100%;
    cursor: pointer;
    position: relative;
    height: 45px;
    @include _2000 {
      // padding: 8px 10px;
      height: 40px;
    }
    @include _1700 {
      height: 35px;
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    // @include _1024 {
    //   height: 40px;
    border: 1px solid $blue-m-bg;
    background: $blue-m-bg;
    &::placeholder {
      color: $blue-m-text;
      @include FSbaseReg;
    }
    // }
  }
  span,
  input {
    font-family: "Montserrat";
    color: black;
    @include FSbaseBold;
    &::placeholder {
      @include FSbaseReg;
      color: $blue-m-text;
    }
    @include _1024 {
      &::placeholder {
        color: $blue-m-text;
      }
    }
  }
  input {
    // @include _1024 {
    background: $blue-m-bg;
    // }
  }
  span {
    color: $blue;
    font-weight: 700;
    @include FSbaseBold;
  }
  &-title {
    display: flex;
    color: $blue;
    gap: 2px;
    &-red {
      color: $red !important;
    }
  }
  &-icon {
    position: absolute;
    bottom: 10px;
    right: 12px;
    cursor: pointer;
    > img {
      @include SmdBtn;
    }
    @include _2000 {
      right: 12px;
    }
    @include _1700 {
      bottom: 10px;
      right: 12px;
    }
    @include _1024 {
      right: 13px;
    }
  }
}

.inputDate.validate {
  .inputDate-input {
    border: 1px solid $orange;
  }
}

.inputDate.filterDate {
  display: flex;
  flex-direction: column;
  // gap: 10px;
  position: relative;
  .inputDate-input.filterDate {
    box-shadow: none;
    background-color: hsl(0, 0%, 100%);
    border: 1px solid $grey;
    @include borderRadius;
    padding: 10px;
    cursor: pointer;
    position: relative;
    height: 40px;
    // @include _1024 {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    @include _1700 {
      height: 35px;
    }
    input {
      background-color: $blue-m-bg;
    }
    &::placeholder {
      color: $blue-m-text;
      @include FSbaseReg;
    }
    // }
  }
  span {
    color: $blue;
    @include FSbaseBold;
  }
  input {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: #19181a;
  }
  .inputDate-icon {
    position: absolute;
    bottom: 8px;
    right: 10px;
    cursor: pointer;
    > img {
      @include SmdBtn;
    }
    @include _1024 {
      right: 13px;
    }
  }
  span {
    @include _1024 {
      color: $blue;
    }
  }
}
.inputDate.regular {
  span,
  input {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: normal !important;
    color: black;
  }
}

.inputDate.title-blue {
  .inputDate-title {
    color: $blue;
  }
}
.inputDate-input-time {
  .react-datepicker {
    display: flex;
    &__time-container {
      width: 100%;
      border-left: none;
      margin: 0;
    }
    &__header--time {
      // height: 110px;
    }
    &__time-box {
      width: fit-content !important;
    }
    &-time__header {
      display: none;
    }
    &__navigation--next--with-time {
      right: 70px;
    }
    &__time-list {
      // height: 280px !important;

      &-item {
        @include FSbaseReg;
        height: 25px !important;
        padding: 5px !important;
        width: 50px;
        &:hover {
          background-color: $blue-opacity !important;
          // transition: background-color 0.2s;
          color: #fff;
          @include borderRadius;
        }
        &--selected {
          background-color: $blue !important;
          color: #fff;
          @include borderRadius;
          @include FSbaseReg;
        }
      }
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
        @include borderRadius;
      }

      &::-webkit-scrollbar-thumb {
        @include borderRadius;
        background-color: $main-color;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        background-color: #f9f9fd;
      }
    }
  }
  .react-datepicker__time {
  }
  .react-datepicker__children-container {
    margin: 0;
    width: fit-content;
    padding-right: 0;
    padding-left: 0;
  }
  .react-datepicker__time-list {
    // display: flex;
  }
}
