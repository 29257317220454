@import "./src/styles/variables";

.tasksColumn {
  position: relative;
  @include borderRadius;
  height: 100%;
  overflow: hidden;
  .lastLine {
    opacity: 0;
  }
  &-header {
    width: 100%;
    padding: 12px 10px 12px 10px;
    background-color: $grey-dark;
    border-radius: 6px 0 0 6px;
    clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
    position: absolute;
    top: 0;
    z-index: 10;
    @include _2000 {
      padding: 10px 8px 9px 8px;
    }
    @include _1700 {
      padding: 5px 8px 5px 8px;
    }

    &-title {
      font-family: "Montserrat";
      font-style: normal;
      // font-weight: 700;
      // font-size: 18px;
      @include FSnav;
      line-height: 21px;
      color: #fff;
      height: 100%;
    }
    &-atWork {
      background-color: $atWork;
    }
    &-onVerification {
      background-color: $onVerification;
    }
    &-completed {
      background-color: $completed;
    }
    &-overdue {
      background-color: $overdue;
    }
    &-rejected {
      background-color: $rejected;
    }
    &-assigned {
      background-color: $assigned;
    }
  }
  &-item {
    background: #f6f6f6;
    border: 1px solid $grey;
    @include borderRadius;
    height: 100%;
  }
  &-content {
    @include borderRadius;
    position: relative;
    list-style: none;
    background: #f6f6f6;
    padding: 60px 10px 10px 10px;
    width: 100%;
    height: 100%;
    transition: 0.3s linear;
    // overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include _2000 {
      padding: 50px 5px 5px 5px;
    }
    @include _1700 {
      padding: 40px 5px 5px 5px;
      // height: 100%;
      gap: 5px;
    }
  }
}
