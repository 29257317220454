@import "src/styles/_variables.scss";

.participantsMobile {
  width: 100%;
  padding-bottom: 60px;
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
  &-pagination {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
