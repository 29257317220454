@import "../../styles/_variables";

.companiesGridCard {
  @include cardColumnWrapper;
  overflow: hidden;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0 10px;
    &__content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: space-between;
      margin-bottom: 10px;
      align-items: center;
    }
    &__logoCompany {
      @include LGavatar;
    }
    &__title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseReg;
      line-height: 16px;
      max-width: 90%;
      @include nowrap;
    }
    &__text,
    &__name {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      max-width: 90%;
    }

    &__name {
      @include nowrap;
    }
    &__button {
      background-color: #fff;
      img {
        @include SmdBtn;
      }
    }
    &__menu {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }

  &-info {
    border-top: 2px solid #f6f6f6;
    list-style-type: none;
    margin: 0 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    &__item {
      display: flex;
      justify-content: space-between;
    }
    &__title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      margin-right: 4px;
      color: $blue;
    }
    &__text {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      text-align: end;
      max-width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex-shrink: 1;
    }
  }
  &-footer {
    list-style-type: none;
    background-color: $main-color;
    border-radius: 0px 0px 2px 2px;
    padding: 10px;
    &__item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &__img {
      margin-right: 10px;
    }
    &__text {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      color: #fff;
      text-decoration: none;
    }
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
