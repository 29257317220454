@import "./src/styles/variables";
@keyframes animate_reduce {
  0% {
    transform: scale3d(0.95, 0.95, 0.95);
  }
  50% {
    transform: scale3d(1.2, 1.2, 1.2);
  }
  100% {
    transform: scale3d(0.95, 0.95, 0.95);
  }
}
.header {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  position: relative;
  padding: 10px 15px;
  background-color: $main-color;
  @include _1700 {
    height: 40px;
  }
  &-name {
    display: flex;
    @include gapMin;
    align-items: center;
    h1 {
      font-family: "Montserrat";
      @include FSh1;
      color: white;
    }
  }
  &-notifications {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    &-circle {
      position: absolute;
      top: -1px;
      right: 2px;
      background-color: #f3be03;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      display: block;
      border-radius: 50%;
      animation: animate_reduce 2s linear;
      animation-iteration-count: 5;
      @include _2000 {
        top: 0px;
        right: 2px;
      }
    }
    &:hover {
      .header-notifications-circle {
        animation: none;
      }
    }
  }
}
