@import "./src/styles/variables.scss";

.SettingsGeneralElem {
  font-family: "Montserrat";
  font-style: normal;
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 9px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__title {
    @include FSbaseBold;
    line-height: 16px;
    color: $blue;
  }
  &__text {
    @include FSxsReg;
    // line-height: 12px;
    color: $grey;
  }
}
