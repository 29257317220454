@import "../../styles/variables";

.imageList {
  display: flex;
  flex-direction: column;
  @include gapMin;
  span {
    font-family: "Montserrat";
  }
  &-title {
    font-weight: 700;
    color: $blue;
    @include FSbaseBold;
  }
  &-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
    grid-template-rows: auto;
    @include gapMin;
    max-height: 355px;
    overflow-y: scroll;
    &-item {
      height: 80px;
      background-size: cover;
      background-position: center;
      position: relative;
      @include borderRadius;
      cursor: pointer;
      border: 1px solid $grey;
      &-remove {
        position: absolute;
        bottom: 0;
        right: 0;
        background: white;
        border-radius: 6px 0 0 0 !important;
        padding: 4px 6px;
        cursor: pointer;
      }
    }
  }
}
