@import "src/styles/variables";

.windowChatMain {
  height: 100%;
  padding: 0px 10px 0px 10px;
  // @include _1024 {
  //   height: calc(100vh - 170px);
  // }
  &-list {
    background-color: #fff;
    &-event {
      text-align: center;
      span {
        @include FSbaseReg;
        font-style: italic;
      }
    }
    .msg_read {
      transition: all 0.5s;
    }
    .msg_noread {
      transition: all 0.5s;
      background-color: #e3e3e3;
      border-radius: 10px;
    }
    @include flexGap(10px, col);
    max-height: calc(100vh - 450px);
    padding: 5px 0;
    padding-right: 5px;
    @include _1024 {
      max-height: calc(100vh - 400px);
      height: calc(100vh - 400px);
    }
    // @include _400 {
    //   max-height: calc(100vh - 500px);
    // }
    &-title {
      display: flex;
      justify-content: center;
      @include FSstrSub;
    }
    &-item {
      @include flexGap(10px, col);
      position: relative;
      &-wrapper {
        background-color: $background-color-content;
        width: fit-content;
        border-radius: 10px 10px 10px 10px;
        padding: 5px 10px 5px 10px;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .forward-select {
          transition: all 0.3s;
          bottom: -4px;
          img {
            width: 15px;
            height: 15px;
          }
        }
      }
      &-wrapper.forward {
        transition: all 0.3s;
        background-color: $main-color !important;
        span {
          color: white;
        }
      }
      &-logo {
        position: absolute;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        border: 1px solid white;
      }
      &-files,
      &-images,
      &-voices,
      &-text {
        // background: #f6f6f6;
        width: fit-content;
        position: relative;
        @include FSbaseReg;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        gap: 5px;
        .time {
          text-align: right;
          span {
            @include FSxsReg;
            color: $grey-dark;
          }
        }
      }
      &-text {
        max-width: 466px;
      }
      &-text.forward,
      &-images.forward,
      &-voices.forward {
        transition: all 0.3s;
        // background-color: #0094e7;
        span {
          color: white;
        }
      }
      &-files.forward {
        transition: all 0.3s;
        // background-color: #0094e7;
      }
      &-images {
        &-list {
          display: flex;
          gap: 5px;
          flex-wrap: wrap;
          &-item {
            width: 109px;
            height: 109px;
            @include borderRadius;
            overflow: hidden;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      }
      &-files {
        max-width: 406px;
        width: 100%;
        @include flexGap(10px, col);
        &-list {
          @include flexGap(10px, col);
        }
      }
      &-voices {
        max-width: 380px;
        width: 100%;
        @include flexGap(10px, col);
        &-list {
          @include flexGap(10px, col);
          &-item {
            @include flexGap(10px, row);
            &-road {
              width: 100%;
              height: 30px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center;
            }
          }
        }
      }
      &-profile {
        width: 100%;
        &-info {
          width: 100%;
          display: flex;
          justify-content: flex-end;
          &-title {
            @include FSbaseBold;
          }
          span {
            color: white;
            color: black;
          }
          .date {
            @include FSmdMed;
          }
          .positions {
            @include FSmdMed;
            // font-weight: 500;
          }
        }
      }
    }
    &-item.myMessage {
      position: relative;
      align-items: flex-end;
      .windowChatMain-list-item-profile,
      .windowChatMain-list-item-logo {
        display: none;
      }
      .windowChatMain-list-item-text,
      .windowChatMain-list-item-images,
      .windowChatMain-list-item-voices,
      .windowChatMain-list-item-files {
        border-radius: 10px 10px 0 10px;
      }
      .windowChatMain-list-item-forwardedMessages {
        @include flexGap(10px, col);
        margin-right: 0px;
        padding-right: 10px;
        border-right: 2px solid $grey;
        .windowChatMain-list-item-profile,
        .windowChatMain-list-item-logo {
          display: block;
        }
        &-body {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
      .windowChatMain-list-item-wrapper {
        border-radius: 10px 0px 10px 10px;
        // margin-right: 30px;
        position: relative;
        background-color: #deeaf1;
        .forward-select {
          position: absolute;
          left: -20px;
        }
      }
    }
    &-item.memberMessage {
      .windowChatMain-list-item-profile-info {
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }

      .windowChatMain-list-item-forwardedMessages {
        @include flexGap(10px, col);
        margin-left: 0px;
        padding-left: 10px;
        border-left: 2px solid $grey;
        &-body {
          @include flexGap(10px, col);
        }
      }
      .windowChatMain-list-item-wrapper {
        border-radius: 0px 10px 10px 10px;
        margin-left: 30px;
        position: relative;
        .forward-select {
          position: absolute;
          right: -20px;
        }
      }
    }
  }
}
