@import "./src/styles/variables";
.ResponsiblesItem {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  @include gapBig;
  border-top: 1px solid $blue-m-bg;
  padding-top: 20px;
  @include _768 {
    display: flex;
    flex-direction: column;
  }
  &-btns {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-span {
      @include FSbaseBold;
      color: #fff;
    }
    &-list {
      display: flex;
      @include gapBig;
    }
  }
  &-btn {
    height: 45px;
    width: 45px;
    @include borderRadius;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    @include _2000 {
      width: 40px;
      height: 40px !important;
    }
    @include _1700 {
      width: 35px;
      height: 35px !important;
    }
  }
  &-btn.red {
    background-color: $red;
    @include HoverBtnRed;
    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
  &-btn.grey {
    background-color: $blue-m-text;
    @include HoverBtnDarkGrey;
  }

  &-btn.dark {
    background-color: $blue-dark-btn;
    @include HoverBtnDark;
  }

  &-btn.dark-light {
    background-color: $blue-dark-btn;
    @include HoverBtnDark;
  }

  &-btn.blue {
    background-color: $main-color;
    @include HoverBtnBlue;
  }
}
