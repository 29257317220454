@import "src/styles/variables";

.documentationList {
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    @include gapMin;
  }
  &-row {
    display: flex;
    flex-direction: column;
    @include gapBig;
    position: relative;
    @include _1024 {
      @include gapMin;
    }
    &-header {
      @include headerRow;
      display: grid;
      grid-template-columns: 20px 40px 1fr 100px 100px 200px 1fr;
      gap: 5px;
      &-item {
        display: flex;
        align-items: center;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-grid {
    display: grid;
    @include gapBig;
    // flex-wrap: wrap;
    // grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    @include cardDocColumnGrid;
    @include _1024 {
      @include gapMin;
    }
  }
}
