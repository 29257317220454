@import "/src/styles/variables";

.companiesMobile {
  width: 100%;
  padding-bottom: 60px;
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
}
