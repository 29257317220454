@import "../../../../../../styles/variables";

.structuresListRow {
  padding-left: 15px;
  //   padding-top: 20px;
  list-style-type: none;
  //   overflow: hidden;
  margin-bottom: 20px;
  position: relative;
  font-family: "Montserrat";
  @include _1700 {
    margin-bottom: 10px;
  }
  @include _1024 {
    margin-bottom: 10px;
  }
  &:last-child {
    margin-bottom: 100px;
  }
  &-last {
    &:last-child {
      margin-bottom: 0px;
      padding-bottom: 0px;
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 10px 20px 10px 20px;
    background-color: #fff;
    border: 1px solid $grey;
    @include borderRadius;
    position: relative;
    z-index: 2;
    @include _1024 {
      padding: 10px;
      height: 40px;
    }
    &::before {
      content: " ";
      position: absolute;
      height: 1530px;
      width: 40px;
      bottom: -25px;
      left: -24px;
      background-image: url("../../../../../../assets/icons/angle-long.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 95%;
      @include _1024 {
        bottom: -32px;
      }
    }
    &-last {
      background: #7bc7f1;
      border: 1px solid $grey;
      @include borderRadius;
      min-height: 50px;
      color: #fff;
      z-index: 1;
      &::before {
        height: 100px;
        bottom: 10px;
        background-image: url("../../../../../../assets/icons/angle.svg");
      }
    }
  }
  &__btns {
    display: flex;
    height: 100%;
    align-items: center;
  }
  &__btn {
    height: auto;
    background-color: inherit;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    transition: all 0.2s;
    img {
      @include SmdBtn;
    }
  }

  &__title {
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    margin-bottom: 5px;
  }
  &__text {
    font-style: normal;
    line-height: 14px;
    @include FSmdReg;
  }
  &__section {
    padding: 0px 20px 0 20px;
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 249px;
    z-index: 6;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
