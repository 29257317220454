@import "src/styles/_variables.scss";

.participantInfoMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 80px;
  &-wrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 10px;
    @include min_600 {
      display: grid;
      grid-template-columns: 5fr 1fr;
    }
  }
  &-avatar {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    &-title {
      @include FSbaseBoldM;
      color: $blue;
      width: 100%;
    }
    &-img {
      @include ProfileAvatar;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include min_480 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      height: fit-content;
      row-gap: 20px;
    }
    @include min_768 {
      padding-top: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: auto;
    }
    &-item {
      display: flex;
      gap: 10px;
      @include min_600 {
        flex-direction: column;
      }
      &-title {
        @include FSbaseBoldM;
        color: $blue;
      }
      &-value {
        @include FSbaseBoldM;
      }
      &-list {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
      }
    }
  }
}
