@import "../../../../../../styles/variables";

@keyframes down {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-15px);
    opacity: 0.2;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.structuresGridCard {
  position: relative;
  list-style-type: none;
  display: flex;
  justify-content: center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  scroll-snap-type: x mandatory;

  ul {
    padding-top: 20px;
    position: relative;

    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
  }
  li {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    align-items: center;
    position: relative;
    padding: 20px 5px 0 5px;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    animation: down 0.4s;
  }
  li::before,
  li::after {
    content: "";
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 2px solid $grey;
    width: 50%;
    height: 20px;
  }
  li::after {
    right: auto;
    left: 50%;
    border-left: 2px solid $grey;
  }
  li:only-child::after,
  li:only-child::before {
    display: none;
  }
  li:only-child {
    padding-top: 0;
  }
  li:first-child::before,
  li:last-child::after {
    border: 0 none;
  }

  li:last-child::before {
    border-right: 2px solid $grey;
    border-radius: 0 10px 0 0;
    -webkit-border-radius: 0 10px 0 0;
    -moz-border-radius: 0 10px 0 0;
  }
  li:first-child::after {
    border-radius: 10px 0 0 0;
    -webkit-border-radius: 10px 0 0 0;
    -moz-border-radius: 10px 0 0 0;
  }
  ul::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 2px solid $grey;
    width: 0;
    height: 20px;
  }
  &-item {
    background: #ffffff;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid $grey;
    display: flex;
    flex-direction: column;
    min-height: 110px;
    width: 300px;
    @include borderRadius;
    padding: 10px 10px 10px 10px;
    font-family: "Montserrat";
    position: relative;
    scroll-snap-align: start;
    //   margin-bottom: 20px;
    z-index: 1;
    @include _2000 {
      width: 240px;
    }
    &-header {
      padding-bottom: 5px;
      border-bottom: 1px solid $blue;
      font-style: normal;
      line-height: 16px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-height: 40px;
      position: relative;
      &-title {
        color: $blue;
        @include FSmdSemiBold;
        text-align: center;
      }
      @include _1300 {
        min-height: 30px;
      }
      &-btn {
        top: 5;
        right: -5px;
        position: absolute;
      }
    }
    &-header-two {
      border-bottom: 1px solid $main-color;
    }
    &-header-three {
      border-bottom: 1px solid $blue-dark;
    }
    &-header-title-two {
      color: $main-color;
    }
    &-header-title-three {
      color: $blue-dark;
    }
    &-info {
      display: flex;
      padding: 10px 0 10px 0;
      border-bottom: 1px solid $blue;
      display: flex;
      flex-direction: column;
      gap: 5px;
      &-row {
        display: grid;
        grid-template-columns: 1fr 4fr 20px;
      }
      &-contact {
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-self: flex-start;
      }
      &__image {
        margin-right: 7px;
        margin-top: 2px;
        @include XSavatar;
      }
      &__wrapper {
        display: flex;
        flex-direction: column;
        gap: 5px;
      }
      &__title {
        margin: 0;
        font-style: normal;
        font-weight: 500;
        font-size: 10px;
        line-height: 10px;
        line-height: 14px;
        @include FSmdSemiBold;
      }
      &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        line-height: 10px;
        line-height: 14px;
        @include FSxsReg;
      }
    }
    &-info-two {
      border-bottom: 1px solid $main-color;
    }
    &-info-three {
      border-bottom: 1px solid $blue-dark;
    }
  }
  ul:last-child {
    ul:last-child {
      h5 {
        color: $completed;
      }
      .structuresGridCard-item-header {
        border-bottom: 1px solid $completed;
      }
      .structuresGridCard-item-info {
        border-bottom: 1px solid $completed;
      }
    }
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 6;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
.structuresGridCard-item-info-noBorder {
  border-bottom: 1px solid #fff !important;
}
