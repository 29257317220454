@import "/src/styles/variables";

.messageItem {
  display: flex;
  // flex-direction: column;
  gap: 10px;
  position: relative;
  // @include borderRadius;
  span {
    font-family: "Montserrat";
  }
  &.visibleAvatar {
    border-top: 1px solid $main-color-light;
    margin-top: 10px;
    padding-top: 10px;
  }
  display: flex;
  padding: 0 10px 0 0px;
  &-logo {
    // position: absolute;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    // border: 1px solid white;
  }
  &-wrapper {
    // background-color: $background-color-content;
    // width: fit-content;
    // border-radius: 10px 10px 10px 10px;
    padding: 5px 10px 5px 0px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    border-bottom: 1px solid $main-color-light;
    background-color: #fff;
    width: 100%;
  }
  &-profile {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-text {
      @include FSmdMed;
      color: $grey;
    }
  }
  &-text {
    // background: #f6f6f6;
    // width: fit-content;
    position: relative;
    @include FSbaseReg;
    display: flex;
    flex-direction: column;
    gap: 5px;
    span {
      color: black;
    }
  }
  &-files {
    display: grid;
    grid-template-columns: minmax(auto, 220px);
  }
  &-header {
    display: flex;
    gap: 10px;
    &-logo {
      border-radius: 50%;
      background-size: cover;
      // @include XSavatar;
    }
    &-info {
      @include flexGap(0px, col);
      &-title {
        @include FSbaseBold;
      }
      &-text {
        @include FSmdMed;
      }
    }
    span {
      // color: white;
    }
  }

  &-text {
    // color: white;
    @include FSbaseReg;
  }
  &-photos {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    cursor: pointer;
    &-item {
      width: 109px;
      height: 109px;
      @include borderRadius;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &-files {
    .taskInfo-documents-cards-item {
      padding: 0;
    }
  }
  &-voices {
    &-item {
      @include flexGap(10px, row);
      &-road {
        width: 100%;
        height: 30px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
    }
  }

  &-footer {
    text-align: right;
    span {
      @include FSxsReg;
      color: $grey-dark;
    }
  }
}

.messageItem.myMessage {
  position: relative;
  align-items: flex-end;
  .messageItem-logo {
    display: none;
  }
  .messageItem-profile {
    display: none;
  }
  .messageItem-wrapper {
    // border-radius: 10px 0px 10px 10px;
    margin-right: 10px;
    position: relative;
    // background-color: #cbe7f7;
  }
}

.messageItem.memberMessage {
  .messageItem-wrapper {
    // border-radius: 0px 10px 10px 10px;
    // margin-left: 35px;
    position: relative;
    // background-color: #f5f9fb;
  }
  .messageItem-logo {
    left: 5px;
  }
}
