@import "src/styles/variables";

.windowChat {
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  display: flex;
  flex-direction: column;
  @include _1024 {
    height: 100%;
    width: 100%;
  }

  &-noChat {
    @include flexAllCenter();
    height: 100%;
    span {
      color: $grey;
    }
  }
}
