@import "/src/styles/variables";

.deliveryWorkCheckingMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px 0 60px 0;
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    &-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
  }
  &-btn {
    display: flex;
    justify-content: center;
    gap: 10px;
  }
}
