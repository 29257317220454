@import "./src/styles/variables.scss";

.settingsGeneral {
  padding: 0 20px 0 20px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    padding: 0;
  }
  &-section {
    display: flex;
    flex-direction: column;
    @include gapBig;

    max-width: 1445px;

    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include _1024 {
        @include gapMin;
        grid-template-columns: repeat(3, 1fr);
      }
      @include _900 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-title {
    @include FSsubtitle;
    line-height: 18px;
  }
}
