@import "../../../../../../../styles/variables";

.modalAddPartic {
  display: flex;
  flex-direction: column;
  @include gapBig;
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-searchPanel {
    display: flex;
    align-items: center;
    @include gapMin;
    padding-right: 20px;
    &-info {
      background-color: #f6f6f6;
      border-radius: 0 50px 50px 0;
      max-height: 50px;
      padding: 17px 20px 17px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: fit-content;
      white-space: nowrap;
      span {
        font-weight: 700;
        @include FSbaseBold;
      }
    }
    .searchPanel {
      width: 100%;
    }
  }
  &-cards {
    display: flex;
    @include gapMin;
    flex-wrap: wrap;
    &-header {
      display: grid;
      padding: 0 30px 0 20px;
      grid-template-columns: 30px repeat(5, 1fr);
      gap: 5px;
      width: 100%;
      &-item {
        text-align: center;
        span {
          font-family: "Montserrat";
          @include FSbaseBold;
          color: $blue;
        }
      }
    }
  }
  &-cards.rows {
    padding: 0 20px 0 10px;
  }
  &-footer {
    &-pagination {
      display: flex;
      justify-content: center;
    }
    &-btns {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
    padding: 0 20px 20px 10px;
  }
}
