@import "/src/styles/variables";

.activeHeaderWrapper {
  background-color: #fff;
  @include borderRadius;
  height: 45px;
  width: 100%;
  display: flex;
  @include gapBig;
  position: absolute;
  align-items: center;
  z-index: 3;
  top: 0px;
  left: 0;
  right: 0;
  padding: 0 20px 0 10px;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  @include _1700 {
    height: 40px;
  }
  &-item {
    display: flex;
    gap: 5px;
    cursor: pointer;
    align-items: center;
    height: 100%;
    &-text {
      @include FSbaseReg;
    }
    @include HoverModalText;
    @include _1200 {
      display: flex;
      align-items: center;
      @include gapMin;
      transition: all 1s linear;
      max-width: 20px;
      transition: all 0.7s ease-in-out;
      overflow: hidden;
      // white-space: nowrap;
      &:hover {
        max-width: 90px;
        width: 90px;
      }
    }
  }
}
