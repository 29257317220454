@import "../../../../../../../../../styles/variables";

.profileRoleCard {
  width: 258px;
  background: #ffffff;
  padding: 10px;
  border: 2px solid #2c2c2c;
  cursor: pointer;

  &:hover {
    border: 2px solid $blue;
    transition: 0.3s;
    box-shadow: 0px 4px 16px rgba(24, 106, 188, 0.25);
    .profileRoleCard-header__title {
      transition: 0.3s;
      color: $blue;
    }
    .profileRoleCard-header__icon {
      transition: 0.3s;
      fill: $blue;
      > svg {
        fill: $blue;
        > path {
          fill: $blue;
        }
      }
    }
    .profileRoleCard-header__btn {
      border: 2px solid $blue;
      color: $blue;
    }
  }
  @include _1455 {
    width: 100%;
    display: grid;
    grid-template-columns: minmax(auto, 150px) auto;
  }
  &-header {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 2px solid $main-color-light;
    @include _1455 {
      border-bottom: none;
      padding: 0;
      border-right: 2px solid $main-color-light;
    }
    @include _1200 {
      justify-content: center;
    }
    &__title {
      margin-top: 5px;
      @include FSsubtitle;
      line-height: 18px;
      @include _1455 {
        font-size: 16px;
      }
    }
    &__img {
      &:hover {
        color: green;
      }
    }
    &__icon {
      fill: #000;
      height: 40px;
      width: 40px;
      @include _1455 {
        width: 30px;
        height: 30px;
      }
      @include _1200 {
        height: 50px;
        width: 50px;
      }
    }
  }
  &-list {
    list-style-type: none;
    padding: 0 10px;
    @include _1455 {
      display: grid;
      // gap: 10px;
      grid-template-columns: repeat(9, 11%);
      padding: 0;
      justify-content: space-between;
    }
    @include _1200 {
      height: 100%;
      grid-template-columns: repeat(3, 33%);
      grid-template-rows: repeat(3, 55px);
      row-gap: 10px;
    }
  }
  &-item {
    border-bottom: 2px solid $main-color-light;
    padding: 10px 0 10px 22%;
    @include _1455 {
      display: flex;
      padding: 0;
      border-bottom: none;
      align-items: center;
      justify-content: center;

      border-right: 2px solid $main-color-light;
      &:last-child {
        border-right: none;
      }

      &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
    @include _1200 {
      &:nth-child(3n + 3) {
        border-right: none;
      }
    }
    &__img {
      height: 15px;
      width: 16px;
      margin-right: 10px;
      @include _1455 {
        margin-right: 0px;
        margin-bottom: 10px;
      }
    }
    &__text {
      @include FSbaseBold;
      line-height: 16px;
      @include _1455 {
        text-align: center;
      }
    }
    &:last-child {
      border-bottom: 2px solid #fff;
    }
  }
}
