@import "/src/styles/variables";

.cardRowsParticMinChat {
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 8px 10px;
  align-items: center;
  @include cardRowWrapper;
  display: grid;
  width: 100%;
  grid-template-columns: 30px repeat(5, 1fr);
  gap: 5px;
  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    span,
    a {
      font-family: "Montserrat";
      @include FSbaseBold;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    a {
      color: black;
      text-decoration: none;
    }
  }
  &-item.name {
    @include borderRadius;

    > span {
      overflow: hidden;
      align-self: center;
      @include cardRowPaddingSpan;
      color: $blue-active;
      height: 100%;
    }
    @include HoverBtnRow;
  }
  &-item.department {
    // color: $light-green;
  }
  &-item.position {
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      color: $blue-dark;
      display: inline-block;
      text-align: start;
    }
  }
  &-item.company {
    color: $light-green;
    @include borderRadius;
    max-width: 360px;
    overflow: hidden;
    align-self: center;
    background-color: rgba(22, 195, 91, 0.1);
    > span {
      display: inline-block;
      text-align: start;
      @include cardRowPaddingSpan;
    }
  }
}
