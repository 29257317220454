@import "../../styles/variables";
@include animate_pulse;

.icons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pulse:not(:active) {
  border-radius: 50%;
}

.pulse:active {
  border-radius: 50%;
  animation: animate_pulse 0.3s;
}

.icons-viewCard,
.icons-arrowLeft,
.icons-arrowDouble {
  svg {
    stroke: black;
    transition: stroke 0.3s;
    &:hover {
      stroke: $main-color;
    }
  }
}

.cursor {
  cursor: pointer;
}

.icons-gearSettings {
  svg {
    fill: black;
  }
}

.icons-filterIcon,
.icons-infoItem,
.icons-edit,
.icons-crossBlackIcon,
.icons-voiceMicro,
.icons-paperIcon,
.icons-emailOpen,
.icons-tasksIcon,
.icons-gearSettings,
.icons-arrowTop,
.icons-menu,
.icons-addToFolder,
.icons-download,
.icons-structures,
.icons-copy,
.icons-timeIcon,
.icons-arrow,
.icons-arrowDown,
.icons-dateIcon,
.icons-phone,
.icons-mail,
.icons-сirclePlus,
.icons-circleMinus,
.icons-infoProject,
.icons-crossBlack,
.icons-eyeOpen,
.icons-eyeClose,
.icons-file,
.icons-folderClear,
.icons-reset,
.icons-chat {
  svg {
    fill: black;
    transition: fill 0.3s;
    &:hover {
      fill: $main-color;
    }
  }
}

.icons-remove {
  svg {
    fill: #db1f35;
    transition: fill 0.3s;
    &:hover {
      fill: #ff001e;
    }
  }
}

.icons-blue {
  svg {
    fill: $blue;
  }
}
.icons-black {
  svg {
    fill: black;
  }
}
.icons-strokeBlack {
  svg {
    stroke: black;
  }
}
.icons-strokeBlue {
  svg {
    stroke: $main-color;
  }
}

.icons-white {
  svg {
    fill: white;
    transition: fill 0.3s;
    &:hover {
      fill: $blue;
    }
  }
}

.icons-grey {
  svg {
    fill: $blue-m-text;
  }
}

.icons-closeWhite {
  svg {
    fill: #ffffff;
    transition: fill 0.3s;
    &:hover {
      fill: $blue;
    }
  }
}

.header-icon {
  svg {
    fill: #ffffff;
  }
}
.additionalFilterPanel-icon {
  svg {
    fill: black;
    &:hover {
      fill: black;
    }
  }
}

.xxxl {
  > svg {
    height: 50px;
    width: 50px;
    @include _2000 {
      height: 40px;
      width: 40px;
    }
    @include _1700 {
      height: 30px;
      width: 30px;
    }
    @include _1024 {
      height: 25px;
      width: 25px;
    }
    @include _768 {
      height: 22px;
      width: 22px;
    }
  }
}
.xxl {
  > svg {
    width: 30px;
    height: 30px;
    @include _2000 {
      width: 25px;
      height: 25px;
    }
    @include _1700 {
      width: 20px;
      height: 20px;
    }
    @include _1024 {
      width: 22px;
      height: 22px;
    }
  }
}
.xl {
  > svg {
    width: 25px;
    height: 25px;
    @include _2000 {
      width: 20px;
      height: 20px;
    }
    @include _1700 {
      width: 16px;
      height: 16px;
    }
    @include _1024 {
      width: 18px;
      height: 18px;
    }
  }
}

.md {
  > svg {
    width: 18px;
    height: 18px;
    @include _2000 {
      width: 16px;
      height: 16px;
    }
    @include _1700 {
      width: 14px;
      height: 14px;
    }
    @include _1024 {
      width: 16px;
      height: 16px;
    }
  }
}

.xs {
  > svg {
    width: 14px;
    height: 14px;
    @include _2000 {
      width: 12px;
      height: 12px;
    }
    @include _1700 {
      width: 10px;
      height: 10px;
    }
    @include _1024 {
      width: 12px;
      height: 12px;
    }
  }
}

.m-xl {
  > svg {
    width: 20px;
    height: 20px;
  }
}

.m-md {
  > svg {
    width: 18px;
    height: 18px;
  }
}
.icons-crossBlackIcon {
  > svg {
    width: 22px;
    height: 22px;
    @include _2000 {
      width: 18px;
      height: 18px;
    }
    @include _1700 {
      width: 14px;
      height: 14px;
    }
  }
}

.icons-100 {
  > svg {
    width: 100%;
    height: 100%;
  }
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.icons-arrowForPagination {
  svg {
    stroke: $main-color;
    transition: stroke 0.3s;
    &:hover {
      stroke: $blue;
    }
  }
}
