@import "../../styles/_variables.scss";

.inputPassword {
  display: flex;
  flex-direction: column;
  @include gapMin;
  position: relative;
  &-auth-label {
    color: white;
    font-family: "Montserrat";
    @include FSbaseBold;
  }
  &-auth-input {
    background: white;
    @include borderRadius;
    padding: 12px 30px 12px 10px;
    color: black;
    font-size: 18px;
    @include FSsubtitle;
    font-family: "Montserrat";
    height: 45px;
    width: 100%;
    @include _2000 {
      padding: 8px 30px 8px 10px;
      height: 40px;
    }

    @include _1700 {
      height: 35px;
    }
    &::placeholder {
      @include FSsubtitle;
      font-family: "Montserrat";
    }
  }
  &-label {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    color: $blue;
  }
  &-wrapper {
    position: relative;
  }
  &-input {
    border: 1px solid $grey;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 12px 30px 12px 10px;
    color: black;
    background: white;
    @include borderRadius;
    height: 45px;

    @include _2000 {
      padding: 8px 30px 8px 10px;
      height: 40px;
    }
    @include _1700 {
      height: 35px;
    }
    // @include _1024 {
    //   height: 40px;
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    &::placeholder {
      color: $blue-m-text;
    }
    // }
  }

  &-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}
