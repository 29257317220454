@import "../../../../../styles/variables";

.basicInformation {
  display: flex;
  @include gapBig;
  padding: 0 20px 0 10px;
  display: grid;
  grid-template-columns: 3fr 1fr;
  height: calc(100vh - 300px);
  @include _1300 {
    height: calc(100vh - 270px);
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
    }
    &-item.desc {
      span:not(.title) {
        font-weight: 400;
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
      }
      span.title {
        color: $blue;
      }
      // span.text {
      //   @include nowrap;
      // }
    }
    &-specifications {
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(4, minmax(200px, 1fr));
      flex-wrap: wrap;
      @include gapBig;
      @include _1700 {
        grid-template-columns: repeat(3, minmax(200px, 1fr));
      }
      @include _1300 {
        grid-template-columns: repeat(2, minmax(200px, 1fr));
      }
      &-block {
        &-item {
          display: flex;
          flex-direction: column;
          @include gapMin;
          span.title {
            color: $blue;
          }
          // span.text {
          //   @include nowrap;
          // }
        }
      }
    }
    &-btn {
      display: flex;
      justify-content: center;
      align-items: center;
      background: $red;
      @include borderRadius;
      padding: 8px 14px;
      max-width: fit-content;
      cursor: pointer;
      span {
        color: white;
        // font-weight: 700;
        // font-size: 18px;
        @include FSsubtitle;
      }
    }
  }
  &-edit {
    width: 100%;
    // max-width: 884px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-forms {
      display: flex;
      flex-direction: column;
      @include gapBig;
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
      &-set {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include gapBig;
        &-block {
          width: 100%;
          display: flex;
          flex-direction: column;
          @include gapBig;
        }
      }
    }
    .select-title {
      font-family: "Montserrat";
      @include FSbaseBold;
      color: $blue;
    }
    &-btn {
      display: flex;
      flex-direction: column;
      @include gapMin;
      padding-bottom: 20px;
      &-set {
        display: flex;
        @include gapMin;
      }
    }
  }
}
