@import "/src/styles/variables";

.projectWorkSchedule {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 55px;
    .button span {
      font-weight: normal;
    }
  }
  &-chart {
    display: flex;
    flex-direction: column;
    @include gapBig;
    padding-bottom: 20px;
    &-header {
      display: flex;
      justify-content: space-between;
      position: relative;
      &-btn {
        @include flexAllCenter();
        max-width: 44px;
        justify-content: center;
        background: #0094e7;
        @include borderRadius;
        padding: 5px 10px;
        height: 34px;
        cursor: pointer;
        img {
          @include SmdBtn;
        }
      }
      &-btn:last-child {
        width: 44px;
        padding: 0px;
        span {
          font-size: 30px;
          color: white;
        }
      }
      &-right {
        @include flexGap(351px, row);
      }
      &-colendarPanel {
        @include flexGap(20px, row);
        align-items: center;
        &-prev,
        &-next {
          @include flexAllCenter();
          background: rgba(255, 255, 255, 0.5);
          border: 1px solid #c0c0c0;
          @include borderRadius;
          width: 32px;
          height: 32px;
          cursor: pointer;
          > img {
            @include SmdBtn;
          }
        }
        &-next svg {
          transform: rotate(180deg);
        }
        &-value {
          @include flexAllCenter();
          background: #04488e;
          @include borderRadius;
          padding: 8px 30px;
          min-width: 173px;
          span {
            @include FSbaseBold;
            color: white;
          }
        }
      }
      &-size {
        @include flexGap(10px, row);
        &-btn {
          @include flexAllCenter();
          max-width: 44px;
          justify-content: center;
          background: #0094e7;
          @include borderRadius;
          padding: 5px 10px;
          height: 34px;
          cursor: pointer;
          > img {
            @include SmdBtn;
          }
        }
      }
      &-menuImport {
        position: absolute;
        bottom: -70px;
        left: 0;
        background: #ffffff;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 10px;
        @include flexGap(10px, col);
        span {
          @include FSmdMed;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            transition: all 0.2s;
            color: $main-color-light;
          }
        }
      }
    }
    &-main {
      display: flex;
    }
  }
}
