@import "/src/styles/variables";

.projectWorkScheduleColListRow {
  display: grid;
  @include gapMin;
  justify-content: space-between;
  grid-template-columns:
    40px
    3fr
    // minmax(auto, 200px)
    1fr
    1fr
    1fr
    1fr
    1fr
    100px;
  align-items: center;
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 14.5px 10px;
  @include _1024 {
    grid-template-columns:
      40px
      3fr
      // minmax(auto, 200px)
      1fr
      1fr
      1fr
      1fr
      1fr
      20px;
  }
  img {
    cursor: pointer;
  }
  span {
    text-align: center;
    @include FSbaseBold;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  &-box {
    display: flex;
    justify-content: center;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    &-checkbox {
      display: flex;
      gap: 20px;
    }
    &-btns {
      display: flex;
      gap: 10px;
      justify-content: flex-end;
    }
  }
}
