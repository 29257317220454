@import "./src/styles/variables";

.menuDropdownWrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  padding: 10px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 5px 10px 0px rgba(133, 140, 147, 0.12);
  width: fit-content;
  z-index: 100;
  &-navigate {
    padding: 5px 0 5px 0;
    //@include gapSm;
  }
  &-left {
    top: 50%;
    transform: translate(0, -50%);
    right: 105%;
  }
  &-top {
    bottom: 105%;
    left: 0%;
  }
  &-bottom {
    top: 110%;
    left: 50%;
    right: 0%;
    transform: translate(-50%, 0);
    width: max-content;
  }
  &-right {
    top: 50%;
    transform: translate(0, -50%);
    left: 105%;
  }
  &-bottom-left {
    top: 110%;
    left: 0%;
    right: 0%;
    // transform: translate(-50%, 0);
    width: max-content;
  }
  &-bottom-right {
    top: 110%;

    right: 0%;
    // transform: translate(-50%, 0);
    width: max-content;
  }
}
