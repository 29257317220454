@import "/src//styles/variables";

.taskListForLayer {
  &-row {
    width: 100%;

    &__cards {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      margin: 0 -15px 0 -10px;
      @include _1700 {
        margin-bottom: 10px;
      }
    }
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-header {
    font-family: "Montserrat";
    list-style-type: none;
    width: 100%;
    margin: 0;
    display: grid;
    gap: 10px;
    @include headerRow;
    padding-right: 10px;
    position: relative;
    grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 120px;
    @include _1900 {
      grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 95px;
    }
    @include _1455 {
      grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1.2fr 1.2fr 1fr 95px;
    }
    &-item {
      display: flex;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        vertical-align: middle;
        height: auto;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-item.numder-item {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }
  &-cards {
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include _1700 {
      margin-bottom: 10px;
    }
  }

  .emptySection {
    height: 350px;
  }
}
