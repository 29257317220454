@import "../../../../styles/variables";

.layoutMobileHeader {
  width: 100%;
  background-color: $main-color;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999;
  position: fixed;

  &-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
    padding: 10px 0;
  }
  &-left {
    width: 24px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-plug {
    width: 40px;
  }
  &-name {
    display: flex;
    align-items: center;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
    gap: 10px;
    &-title {
      @include FStitleM;
      color: #fff;
      @include nowrap;
      display: inline-block;
      text-align: start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  &-menu-icon {
    width: 24px;
    height: 24px;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 3px;
      width: 100%;
      background: #fff;
      border-radius: 2px;
      opacity: 1;
      left: 0;
      -webkit-transform: rotate(0deg);
      -moz-transform: rotate(0deg);
      -o-transform: rotate(0deg);
      transform: rotate(0deg);
      -webkit-transition: 0.25s ease-in-out;
      -moz-transition: 0.25s ease-in-out;
      -o-transition: 0.25s ease-in-out;
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2),
      &:nth-child(3) {
        top: 9px;
      }
      &:nth-child(4) {
        top: 18px;
      }
    }
  }
  &-menu-icon.open span {
    &:nth-child(1) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
    &:nth-child(2) {
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    &:nth-child(3) {
      -webkit-transform: rotate(-45deg);
      -moz-transform: rotate(-45deg);
      -o-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
    &:nth-child(4) {
      top: 18px;
      width: 0%;
      left: 50%;
    }
  }
}
