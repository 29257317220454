@import "/src/styles/variables";

.modalTask {
  display: flex;
  flex-direction: column;
  @include gapBig;
  position: relative;
  &-btns {
    display: grid;
    grid-template-columns: 1fr 40px;
    @include gapBig;
    // @include _768 {
    //   display: flex;
    //   flex-direction: column;
    //   align-items: flex-end;
    //   width: 100%;
    // }
    &-wrapper {
      display: flex;
      @include gapBig;
      width: 100%;
    }
    &-wrapper-mobile {
      display: flex;
      @include gapBig;
      width: 100%;
      justify-content: center;
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-btn-comment {
    cursor: pointer;
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    @include _2000 {
      height: 40px;
      width: 40px;
    }
    @include _1700 {
      height: 35px;
      width: 35px;
    }

    @include HoverBtnBlue;
    @include borderRadius;
    &.countComment {
      position: relative;
      overflow: visible;
      &::after {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        content: attr(data-count);
        top: -10px;
        padding: 2px;
        right: -10px;
        // transform: translate(-50%);
        background-color: $yellow;
        border-radius: 50%;
        min-width: 16px;
        height: 16px;
        color: #fff;
      }
      &[data-count=""]::after {
        color: #fff;
      }
    }
  }
  &-main {
    display: grid;
    @include gapBig;
    grid-template-columns: 1fr 350px;
    &.fullScreen {
      grid-template-columns: 3fr 600px;
    }
    &.rows {
      display: flex;
      flex-direction: column-reverse;
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
    @include _1455 {
      grid-template-columns: 3fr 300px;
    }
    @include _1300 {
      display: flex;
      flex-direction: column;
    }
    span {
      font-family: "Montserrat";
    }
    &-right {
      @include _600 {
        grid-template-columns: 1fr;
      }
    }
    &-chat {
      position: absolute;
      width: 440px;
      height: fit-content;

      // border: 1px solid $grey;

      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      @include borderRadius;
      padding: 12px;
      right: 0;
      z-index: 4;
      bottom: 0;
      // max-height: 90%;
      span {
        font-family: "Montserrat";
      }
      .title {
        color: $blue;
        font-weight: 700;
      }
      .bold {
        @include FSbaseBold;
      }
      @include _600 {
        width: 100%;
      }
    }

    &-edit {
      display: grid;
      @include gapBig;
      grid-template-columns: 3fr 440px;

      span {
        font-family: "Montserrat";
      }
      .title {
        color: $blue;
        font-weight: 700;
      }
      .bold {
        @include FSbaseBold;
      }
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
    .title {
      color: $blue;
      font-weight: 700;
    }
    .bold {
      @include FSbaseBold;
    }
  }
  &-select-change {
    width: 30%;
    .react-select__single-value {
      @include FSbaseBold;
    }
    @include _768 {
      width: 100%;
    }
  }
}
.modalTaskMobile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
    @include _768 {
      grid-template-columns: 1fr;
    }
  }
  &-btns {
    display: flex;
    gap: 10px;
    @include _600 {
      .select {
        width: 50% !important;
      }
    }
    @include _400 {
      flex-direction: column;
      .select {
        width: 100% !important;
      }
    }
  }
}
