@import "../../styles/variables";

.modalAddProfile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include gapBig;
    width: 100%;
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
