@import "../../../../../styles/variables";

.deliveryWorkRequestsRow {
  @include cardRowWrapper;
  position: relative;
  &__list {
    list-style-type: none;
    @include borderRadius;
    gap: 5px;
    display: flex;
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns:
      // 80px

      2fr
      2fr
      2fr
      2fr
      2fr
      1.5fr
      3fr
      90px;
  }
  &__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    padding: 1px;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: $blue-dark;
    }
    &-number {
      justify-content: flex-start;
      > span {
        margin-left: 20px;
        color: #000;
      }
    }
    &-typeWork {
      @include borderRadius;
      overflow: hidden;
      align-self: center;

      cursor: pointer;
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-dateStart {
      > span {
        color: $completed;
      }
    }
    &-typeReceiving {
      > span {
        color: #000;
      }
    }
    &-btns {
      justify-content: flex-end;
      overflow: visible;
    }
    &-atWork {
      > span {
        color: $atWork;
      }
    }
    &-rejected {
      > span {
        color: $rejected;
      }
    }
    &-completed {
      > span {
        color: $completed;
      }
    }
    &-onVerification {
      > span {
        color: $onVerification;
      }
    }
    &-overdue {
      > span {
        color: $overdue;
      }
    }
    &-assigned {
      > span {
        color: $assigned;
      }
    }
    &-btns {
      display: flex;
      gap: 10px;
      overflow: visible;
    }
  }

  &__btn {
    background-color: #fff;
    overflow: visible;
    &-editMenu {
      position: absolute;
      top: 40px;
      background: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      padding: 10px;
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      gap: 10px;
      right: 0;
      z-index: 5;

      span {
        font-family: "Montserrat";
        @include FSmdMed;
        color: #2c2c2c;
      }
      @include HoverModalText;
      // span:hover {
      //   color: $main-color;
      // }
    }
    > img {
      @include SmdBtn;
    }
  }
  &__btn:nth-child(1) {
    margin-left: 0;
  }
}
