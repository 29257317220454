.documentationProjectMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
}
