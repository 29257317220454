@import "./src/styles/variables";

.modalForward {
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-form {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-chat {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
    input {
      height: 70px;
    }
    &-btns {
      display: flex;
      gap: 5px;
      justify-content: space-between;
    }
  }
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-chatList {
    display: flex;
    flex-direction: column;
    @include gapMin;
    max-height: 500px;
  }
}
