@import "/src/styles/variables";

.skeletonStucuresRow {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px 0 10px;

  @include _1024 {
    gap: 10px;
    padding: 0;
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 20px;
    border: 1px solid $main-color-ultra-light;
    @include borderRadius;
    width: 100%;
    height: 80px;
    padding: 0 20px 0 10px;
    background-color: #fff;
    &-wrapper {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    @include _1024 {
      height: 50px;
      padding: 0 10px;
      gap: 10px;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-left: 10px;
    @include _1024 {
      gap: 10px;
    }
  }
  &-item {
    border: 1px solid $main-color-ultra-light;
    @include borderRadius;
    width: 100%;
    background-color: #fff;
    height: 60px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 20px 10px 20px;
    @include _1024 {
      justify-content: center;
      height: 40px;
      padding: 0 10px;
      gap: 10px;
    }
  }
}
