@import "src/styles/variables";

.breadcrumbsItem {
  display: flex;
  align-items: center;

  gap: 5px;
  &-arrow {
    transform: rotate(180deg);
  }
  &-name {
    @include FSbaseBold;
    max-width: 200px;
    @include nowrap;
  }
}
