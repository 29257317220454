@import "../../styles/variables";

.inputFile {
  display: flex;
  flex-direction: column;
  @include gapMin;
  max-width: 440px;
  width: 100%;
  height: auto;
  input {
    display: none;
  }
  span {
    font-family: "Montserrat";
  }
  &-title {
    @include FSbaseBold;
    color: #04488e;
  }
  &-zone {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-input {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 8px 10px;
      font-size: 16px;
      height: 45px;
      width: 100%;
      @include FSbaseReg;
      @include _2000 {
        height: 40px;
      }
      @include _1700 {
        height: 35px;
      }
    }
    &-list {
      padding: 2px;
      max-width: 440px;
      max-height: 190px;
      display: flex;
      flex-direction: column;
      gap: 5px;
      .docItem {
        width: 100%;
        max-width: none;
        height: 40px;
      }
    }
  }
}
