@import "../../styles/variables";

.imageUpload {
  @include ProfileAvatar;
  position: relative;
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  overflow: hidden;
  cursor: pointer;
  &__input {
    display: none;
    &-img {
      cursor: default;
    }
  }
  &__image {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    cursor: pointer;
    object-fit: cover;
    &-no-edit {
      cursor: default;
    }
  }
  &__btns {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #ffffff;
    width: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include gapMin;
    padding: 5px;
    border-radius: 10px 0px;
  }
  &__btn {
    background-color: #ffffff;
    > img {
      @include SmdBtn;
    }
  }
  &-profileAvatar {
    @include ProfileAvatar;
    overflow: visible;
    margin-bottom: 50px;
    &__image {
      @include borderRadius;
    }
    &__btns {
      left: 0;
      bottom: -50px;
      width: 100%;
      background-color: $background-color-content;
      display: flex;
      justify-content: flex-start;
      padding: 0;
      @include gapBig;
    }
    &__btn-big {
      font-family: "Montserrat";
      // width: 231px;
      width: 100%;
      height: 34px;
      background-color: $main-color;
      @include borderRadius;
      color: #ffffff;
      font-weight: 700;
      @include flexAllCenter();
      @include FSbtn;
      line-height: 18px;
    }
  }
  &-profileLogo {
    // width: 184px;
    // height: 184px;
    @include ProfileAvatar;
  }
  &-profileMobileAvatar {
    @include ProfileAvatar;
    // @include min_480 {
    //   width: 300px;
    // }
    // @include min_768 {
    //   width: 100%;
    //   height: 200px;
    // }
  }
}
