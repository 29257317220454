@import "../../../../../styles/variables";

.projectTasks {
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding-bottom: 60px;
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }
}
