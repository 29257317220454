@import "../../../../styles/_variables";

.companyProfiles {
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding: 0 20px 0 10px;
  padding-bottom: 100px;
  &-search {
    padding: 10px;
    @include borderRadius;
    background-color: #fff;
  }
  &-header {
    @include headerWrapper;
    display: grid;
    grid-template-columns: 2fr 3fr 20px;
    &-item {
      display: flex;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        vertical-align: middle;
        height: auto;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-clik {
        cursor: pointer;
      }
    }
  }

  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
