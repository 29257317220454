@import "/src/styles/variables";

.profileRole {
  font-family: "Montserrat";
  font-style: normal;
  padding: 0 20px 0 10px;

  &-title {
    @include FSsubtitle;
    line-height: 18px;
    margin-bottom: 20px;
    @include _1700 {
      margin-bottom: 10px;
    }
  }
  &-list {
    display: flex;
    list-style-type: none;
    gap: 20px;
    border-bottom: 2px solid $main-color-light;
    padding-bottom: 20px;
    @include _1700 {
      padding-top: 10px;
    }
    @include _1455 {
      flex-direction: column;
    }
  }
}
