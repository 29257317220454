@import "./src/styles/variables";

.modallAddPosition {
  display: flex;
  flex-direction: column;
  @include gapBig;
  position: relative;
  // @include _1024 {
  height: 100%;
  // }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
