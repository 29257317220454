@import "/src/styles/variables";

.modalAddPointInLayer {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: #7bc7f1;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-header {
    background: #7bc7f1;
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    span {
      @include FSbaseBold;
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapMin;
    position: relative;
    overflow: hidden;
    display: grid;
    grid-template-rows: 20px calc(100dvh - 420px) 45px;
    @include _2000 {
      grid-template-rows: 20px calc(100dvh - 300px) 40px;
    }

    //grid-template-rows: 20px 1fr 40px;
    @include _1024 {
      grid-template-rows: 20px calc(100dvh - 260px);
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-wrapper {
      min-height: 400px;
      width: 100%;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-plan {
      display: flex;
      align-items: center;
      justify-content: center;
      @include borderRadius;
      // height: 660px;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      position: relative;
      overflow: hidden;
      width: 100%;
      @include _1024 {
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
      cursor: default;
      &.watch {
        cursor: url("../../assets/icons/handBlack.svg"), auto;
      }
      &.cleaning {
        cursor: url("../../assets/icons/cleaning-black.svg"), move;
      }
      &.draw {
        cursor: url("../../assets/icons/pencil.svg") 0 20, move;
      }
      &.pin {
        cursor: url("../../assets/icons/point-black.svg") 10 25, move;
      }
    }
    &-btnChoose {
      display: flex;
      @include gapBig;
      align-items: center;
      max-width: 264px;
      position: absolute;
      bottom: 75px;
      right: 20px;
      z-index: 99;
      @include _768 {
        bottom: 110px;
      }
      &-item {
        display: flex;
        justify-content: center;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        height: 40px;
        width: 40px;
        cursor: pointer;
        @include _1700 {
          width: 35px;
          height: 35px;
        }
        img {
          width: 22px;
          height: 22px;
          @include _2000 {
            width: 18px;
            height: 18px;
          }
          @include _1700 {
            width: 16px;
            height: 16px;
          }
        }
      }
      &-item.active {
        background: $blue;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        @include borderRadius;
      }
    }
    &-btns {
      display: flex;
      @include gapMin;
      justify-content: center;
      @include _768 {
        flex-direction: column;
      }
    }
  }
  .title {
    color: $blue;
    font-weight: 700;
  }
}
