@import "/src/styles/variables";

.projectWorkScheduleAdd {
  @include flexGap(20px, col);
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-list {
    display: flex;

    flex-wrap: wrap;
  }
  &-employees {
    display: flex;
    gap: 10px;
    // padding: 12px 10px;
    // border: 1px solid $grey;
    // @include borderRadius;
    overflow-y: auto;
    flex-wrap: wrap;
    max-height: 88px;
    > span {
      @include FSbaseBold;
    }
  }
  &-title {
    @include FSbaseBold;
  }
  &-gridEmployeesInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    gap: 20px;
    @include _1024 {
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-gridScheduleInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @include _1024 {
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-gridEmployees {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    &-edit {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 1fr 1fr;
      max-width: 1360px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-gridDateInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;
    @include _1024 {
      grid-template-columns: 1fr 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 20px;

    @include _1024 {
      grid-template-columns: 1fr 1fr;
      column-gap: 10px;
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
  }
  &-grid-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}
