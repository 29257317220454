@import "src/styles/variables";

.errorBoundary {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  &-title {
    @include FSsubtitle;
  }
  &-content {
    max-width: 90dvw;
    display: flex;
    flex-direction: column;
    @include gapMin;
    border: 1px solid red;
    border-radius: 5px;
    padding: 10px;
    color: red;
    position: relative;
  }
}
