@import "/src/styles/variables";

.menuMarks {
  width: 100%;
  max-width: 297px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-bottom: 10px;
  z-index: 1;
  @include _768 {
    max-width: 100%;
  }
  &-delelePoint {
    @include flexGap(10px, row);
    align-items: center;
    padding: 10px;
    background: $orange;
    @include borderRadius;
    width: 100%;
    .hr {
      background: #ffffff;
      @include borderRadius;
      height: 36px;
      width: 2px;
    }

    span {
      font-size: 12px !important;
      // white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Montserrat";
      @include FSsubtitle;
      color: white;
      width: 100%;
    }
  }
  &-btns {
    @include flexGap(10px, row);
    align-items: center;
    padding: 10px;
    @include borderRadius;
    width: 100%;
    background: $blue;
    cursor: pointer;
    height: 45px;
    @include _2000 {
      padding: 5px;
      height: 40px;
    }
    @include _1700 {
      height: 35px;
    }
    .hr {
      background: #ffffff;
      @include borderRadius;
      height: 36px;
      width: 2px;
    }

    span {
      text-align: center;
      text-overflow: ellipsis;
      overflow: hidden;
      font-family: "Montserrat";
      @include FSsubtitle;
      color: white;
      width: 100%;
    }
  }
  &-selectMarks {
    position: relative;
    &-item {
      display: flex;
      gap: 10px;
      align-items: center;
      img {
        @include SmdBtn;
      }
    }
    @include _768 {
      display: grid;
      grid-template-columns: 1fr 40px;
      gap: 10px;
    }
    &-bnts {
      width: 40px;
      height: 40px;
      @include borderRadius;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $blue;
      svg:hover {
        fill: White;
      }
    }
  }
  &-choose {
    display: flex;
    gap: 20px;
    align-items: center;
    &-item {
      padding: 9px 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      height: 100%;
      width: 100%;
      cursor: pointer;
    }
    &-item.active {
      background: $blue;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
    }
  }
  &-task {
    &-title {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 700;
      color: $blue;
      @include FSstrSub;
      margin-bottom: 10px;
    }
    &-list {
      list-style-type: none;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
    &-item {
      cursor: pointer;
      @include cardRowWrapper;
      > span {
        color: $blue;
        @include FSstrSub;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      @include HoverTaskRow;
    }
  }
  &-task-container-taskList {
    position: relative;
    height: 0;
  }
  &-task-mobile {
    position: absolute;
    top: 40px;
    width: 100%;
    background-color: #ffffff;
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    @include borderRadius;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    &-title {
      @include FSbaseBoldM;
      color: $blue;
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapMin;
      max-height: 300px;
    }
    &-item {
      cursor: pointer;
      width: 100%;
      @include cardRowWrapper;
      > span {
        color: $blue;
        @include FSstrSub;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
