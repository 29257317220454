@import "./src/styles/variables";

.employeeBaseInfoProfile {
  border-top: 1px solid $blue-m-bg;

  padding-top: 20px;
  display: flex;
  @include _1700 {
    padding-top: 10px;
  }
  @include _768 {
    flex-direction: column;
    @include gapBig;
  }
  &-list {
    display: grid;
    @include gapBig;
    width: 100%;
    grid-template-columns: repeat(4, minmax(125px, 1fr)) 160px;
    @include _768 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _480 {
      display: flex;
      flex-direction: column;
    }
  }
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _480 {
      flex-direction: row;
    }
    &-title {
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
    }
    &-text {
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      color: #000000;
      //   @include nowrap;
      max-width: 99%;
      &-hover {
        cursor: pointer;
        transform: all 0.3s;
        &:hover {
          color: $main-color;
          text-decoration: underline;
        }
      }
    }
  }
}
