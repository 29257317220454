@import "/src/styles/variables";

.projectEditInfo {
  display: flex;
  flex-direction: column;
  @include gapBig;
  background: #ffffff;
  border: 2px solid $main-color;
  @include borderRadius;
  padding: 10px;
  height: fit-content;
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &.rows {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 50%));
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
    @include _1300 {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
  }
  &-item.horizontal {
    flex-direction: row;
  }
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    width: 100%;

    .title,
    .bold {
      @include FSbaseBold;
    }
    &-textBox {
      display: flex;
      // flex-direction: column;
      align-items: center;
      @include gapMin;
      &-title {
        color: $blue;
        &-checkBox {
          display: flex;
          align-items: center;
          @include gapMin;
        }
      }
      .grey {
        color: $grey;
      }
      span {
        @include FSbaseBold;
      }
      @include _400 {
        &.checkBox {
          justify-content: flex-start;
        }
      }
    }
    &-canvas {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid $blue-m-bg;
      > div {
        zoom: 0.85;
        @include _2000 {
          zoom: 0.85;
        }
        @include _1700 {
          zoom: 0.75;
        }
        @include _1455 {
          zoom: 0.6;
        }
        @include _1300 {
          zoom: 0.55;
        }
      }

      &.zoomRows.zoomSmall {
        > div {
          zoom: 0.75;
          @include _2000 {
            zoom: 0.65;
          }

          @include _1455 {
            zoom: 0.55;
          }
          @include _1300 {
            zoom: 0.5;
          }
          @include _1024 {
            zoom: 0.45;
          }
          @include _900 {
            zoom: 0.4;
          }
          @include _768 {
            zoom: 0.7;
          }

          @include _600 {
            zoom: 0.6;
          }
          @include _400 {
            zoom: 0.35;
          }
        }
      }
      &.zoomColumns {
        > div {
          zoom: 0.7;
          @include _2000 {
            zoom: 0.55;
          }
        }
      }
    }
    &-select {
      display: flex;
      align-items: center;
      gap: 15px;
      .select,
      .inputSearch {
        width: 100%;
      }
      .icons-closeBlack {
        padding-top: 28px;
      }
    }
  }
}
