@import "src/styles/variables";

.accessInfo {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr);
    @include gapMin;
    @include _1024 {
      grid-template-columns: 1.5fr repeat(6, 1fr);
    }
    &.file {
      grid-template-columns: 2fr repeat(5, 1fr);
      @include _1024 {
        grid-template-columns: 1.5fr repeat(5, 1fr);
      }
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-title.name {
      text-align: start;
    }
    span {
      text-align: center;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;

    max-height: 120px;
  }
}
.accessInfoMobile {
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
