@import "/src/styles/variables";

.projectWorkScheduleCol {
  display: flex;
  flex-direction: column;
  @include gapBig;
  span {
    font-size: "Montserrat";
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapBig;
    position: relative;
    &-header {
      display: flex;
      flex-direction: column;
      @include gapBig;
      position: relative;

      &-btn {
        @include flexAllCenter();
        max-width: 44px;
        width: 100%;
        justify-content: center;
        background: #0094e7;
        @include borderRadius;
        padding: 5px 10px;
        height: 34px;
        cursor: pointer;
        span {
          font-size: 30px;
          color: white;
        }
      }
      &-menuImport {
        position: absolute;
        bottom: -70px;
        left: 0;
        background: #ffffff;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 10px;
        z-index: 2;
        display: flex;
        flex-direction: column;
        @include gapMin;
        span {
          @include FSmdMed;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            transition: all 0.2s;
            color: $main-color-light;
          }
        }
      }
    }
    &-stages {
      // @include flexGap(20px, col);
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
        position: relative;
        &-works {
          display: flex;
          flex-direction: column;
          @include gapMin;
          padding-bottom: 20px;
          padding-left: 30px;
          position: relative;
          &-line {
            position: absolute;
            width: 3px;
            background-color: #c4c4c4;
            top: -20px;
            left: 9px;
            z-index: 1;
            height: 100%;
          }
        }
        &:last-child {
          .projectWorkScheduleCol-list-stages-item-works-line {
            display: none;
          }
        }
        &:not(:first-child) {
          &::before {
            content: "";
            position: absolute;
            width: 3px;
            height: 40px;
            background-color: #c4c4c4;
            top: -20px;
            left: 9px;
            z-index: 1;
          }
        }
      }
    }
  }
}
