@import "../../../../../../../styles/_variables.scss";

.profileMobileTabsList {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 2px;
  &-header {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    &-title {
      @include FSbaseBoldM;
      color: $blue;
    }
  }
}
