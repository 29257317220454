@import "../../../../../styles/_variables.scss";

.profileMobileEdit {
  width: 100%;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    border-bottom: 1px solid $main-color;
    padding: 20px;
    &-title {
      @include FStitleM;
      text-align: center;
    }
    &-subtitle {
      @include FSsubtitleM;
      text-align: center;
    }
  }
}
