@import "./src/styles/variables";

.modalInfoDeliveryWork {
  @include flexGap(20px, col);
  &-work {
    display: flex;
    flex-direction: column;
    // gap: 20px;
    &-wrapper {
      display: grid;
      gap: 20px;
      grid-template-columns: 1fr 0.5fr;
      &:not(:last-child) {
        border-bottom: 1px solid $main-color;
      }
      padding-bottom: 20px;
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-btns {
    display: flex;
    justify-content: center;
    &-engineer,
    &-creator {
      display: flex;
      gap: 20px;
      .button {
        width: 222px;
      }
    }
  }
  &-info {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 0.5fr;
  }
  &-work {
    @include flexGap(20px, col);

    &-info {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @include _1300 {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &-engineerAnswer {
      display: flex;
      flex-direction: column;
      gap: 20px;
      // padding: 20px 0 20px 0;
      &-content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @include _1300 {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
    &-mail {
      display: flex;
      flex-direction: column;
      gap: 20px;
      &-grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 20px;
        @include _1300 {
          grid-template-columns: repeat(2, 1fr);
        }
      }
    }
  }

  &-fileList {
    @include flexGap(10px, col);
    max-width: 400px;
  }

  &-item {
    @include flexGap(10px, col);
    &-row {
      display: flex;
      gap: 60px;
    }
    &-person {
      @include flexGap(10px, col);

      > div {
        display: flex;
        justify-content: flex-start;
      }
      &-logo {
        border-radius: 50%;
        background-size: cover;
        background-position: center;
        @include XSavatar;
      }
      &-info {
        @include flexGap(10px, col);
        gap: 5px;
        &-text {
          @include FSbaseReg;
        }
      }
    }
    &-date {
      display: flex;
      flex-direction: column;
      gap: 20px;

      &-elem {
        display: flex;
        flex-direction: column;
        gap: 10px;
        @include _600 {
          flex-direction: row;
        }
      }
    }
    &-list {
      // @include flexGap(10px, row);
      // flex-wrap: wrap;
      // max-height: 70px;
      // overflow-y: scroll;
      // border: 1px solid $grey;
      // @include borderRadius;
      // padding: 12px 20px;
    }
    &-btns {
      @include flexGap(20px, row);
    }
    &-select {
      width: 220px;
    }
    &-historyChanges {
      @include flexGap(10px, col);
      padding-bottom: 20px;
    }
  }
  span {
    // @include FSbaseReg;
  }
  .title {
    color: $blue;
    font-weight: 700;
    @include FSbaseBold;
  }
  .bold {
    @include FSbaseBold;
  }
}
