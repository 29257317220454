@import "/src/styles/variables";

.skeletonPermissions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: fit-content;
  &-header {
    display: grid;
    width: 100%;
    grid-template-columns: 2fr repeat(6, 1fr);
    // gap: 5px;
    gap: 20px;
    &.permissionsFile {
      grid-template-columns: 2fr repeat(5, 1fr);
    }
    &.permissionsFileEdit {
      grid-template-columns: 2fr repeat(5, 1fr) 40px;
    }
    &.permissionsFolderEdit {
      grid-template-columns: 2fr repeat(6, 1fr) 40px;
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-title.name {
      text-align: start;
    }
    span {
      text-align: center;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-box {
    display: flex;
    justify-content: center;
  }
  &-item {
    width: 100%;
    display: grid;
    grid-template-columns: 2fr repeat(6, 1fr);
    padding: 15px 0 5px 5px;
    border-top: 1px solid $blue-m-bg;
    gap: 20px;
    align-items: center;
    justify-content: space-between;
    &.permissionsFolderEdit {
      grid-template-columns: 2fr repeat(6, 1fr) 40px;
    }
    &.permissionsFileEdit {
      grid-template-columns: 2fr repeat(5, 1fr) 40px;
    }
  }
}
