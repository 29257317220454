@import "/src/styles/variables";

.ModalEditTask {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-main {
    overflow-x: hidden;

    @include gapBig;

    &-form {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    &-chat {
      width: 100%;
      background: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      padding: 12px;
      height: fit-content;
    }

    &-edit {
      display: grid;
      @include gapBig;

      grid-template-columns: 3fr 350px;
      &.fullScreen {
        grid-template-columns: 3fr 600px;
      }
      &.rows {
        display: flex;
        flex-direction: column-reverse;
        @include _1300 {
          display: flex;
          flex-direction: column;
        }
      }
      @include _1455 {
        grid-template-columns: 3fr 300px;
      }
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-btnCreate {
    display: flex;
    justify-content: center;
  }
  .title {
    color: $blue;
    @include FSbaseBold;
  }
  .bold {
    @include FSbaseBold;
  }
}
