@import "src/styles/variables";

.formCreate {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) 2fr;
  gap: 20px;
  @include _1300 {
    grid-template-columns: 1fr 700px;
  }
  @include _1024 {
    grid-template-columns: 1fr 400px;
  }
  @include _768 {
    display: flex;
    flex-direction: column-reverse;
  }
  &-form {
    background: white;
    border: 1px solid $grey;
    @include borderRadius;
    padding: 20px 10px;
    width: 100%;
    max-width: 548px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    &-data {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }
    @include _1024 {
      padding: 10px;
      max-width: 100%;
    }
  }
  .select-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    line-height: 14px;
    color: $blue;
    margin-bottom: 5px;
  }
  .css-1s2u09g-control {
    border: 1px solid $grey;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 10px;
    color: black;
    background: white;
    @include borderRadius;
    min-height: 45px;
    @include _2000 {
      min-height: 40px;
      padding: 5px 5px 5px 10px;
    }
  }
  .css-1pahdxg-control {
    transition: all 0.2s;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 10px;
    color: black;
    background: white;
    @include borderRadius;
    border: 1px solid $grey;
    @include _2000 {
      min-height: 40px;
      padding: 5px 5px 5px 10px;
    }
    &:hover {
      border: 1px solid $grey;
    }
  }
  .css-319lph-ValueContainer {
    padding: 0;
  }
  .css-1pndypt-Input {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
    }
  }
  .period {
    &-title {
      font-family: "Montserrat";
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
      margin-bottom: 5px;
    }
    &-input {
      // border: 1px solid $grey;
      width: 100%;
      padding: 12px 10px;
      height: 100%;
      img {
        top: 14px;
        right: 10px;
      }
      &-date {
        font-family: "Montserrat";
        @include FSbaseReg;
      }
    }
    .rdrDateRangePickerWrapper {
      left: 102%;
      bottom: 0;
    }
  }
  &-viewing {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    // max-width: 832px;
    &-btns {
      display: flex;
      @include gapMin;
      &-btn {
        @include borderRadius;
        padding: 8px 14px;
        width: 100%;
        max-width: 222px;
        font-family: "Montserrat";
        // font-weight: 700;
        // font-size: 18px;
        @include FSsubtitle;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
      }
      &-btn.accept {
        background-color: $main-color;
      }
      &-btn.cancellation {
        background-color: $grey;
      }
    }
  }
  .titleSection {
    font-family: "Montserrat";
    // font-weight: 700;
    // font-size: 18px;
    @include FSsubtitle;
  }
}
