@import "../../styles/variables";

.projects {
  &-list {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    &-filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }
    &-searchPanel {
      padding: 0 20px 0 10px;
    }
    &-cards {
      padding: 0 20px 0 10px;
      display: flex;
      @include gapBig;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
      &-header {
        width: 100%;
        display: grid;
        @include gapMin;
        justify-content: space-between;
        grid-template-columns:
          // 80px

          3fr
          1.5fr
          2fr
          1.5fr
          1.5fr
          3fr
          1.5fr
          20px;
        @include _1700 {
          grid-template-columns:
            3fr
            1.5fr
            2fr
            1.5fr
            1.5fr
            3fr
            1.5fr
            20px;
        }
        @include headerWrapper;
        &-item {
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          justify-content: center;
          text-overflow: ellipsis;
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-item.number {
          display: flex;
          align-items: center;
          @include gapBig;
          justify-content: flex-start;
        }
      }
    }
    &-cards.rows {
      flex-direction: column;
      padding: 0;
      @include gapMin;
      padding: 0 10px 0 5px;
      width: 100%;
    }
    &-cards-column {
      @include cardColumnGrid;
      margin-bottom: 0;
    }
    &-cardCharts {
      padding: 0 10px 0 5px;
      display: flex;
      @include gapMin;
      flex-wrap: wrap;
      &-header {
        display: grid;
        grid-template-columns:
          minmax(auto, 91px)
          minmax(auto, 258px)
          minmax(auto, 210px)
          minmax(auto, 218px)
          minmax(auto, 218px)
          minmax(auto, 114px)
          minmax(auto, 136px)
          minmax(auto, 147px)
          minmax(auto, 150px)
          minmax(100px, 100px);
        padding: 0 20px 0 15px;
        &-item {
          text-align: center;
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
          }
        }
        &-item.number {
          display: flex;
          align-items: center;
          @include gapBig;
        }
      }
    }
    &-pagination {
      margin-top: 20px;
      display: flex;
      justify-content: center;
    }
  }
}
