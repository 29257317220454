@import "../../styles/variables";
.historyChanges {
  display: flex;
  flex-direction: column;
  @include gapMin;
  background-color: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 10px;
  span {
    font-family: "Montserrat";
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
    display: flex;
    justify-content: space-between;
    &-content {
      display: flex;
      align-items: center;
      @include gapMin;
    }
    &-icon {
      &-rotate {
        transform: rotate(180deg);
      }
    }
  }
  &-list {
    list-style-type: none;
  }
  &-item {
    display: grid;
    grid-template-columns: 40px 80px 50px 1fr;
    @include _2000 {
      grid-template-columns: 30px 70px 50px 1fr;
    }
    @include _1300 {
      grid-template-columns: 20px 60px 40px 1fr;
    }

    gap: 5px;
    &-number {
      @include FSbaseReg;
      font-variant-numeric: tabular-nums;
      -moz-font-feature-settings: "tnum";
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      @include _400 {
        @include FSxsBold;
        font-size: 12px !important;
      }
    }
    &-date {
      @include FSbaseReg;
      font-variant-numeric: tabular-nums;
      -moz-font-feature-settings: "tnum";
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      @include _400 {
        @include FSxsBold;
        font-size: 12px !important;
      }
    }
    &-time {
      @include FSbaseReg;
      color: $overdue;
      font-variant-numeric: tabular-nums;
      -moz-font-feature-settings: "tnum";
      -webkit-font-feature-settings: "tnum";
      font-feature-settings: "tnum";
      @include _400 {
        @include FSxsBold;
        font-size: 12px !important;
      }
    }
    &-span {
      @include FSbaseReg;
      color: $blue;
      @include _400 {
        @include FSxsBold;
        font-size: 12px !important;
      }
      &-fio {
        @include FSbaseBold;
        @include _400 {
          @include FSxsBold;
          font-size: 12px !important;
        }
      }
    }
  }
}
