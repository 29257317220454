@import "../../styles/variables";

.layoutMobile {
  width: 100%;
  // height: 100vh;
  background-color: $background-color-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.main-mobile {
  width: 100%;
  height: auto;
  background-color: $background-color-content;
  padding: 60px 0 0;
  position: relative;
  &-fixed {
    overflow: hidden;
  }
}
