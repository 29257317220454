@import "../../../../../../styles/variables";

@keyframes down {
  0% {
    transform: translateY(-50px);
    opacity: 0;
  }
  50% {
    transform: translateY(-15px);
    opacity: 0.4;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
.structuresListLastRow {
  //   padding-left: 15px;
  //   padding-top: 20px;
  list-style-type: none;
  //   overflow: hidden;
  //   padding-bottom: 20px;
  animation: down 0.1s;
  position: relative;
  font-family: "Montserrat";
  margin: 0 20px 20px 20px;
  &:last-child {
    margin-bottom: 0px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px 10px 20px;
    background: #7bc7f1;
    @include borderRadius;
    height: 60px;
    color: #fff;
    z-index: 1;
    @include _1024 {
      height: 40px;
      padding: 0 10px;
      gap: 10px;
    }
    &::before {
      content: " ";
      position: absolute;
      width: 40px;
      bottom: -25px;
      left: -22px;
      height: 100px;
      bottom: 10px;
      background-image: url("../../../../../../assets/icons/angle.svg");
      background-repeat: no-repeat;
      background-position: center;
      background-size: auto 95%;
      @include _1024 {
        bottom: 5px;
      }
    }
  }
  &__btns {
    display: flex;
    height: 100%;
    align-items: center;
    overflow: visible;
  }
  &__btn {
    height: auto;
    background-color: inherit;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    img {
      @include SmdBtn;
    }
  }

  &__title {
    font-style: normal;
    @include FSbaseBold;
    line-height: 14px;
    margin-bottom: 5px;
  }
  &__text {
    font-style: normal;
    line-height: 14px;
    @include FSmdReg;
  }
  &__section {
    padding: 0px 20px 0 20px;
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 249px;
    z-index: 6;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
