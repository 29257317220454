@import "/src/styles/variables";

.modalAddProject {
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }

  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-wrapper {
      display: grid;
      @include gapBig;
      grid-template-columns: 1fr minmax(auto, 386px);
      @include _1024 {
        grid-template-columns: 1fr 320px;
      }
      @include _900 {
        display: flex;
        flex-direction: column;
      }
    }
    &-form {
      @include flexGap(20px, col);
      .input,
      .select,
      .inputDate {
        width: 100%;
      }
      &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include gapBig;
        @include _768 {
          display: flex;
          flex-direction: column;
        }
      }
      &-region {
        span {
          color: $blue;
        }
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
    }
  }
}
