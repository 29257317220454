@import "./src/styles/variables";

.tasksDragDrop {
  margin: 0 20px 0 10px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  height: calc(100vh - 290px);
  @include _1700 {
    height: calc(100vh - 220px);
  }
  @include _1024 {
    height: calc(100vh - 200px);
    margin: 0;
  }
  &-column {
    display: grid;
    padding: 10px 10px;
    background: #ffffff;
    /* тень */
    border: 1px solid $grey;
    @include borderRadius;
    list-style-type: none;

    gap: 5px;
    // overflow-y: scroll;
    // overflow-x: hidden;
    justify-content: space-between;
    grid-template-columns: repeat(5, 1fr);
    min-height: 400px;
    @include _1300 {
      padding: 5px;
    }
    @include _1024 {
      height: calc(100vh - 200px);
      grid-template-columns: repeat(5, 250px);
      gap: 10px;
      overflow-y: hidden;
      overflow-x: scroll;
    }
  }
}
