@import "../../styles/variables";

.modalEditCompany {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-header {
    border-radius: 10px 10px 0 0;
    background-color: $main-color-light;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    margin-bottom: 20px;
  }
  &-close {
    background-color: inherit;
    width: 24px;
    height: 24px;
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #ffffff;
  }
  &-wrapper {
    padding: 0 20px 0 20px;
    overflow-y: scroll;
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-baseInfo {
      display: flex;
      @include gapBig;
      @include _768 {
        display: flex;
        flex-direction: column-reverse;
      }
    }
    &-avatar {
      @include _768 {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
      }
    }
    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      width: 100%;
      justify-content: space-between;
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-company {
        width: 32%;
        margin-bottom: 20px;
      }
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        // margin-right: 20px;

        .inputSearch-title {
          color: $blue;
        }
        &.name-company {
          grid-column-start: 1;
          grid-column-end: -1;
        }
      }

      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
        margin-bottom: 10px;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
      &-select {
        width: 32%;
      }
    }
    .employee-list {
      @include flexGap(20px, col);
      &-item {
        @include flexGap(20px, row);
        .inputSearch,
        .select {
          width: 300px;
        }
        &-btns {
          padding-top: 28px;
          @include flexGap(20px, row);
        }
      }
    }
  }
  &-btn-wrapper {
    display: flex;
    justify-content: center;
    border-top: none;
    padding-top: 20px;
    @include _1700 {
      padding-top: 10px;
    }
    @include _1024 {
      padding-top: 0;
    }
  }
  &-employee {
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    &-list {
      display: grid;
      grid-template-columns: 1fr 160px;
      @include gapBig;
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-navigate {
    display: flex;
    gap: 10px;
    &-item {
      cursor: pointer;
      span {
        font-family: "Montserrat";
        font-weight: 700;
        @include FSsubtitle;
        color: black;
        white-space: nowrap;
      }
      @include HoverModalText;
      &.active {
        span {
          color: $blue;
          background: $blue;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
}
.modalInfoCompany {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-listModalProfile {
    }
    &-baseInfo {
      display: grid;
      grid-template-columns: 1fr auto;
      @include gapBig;

      @include _1024 {
        padding-bottom: 0;
      }
      @include _768 {
        display: flex;
        flex-direction: column-reverse;
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        @include gapBig;
      }
    }
    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-logo {
      > span {
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $grey;
      }
      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &-noImg {
        width: 43px;
        height: 43px;
      }
    }
    &-avatar {
      display: flex;
      flex-direction: column;
      @include gapMin;
      @include _768 {
        width: 100%;
        align-items: center;
      }
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      @include _600 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        min-width: 20%;
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _600 {
          flex-direction: row;
        }
      }
      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
    }
    &-emploees {
      margin-bottom: 20px;
    }
    &-emploees,
    &-groups {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 6px 10px;
      @include borderRadius;
      border: 1px solid $grey;
      background: white;
      &-item {
        margin-right: 20px;
        > span {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 30px;
          color: #000000;
        }
      }
    }
  }
}
