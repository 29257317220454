@import "/src/styles/variables";

.modalStageInfo {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-text {
    @include FSbaseBold;
  }
  &-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    @include _400 {
      flex-direction: row;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 20px;
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _400 {
      grid-template-columns: 1fr;
    }
  }
  .bold.green {
    color: $light-green;
  }
  .bold.red {
    color: $red;
  }
  .bold.yellow {
    color: $orange;
  }
  &-works {
    display: flex;
    flex-direction: column;
  }
}
