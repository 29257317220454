@import "./src/styles/variables";

.modalAddCompany {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-baseInfo {
      width: 100%;
      display: flex;
      @include gapBig;
      @include _900 {
        flex-direction: column-reverse;
      }
    }
    &-wrapper {
      width: 100%;
    }
    &-avatar {
      display: flex;
      flex-direction: column;
      @include gapMin;
      @include _900 {
        width: 100%;
        align-items: center;
      }
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      width: 100%;
      justify-content: space-between;
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-company {
        width: 100%;
        margin-bottom: 20px;
      }
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        // margin-right: 20px;

        &-inn {
          display: block;
          width: 100%;
          position: relative;
          &-btn {
            position: absolute;
            right: 15px;
            bottom: 30%;
            transform: translateY(50%);
            z-index: 1;
          }
        }
      }

      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
      &-select {
        width: 32%;
      }
    }
    &-emploees,
    &-groups {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      @include borderRadius;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
      background: white;
      margin-top: 20px;
      margin-bottom: 20px;
      &-item {
        margin-right: 20px;
        > span {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 32px;
          color: #000000;
        }
      }
    }
  }
  &-employee {
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    &-list {
      display: grid;
      grid-template-columns: 1fr 160px;
      @include gapBig;
      @include _900 {
        grid-template-columns: 1fr;
      }
    }
  }

  &-btn-wrapper {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    @include _1700 {
      padding-top: 10px;
    }
    @include _1024 {
      padding: 0;
    }
  }
}
