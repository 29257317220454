@import "src/styles/_variables.scss";

.photoReportsMobile {
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-content {
    padding: 20px 0 20px 0;
  }
}
