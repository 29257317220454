@import "/src/styles/variables";

.taskListTable {
  list-style-type: none;
  font-family: "Montserrat";
  background-color: #fff;
  border-left: 1px solid $grey;
  border-bottom: 1px solid $grey;
  border-right: 1px solid $grey;
}
