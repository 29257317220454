@import "/src/styles/variables";

.modalReportsFilterMobile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  position: relative;
  height: 100%;
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-btns {
    display: flex;
    justify-content: space-between;
    @include gapMin;
    @include _600 {
      display: flex;
      flex-direction: column;
    }
  }
}
