@import "./src/styles/variables";

.modalEditEvent {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-big-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-person {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-logo {
      @include XSavatar;
      border-radius: 50%;
      background-size: cover;
      background-position: center;
    }
    &-info {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-name {
        @include FSbaseBold;
      }
      &-position {
        @include FSbaseReg;
      }
    }
  }
  .react-datepicker__input-container input {
    color: black;
    font-weight: 500;
  }
  .select,
  .address-input,
  .selectMulti {
    width: 100%;
    max-width: 100%;
  }
  .address-input {
    span {
      line-height: normal;
    }

    // .input-icon {
    //   bottom: 5px;
    // }
  }
  &-grid {
    row-gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    @include gapMin;

    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &.adress {
        input {
          padding-right: 30px !important;
        }
      }
    }
  }
  &-participants {
    width: calc(34% - 20px);
  }
  .editInfoTask-regularity {
    display: flex;
    align-items: center;
    @include gapMin;
    &-title {
      @include FSbaseBold;
    }
  }
}
