@import "../../styles/variables";

.modalEditProtocol.modal {
  border-top: none;
  padding-top: 0;
}

.modalEditProtocol {
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding-top: 20px;
  background-color: inherit;
  @include _1700 {
    padding-top: 10px;
  }
  @include _1024 {
    padding-bottom: 10px;
    padding-top: 0px;
  }
  &-header {
    display: flex;
    justify-content: space-between;
    position: relative;
    &-btn {
      @include flexAllCenter();
      max-width: 44px;
      justify-content: center;
      background: #0094e7;
      @include borderRadius;
      padding: 5px 10px;
      height: 34px;
      cursor: pointer;
    }
    &-modalMenu {
      position: relative;
    }
    &-menuImport {
      position: absolute;
      bottom: -60px;
      right: 0;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 10px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      @include gapMin;
      span {
        @include FSmdMed;
        white-space: nowrap;
        cursor: pointer;
      }
      @include HoverModalText;
    }
  }
  &-section-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
  }
  &-person {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-wrapper {
      display: flex;
      @include gapMin;
    }
    &-logo {
      @include XSavatar;
    }
  }
  &-title {
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseBold;
    color: $blue;
  }
  &-bold {
    @include FSbaseBold;
  }
  &-text {
    @include FSbaseReg;
  }
  &-row {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include gapBig;
    @include _768 {
      grid-template-columns: repeat(2, 1fr);
    }
    @include _600 {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    .listModal__select {
      @include _768 {
        width: 100% !important;
      }
      @include _600 {
        width: 100% !important;
      }
    }
    &-info {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      @include gapBig;
      @include _1300 {
        grid-template-columns: repeat(4, 1fr);
      }
      @include _1024 {
        grid-template-columns: repeat(3, 1fr);
      }

      @include _600 {
        display: flex;
        flex-direction: column;
        @include gapBig;
      }
    }
  }
  &-participants {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-gridLists {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    @include gapBig;
    @include _600 {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-fileList {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-participantsList {
    display: flex;
    flex-wrap: wrap;
    @include gapMin;
    padding: 12px 10px;
    border: 1px solid $grey;
    @include borderRadius;
    &-item {
      display: flex;
      @include gapMin;
      align-items: center;
      @include FSbaseReg;
      img {
        cursor: pointer;
      }
    }
  }
  &-btns {
    display: flex;
    justify-content: center;
  }
}
