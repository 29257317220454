@import "../../styles/variables";

.modalInfoEditFile {
  display: flex;
  width: 100%;
  flex-direction: column;
  @include gapBig;
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
}
