@import "../../../../styles/variables";

.profileTabsListRow {
  list-style-type: none;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  margin-top: 2px;
  margin-bottom: 2px;
  gap: 2px;
  // height: 38px;
  font-family: "Montserrat";
  font-style: normal;
  &:hover {
    background: rgba(4, 72, 142, 0.2);
    .profileTabsListRow__title {
      background: rgba(4, 72, 142, 0.2);
    }
    .profileTabsListRow__item {
      background: rgba(4, 72, 142, 0.2);
    }
  }
  &__title {
    @include FSbaseReg;
    line-height: 16px;
    background: #ffffff;
    width: 264px;
    height: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-right: 20px;
    padding-left: 10px;
    // border-top: 1px solid $main-color;
    @include _1024 {
      width: 220px;
      margin-right: 10px;
    }
    &-page {
      @include FSbaseBold;
      padding-left: 10px;
      border-top: 1px solid $blue-m-bg;
    }
    &-disabled {
      color: #c0c0c0;
    }
    > img {
      margin-right: 10px;
    }
  }

  &__item {
    background: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    > span {
      @include FSbaseBold;
      line-height: 16px;
      text-align: center;
    }
    &-page {
      border-top: 1px solid $main-color;
    }
  }
}
