@import "../../styles/variables";

.areasChart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background: white;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 20px;
  height: 100%;
  @include _1024 {
    padding: 10px;
  }
  &-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-content {
    padding: 20px;
    max-height: 484px;
    border-left: 3px solid #d9d9d9;
    border-bottom: 3px solid #d9d9d9;
    @include _1024 {
      padding: 10px;
    }
  }
}
