@import "../../styles/variables";

.modalMobileMenu {
  &-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &-item {
    a,
    span {
      @include FSbaseBoldM;
      text-decoration: none;
      color: black;
    }
  }
}
