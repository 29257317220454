@import "/src/styles/variables";

.taskListTableItem {
  display: grid;
  grid-template-columns: 60px 250px 40px 0 60px;
  align-items: center;
  // transition: all 0.5s;
  position: relative;
  height: 40px;
  &-showTaskSection {
    grid-template-columns: 60px 250px 40px 1fr 60px;
  }
  @include _2000 {
    grid-template-columns: 60px 150px 40px 0 60px;
    &-showTaskSection {
      grid-template-columns: 60px 150px 40px 1fr 60px;
    }
  }
  @include _1700 {
    grid-template-columns: 60px 1fr 40px 0 60px;
    &-showTaskSection {
      grid-template-columns: 60px 1fr 40px 1fr 60px;
    }
  }
  // span {
  //   @include FSbaseReg;
  //   text-align: center;
  //   white-space: nowrap;
  // }
  &-elem {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &-text {
      @include FSbaseReg;
    }

    &-number {
      justify-content: start;
      padding-left: 10px;

      span {
        @include FSbaseBold;
      }
    }
    &-name {
      gap: 10px;
      justify-content: flex-start;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      position: relative;
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &-project {
        span {
          @include FSbaseBold;
        }
      }
    }
    &-percent {
      @include FSbaseReg;
      &-project {
        span {
          @include FSbaseBold;
        }
      }
    }
    &-icon {
      transform: rotate(0deg);
      transition: transform 0.3s linear;
      &-open {
        transform: rotate(180deg);
        transition: transform 0.3s linear;
      }
    }
    &-showSection {
      display: grid;
      grid-template-columns: 100px 100px 60px 60px;
      @include _2000 {
        grid-template-columns: 100px 100px 40px 40px;
      }
      @include _1700 {
        grid-template-columns: 80px 80px 40px 40px;
      }
      height: 100%;
      opacity: 0;
      transition: opacity 0.3s linear;
      &-open {
        opacity: 1;
        transition: opacity 0.3s linear;
      }
    }
    &-menu {
      position: relative;
      &-window {
        position: absolute;
        left: 95px;
        display: flex;
        flex-direction: column;
        width: 220px;
        padding: 10px;
        background: #ffffff;
        z-index: 5;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        @include _2000 {
          left: 00px;
        }
        @include _1700 {
          left: 35px;
        }
        span {
          cursor: pointer;
          font-family: "Montserrat";
          font-style: normal;
          @include FSmdMed;
          line-height: 14px;
          text-align: start !important;
          &:not(:last-child) {
            margin-bottom: 10px;
          }
        }
        @include HoverModalText;
        &-top {
          top: 40px;
        }
        &-bottom {
          bottom: 40px;
        }
      }
    }
  }
  &:nth-child(even) {
    background-color: $main-color-ultra-light;
  }
}
