@import "./src/styles/_variables.scss";
.navbarItemLink {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 10px 15px;
  border-radius: 30px 0px 0px 30px;
  text-decoration: none;
  cursor: pointer;
  transition: all 0.2s;
  path {
    fill: #fff;
  }
  @include _1024 {
    display: flex;
    flex-direction: column;
    @include borderRadius;
    border: 1px solid $grey;
  }
  &-title {
    font-family: "Montserrat";
    font-weight: 600;
    @include FSnav;
    color: white;

    @include _2000 {
      // margin-left: 12px;
      white-space: nowrap;
    }
    @include _1024 {
      color: black;
    }
  }
  @include _2000 {
    padding: 8px 15px;
  }

  @include _1700 {
    padding: 8px 10px;
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    &-count {
      background-color: $main-color;
      position: absolute;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      font-weight: 600;
      font-size: 12px;
      top: -5px;
      right: -5px;
    }
    @include _1024 {
      path {
        fill: black;
      }
    }
  }
}

.active.navbarItemLink {
  background-color: $background-color-link;
  @include _1024 {
    background-color: $main-color;
  }
  path {
    fill: black;
  }
  span {
    color: black;
  }
  @include _1024 {
    path {
      fill: #fff;
    }
    span {
      color: #fff;
    }
  }
}

.text-black {
  color: black;
}
