@import "../../../styles/variables";

.project {
  display: flex;
  height: 100%;
  &-content {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &-filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        gap: 10px;
      }
      &-info {
        @include flexGap(20px, col);
        height: 100%;
        height: calc(100vh - 215px);
        padding-bottom: 20px;
        @include _1700 {
          height: calc(100vh - 150px);
        }
        @include _1300 {
          height: calc(100vh - 140px);
        }
      }
    }
  }
}
