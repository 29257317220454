@import "/src/styles/variables";

.skeletonBox {
  @include skeletons;
  @include borderRadius;
  //   @include LGavatar;
  width: 74px;
  height: 74px;
  @include _1024 {
    height: 48px;
    width: 48px;
  }
}
