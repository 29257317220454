@import "/src/styles/variables";

.meetingMinutesMobileCard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $grey;
  @include borderRadius;

  background-color: #fff;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    margin: 0 10px;

    &-img {
      @include AvatarM;
    }
    &-title {
      @include FSbaseBoldM;
      text-align: center;
    }
    border-bottom: 1px solid $main-color;
  }
  &-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      display: flex;
      justify-content: space-between;
      &-title {
        white-space: nowrap;
        @include FSbaseBoldM;
        margin-right: 5px;
        color: $blue;
      }
      &-text {
        @include FSbaseBoldM;
        text-align: end;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    gap: 10px;
    padding: 10px;
    &-item {
      display: flex;
      gap: 5px;
      align-items: center;
      &-text {
        @include FSbaseBoldM;
        color: #fff;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}
