@import "/src/styles/variables";

.workSchedulerRowCard {
  @include cardRowWrapper;
  &__list {
    list-style-type: none;
    @include borderRadius;
    gap: 10px;
    align-items: center;
    display: grid;
    width: 100%;
    justify-content: space-between;
    grid-template-columns:
      // 60px

      2fr
      1.5fr
      1.5fr
      // 1fr
      1fr
      100px;
  }
  &__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-number {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-start;
    }
    &-author {
      color: $blue-dark;
    }
    &-name {
      @include borderRadius;

      overflow: hidden;
      align-self: center;
      cursor: pointer;
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-contract {
      color: $light-green;
    }
    &-schedule {
      color: $purple;
    }
    &-overdue {
      color: $red;
    }
  }
  &__btns {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 10px;
  }
  &__btn {
    background-color: inherit;
    cursor: pointer;
  }
}
