@import "/src/styles/variables";

.activeHeaderTasks {
  background-color: #fff;
  display: flex;
  @include gapBig;
  align-items: center;
  z-index: 3;
  &-item {
    display: flex;
    gap: 5px;
    cursor: pointer;
    align-items: center;
    height: 100%;
    width: fit-content;
    &-wrapper {
      display: flex;
      @include gapMin;
      @include _1300 {
        display: flex;
        align-items: center;
        transition: all 1s linear;
        max-width: 20px;
        transition: all 0.7s ease-in-out;
        overflow: hidden;
        white-space: nowrap;
        &:hover {
          max-width: 300px;
        }
        &.active {
          max-width: 300px;
        }
      }
    }
    &-text {
      @include FSbaseReg;

      @include HoverModalText;
    }
  }
}
