@import "/src/styles/variables";

.modalPutAside {
  span {
    font-family: "Montserrat";
  }
  .hr {
    background: $main-color-light;
    @include borderRadius;
    height: 3px;
    width: 100%;
  }
  &-header {
    background: $main-color-light;
    border-radius: 10px 10px 0px 0px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    span {
      @include FSbaseBold;
      color: white;
    }
    img {
      cursor: pointer;
    }
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-grid {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
  }
}
