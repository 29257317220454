@import "src/styles/variables";

.workProjectInfo {
  list-style-type: none;
  height: fit-content;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #fff;
  padding: 10px;
  font-family: "Montserrat";
  gap: 20px;
  border: 1px solid #c0c0c0;
  @include borderRadius;
  @include _1300 {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  @include _1024 {
    column-gap: 10px;
  }
  @include _600 {
    display: flex;
    flex-direction: column;
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-text {
      @include FSbaseBold;
    }
    &-canvas {
      position: relative;
      // height: 274px;
      // > div {
      //   width: 100%;
      //   > div {
      //     width: 100% !important;
      //   }
      // }
    }
  }
  &__item .title {
    font-weight: 700;
    color: $blue;
    @include FSbaseBold;
    margin-bottom: 10px;
  }
}
