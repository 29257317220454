@import "/src/styles/variables";

.modalListChartFilterMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  &-btns {
    display: flex;
    justify-content: center;
    gap: 10px;
    @include _600 {
      display: flex;
      flex-direction: column;
    }
  }
}
