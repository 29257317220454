@import "./src/styles/variables.scss";

.settingsProject {
  padding: 0 20px 0 10px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  height: 100%;

  @include _1024 {
    padding: 0;
  }
}
