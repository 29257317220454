@import "../../styles/variables";

.inputRadio {
  display: flex;
  align-items: center;
  @include gapMin;
  &-title {
    font-family: "Montserrat";
    @include FSbaseReg;
  }
  &-radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: 2px solid #c0c0c0;
    position: relative;
    cursor: pointer;
    &::after {
      content: "";
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background-color: #c0c0c0;
      position: absolute;
      top: 50%;
      right: 50%;
      transform: translate(50%, -50%);
    }
  }
}

.inputRadio.check {
  .inputRadio-radio {
    border: 2px solid $main-color !important;
    &::after {
      background-color: $main-color !important;
    }
  }
}
