@import "../../../../../styles/variables";

.layers {
  display: flex;
  flex-direction: column;
  @include gapBig;
  height: calc(100vh - 300px);
  // canvas {
  //   height: 100vh;
  //   width: 100vw;
  //   display: block;
  // }
  @include _1300 {
    height: calc(100vh - 270px);
  }
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  .layers-cards {
    display: flex;
    @include gapBig;
    padding: 0 20px 0 10px;
    flex-wrap: wrap;
    @include cardColumnGrid;
  }
  &-container {
    display: grid;
    @include gapBig;
    padding: 0 20px 0 10px;
    grid-template-columns: 320px 1fr;
    @include _1700 {
      grid-template-columns: 300px 1fr;
    }
    @include _1300 {
      display: flex;
      flex-direction: column;
    }
    &-planList {
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 10px;
      display: flex;
      flex-direction: column;
      @include gapMin;
      // max-width: 264px;
      width: 100%;
      height: fit-content;
      @include _1300 {
        flex-direction: row;
        max-width: 100%;
        > div {
          display: flex;
          width: 100%;
          @include gapMin;
        }
      }
      span {
        font-family: "Montserrat";
      }
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
        span {
          font-family: "Montserrat" !important;
        }
        .hr {
          background: $main-color-light;
          @include borderRadius;
          width: 100%;
          height: 2px;
        }
        &-container {
          display: flex;
          gap: 12px;
          align-items: center;
          justify-content: space-between;
          padding: 11px;
          clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
          z-index: 1;
          cursor: pointer;
          padding-right: 30px;
          width: 112%;
          @include _1300 {
            clip-path: none;
            width: 100%;
            @include borderRadius;
          }
          &-name {
            display: flex;
            flex-direction: column;
            gap: 2px;
            max-width: 200px;
            @include _1300 {
              max-width: 150px;
            }
            .name {
              font-weight: 700;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              @include FSbaseBold;
            }
            .docName {
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              width: 100%;
              @include FSbaseReg;
            }
          }
          // img {
          //   @include SmdBtn;
          // }
        }
        &-container.active {
          display: flex;
          background-color: $blue;
          align-items: center;
          span {
            color: white;
          }
        }
      }
    }
    &-plan {
      position: relative;
      display: grid;
      grid-template-columns: 1fr 300px;
      @include gapMin;
      width: 100%;

      &-zone {
        overflow-x: hidden;
        width: 100%;
        position: relative;
        display: flex;
        justify-content: center;

        // height: 70vh;
        @include min_2000 {
          > div {
            display: block;
            overflow: hidden;
            > div:last-child {
              transform: scale(1.1);
              transform-origin: 0 0;
            }
          }
        }
        @include min_2200 {
          > div {
            display: block;
            overflow: hidden;
            > div:last-child {
              transform: scale(1.4);
              transform-origin: 0 0;
            }
          }
        }
      }
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }

  .title {
    font-family: "Montserrat";
    color: $blue;
    font-weight: 700;
  }
  .bold {
    @include FSbaseBold;
  }
}

.asd {
  width: 100%;
}
