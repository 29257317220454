@import "/src/styles/variables";

.skeletonGridProject {
  @include cardColumnGrid;
  padding: 0 20px 0 10px;
  @include _1024 {
    @include cardColumnGridMobile;
    padding: 10px 0 10px 0;
  }
  &-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    border: 1px solid $main-color-ultra-light;
    @include borderRadius;
    background-color: #fff;
    gap: 10px;
    @include _2000 {
      gap: 5px;
    }
    &-img {
      @include skeletons;
      width: 100%;
      height: 190px;
      @include borderRadius;
      @include _2000 {
        height: 170px;
      }
      @include _1700 {
        height: 150px;
      }
      @include _1024 {
        height: 140px;
      }
    }
    &-main {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      @include _2000 {
        padding-top: 0px;
      }
    }
    &-footer {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      align-items: flex-end;
      @include _2000 {
        padding-top: 0px;
      }
      &-left {
        display: flex;
        flex-direction: column;
        gap: 5px;
        width: 59%;
      }
    }
  }
}
