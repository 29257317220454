.modallAddEditCatalogWork {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;
  position: relative;
  &-btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
