@import "../../../styles/_variables.scss";

.deliveryWork {
  display: flex;
  height: calc(100vh - 50px);
  width: 100%;
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &__filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }
  }
  &__searchPanel {
    padding: 0 20px 0 10px;
  }
}
