@import "../../../styles/variables";

.notifications {
  display: flex;
  height: calc(100vh - 100px);
  width: 100%;
  @include _1700 {
    height: calc(100vh - 40px);
  }
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include _1700 {
      padding-top: 10px;
    }
    &-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include gapBig;
      &-btns {
        border-radius: 50px 0 0 50px;
        max-height: 50px;
        padding: 14px 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        @include gapBig;
        width: fit-content;
        background-color: white;
        &-button {
          font-family: "Montserrat";
          font-weight: 500;
          transition: all 0.3s;
          &:hover {
            color: $main-color;
          }
          @include FSbaseBold;
          color: black;
          cursor: pointer;
        }
        @include _2000 {
          border-radius: 45px 0 0 45px;
          height: 45px;
          padding: 10px 15px;
        }
        @include _1700 {
          border-radius: 40px 0 0 40px;
          height: 35px;
          padding: 10px 15px;
        }
      }
    }
  }
  &-list {
    @include flexGap(10px, col);
    padding: 0 20px 0 10px;
    height: calc(100% - 170px);
    overflow-y: auto;
    &-item {
      display: flex;
      gap: 10px;
      width: 100%;
      &-title.disabled {
        opacity: 0.5;
      }
      &-title {
        width: 100%;
        display: grid;
        align-items: center;
        grid-template-columns: 20px 1fr 20px;
        gap: 10px;
        background: #ffffff;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 15px 10px;
        cursor: pointer;
        @include _1700 {
          padding: 10px 10px;
        }
        span {
          font-family: "Montserrat";
          @include FSbaseReg;
        }
        &-status {
          width: 16px;
          height: 16px;
          display: flex;
          align-items: center;

          &-circle {
            background-color: #f3be03;
            width: 10px;
            height: 10px;
            border-radius: 50%;
            border-radius: 50%;
            animation: animate_reduce 2s linear;
            animation-iteration-count: 5;
            @include _2000 {
              top: 0px;
              right: 2px;
            }
          }
        }
      }
    }
  }
}
