@import "src/styles/variables";

.donutChart {
  position: relative;
  padding-top: 10px;
  width: 330px;
  @include _1455 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: auto;
    height: 100%;
  }
  &-title {
    position: absolute;
    top: 37%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    @include gapMin;
    width: 70%;
    text-align: center;
    @include _1455 {
      top: 44%;
    }
    @include _1024 {
      top: 40%;
    }
  }
}

.donutChart-create {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background: white;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 20px;
  height: 100%;
  &-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-content {
    padding: 20px;
    display: flex;
    justify-content: center;
    height: 100%;
    max-height: 484px;
    border-left: 3px solid #d9d9d9;
    border-bottom: 3px solid #d9d9d9;
  }
}
