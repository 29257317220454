@import "./src/styles/variables";

.menuDots {
  position: relative;

  &-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    &.horizon {
      transform: rotate(90deg);
    }

    &.blueButton {
      background-color: $blue;
      padding: 2px;
      border-radius: 20px;
    }
  }
  @include HoverModalText;
  &-wrapper {
    position: relative;
  }
  &-item {
    padding: 5px 15px;
    cursor: pointer;
    @include FSbaseBold;
    color: black;
    &-link {
      text-decoration: none;
    }
    // &:hover {
    //   background-color: $main-color-ultra-light;
    // }

    // &.active {
    //   background-color: $main-color-ultra-light;
    // }
  }
}
