@import "/src/styles/variables";

.ganttChartHeader {
  display: flex;
  justify-content: space-between;
  &-left {
    display: flex;
    gap: 10px;
  }
  &-btn {
    @include flexAllCenter();
    width: 40px;
    justify-content: center;
    background: #0094e7;
    @include borderRadius;
    height: 40px;
    cursor: pointer;
    img {
      @include SmdBtn;
    }
    @include HoverBtnBlue;
    @include _2000 {
      width: 35px;
      height: 35px;
    }
    &-size-btn {
      &-disabled {
        background-color: $grey;
        cursor: not-allowed;
        position: relative;
        overflow: hidden;
        background: linear-gradient(to right, $grey, $grey 50%, $grey 50%) !important;
        background-size: 200% 200%;
        background-position: 100%;
        transition: background-position 0.5s ease;
        text-decoration: none; // text decorations are clipped in WebKit browsers

        &:hover {
          background-position: 0 100%;
          svg {
            g {
              fill: $grey !important;
            }
            path {
              fill: $grey !important;
            }
          }
        }
      }
    }
  }
  &-setting {
    position: relative;

    &-menuImport {
      position: absolute;
      bottom: -60px;
      left: 0;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 10px;
      z-index: 2;
      @include flexGap(10px, col);
      span {
        @include FSmdMed;
        white-space: nowrap;
        cursor: pointer;
      }
      @include HoverModalText;
    }
  }

  &-right {
    display: flex;
    gap: 10px;
  }
}
