@import "../../../../styles/variables";

.employeeInfo {
  display: flex;
  height: 100%;
  width: 100%;

  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    @include _1700 {
      padding-top: 10px;
    }
  }
  &-nav {
    background: #ffffff;
    // border: 1px solid #000000;
    @include borderRadius;
    height: 40px;
    margin: 0 20px 0 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__list {
      list-style-type: none;
      display: flex;
      @include gapBig;
      @include _2000 {
        @include gapMin;
      }
    }

    &-item {
      font-family: "Montserrat";
      font-style: normal;
      @include FSnav;
      line-height: 18px;
      color: #c0c0c0;
      cursor: pointer;
      transition: color 0.8s;
      position: relative;
      display: flex;
      align-items: center;

      span {
        @include FSnav;
        display: inline-block;
        color: black;
        overflow: hidden;
        cursor: pointer;
      }
      @include HoverModalText;
      &-active {
        color: $blue;
        > span {
          color: $blue;
          background: $blue;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-text-fill-color: transparent;
        }
      }
    }
  }
  &__wrapper {
    width: 100%;
    margin-top: 20px;
    height: calc(100vh - 300px);
    overflow-y: scroll;
    overflow-x: hidden;
    @include _2000 {
      height: calc(100vh - 300px);
    }
    @include _1300 {
      height: calc(100vh - 280px);
    }
  }
}
