@import "../../../styles/_variables.scss";

.calendarMobile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  height: 100%;
  background-color: #f5f9fb;
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    padding: 20px 0 60px 0;
  }
}
