@import "/src/styles/variables";

.statusIcon {
  @include borderRadius;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 1px;
  &-error {
    background-color: #db1f35d4;
  }
  &-success {
    background-color: $completed;
  }
  &-warning {
    background-color: $onVerification;
  }
  &-calendar {
    width: 100%;
    height: 90%;
  }
  &-text {
    position: absolute;
    top: 55%;
    transform: translateY(-50%);
    color: #fff;
    font-size: 8px;
  }
  span {
    font-size: 8px;
  }
}
