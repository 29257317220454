@import "./src/styles/variables";

.modalAddInChat {
  @include flexGap(20px, col);
  .searchPanel {
    width: 100%;
  }
  padding: 20px 20px 20px 0;
  @include _1024 {
    padding: 0px;
  }
  &-main {
    padding: 0px 0px 0px 20px;
    &-title {
      font-family: "Montserrat";
      @include FSbaseBold;
      color: #04488e;
    }
    @include _1024 {
      padding: 0;
    }
  }
  &-header {
    display: flex;
    align-items: center;
    gap: 10px;
    @include _1024 {
      display: flex;
      flex-direction: column;
    }
  }
  &-header-mobile {
    display: flex;
    justify-content: space-between;
    @include gapBig;
    @include FSbaseBoldM;
    width: 100%;
    &-check {
      display: flex;
      @include gapMin;
    }
  }
  &-table {
    display: flex;
    flex-direction: column;
    @include gapBig;
    padding: 0px 0px 0px 20px;
    @include _1024 {
      padding: 0;
    }
    &-header {
      display: grid;
      align-items: center;
      text-align: center;
      padding: 0 10px;
      grid-template-columns: 20px repeat(5, 1fr);
      gap: 5px;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: #04488e;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-grid {
    @include cardColumnGridMobile;
  }
}
