@import "src/styles/variables";

@keyframes ani {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.message {
  @include flexGap(10px, col);
  position: relative;
  opacity: 0;
  animation: ani 0.5s forwards;
  // @include _400 {
  //   max-width: 300px;
  // }
  &-wrapper {
    background-color: $background-color-content;
    width: fit-content;
    max-width: 80%;
    border-radius: 10px 10px 10px 10px;
    padding: 5px 10px 5px 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    .forward-select {
      transition: all 0.3s;
      bottom: -4px;
      img {
        width: 15px;
        height: 15px;
      }
    }
  }

  &-wrapper.forward {
    transition: all 0.3s;
    background-color: $main-color !important;
    span {
      color: white;
    }

    .docItem {
      span {
        color: black !important;
      }
    }
  }
  .forvardIcon {
    position: absolute;
    top: 0;
    // display: none;
    opacity: 0;
    svg {
      width: 15px;
      height: 15px;
    }
  }
  &:hover {
    .forvardIcon {
      transition: all 0.3s;
      display: block;
      opacity: 1;
    }
  }
  &-logo {
    position: absolute;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    border: 1px solid white;
  }
  &-time {
    text-align: right;
    span {
      @include FSxsReg;
      color: $grey-dark;
    }
  }
  &-files,
  &-images,
  &-voices,
  &-text {
    // background: #f6f6f6;
    width: fit-content;
    position: relative;
    @include FSbaseReg;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    gap: 5px;
    // word-break: break-all;
    max-width: 466px;
    @include _900 {
      max-width: 300px;
    }
  }

  &-photos {
    display: flex;
    gap: 5px;
    flex-wrap: wrap;
    &-item {
      width: 109px;
      height: 109px;
      @include borderRadius;
      overflow: hidden;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
  &-text.forward,
  &-images.forward,
  &-voices.forward {
    transition: all 0.3s;
    // background-color: #0094e7;
    span {
      color: white;
    }
  }
  &-files.forward {
    transition: all 0.3s;
    // background-color: #0094e7;
  }
  &-images {
    &-list {
      display: flex;
      gap: 5px;
      flex-wrap: wrap;
      &-item {
        width: 109px;
        height: 109px;
        @include borderRadius;
        overflow: hidden;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
  &-files {
    max-width: 406px;
    width: 100%;
    @include flexGap(10px, col);
    &-list {
      @include flexGap(10px, col);
    }
    @include _900 {
      max-width: 300px;
    }
  }
  &-voices {
    max-width: 380px;
    width: 100%;
    @include flexGap(10px, col);
    &-list {
      @include flexGap(10px, col);
      &-item {
        @include flexGap(10px, row);
        &-road {
          width: 100%;
          height: 30px;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center;
        }
      }
    }
  }
  &-profile {
    width: 100%;
    &-info {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      // span {
      //   color: black;
      // }
      &-title {
        @include FSbaseBold;
        color: $blue;
      }

      .date {
        @include FSmdMed;
      }
      .positions {
        @include FSmdMed;
        // font-weight: 500;
      }
    }
  }
}
.message.myMessage {
  position: relative;
  align-items: flex-end;
  .message-profile,
  .message-logo {
    display: none;
  }
  .message-text,
  .message-images,
  .message-voices,
  .message-files {
    border-radius: 10px 10px 0 10px;
  }
  .message-forwardedMessages {
    @include flexGap(10px, col);
    margin-right: 0px !important;
    padding-right: 10px;
    border-top: 1px solid $grey-blue-dark;
    border-right: 1px solid $grey-blue-dark;
    .message-profile,
    .message-logo {
      display: block;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  .message-wrapper {
    border-radius: 10px 0px 10px 10px;
    // margin-right: 30px;
    position: relative;
    background-color: #cbe7f7;
    .forward-select {
      position: absolute;
      left: -20px;
    }
    .forvardIcon {
      position: absolute;
      top: 0;
      left: -20px;
    }
  }
}
.message.memberMessage {
  .message-logo {
    display: block;
    position: absolute;
    left: 5px;
  }
  .message-profile-info {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }

  .message-forwardedMessages {
    @include flexGap(10px, col);
    margin-left: 0px;
    padding-left: 10px;
    border-top: 1px solid $grey-blue-dark;
    border-left: 1px solid $grey-blue-dark;
    border-radius: 0px 10px 10px 10px;
    &-body {
      @include flexGap(10px, col);
    }
  }
  .message-wrapper {
    border-radius: 0px 10px 10px 10px;
    margin-left: 35px;
    position: relative;

    .forward-select {
      position: absolute;
      right: -20px;
    }
    .forvardIcon {
      position: absolute;
      top: 0;
      right: -20px;
    }
    &.forvard {
      .message-profile-info {
        span {
          color: white;
        }
      }
    }
  }
}

.message-forwardedMessages {
  background-color: #f5f9fb;
  border-radius: 10px 0px 10px 10px;
  padding: 10px;
  &-time {
    text-align: right;
    span {
      @include FSxsReg;
      color: $grey-dark;
    }
  }
  &-wrapper.forward {
    span {
      color: black;
    }
  }
  .message-profile-info {
    justify-content: flex-start;
  }
}
.message-forwardedMessages.memberMessage {
  background-color: #cbe7f7;
}
