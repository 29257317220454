@import "../../../../../styles/variables";

.documentationProject {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @include gapBig;
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-content {
    padding: 0 20px 0 10px;
  }
}
