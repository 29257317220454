@import "/src/styles/variables";

.skeletonRow {
  display: flex;
  flex-direction: column;
  background-color: inherit;
  padding: 0 20px 20px 10px;
  gap: 20px;
  &-header {
    padding: 0 10px 10px 10px;
    gap: 20px;
    display: grid;
    align-items: center;
    @include headerRow;
  }
  &-header.employees-grid-skeleton {
    grid-template-columns:
      2fr
      1fr
      1fr
      1.5fr
      1.5fr
      2fr
      2fr
      1fr
      100px;
    // padding: 0 10px 0 10px;
  }
  &-header.groups-grid-skeleton {
    grid-template-columns:
      2fr
      2fr
      2fr
      2fr
      110px;
  }
  &-header.companies-grid-skeleton {
    grid-template-columns:
      3fr
      2fr
      1.5fr
      2fr
      1.5fr
      1.5fr
      1.5fr
      110px;
  }
  &-header.projects-grid-skeleton {
    grid-template-columns:
      3fr
      1.5fr
      2fr
      1.5fr
      1.5fr
      3fr
      1.5fr
      100px;
  }
  &-header.participants-grid-skeleton {
    grid-template-columns: 80px 2fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 80px;
  }
  &-header.companies-project-grid-skeleton {
    grid-template-columns: 80px 3fr 2fr 1fr 2fr 2fr 2fr 1fr 80px;
  }
  &-header.workSchedule-grid-skeleton {
    padding-top: 20px;
    grid-template-columns:
      60px
      2fr
      1fr
      1.5fr
      1.5fr
      1fr
      1fr
      1fr
      100px;
  }
  &-header.tasks-grid-skeleton {
    grid-template-columns: 3fr 1fr 1fr 2fr 1.2fr 1.2fr 1fr 95px;
  }
  &-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  &-item {
    @include cardRowWrapper;
    gap: 20px;
    display: grid;
    align-items: center;
    border: 1px solid $main-color-ultra-light;
  }
  &-item.employees-grid-skeleton {
    grid-template-columns:
      2fr
      1fr
      1fr
      1.5fr
      1.5fr
      2fr
      2fr
      1fr
      100px;
    // padding: 0 10px 0 10px;
  }
  &-item.groups-grid-skeleton {
    grid-template-columns:
      2fr
      2fr
      2fr
      2fr
      110px;
  }
  &-item.companies-grid-skeleton {
    grid-template-columns:
      3fr
      2fr
      1.5fr
      2fr
      1.5fr
      1.5fr
      1.5fr
      110px;
  }
  &-item.projects-grid-skeleton {
    grid-template-columns:
      3fr
      1.5fr
      2fr
      1.5fr
      1.5fr
      3fr
      1.5fr
      100px;
  }
  &-item.participants-grid-skeleton {
    grid-template-columns: 80px 2fr 1.5fr 1.5fr 1.5fr 1.5fr 1fr 80px;
  }
  &-item.companies-project-grid-skeleton {
    grid-template-columns: 80px 3fr 2fr 1fr 2fr 2fr 2fr 1fr 80px;
  }
  &-item.workSchedule-grid-skeleton {
    grid-template-columns:
      60px
      2fr
      1fr
      1.5fr
      1.5fr
      1fr
      1fr
      1fr
      100px;
  }
  &-item.tasks-grid-skeleton {
    grid-template-columns: 3fr 1fr 1fr 2fr 1.2fr 1.2fr 1fr 95px;
  }
}
