@import "/src/styles/variables";

.workSchedule {
  @include flexGap(20px, col);
  padding: 0 20px 0 10px;
  span {
    font-family: "Montserrat";
  }
  .title {
    color: $blue;
    font-weight: 700;
  }
  .bold {
    @include FSbaseBold;
  }
  .connectionWork {
    background-color: $yellow !important;
  }
  &-row {
    width: 100%;
    padding: 20px 0px 20px 0px;
    &__header {
      font-family: "Montserrat";
      list-style-type: none;
      width: 100%;
      margin: 0 0 10px 0;
      display: grid;
      gap: 10px;
      padding: 0 10px 0 10px;
      justify-content: space-between;
      grid-template-columns:
        // 60px

        2fr
        1.5fr
        1.5fr
        // 1fr
        1fr
        100px;
    }
    &-header {
      &__item {
        display: flex;
        align-items: center;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__item.numder-item {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        gap: 20px;
      }
    }
    &__cards {
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      gap: 10px;
      @include _1700 {
        margin-bottom: 10px;
      }
    }
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }
}
