@import "../../styles/variables";

.modalAddFolder {
  span {
    font-family: "Montserrat";
  }
  @include _1024 {
    padding-bottom: 60px;
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-title {
      color: black;
      @include FSbaseBold;
    }
    &-grid-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      max-width: 1360px;
      @include _768 {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
    }
    &-grid-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
}
