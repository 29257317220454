@import "/src/styles/variables";

.employeesListRow {
  @include cardRowWrapper;
  &.not-login {
    // border: 1px solid #cfc7c77a;
    .employeesListRow__item > span,
    a {
      opacity: 0.5;
    }
  }
  &__list {
    list-style-type: none;
    @include borderRadius;
    gap: 10px;
    align-items: center;
    display: grid;
    width: 100%;

    grid-template-columns:
      2fr
      1.5fr
      1.5fr
      2fr
      2fr
      0.8fr
      20px;
    @include _1700 {
      grid-template-columns:
        2fr
        1.5fr
        1.5fr
        2fr
        2fr
        0.8fr
        20px;
    }
  }
  &__list-company {
    display: grid;
    align-items: center;
    @include borderRadius;
    width: 100%;
    grid-template-columns:
      2fr
      1.5fr
      2fr
      2fr
      0.8fr
      20px;
    @include _1700 {
      grid-template-columns:
        2fr
        1.5fr
        2fr
        2fr
        0.8fr
        20px;
    }
  }
  &__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    padding: 1px;
    span,
    a {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      color: black;
      text-decoration: none;
      @include HoverLink;
    }
    &-number {
      display: flex;
      @include gapBig;
      align-items: center;
      justify-content: flex-start;
    }
    &-fio {
      @include borderRadius;
      > span {
        overflow: hidden;
        align-self: center;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-position {
      // color: $blue-dark;
      // @include borderRadius;
      max-width: 360px;
      overflow: hidden;
      align-self: center;
      > span {
        display: inline-block;
        text-align: start;
      }
    }

    &-btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      gap: 10px;
      overflow: visible;
    }
  }

  &__btn {
    background-color: inherit;
    > img {
      @include SmdBtn;
    }
  }
}
