@import "../../styles/variables";

.rowName {
  color: $main-color-light;
  cursor: pointer;
  @include borderRadius;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  justify-content: center;
  > span {
    display: inline-block;
    text-align: start;
    color: $blue-active;
    @include cardRowPaddingSpan;
    @include FSbaseBold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  @include HoverBtnRow;
}
