@import "src/styles/variables";

.documentationPanelMobile {
  background-color: #fff;
  padding: 10px;
  @include borderRadius;
  border: 1px solid $grey;
  z-index: 0;
  position: relative;
}

.swiper-menuFolder-item {
  width: fit-content;
  height: 100%;
}
