@import "../../../../../../../styles/variables";

.modalDelegateTasksEmployeeRow {
  display: flex;
  background: #ffffff;
  border: 1px solid $grey;
  padding: 8px 10px;
  margin-bottom: 10px;
  justify-content: space-between;
  min-height: 50px;
  @include borderRadius;
  &__list {
    list-style-type: none;
    @include borderRadius;
    gap: 5px;
    display: flex;
    align-items: center;
    display: grid;
    width: 100%;
    grid-template-columns: minmax(auto, 80px) 1fr 1fr 1fr 1fr 1fr;
  }
  &__item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    padding: 1px;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-number {
      display: flex;
      @include gapBig;
      align-items: center;
      justify-content: flex-start;
    }
    &-fio {
      color: $blue-dark;
    }
    &-position {
      color: $blue-active;
      @include borderRadius;
      max-width: 360px;
      overflow: hidden;
      align-self: center;
      background-color: $blue-light-opacity;
      > span {
        display: inline-block;
        text-align: start;
        padding: 9px 13px;
      }
    }
    &-сompany {
      color: $light-green;
      @include borderRadius;
      max-width: 360px;
      overflow: hidden;
      align-self: center;
      background-color: rgba(22, 195, 91, 0.1);
      > span {
        display: inline-block;
        text-align: start;
        padding: 9px 13px;
      }
    }
    &-group {
      color: $light-green;
    }
    &-btns {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      overflow: visible;
    }
  }
}
