@import "../../styles/variables";

.searchInputMobile {
  display: flex;
  height: 40px;
  border: 1px solid $blue-m-bg;
  background-color: $blue-m-bg;
  @include borderRadius;
  position: relative;
  width: 100%;
  &::placeholder {
    color: $blue-m-text;
    @include FSbaseReg;
  }
  &-icon-search {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    > img {
      @include SmdBtn;
    }
    @include _1300 {
      top: 7px;
    }
    // @include HoverIcon;
  }
  &-icon-close {
    position: absolute;
    top: 8px;
    right: 40px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.4s;
    &-active {
      opacity: 1;
      // @include HoverIcon;
    }
  }
  input {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    @include borderRadius;
    padding: 9px 40px 9px 10px;
    font-family: "Montserrat";
    @include FSbaseRegM;
    width: 100%;
  }
}
