@import "/src/styles/variables";

.modalAddGroup {
  &-form {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-content {
    // &:not(:last-child) {
    // border-bottom: 1px solid $main-color;
    // padding-bottom: 20px;
    // }
    display: flex;
    flex-direction: column;
    @include gapBig;
    // &:last-child {
    //   border-bottom: 1px solid #fff;
    //   padding-bottom: 0px;
    // }

    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-director {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
    &-info {
      list-style-type: none;
      display: flex;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      @include gapBig;
      @include _1024 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        display: flex;
        flex-direction: column;
      }
      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
        margin-bottom: 10px;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
    }
    &-select {
      width: 30%;
    }
  }
  &-chat {
    display: flex;
    align-items: center;
    @include gapMin;
    &-text {
      @include FSbaseBold;
      color: $blue;
    }
  }
  &-btn-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
