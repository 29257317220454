@import "../../styles/variables";
@keyframes wave-squares {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}

.emptySection {
  height: calc(100vh - 350px);
  // min-height: 500px;
  width: 100%;
  position: relative;
  @include _1024 {
    // min-height: 300px;
    height: calc(100vh - 260px);
  }
  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
  }

  &__title {
    font-family: "Montserrat";
    font-style: normal;
    line-height: 30px;
    text-align: center;
    color: $blue;
    opacity: 0.5;
    @include FSh1;
    margin-bottom: 20px;
    @include _1024 {
      @include FStitleM;
    }
  }
  &-img {
    width: 450px;
    position: relative;
    height: 192px;
    margin-bottom: 30px;
    @media screen and (max-height: 600px) {
      display: none;
    }
    @include _1024 {
      width: 300px;
      height: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      // background: linear-gradient(
      //   to right,
      //   rgba(255, 255, 255, 0.2) 8%,
      //   rgba(176, 176, 176, 0.3) 18%,
      //   rgba(255, 255, 255, 0.2) 33%
      // );
      // background-size: 100% 100%;
      // animation: wave-squares 2s infinite ease-out;
    }
    &-building {
      position: absolute;
      top: 0;
      left: 0;
      width: 430px;
      height: 192px;
      transition: 4s;
      z-index: 1;
      filter: grayscale(0);
    }
    &-pipe {
      left: 0;
      bottom: 0;
      position: absolute;
      width: 201.5px;
      height: 88.82px;
      transition: 4s;
      z-index: 2;
      filter: grayscale(0);
    }
    &-shovel {
      position: absolute;
      right: 0;
      bottom: 0;
      transition: 4s;
      z-index: 3;
      width: 185px;
      height: 182px;
      filter: grayscale(0);
    }
    &-cone {
      position: absolute;
      right: 50%;
      bottom: 0;
      transition: 4s;
      z-index: 4;
      width: 45px;
      height: 64px;
      filter: grayscale(0);
    }
    &-barrier {
      position: absolute;
      z-index: 5;
      bottom: 0;
      transition: 4s;
      width: 436px;
      height: 97px;
      filter: grayscale(0);
    }
    &-zaplan {
      position: absolute;
      width: 161px;
      height: 95px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      transition: all 3s;
      opacity: 0;
      z-index: 5;
      filter: grayscale(1) blur(1px);
    }
    &-zaplan-logo {
      width: 161px;
      height: 95px;
    }
    &-sun {
      position: absolute;
      width: 30px;
      height: 30px;
      right: 45%;
      top: 8%;
      opacity: 0;
      z-index: 0;
      transition: all 4s;
      path {
        fill: rgb(235, 166, 17);
      }
    }
  }
}
.emptySection-img:hover .emptySection-img-building {
  transform: scale(0.9);
  opacity: 0.3;
  filter: grayscale(0.7) blur(1px);
}
.emptySection-img:hover .emptySection-img-pipe {
  transform: scale(0.5);
  transform: translate(20px);
  transform: skew(2deg, 2deg);
  opacity: 0.9;
  filter: grayscale(0.7) blur(0.5px);
}
.emptySection-img:hover .emptySection-img-shovel {
  transform: scale(0.5);
  transform: skew(-2deg, -2deg);
  transform: translate(-5px);
  // opacity: 0.5;
  filter: grayscale(0.7) blur(0.5px);
}

.emptySection-img:hover .emptySection-img-cone {
  transform: scale(0.9);
  // opacity: 0.5;
  filter: grayscale(0.7) blur(0.3px);
}

.emptySection-img:hover .emptySection-img-barrier {
  transform: scale(1.01);
  filter: grayscale(0.7) blur(0.1px) contrast(55%);
}
.emptySection-img:hover .emptySection-img-sun {
  opacity: 1;
  top: -10px;
  transform: scale(1.01);
  transform: rotate(180deg);
  fill: rgb(255, 255, 0);
}
.emptySection-img:hover .emptySection-img-zaplan {
  opacity: 1;
  top: 50%;
  left: 50%;
  z-index: 7;
  transform: scale(1.5) translate(-35%, -40%);
  filter: grayscale(0) blur(0);
}
