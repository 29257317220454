@import "/src/styles/variables";

.modalEditLayer {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-choose {
    display: flex;
    justify-content: flex-end;
    span {
      font-family: "Montserrat";
    }
    &-main {
      display: flex;
      align-items: center;
      max-width: fit-content;
      border-radius: 50px 0 0 50px;
      background-color: #f5f9fb;
      min-width: 50px;
      padding: 0px 15px;
      height: 50px;
      gap: 10px;
      transform: translateX(20px);
      span {
        color: #c0c0c0;
        font-weight: 700;
        cursor: pointer;
        @include FSnav;
      }
      @include HoverFilterPanelText;
      &-text.active {
        color: black;
        background: black;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &-basicInfo {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-plan {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-title {
        @include FSbaseBold;
        color: $blue;
      }
      &-wrapper {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-img {
        width: 100%;
        height: 450px;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;
      }
    }

    &-btns {
      width: 100%;
      display: flex;
      gap: 10px;
      flex-direction: column;
      &-wrapper {
        display: flex;
        width: 100%;
        gap: 10px;
        input[type="file"] {
          display: none;
        }
        // label {
        //   width: 150px;
        // }
        @include _768 {
          display: flex;
          flex-direction: column;
        }
      }
      &-filePlan {
        width: fit-content;
      }
      &-submit {
        display: flex;
        justify-content: center;
      }
    }
  }
}
