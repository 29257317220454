@import "/src/styles/variables";

.companiesCardMobile {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  border: 1px solid $grey;
  @include borderRadius;
  min-height: 310px;
  background-color: #fff;
  overflow: hidden;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    padding: 10px 0;
    margin: 0 10px;
    &-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: top;
      &-text {
        &-title {
          @include FSbaseBoldM;
          color: $blue;
        }

        &-count {
          @include FSbaseBoldM;
        }
      }
    }
    &-img {
      @include AvatarM;
    }
    &-title {
      @include FSbaseBoldM;
      text-align: center;
      @include nowrap;
      max-width: 200px;
    }
    border-bottom: 1px solid $main-color;
  }
  &-content {
    padding: 10px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-item {
      display: flex;
      justify-content: space-between;
      display: grid;
      grid-template-columns: 1fr 3fr;
      &-title {
        white-space: nowrap;
        @include FSbaseBoldM;
        color: $blue;
        margin-right: 5px;
      }
      &-text {
        @include FSbaseBoldM;
        text-align: end;
        @include nowrap;
      }
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    background-color: $main-color;
    @include gapMin;
    padding: 10px;
    &-item {
      display: flex;
      gap: 5px;
      align-items: center;
      &-text {
        @include FSbaseBoldM;
        color: #fff;
        display: inline-block;
        text-decoration: none;
      }
    }
  }
}
