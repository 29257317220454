@import "/src/styles/variables";

.projectWorkScheduleChart {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  // &:has(.project-content-filter-info) {
  //   padding-bottom: 0;
  //   border: 1px solid red;
  // }
}
