@import "/src/styles/variables";

.deliveryWorkMobile {
  width: 100%;
  height: auto;
  padding-bottom: 60px;
  &-grid {
    @include cardColumnGridMobile;
    padding: 10px 0;
  }
}
