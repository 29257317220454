@import "../../../styles/_variables.scss";

.analytics {
  display: flex;
  height: 100%;
  &-content {
    padding-top: 20px;

    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;

    height: 100%;
    @include _1700 {
      padding-top: 10px;
    }
    &-main {
      height: calc(100vh - 170px);
      display: flex;
      flex-direction: column;
      @include gapBig;
      padding-bottom: 20px;
      @include _2000 {
        height: calc(100vh - 150px);
      }
      @include _1700 {
        height: calc(100vh - 110px);
      }
    }
    &-searchPanel,
    &-reports,
    &-map,
    &-charts,
    &-list {
      padding: 0 20px 0 10px;
    }
    &-charts {
      display: grid;
      @include gapBig;
      grid-template-areas:
        "block1 block2"
        "block3 block3";
      grid-template-columns: 1fr 1fr;

      &-completedTasks {
        grid-area: block1;
      }
      &-completedTasksStages {
        grid-area: block2;
      }
      &-closedProjects {
        grid-area: block3;
      }
      @include _1300 {
        display: flex;
        flex-direction: column;
      }
    }
    &-filter {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
    }
    &-addChart {
      display: flex;
      flex-direction: column;
      @include gapBig;
      &-content {
        width: 100%;
        display: grid;
        grid-template-columns: 250px 1fr;
        @include gapBig;
        padding: 0 10px;
        @include _2000 {
          display: flex;
          flex-direction: column;
        }
        &-panel {
          background: white;
          border: 1px solid $grey;
          @include borderRadius;
          padding: 20px 10px;
          display: flex;
          flex-direction: column;
          width: 100%;
          max-width: 264px;
          @include gapBig;
          height: fit-content;
          @include _2000 {
            // flex-direction: row;
            max-width: 100%;
          }
          &-title {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
            text-align: center;
          }
          &-list {
            display: flex;
            flex-direction: column;
            display: flex;
            flex-direction: column;
            @include gapMin;
            @include _2000 {
              display: grid;
              grid-template-columns: repeat(8, 1fr);
              flex-direction: row;
              width: 100%;
            }
            &-item {
              display: flex;
              @include gapMin;
              background: white;
              align-items: center;
              padding: 13px 10px;
              border: 1px solid $grey;
              @include borderRadius;
              cursor: pointer;
              span {
                font-family: "Montserrat";
                @include FSbaseBold;
                color: $blue;
              }
              @include HoverBtnAnal;
              @include _2000 {
                padding: 5px 10px;
              }
            }
            &-item.active {
              background-color: $blue;
              background: linear-gradient($blue, $blue);
              border: 1px solid $blue;
              > span {
                color: white;
              }
              svg {
                fill: white;
              }
            }
          }
        }
        &-form {
          width: 100%;
        }
      }
    }
  }
}
