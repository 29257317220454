@import "./src/styles/variables.scss";

.addedPosition {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-title {
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
  }
  &-content {
    border: 1px solid $blue-m-bg;
    @include borderRadius;
    background-color: $blue-m-bg;
    height: calc(100dvh - 250px);
  }
  &-grid {
    padding: 10px;
    height: fit-content;
    // @include cardColumnGrid;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    // grid-template-rows: auto;
    // grid-auto-flow: column;
    // display: flex;
    // flex-direction: column;
    // grid-auto-rows: 50px;
    grid-template-rows: repeat(30, 1fr);
    grid-auto-flow: column;
    gap: 5px;
    margin-bottom: 0;
    @include _1024 {
      @include cardColumnGridMobile;
    }
  }
  &-empty {
    padding: 10px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &-title {
      @include FSh1;
      margin-bottom: 20px;

      color: $blue;
      opacity: 0.5;
      @include _1024 {
        @include FStitleM;
      }
    }
  }
}
