@import "./variables";
@import "./fonts.scss";
@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}
// html {
//   overflow-x: hidden;
//   width: 100vw;
// }
#portal {
  height: 100%;
  width: 100%;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Montserrat";
}

ul {
  list-style-type: none;
}
.app {
  height: 100vh;
  z-index: 1;
  position: relative;
  font-family: "Montserrat";
  background-color: #f5f9fb;
  &-mobile-fixed {
    overflow: hidden;
  }
}

button,
input,
textarea {
  outline: none;
  border: none;
}

button {
  cursor: pointer;
}

// customeClasses ----------------------------------------------------------------
hr.hr-white {
  background: #ffffff;
  @include borderRadius;
  width: 100%;
  height: 1px;
}

.w-100 {
  width: 100%;
}

hr.hr-grey {
  background: #f6f6f6;
  @include borderRadius;
  width: 100%;
  height: 2px;
}

.d-none {
  display: none;
}

.d-flex {
  display: flex;
}

.d-flex-jcsb {
  display: flex;
  justify-content: space-between;
}
// класс на насильственного переноса
.word-break {
  hyphens: auto;
  // word-break: break-all;
}

.d-flex-d-col-g-30 {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.d-flex-d-col-g-5 {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.justify-self-end {
  justify-self: flex-end;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-15 {
  gap: 15px;
}

.gap-12 {
  gap: 12px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

.gap-40 {
  gap: 40px;
}

.gap-50 {
  gap: 50px;
}

.gap-80 {
  gap: 80px;
}

.flex-dir-column {
  display: flex;
  flex-direction: column;
}

.flex-dir-row {
  flex-direction: row;
}

.justify-end {
  justify-content: flex-end;
}

.justify-start {
  justify-content: flex-start;
}

.justify-between {
  justify-content: space-between;
}

.align-center {
  align-items: center;
}

.align-end {
  align-items: flex-end;
}

.justify-center {
  justify-content: center;
}

.bgc-white {
  background-color: white;
}

.b-r-5 {
  @include borderRadius;
}

.p-10 {
  padding: 10px;
}

.fz-20 {
  font-size: 20px;
}

.color-grey {
  color: $grey;
}

.modal {
  z-index: 102;
}

.rotate90 {
  transform: rotate(90deg);
}

.rotate180 {
  transform: rotate(180deg);
}

.white-space-nowrap {
  white-space: nowrap;
}

.text-center {
  text-align: center;
}

.pb-20 {
  padding-bottom: 20px;
}

.bgc-shadow {
  position: absolute;
  top: 0;
  right: 0;
  &:after {
    content: "";
    position: fixed;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.37);
    width: 100%;
    height: 100%;
    z-index: 101;
  }
}

// scrollbar ----------------------------------------------------------------
.customScroll,
.customScroll-light {
  overflow-y: auto;
}

.customScroll-horizontally {
  overflow-x: auto;
}

.customScroll::-webkit-scrollbar {
  width: 10px;
  background-color: #f9f9fd;
  border-radius: 10px;
  @media (max-width: (1024px + 0.02)) {
    width: 5px;
  }
}

.customScroll::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $blue-light-opacity;
  &:hover {
    background-color: $main-color;
  }
}

.customScroll::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

body::-webkit-scrollbar {
  width: 5px;
  background-color: #f9f9fd;
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $blue-light-opacity;
  &:hover {
    background-color: $main-color;
  }
}

body::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

.customScroll-horizontally::-webkit-scrollbar {
  height: 10px;
  background-color: #f9f9fd;
  border-radius: 10px;
  @media (max-width: (1024px + 0.02)) {
    height: 5px;
  }
}

.customScroll-horizontally::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $blue-light-opacity;
  &:hover {
    background-color: $main-color;
  }
}

.customScroll-horizontally::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
}

// -------

.customScroll-light::-webkit-scrollbar {
  width: 3px;
  background-color: #f9f9fd;
  border-radius: 10px;
}

.customScroll-light::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $grey;
}

.customScroll-light::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  background-color: #f9f9fd;
  left: 10px;
}

:root {
  --toastify-color-light: #fff;
  --toastify-color-dark: #000000;
  --toastify-color-info: #04488e .;
  --toastify-color-success: #16c35b;
  --toastify-color-warning: #dc9d00;
  --toastify-color-error: #db1f35;
  --toastify-color-transparent: rgba(255, 255, 255, 0.7);

  --toastify-icon-color-info: var(--toastify-color-info);
  --toastify-icon-color-success: var(--toastify-color-success);
  --toastify-icon-color-warning: var(--toastify-color-warning);
  --toastify-icon-color-error: var(--toastify-color-error);

  --toastify-toast-width: 400px;
  --toastify-toast-background: #fff;
  --toastify-toast-min-height: 64px;
  --toastify-toast-max-height: 800px;
  --toastify-font-family: sans-serif;
  --toastify-z-index: 9999;

  --toastify-text-color-light: #000000;
  --toastify-text-color-dark: #fff;

  //Used only for colored theme
  --toastify-text-color-info: #fff;
  --toastify-text-color-success: #fff;
  --toastify-text-color-warning: #fff;
  --toastify-text-color-error: #fff;

  --toastify-spinner-color: #616161;
  --toastify-spinner-color-empty-area: #e0e0e0;

  // Used when no type is provided
  // toast("**hello**")
  --toastify-color-progress-light: linear-gradient(
    to right,
    #4cd964,
    #5ac8fa,
    #007aff,
    #34aadc,
    #5856d6,
    #ff2d55
  );
  // Used when no type is provided
  --toastify-color-progress-dark: #bb86fc;
  --toastify-color-progress-info: var(--toastify-color-info);
  --toastify-color-progress-success: var(--toastify-color-success);
  --toastify-color-progress-warning: var(--toastify-color-warning);
  --toastify-color-progress-error: var(--toastify-color-error);
}
.Toastify {
  &__toast-container {
    margin-bottom: 30px;
    > div {
      font-family: "Montserrat";
      @include FSbaseReg;
      @include _1024 {
        top: 0px;
      }
    }
  }
  &__toast-icon {
    width: 15px;
  }
  &__progress-bar {
    height: 3px;
  }
}

// мобильные стили
.mobile-container {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  padding: 0 10px;
}
.react-datepicker {
  font-family: "Montserrat";
  padding: 10px;
  &__portal {
    width: 100%;
    height: 100%;
  }
  &__header {
    background-color: #fff !important;
    border: none;
    padding-top: 20px;
  }
  &__time-list {
    height: 280px !important;
    @include _1700 {
      height: 200px !important;
    }
    @include _1024 {
      height: 280px !important;
    }
  }

  &__navigation {
    position: relative;
    &--previous {
      position: absolute;
      top: 30px;
      left: 20px;
      @include _1700 {
        left: 10px;
      }
      @include _1024 {
        top: 30px;
      }
    }
    &--next {
      position: absolute;
      top: 30px;
      right: 20px;
      @include _1700 {
        right: 5px;
      }
      @include _1024 {
        right: 20px;
      }
    }
    &--years {
      &-dropdown-container {
        padding: 20px !important;
        @include _1700 {
          padding: 10px !important;
        }
        @include _1024 {
          padding: 20px !important;
        }
      }
      &-upcoming {
        border-color: #ccc;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        height: 9px;
        position: absolute;
        top: 5px;
        left: 50%;
        transform: translate(-50%, 0) rotate(-45deg);
        width: 9px;
        &:hover {
          border-color: #fff;
        }
      }
      &-previous {
        border-color: #ccc;
        border-style: solid;
        border-width: 3px 3px 0 0;
        content: "";
        display: block;
        height: 9px;
        position: absolute;
        top: -2px;
        left: 50%;
        transform: translate(-50%, 0) rotate(135deg);
        width: 9px;
        &:hover {
          border-color: #fff;
        }
      }
    }
  }
  &__current-month {
    text-transform: capitalize;
    display: none;
  }
  &__month {
    &-dropdown {
      width: 35%;
      left: 20%;
      top: 8px;
      background-color: #fff;
      border: none;
      padding: 8px 0 8px 0;
      box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
        0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
      border-radius: 8px;
      &-container--scroll {
        @include _1700 {
          margin: 0 0 0 20px;
        }
      }
    }
    &-read-view {
      padding: 8px 32px 8px 16px;
      @include _1700 {
        padding: 4px 30px 4px 8px;
      }
      @include _1024 {
        padding: 8px 32px 8px 16px;
      }
      &--selected-month {
        text-transform: capitalize;
        font-family: "Montserrat";
        font-style: normal;
        line-height: 24px;
        @include FSbaseBold;
        color: $blue;
        @include borderRadius;
      }
      &--down-arrow {
        top: 13px;
        right: 10px;
        @include _1700 {
          top: 5px;
        }
        @include _1024 {
          top: 13px;
          right: 10px;
        }
      }
      &:hover {
        background-color: #f8f7fa;
        transition: 0.3s;
        .react-datepicker__month-read-view--selected-month {
          color: $blue;
          transition: 0.3s;
        }
        .react-datepicker__month-read-view--down-arrow {
          border-color: $blue !important;
          transition: 0.3s;
        }
      }
    }
    &-option {
      text-transform: capitalize;
      text-align: left;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseReg;
      line-height: 24px;
      padding: 4px 16px;
      &--selected_month {
        color: $blue;
        background-color: $background-color-link;
      }
      &--selected {
        display: none;
      }
      &:hover {
        background-color: $blue;
        color: #fff;
        transition: 0.3s;
      }
    }
    &-read-view--down-arrow {
      top: 13px;
      right: 10px;
      @include _1700 {
        top: 10px;
      }
      @include _1024 {
        top: 13px;
        right: 10px;
      }
    }
  }
  &__year {
    &-dropdown {
      top: 8px;
      left: 54%;
      width: 25%;
      border: none;
      background-color: #fff;
      box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08), 0px 8px 32px rgba(19, 10, 46, 0.07),
        0px 3px 14px rgba(19, 10, 46, 0.03), 0px 1px 3px rgba(19, 10, 46, 0.13);
      border-radius: 8px;
      &-container--scroll {
        @include _1700 {
          margin: 0 20px 0 0;
        }
      }
    }
    &-read-view {
      padding: 8px 32px 8px 16px;
      font-family: "Montserrat";
      font-style: normal;
      line-height: 24px;
      @include FSbaseSemibold;
      @include _1700 {
        padding: 8px 30px 4px 8px;
      }
      @include _1024 {
        padding: 8px 32px 8px 16px;
      }
      &--down-arrow {
        top: 12px;
        right: 10px;
      }
      &:hover {
        background-color: #f8f7fa;
        transition: 0.3s;
        .react-datepicker__year-read-view--selected-year {
          color: $blue;
          transition: 0.3s;
        }
        .react-datepicker__year-read-view--down-arrow {
          border-color: $blue !important;
          transition: 0.3s;
        }
      }
    }
    &-option {
      position: relative;
      text-align: center;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseReg;
      line-height: 24px;
      min-height: 15px;
      padding: 4px 0;
      &--selected {
        display: none;
      }
      &--selected_year {
        color: $blue;
        background-color: $background-color-link;
      }
      &:hover {
        background-color: $blue;
        color: #fff;
      }
    }
    &-select {
      border: none;
    }
  }
  &__day {
    width: 35px !important;
    line-height: 35px !important;
    font-size: 16px;
    @include FSbaseReg;
    border-radius: 50%;
    transition: background-color 0.2s, color 0.2s;
    @include _1700 {
      width: 25px !important;
      line-height: 25px !important;
    }
    @include _1024 {
      width: 35px !important;
      line-height: 35px !important;
    }
    @include _400 {
      width: 35px !important;
      line-height: 35px !important;
    }
    &--outside-month {
      color: $grey;
    }
    &--selected {
      border-radius: 50%;
      background-color: $blue;
    }
    &:hover {
      background-color: $blue-opacity;
      // transition: background-color 0.2s;
      color: #fff;
      border-radius: 50%;
    }
    &-name {
      font-family: "Montserrat";
      // width: 2rem !important;
      // line-height: 2rem !important;
      text-transform: capitalize;
      font-style: normal;
      line-height: 24px;
      @include FSbaseSemibold;
      width: 35px !important;
      line-height: 35px !important;
      @include _1700 {
        width: 25px !important;
        line-height: 25px !important;
      }
      @include _1024 {
        width: 35px !important;
        line-height: 35px !important;
      }
      @include _400 {
        width: 35px !important;
        line-height: 35px !important;
      }
    }
    &--keyboard-selected {
      background-color: #f8f7fa;
      color: $blue;
      border-radius: 50%;
    }
  }
  &-time {
    &__input {
      position: relative;
    }
    &__caption {
      font-style: normal;
      line-height: 24px;
      @include FSbaseSemibold;
    }
    &__list {
      @include _1700 {
        height: 209px;
      }
    }
    &__input {
      width: 83px;
      &-container {
        input[type="time"] {
          font-style: normal;
          line-height: 24px;
          @include FSbaseSemibold;
          width: 130px !important;
        }
        input[type="time"]::-webkit-datetime-edit-text {
          padding: 19px 4px;
        }
        input[type="time"]::-webkit-datetime-edit-hour-field {
          background-color: $background-color-link;
          border-radius: 15%;
          color: $blue;
          padding: 19px 13px;
        }
        input[type="time"]::-webkit-datetime-edit-minute-field {
          background-color: $background-color-link;
          border-radius: 15%;
          color: $blue;
          padding: 19px 13px;
        }
        input[type="time"]::-webkit-calendar-picker-indicator {
          cursor: pointer;
        }
      }
    }
  }

  &__portal {
    background-color: rgba(0, 0, 0, 0.2) !important;
    @include _1024 {
      position: fixed;
      background-color: rgba(0, 0, 0, 0.1) !important;
    }
  }
}
.submit-spinner {
  display: inline-block;
  width: 1rem;
  height: 1rem;
  vertical-align: -0.125em;
  border: 0.2em solid #fff;
  border-right-color: $main-color;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
}
.Toastify-customeBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &-btns {
    width: 100%;
  }
}
