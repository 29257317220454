@import "src/styles/variables";
.planList {
  display: flex;
  flex-direction: column;
  @include gapMin;
  padding: 10px;
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  width: 100%;
  height: 100%;
  // max-width: 264px;
  height: fit-content;
  @include _1300 {
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }
}

.swiper-planList {
  margin-left: 0;
  &-item {
    width: fit-content;
    height: 100%;
  }
}
