@import "../../../../styles/_variables.scss";

.navigationMobilePanel {
  display: flex;
  border-bottom: 1px solid $grey;
  &-list {
    display: flex;
    gap: 20px;

    width: 100%;
    justify-content: flex-start;
    // overflow-x: scroll;
  }
  &-item {
    @include FSbaseBoldM;
    padding: 10px 0;
    color: $grey;
    display: flex;
    align-items: center;
    gap: 5px;
    white-space: nowrap;
    &.active {
      color: black;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        bottom: -1px;
        border-bottom: 4px solid black;
        width: 100%;
        height: 20px;
      }
    }
  }
}
.swiper-nav {
  margin-left: 0;
  &-item {
    width: fit-content;
    height: 100%;
  }
}
