@import "/src/styles/variables";

.activeHeaderDocumentation {
  background-color: #fff;
  @include borderRadius;
  height: 40px;
  width: 100%;
  display: flex;
  @include gapBig;

  align-items: center;
  z-index: 3;

  &-item {
    display: flex;
    gap: 5px;
    cursor: pointer;
    align-items: center;
    height: 100%;
    &-text {
      @include FSbaseReg;
    }
    @include HoverModalText;
    @include _1300 {
      display: flex;
      align-items: center;
      @include gapMin;
      transition: all 1s linear;
      max-width: 20px;
      transition: all 0.7s ease-in-out;
      overflow: hidden;
      white-space: nowrap;
      &:hover {
        max-width: 300px;
      }
    }
  }
}
