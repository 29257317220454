@import "/src/styles/variables";

.modalAddLayer {
  span {
    font-family: "Montserrat";
  }
  &-main {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .sc-bczRLJ.kiHXWb {
      max-width: none;
      width: 100%;
      height: 158px;
      .sc-dkzDqf.fVfcRr {
        display: flex;
        justify-content: center;
      }
    }
    &-form {
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
      .input {
        width: 100%;
      }
    }
    &-btns {
      display: flex;
      justify-content: center;
    }
  }
}
