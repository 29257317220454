@import "/src/styles/variables";

.cardMeetingMinutesRow {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-family: "Montserrat";
  background-color: #ffffff;
  border: 1px solid $grey;
  padding: 8px 10px;
  @include borderRadius;
  &-container {
    align-items: center;
    width: 100%;
    display: grid;
    gap: 10px;
    grid-template-columns: 150px 1fr 3fr 0.5fr minmax(100px, 1fr);
    &-item {
      display: flex;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;

      span,
      a {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: black;
        text-decoration: none;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-item.name {
      @include borderRadius;

      overflow: hidden;
      align-self: center;
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-item.number {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: flex-start;
    }
    &-item.btns {
      display: flex;
      align-items: center;
      gap: 10px;
      justify-self: flex-end;
      overflow: visible;
      img {
        cursor: pointer;
      }
      img.task {
        width: 24px;
        @include SmdBtn;
      }
    }
    &-item.task {
      span {
        color: $red;
      }
    }
  }
  &-task {
    @include flexGap(10px, col);

    span {
      font-family: "Montserrat";
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-cards {
      @include flexGap(10px, col);
      &-header {
        align-items: center;
        display: grid;
        padding: 0 10px 0 10px;
        grid-template-columns: 3fr 1fr 1fr 2fr 1.2fr 1.2fr 1fr 110px;
        &-item {
          &:not(:nth-child(8)) {
            display: flex;
            text-align: center;
            white-space: nowrap;
            overflow: hidden;
            justify-content: center;
          }
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
            display: inline-block;
            text-align: start;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        &-item.number {
          display: flex;
          align-items: center;
          gap: 25px;
          justify-content: flex-start;
        }
      }
      &-item {
        @include flexGap(10px, col);
        background: #ffffff;
        border: 1px solid $grey;
        @include borderRadius;
        padding: 8px 10px;
      }
      &-subitem {
        @include flexGap(10px, col);
        &-title {
          @include FSbaseBold;
          color: $blue;
        }
      }
    }
  }
}
