@import "/src/styles/variables";

.modalWorkInfo {
  display: flex;
  flex-direction: column;
  @include gapBig;
  span {
    @include FSbaseBold;
  }
  &-historyChanges {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-title {
    color: $blue;
  }
  &-row {
    display: grid;
    @include gapBig;
    grid-template-columns: repeat(5, 1fr);
  }
  &-select-row {
    display: grid;
    @include gapBig;
    grid-template-columns: repeat(2, 1fr);
    @include _768 {
      grid-template-columns: 1fr;
    }
  }
  &-subcontractingOrganizations {
    padding: 10px;
    display: flex;
    @include gapMin;
    flex-wrap: wrap;
    box-shadow: (0px 0px 5px rgba(0, 0, 0, 0.2));
    @include borderRadius;
    width: 440px;
    height: 80px;
  }
  .logo {
    width: 46px;
    height: 46px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
  }
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-text {
    @include FSbaseBold;
  }
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _400 {
      flex-direction: row;
    }
  }
  &-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    gap: 20px;
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _400 {
      grid-template-columns: 1fr;
    }
  }
}
