@import "../../../styles/variables";

.calendar {
  display: flex;
  height: 100%;
  span {
    font-family: "Montserrat";
  }
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &-main {
      width: 100%;
      padding-left: 10px;
      padding-bottom: 20px;
      padding-right: 20px;
      max-height: calc(100vh - 290px);
      @include _2000 {
        max-height: calc(100vh - 275px);
      }
      @include _1700 {
        max-height: calc(100vh - 200px);
      }
    }
    &__filter {
      &-set {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        @include gapBig;
      }
      :nth-child(1) {
        border: 1px solid red;
      }
    }
    &-searchPanel {
      padding: 0 20px 0 10px;
    }
    &-events {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 275px;
      @include gapBig;
      @include _2000 {
        grid-template-columns: 1fr 250px;
      }
      @include _1700 {
        grid-template-columns: 1fr 220px;
      }
      &-table {
        overflow-x: scroll;
      }
    }
  }
  .title {
    color: $blue;
    font-weight: 700;
  }
  .bold {
    @include FSbaseBold;
  }

  .hr {
    background-color: #7bc7f1;
    height: 2px;
    width: 100%;
  }
}
