@import "./src/styles/variables";

.modalAddDepartmentMobile {
  &-form {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-btn-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
