@import "../../styles/_variables.scss";
.leftInfo {
  border-radius: 0 50px 50px 0;
  max-height: 50px;
  padding: 14px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: fit-content;
  cursor: pointer;
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: black;
  }
  @include _2000 {
    border-radius: 0 45px 45px 0;
    height: 45px;
    padding: 10px 15px;
  }
  @include _1700 {
    border-radius: 0 40px 40px 0;
    height: 35px;
    padding: 10px 15px;
  }
  &-icon {
    > img {
      @include SFilterPanelIcon;
    }
  }
}
