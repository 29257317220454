@import "../../styles/variables";

.checkbox {
  display: flex;
  @include gapMin;
  align-items: center;
  span {
    font-family: "Montserrat";
    @include FSbaseReg;
  }
  &-input {
    width: 20px;
    height: 20px;
    border: 1px solid $grey;
    border-radius: 5px; // не менять!!
    position: relative;
    cursor: pointer;
    background-color: $blue-m-bg !important;
    border: 1px solid $blue-m-bg !important;
    @include _1700 {
      width: 18px;
      height: 18px;
    }
    @include _1024 {
      width: 18px;
      height: 18px;
    }
  }
  &-input.check::after {
    content: url("../../assets/icons/checkMarkCheckbox.svg");
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    zoom: 1.1;
    @include _1700 {
      zoom: 1;
    }
    @include _1300 {
      zoom: 0.9;
    }
  }
}
