@import "../../../../../styles/variables";

.groups {
  display: flex;
  flex-direction: column;
  &__searchPanel {
    padding: 0 20px 0 10px;
  }
  &__content {
    padding-top: 20px;
    width: 100%;
    height: 100%;
    @include _1700 {
      padding-top: 10px;
    }
  }
  &-row {
    width: 100%;
    height: calc(100vh - 290px);
    padding: 0 20px 0 10px;
    overflow-y: scroll;
    overflow-x: hidden;
    display: flex;
    flex-direction: column;
    @include gapBig;
    &__header {
      font-family: "Montserrat";
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 10px;
      align-items: center;
      grid-template-columns:
        // 80px

        2fr
        2fr
        // 2fr
        2fr
        20px;
      @include headerWrapper;
    }
    &-header {
      padding-bottom: 10px;
      &__item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__item-number {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        @include gapBig;
      }
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
    padding-top: 20px;
    @include _1700 {
      padding-top: 10px;
    }
  }
}
