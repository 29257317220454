@import "../../styles/variables";

@keyframes animateClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.companyItem {
  display: none;
}

.selectMulti {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  position: relative;
  @include FSbaseBold;
  @include gapMin;
  &-title {
    font-size: 14px;
    // @include FStitleRow;
  }
  &-validate {
    position: absolute;
    bottom: -12px;
    span {
      color: red;
      @include FSxsReg;
    }
  }
  &-close-icon {
    animation: animateClose 2s linear;
    opacity: 0.5;
  }
  .css-g1d714-ValueContainer {
    padding: 0px 0px;
    display: flex;
    gap: 5px;
  }
  .css-1rhbuit-multiValue {
    background-color: #fff;
    width: 44%;
    height: 30px;
    border: 1px solid $grey;
    display: flex;
    margin: 0;
    justify-content: space-between;
    @include borderRadius;
    > div {
      font-family: "Montserrat";
      font-size: 16px;
      @include FSbaseReg;
    }
  }
  .css-6j8wv5-Input {
    width: 5%;
  }
}

.selectMulti.validate {
  .react-select-multi__control,
  .react-select-multi-listModal__control {
    border: 1px solid $orange !important;
    // border-radius: 10px !important ;
    @include borderRadius;
  }
}

.select.titleForInput {
  .select-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
}

.selectMulti.forInput {
  // display: flex;
  // flex-direction: column;
  // @include gapMin;
  .select-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  .react-select-multi__control {
    border: 1px solid $grey;

    width: 100%;
    min-height: 45px;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 3px 5px 3px 6px;
    color: black;
    background: white;
    @include borderRadius;
    background-color: $blue-m-bg !important;
    // border-radius: 10px !important ;
    @include borderRadius;
    &:active {
      // border: 1px solid $grey;
      width: 100%;
      font-family: "Montserrat";
      @include FSbaseReg;
      padding: 3px 5px 3px 6px;
      color: black;
      background: white;
      @include borderRadius;
      min-height: 45px;
      @include _2000 {
        min-height: 40px;
        padding: 3px 5px 3px 6px;
      }
      @include _1700 {
        min-height: 35px !important;
        padding: 0px 0px 0px 8px;
      }
      // @include _1024 {
      //
      border: 1px solid $blue-m-bg;
      background: $blue-m-bg;
      // }
    }
    @include _2000 {
      min-height: 40px;
      padding: 3px 5px 3px 6px;
    }
    @include _1700 {
      min-height: 35px !important;
      padding: 0px 0px 0px 8px;
    }
  }

  .react-select-multi__value-container {
    padding: 0;
  }
  .react-select-multi__input {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
    }
  }
}
.react-select__indicator-separator {
  display: none;
}
.dropdownIndicator {
  height: 12px;
  width: 20px;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  &-open {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
  }
}
.react-select-multi-listModal {
  &__input-container {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
    }
  }
  &__single-value {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: black;
  }
  &__menu {
    @include FSbaseBold;
    > div {
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
        @include borderRadius;
      }

      &::-webkit-scrollbar-thumb {
        @include borderRadius;
        background-color: $main-color;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        background-color: #f9f9fd;
      }
    }
  }
  &__indicator-separator {
    background-color: #fff !important;
  }
  &__input-container {
    @include FSbaseBold;
  }
  &__value-container {
    padding: 0 !important;
    @include FSbaseReg;
  }
  &__control {
    transition: all 0.2s;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 5px 7.5px 10px;
    color: black;
    background: white;
    @include borderRadius;
    @include _2000 {
      min-height: 40px;
      padding: 5px 0px 5px 10px;
    }
    @include _1700 {
      min-height: 35px !important;
      padding: 0px 0px 0px 8px;
    }
    &:hover {
      border: 1px solid $grey;
    }
    // @include _1024 {
    background-color: $blue-m-bg !important;
    border: 1px solid $blue-m-bg !important;
    &::placeholder {
      color: $blue-m-text;
    }
    // }
  }
  &__value-container {
    display: grid !important;
    grid-template-columns: repeat(
      auto-fill,
      minmax(280px, 1fr)
    ) !important; // минимальную ширину не увеличивать !
    gap: 5px;
    max-height: 200px;
    overflow-y: auto !important;
    margin-right: 5px;
    padding: 4px 0 4px 0 !important;
    @include _1200 {
      grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)) !important;
    }
    @include _600 {
      grid-template-columns: repeat(auto-fill, minmax(300px, 1fr)) !important;
    }
    @include _400 {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
    }
    &::-webkit-scrollbar {
      width: 5px;
      background-color: #f9f9fd;
      @include borderRadius;
    }
    &::-webkit-scrollbar-thumb {
      @include borderRadius;
      background-color: $main-color;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      background-color: #f9f9fd;
    }
  }
  &__multi-value {
    padding: 6px;
    display: flex !important;
    justify-content: space-between !important;
    background-color: #fff !important;
    gap: 10px;
    // border-radius: 10px !important;
    @include borderRadius;
    // @include _1024 {
    background-color: #fff !important;
    border-radius: 2px;
    // border: 1px solid $blue-m-bg !important;
    margin: 0 !important;
    // }
    &__label {
      @include FSbaseReg;
      padding: 0 !important;
      color: black !important;
    }
    &__remove {
      padding: 0 !important;
      cursor: pointer;
      &:hover {
        background-color: white !important;
      }
    }
  }
  &__input-container {
    grid-area: 1/1/1/-1 !important;
    // border-bottom: 1px solid $grey;
    // height: 20px;
    margin: 0;
    padding: 0;
    &-border {
      border-bottom: 1px solid $grey !important;
      // margin-bottom: 5px;
      margin-right: 5px;
    }
  }
  &__input {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
      &::placeholder {
        color: $blue-m-text;
      }
    }
  }
  input {
    font-family: "Montserrat";
    @include FSbaseReg;
    color: black;
    &::placeholder {
      color: $blue-m-text;
    }
  }
}

.react-select-multi {
  &__input-container {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
      &::placeholder {
        color: $blue-m-text;
      }
    }
  }
  &__single-value {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: black;
  }
  &__menu {
    @include FSbaseBold;
    > div {
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
        @include borderRadius;
      }

      &::-webkit-scrollbar-thumb {
        @include borderRadius;
        background-color: $main-color;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        background-color: #f9f9fd;
      }
    }
  }
  &__indicator-separator {
    background-color: #fff !important;
  }
  &__input-container {
    @include FSbaseBold;
  }
  &__value-container {
    padding: 0 !important;
    @include FSbaseReg;
    margin-right: 5px;
  }
  &__control {
    transition: all 0.2s;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 5px 7.5px 10px;
    color: black;
    background: white;
    @include borderRadius;
    // border-radius: 10px !important ;
    @include _2000 {
      min-height: 40px;
      padding: 5px 0px 5px 10px;
    }
    @include _1700 {
      min-height: 35px !important;
      padding: 0px 0px 0px 8px;
    }
    &:hover {
      border: 1px solid $grey;
    }
    // @include _1024 {
    background-color: $blue-m-bg !important;
    border: 1px solid $blue-m-bg !important;
    // }
  }
  &__multi-value {
    // @include _1024 {
    background-color: #fff !important;
    // border-radius: 10px !important;
    @include borderRadius;
    padding: 3px;
    border: 1px solid $blue-m-bg !important;
    &__label {
      @include FSbaseReg;
    }

    // }
  }
  &__input {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
      &::placeholder {
        color: $blue-m-text;
      }
    }
  }
  input {
    font-family: "Montserrat";
    @include FSbaseReg;
    color: black;
    &::placeholder {
      color: $blue-m-text;
    }
  }
}
.select-placholder {
  color: $blue-m-text;
}

.selectMulti.forInput.isDisabled {
  .react-select-multi__control {
    opacity: 0.5;
  }
  .react-select-multi-listModal__control {
    opacity: 0.5;
  }
}
.react-select-multi-listModal.isDisabled {
  .react-select-multi-listModal__control {
    opacity: 0.5;
  }
}
