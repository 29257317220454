@import "./src/styles/_variables";

.projectWorkScheduleInfo {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-genTitle {
    @include FSsubtitle;
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
    @include gapBig;
    max-width: 1360px;
  }
  &-list {
    display: flex;
    gap: 130px;
    flex-wrap: wrap;
  }
  &-employees {
    display: flex;
    @include gapMin; // padding: 12px 10px;
    // border: 1px solid $grey;
    // @include borderRadius;
    overflow-y: auto;
    flex-wrap: wrap;
    max-height: 88px;
    > span {
      @include FSbaseBold;
    }
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  &-text {
    @include FSbaseBold;
  }
  &-gridEmployeesInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include gapBig;
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-gridScheduleInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include gapBig;
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _400 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      @include _400 {
        flex-direction: row;
      }
    }
  }
  &-gridEmployees {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include gapBig;

    &-edit {
      display: grid;
      @include gapBig;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      max-width: 1360px;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-gridDateInfo {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include gapBig;
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _400 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      @include _400 {
        flex-direction: row;
      }
    }
  }
  &-grid-list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    @include gapBig;
    max-width: 1360px;
    @include _1024 {
      column-gap: 10px;
    }
    @include _768 {
      grid-template-columns: 1fr 1fr;
    }
    @include _600 {
      grid-template-columns: 1fr;
    }
  }
  &-grid-item {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
}
