@import "src/styles/variables";

.bandsChart {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background: white;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 20px;
  // max-width: 832px;
  // max-height: 484px;
  width: 100%;
  @include _1024 {
    padding: 10px;
  }
  &-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-content {
    padding: 20px;
    img {
      width: 100%;
      max-width: 563px;
    }
    border-left: 3px solid #d9d9d9;
    border-bottom: 3px solid #d9d9d9;
    @include _1024 {
      padding: 10px;
    }
  }
}
