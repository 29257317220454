@import "./src/styles/variables.scss";

.settings {
  display: flex;
  height: 100%;
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &-filter {
      display: flex;
      flex-direction: row-reverse;
      &-set {
        display: flex;
        @include gapMin;
      }
    }
  }
}
