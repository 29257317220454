.modalAddWorkScheldule {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  &-btns {
    display: flex;
    justify-content: center;
  }
}
