@import "./src/styles/variables";

.inputSearch {
  font-family: "Montserrat";
  font-weight: 700;
  display: flex;
  flex-direction: column;
  @include gapMin;
  input {
    background: #fff;
    border: 1px solid $grey;
    @include borderRadius;
    font-family: "Montserrat";
    @include FSbaseBold;
    padding: 10px;
    width: 100%;
    &::placeholder {
      @include FSbaseReg;
    }
  }
  &-title {
    @include FSbaseBold;
    display: flex;
    color: $blue;
    gap: 3px;
    &-red {
      color: red !important;
    }
  }

  &-list {
    margin-top: -5px;
  }
}

.inputSearch.forInput {
  .inputSearch-title {
    @include FSbaseBold;
    color: $blue;
  }
}
