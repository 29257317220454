@import "/src/styles/variables";

.projectInfo {
  display: flex;
  flex-direction: column;
  @include gapBig;
  background: #ffffff;
  border: 2px solid $main-color;
  @include borderRadius;
  padding: 10px;
  height: fit-content;
  &-item {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-textBox {
      flex-direction: row;
      width: 100%;
      @include _400 {
        justify-content: space-between;
      }
    }
    &-text {
      @include FSbaseBold;
      &.atWork {
        color: $atWork;
      }
      &.rejected {
        color: $rejected;
      }
      &.completed {
        color: $completed;
      }
      &.onVerification {
        color: $onVerification;
      }
      &.overdue {
        color: $overdue;
      }
      &.assigned {
        color: $assigned;
      }
      &.important {
        display: flex;
        @include gapMin;
      }
      &-project {
        cursor: pointer;
        transform: all 0.3s;
        &:hover {
          color: $main-color;
          text-decoration: underline;
        }
      }
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    .bold {
      @include FSbaseBold;
    }
    &-canvas {
      position: relative;
      overflow: hidden;
      border-radius: 5px;
      border: 1px solid $blue-m-bg;
      &-magnifier {
        background: rgba(255, 255, 255, 0.5);
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 3;
        position: absolute;
        bottom: 10px;
        right: 10px;
        transform: rotate(90deg);
        @include borderRadius;
        @include _1300 {
          width: 30px;
          height: 30px;
        }
      }
      > div > div {
        zoom: 0.85;
        @include _2000 {
          zoom: 0.85;
        }
        @include _1700 {
          zoom: 0.75;
        }
        @include _1455 {
          zoom: 0.6;
        }
        @include _1300 {
          zoom: 0.55;
        }
      }

      &.zoomRows.zoomSmall {
        > div > div {
          zoom: 0.75;
          @include _2000 {
            zoom: 0.65;
          }

          @include _1455 {
            zoom: 0.55;
          }
          @include _1300 {
            zoom: 0.5;
          }
          @include _1024 {
            zoom: 0.45;
          }
          @include _900 {
            zoom: 0.4;
          }
          @include _768 {
            zoom: 0.7;
          }

          @include _600 {
            zoom: 0.6;
          }
          @include _400 {
            zoom: 0.35;
          }
        }
      }
      &.zoomColumns {
        > div > div {
          zoom: 0.7;
          @include _2000 {
            zoom: 0.55;
          }
        }
      }
    }
  }
  &-layer {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-header {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &.rows {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      @include _1024 {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
    @include _1300 {
      display: grid;
      grid-template-columns: 1fr 1fr 2fr;
    }
    @include _1024 {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
  }
  &-footer {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &.rows {
      display: grid;
      grid-template-columns: repeat(2, minmax(100px, 50%));
      @include _768 {
        display: flex;
        flex-direction: column;
      }
    }
    @include _1300 {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    @include _768 {
      display: flex;
      flex-direction: column;
    }
  }
}
