@import "../../styles/variables";

.period {
  display: flex;
  flex-direction: column;
  @include gapMin;
  position: relative;
  input {
    font-family: "Montserrat";
    @include FSbaseBold;
    width: 100%;
    &::placeholder {
      @include FSbaseReg;
    }
  }
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
  &-input {
    display: flex;
    align-items: center;
    background: white;
    border: 1px solid $grey;
    @include borderRadius;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 12px 10px;
    width: 100%;
    cursor: pointer;
    height: 45px;
    @include _2000 {
      padding: 8px 10px;
      height: 40px;
    }
    &-close {
      position: absolute;
      top: 10px;
      right: 10px;
    }
    // @include _1024 {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    input {
      background-color: $blue-m-bg;
    }
    &::placeholder {
      color: $blue-m-text;
      @include FSbaseReg;
    }
    // }
  }

  &-icon {
    position: absolute;
    bottom: 12px;
    right: 18px;
    cursor: pointer;

    img {
      @include SmdBtn;
    }
    @include _2000 {
      right: 14px;
    }
  }
  // .react-datepicker {
  //   font-family: "Montserrat";
  //   padding: 10px;
  //   &__portal {
  //     width: 100%;
  //     height: 100%;
  //   }
  //   &__header {
  //     background-color: #fff !important;
  //     border: none;
  //     padding-top: 20px;
  //   }
  //   &__navigation {
  //     position: relative;
  //     &--previous {
  //       position: absolute;
  //       top: 30px;
  //       left: 20px;
  //       @include _1024 {
  //         top: 20px;
  //       }
  //     }
  //     &--next {
  //       position: absolute;
  //       top: 30px;
  //       right: 20px;
  //     }
  //     &--years {
  //       &-dropdown-container {
  //         padding: 20px !important;
  //       }
  //       &-upcoming {
  //         border-color: #ccc;
  //         border-style: solid;
  //         border-width: 3px 3px 0 0;
  //         content: "";
  //         display: block;
  //         height: 9px;
  //         position: absolute;
  //         top: 5px;
  //         left: 50%;
  //         transform: translate(-50%, 0) rotate(-45deg);
  //         width: 9px;
  //         &:hover {
  //           border-color: #fff;
  //         }
  //       }
  //       &-previous {
  //         border-color: #ccc;
  //         border-style: solid;
  //         border-width: 3px 3px 0 0;
  //         content: "";
  //         display: block;
  //         height: 9px;
  //         position: absolute;
  //         top: -2px;
  //         left: 50%;
  //         transform: translate(-50%, 0) rotate(135deg);
  //         width: 9px;
  //         &:hover {
  //           border-color: #fff;
  //         }
  //       }
  //     }
  //   }
  //   &__current-month {
  //     text-transform: capitalize;
  //     display: none;
  //   }
  //   &__month {
  //     &-dropdown {
  //       width: 35%;
  //       left: 20%;
  //       top: 8px;
  //       background-color: #fff;
  //       border: none;
  //       padding: 8px 0 8px 0;
  //       box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08),
  //         0px 8px 32px rgba(19, 10, 46, 0.07),
  //         0px 3px 14px rgba(19, 10, 46, 0.03),
  //         0px 1px 3px rgba(19, 10, 46, 0.13);
  //       border-radius: 8px;
  //     }
  //     &-read-view {
  //       padding: 8px 32px 8px 16px;
  //       &--selected-month {
  //         text-transform: capitalize;
  //         font-family: "Montserrat";
  //         font-style: normal;
  //         line-height: 24px;
  //         @include FSbaseBold;
  //         color: $blue;
  //         @include borderRadius;
  //       }
  //       &--down-arrow {
  //         top: 13px;
  //         right: 10px;
  //       }
  //       &:hover {
  //         background-color: #f8f7fa;
  //         transition: 0.3s;
  //         .react-datepicker__month-read-view--selected-month {
  //           color: $blue;
  //           transition: 0.3s;
  //         }
  //         .react-datepicker__month-read-view--down-arrow {
  //           border-color: $blue !important;
  //           transition: 0.3s;
  //         }
  //       }
  //     }
  //     &-option {
  //       text-transform: capitalize;
  //       text-align: left;
  //       font-family: "Montserrat";
  //       font-style: normal;
  //       @include FSbaseReg;
  //       line-height: 24px;
  //       padding: 4px 16px;
  //       &--selected_month {
  //         color: $blue;
  //         background-color: $background-color-link;
  //       }
  //       &--selected {
  //         display: none;
  //       }
  //       &:hover {
  //         background-color: $blue;
  //         color: #fff;
  //         transition: 0.3s;
  //       }
  //     }
  //     &-read-view--down-arrow {
  //       top: 13px;
  //       right: 10px;
  //     }
  //   }
  //   &__year {
  //     &-dropdown {
  //       top: 8px;
  //       left: 54%;
  //       width: 25%;
  //       border: none;
  //       background-color: #fff;
  //       box-shadow: 0px 30px 84px rgba(19, 10, 46, 0.08),
  //         0px 8px 32px rgba(19, 10, 46, 0.07),
  //         0px 3px 14px rgba(19, 10, 46, 0.03),
  //         0px 1px 3px rgba(19, 10, 46, 0.13);
  //       border-radius: 8px;
  //     }
  //     &-read-view {
  //       padding: 8px 32px 8px 16px;
  //       font-family: "Montserrat";
  //       font-style: normal;
  //       line-height: 24px;
  //       @include FSbaseSemibold;
  //       &--down-arrow {
  //         top: 12px;
  //         right: 10px;
  //       }
  //       &:hover {
  //         background-color: #f8f7fa;
  //         transition: 0.3s;
  //         .react-datepicker__year-read-view--selected-year {
  //           color: $blue;
  //           transition: 0.3s;
  //         }
  //         .react-datepicker__year-read-view--down-arrow {
  //           border-color: $blue !important;
  //           transition: 0.3s;
  //         }
  //       }
  //     }
  //     &-option {
  //       position: relative;
  //       text-align: center;
  //       font-family: "Montserrat";
  //       font-style: normal;
  //       @include FSbaseReg;
  //       line-height: 24px;
  //       min-height: 15px;
  //       padding: 4px 0;
  //       &--selected {
  //         display: none;
  //       }
  //       &--selected_year {
  //         color: $blue;
  //         background-color: $background-color-link;
  //       }
  //       &:hover {
  //         background-color: $blue;
  //         color: #fff;
  //       }
  //     }
  //     &-select {
  //       border: none;
  //     }
  //   }
  //   &__day {
  //     width: 35px !important;
  //     line-height: 35px !important;
  //     font-size: 16px;
  //     @include FSbaseReg;
  //     border-radius: 50%;
  //     transition: background-color 0.2s, color 0.2s;
  //     @include _400 {
  //       width: 35px !important;
  //       line-height: 35px !important;
  //     }
  //     &--outside-month {
  //       color: $grey;
  //     }
  //     &--selected {
  //       border-radius: 50%;
  //       background-color: $blue;
  //     }
  //     &:hover {
  //       background-color: $blue-opacity;
  //       // transition: background-color 0.2s;
  //       color: #fff;
  //       border-radius: 50%;
  //     }
  //     &-name {
  //       font-family: "Montserrat";
  //       // width: 2rem !important;
  //       // line-height: 2rem !important;
  //       text-transform: capitalize;
  //       font-style: normal;
  //       line-height: 24px;
  //       @include FSbaseSemibold;
  //       width: 35px !important;
  //       line-height: 35px !important;
  //       @include _400 {
  //         width: 35px !important;
  //         line-height: 35px !important;
  //       }
  //     }
  //     &--keyboard-selected {
  //       background-color: #f8f7fa;
  //       color: $blue;
  //       border-radius: 50%;
  //     }
  //   }
  //   &-time {
  //     &__input {
  //       position: relative;
  //     }
  //     &__caption {
  //       font-style: normal;
  //       line-height: 24px;
  //       @include FSbaseSemibold;
  //     }
  //     &__input {
  //       width: 83px;
  //       &-container {
  //         input[type="time"] {
  //           font-style: normal;
  //           line-height: 24px;
  //           @include FSbaseSemibold;
  //           width: 130px !important;
  //         }
  //         input[type="time"]::-webkit-datetime-edit-text {
  //           padding: 19px 4px;
  //         }
  //         input[type="time"]::-webkit-datetime-edit-hour-field {
  //           background-color: $background-color-link;
  //           border-radius: 15%;
  //           color: $blue;
  //           padding: 19px 13px;
  //         }
  //         input[type="time"]::-webkit-datetime-edit-minute-field {
  //           background-color: $background-color-link;
  //           border-radius: 15%;
  //           color: $blue;
  //           padding: 19px 13px;
  //         }
  //         input[type="time"]::-webkit-calendar-picker-indicator {
  //           cursor: pointer;
  //         }
  //       }
  //     }
  //   }

  //   &__portal {
  //     background-color: rgba(0, 0, 0, 0.2) !important;
  //     @include _1024 {
  //       position: fixed;
  //       background-color: rgba(0, 0, 0, 0.1) !important;
  //     }
  //   }
  // }
}
.period.filterPeriod {
  display: flex;
  flex-direction: column;
  @include gapMin;
  .period-input.filterPeriod {
    background-color: hsl(0, 0%, 100%);
    border: 1px solid hsl(0, 0%, 80%);
    @include borderRadius;
    padding: 10px;
    cursor: pointer;
    height: 40px;
    position: relative;
    // @include _1024 {
    border: 1px solid $blue-m-bg;
    background-color: $blue-m-bg;
    input {
      background-color: $blue-m-bg;
    }
    &::placeholder {
      color: $blue-m-text;
      @include FSbaseReg;
    }
    // }
  }
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }

  input {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: #19181a;
    &::placeholder {
      @include FSbaseReg;
    }
  }
  .period-icon.filterPeriod {
    position: absolute;
    bottom: 7px;
    right: 10px;
    cursor: pointer;
    img {
      @include SmdBtn;
    }
    @include _2000 {
      bottom: 9px;
    }
    @include _1024 {
      right: 13px;
    }
  }
  span {
    // @include _1024 {
    color: $blue;
    // }
  }
}

.period.regular {
  span,
  input {
    font-family: "Montserrat";
    font-size: 16px;
    font-weight: normal !important;
    color: black;
  }
}
