@import "../../../../../styles/variables";

.modalDelegateTasksEmployee {
  width: 100%;
  &-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    > div {
      &:nth-child(1) {
        background-color: #f6f6f6 !important;
        margin-left: -20px;
        margin-right: 20px;
        flex: 1;
      }
    }
  }
  &-searchPanel {
    width: 100%;
    flex: 6;
  }
  &-content {
    width: 100%;
    &-header {
      padding: 20px 0 10px 10px;
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 5px;
      align-items: center;
      grid-template-columns: minmax(auto, 80px) 1fr 1fr 1fr 1fr 1fr;
      &__item {
        display: flex;
        text-align: center;
        white-space: nowrap;
        overflow: hidden;
        justify-content: center;
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &__item.numder-item {
        justify-content: flex-start;
        display: flex;
        align-items: center;
        @include gapBig;
      }
    }
    &-footer {
      display: flex;
      width: 100%;
      align-items: center;
      > div {
        &:last-child {
          margin-left: 20%;
        }
      }
    }
  }
}
