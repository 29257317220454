@import "/src/styles/variables";

.photoReports {
  @include flexGap(20px, col);
  padding: 0 20px 0 10px;
  @include gapBig;
  span {
    font-family: "Montserrat";
  }
  .title {
    color: $blue;
    font-weight: 700;
    @include FSbaseBold;
  }
  &-main {
    display: flex;
    width: 100%;
    @include gapBig;
    &-panel {
      display: flex;
      flex-direction: column;
      gap: 10px;
      padding: 10px;
      background: #ffffff;
      border: 1px solid #c0c0c0;
      @include borderRadius;
      width: 100%;
      max-width: 264px;
      height: -moz-fit-content;
      height: fit-content;
    }
    &-image-section {
      display: flex;
      width: 100%;
      flex-direction: column;
      @include gapBig;
    }
  }
  &-item {
    @include flexGap(10px, col);
    &-screens {
      width: 100%;
      height: 100%;
      display: grid;
      @include gapBig;
      // flex-wrap: wrap;
      grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      &.big-grid {
        grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
      }
      &.grid {
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
      }
      &.small-grid {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
      }
      &-item {
        position: relative;
        @include borderRadius;
        &-wrapper {
          width: 100%;
          height: calc(100% - 15px);
          position: relative;
        }
        &.big-grid {
          height: 115px;
        }
        &.grid {
          height: 165px;
        }
        &.small-grid {
          height: 215px;
        }
        &-remove {
          position: absolute;
          bottom: 0;
          right: 0;
          background: white;
          border-radius: 10px 0 10px 0;
          border: 1px solid $grey;
          padding: 6px 10px;
          cursor: pointer;
        }
        &-img {
          background-size: cover;
          background-position: center;
          position: relative;
          width: 100%;
          height: 100%;
          @include borderRadius;
          cursor: pointer;
        }
        &-text {
          color: $blue;
          @include FSstrSub;
        }
      }
    }
  }
}
.menuFolderItem {
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  width: 100%;
  height: fit-content;
  display: flex;
  span {
    font-family: "Montserrat";
    @include FSbaseBold;
  }
  @include flexAllCenter();
  width: 100%;
  padding: 17px 0px;
  @include borderRadius;
  border: 1px solid $grey;
  @include borderRadius;
  cursor: pointer;
  position: relative;
  span {
    color: $blue;
  }
  svg {
    position: absolute;
    top: 50%;
    right: 10px;
    cursor: pointer;
    transform: translate(0, -50%);
  }
  &-title {
    > span {
      @include FSbaseBold;
    }
  }
  @include HoverBtnFolder;
}
.menuFolderItem.active {
  background-color: $blue;
  background: linear-gradient($blue, $blue);
  border: 1px solid $blue;
  > span {
    color: white;
  }
  svg {
    fill: #ffffff;
  }
}
