@import "src/styles/variables";

.analyticsAddChart {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-content {
    width: 100%;
    display: grid;
    grid-template-columns: 250px 1fr;
    @include gapBig;
    padding: 0 10px;
    @include _2000 {
      display: flex;
      flex-direction: column;
    }
    @include _1024 {
      padding: 20px 0 60px 0;
    }
    &-panel {
      background: white;
      border: 1px solid $grey;
      @include borderRadius;
      padding: 20px 10px;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 264px;
      @include gapBig;
      height: fit-content;
      @include _2000 {
        // flex-direction: row;
        max-width: 100%;
      }
      @include _1024 {
        padding: 10px;
      }
      &-title {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: $blue;
        text-align: center;
      }
      &-list {
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _2000 {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          flex-direction: row;
          width: 100%;
        }
        @include _1024 {
          grid-template-columns: repeat(auto-fit, 40px);
        }
        &-item {
          display: flex;
          @include gapMin;
          background: white;
          align-items: center;
          padding: 13px 10px;
          border: 1px solid $grey;
          @include borderRadius;
          cursor: pointer;
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
          }
          @include HoverBtnAnal;
          @include _2000 {
            padding: 5px 10px;
          }
        }
        &-item.active {
          background-color: $blue;
          background: linear-gradient($blue, $blue);
          border: 1px solid $blue;
          > span {
            color: white;
          }
          svg {
            fill: white;
          }
        }
      }
    }
    &-form {
      width: 100%;
    }
  }
}
