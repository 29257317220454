@import "../../styles/variables";

.notificationFrequency {
  display: flex;
  flex-direction: column;
  @include gapMin;

  &-title {
    color: $blue;
    @include FSbaseBold;
  }
  &-wrapper {
    display: flex;
    align-items: center;
    @include gapMin;
    @include _400 {
      flex-direction: column;
      align-items: flex-start;
    }
  }
  &-checbox {
    display: flex;
    align-items: center;
    @include gapMin;
    &-text {
      @include FSbaseBold;
      white-space: nowrap;
    }
  }
}
