@import "src/styles/_variables.scss";

.modalAddChatMobile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  position: relative;
  height: 100%;

  &-header {
    display: flex;
    justify-content: space-between;
    @include gapBig;
    @include FSbaseBoldM;
    &-check {
      display: flex;
      @include gapMin;
    }
  }
  &-grid {
    @include cardColumnGridMobile;
  }
  &-footer-pagination {
    display: flex;
    justify-content: center;
  }
  &-footer {
    display: flex;
    justify-content: center;
  }
}
