@import "/src/styles/variables";

.profileNotificationCart {
  font-family: "Montserrat";
  font-style: normal;
  background: #ffffff;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 9px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include _1700 {
    padding: 4px 10px;
  }
  &__title {
    @include FSbaseBold;
    line-height: 16px;
    color: $blue;
  }
  &__text {
    @include FSxsReg;
    // line-height: 12px;
    color: $grey;
  }
  &__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
    @include _1700 {
      gap: 2px;
    }
  }
}
