@import "src/styles/variables";

.checkpoint {
  &-content {
    position: absolute;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: $red;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
      background-color: #fe384f;
      transform: scale(1.5);
    }
  }
  &-info {
    position: absolute;
    transform: translate(-246px, 71px);
    @include _1024 {
      transform: translate(-246px, 40px);
    }
    &-name {
      font-family: "Montserrat";
      // font-weight: 700;
      // font-size: 18px;
      @include FSsubtitle;
      color: black;
    }
    &-address {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
      max-width: 170px;
    }
    &-line1 {
      position: absolute;
      width: 150px;
      height: 1px;
      background-color: black;
      transform: translate(-112px, 74px) rotate(122deg);
      @include _1024 {
        width: 118px;
        transform: translate(-98px, 55px) rotate(130deg);
      }
    }
    &-line2 {
      position: absolute;
      width: 170px;
      height: 1px;
      background-color: black;
      transform: translate(-246px, 137px);
      @include _1024 {
        transform: translate(-246px, 100px);
      }
    }
  }
}

.checkpoint-content.active {
  background-color: #fe384f;
  transform: scale(1.5);
  @include _600 {
    transform: scale(2);
  }
}
