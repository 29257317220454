@import "/src/styles/variables";

.editInfoTask {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-subtask {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-row {
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include gapBig;

    @include _768 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      justify-content: space-between;
      &-grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        @include gapBig;
        @include _768 {
          grid-template-columns: 1fr;
        }
      }
      &-startDate {
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _768 {
          flex-direction: row;
        }
      }
    }
  }
  &-person {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-logo {
      border-radius: 50%;
      background-size: cover;
      background-position: center;
      @include XSavatar;
    }
    &-info {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-name {
        @include FSbaseBold;
      }
      &-position {
        @include FSbaseReg;
      }
    }
  }
  .react-datepicker__input-container input {
    color: black;
    font-weight: 500;
  }
  .select {
    width: 100%;
  }
  &-tasks {
    @include flexGap(10px, col);
    max-height: 200px;
    padding: 5px;
    &-item {
      display: flex;
      justify-content: space-between;
      padding: 14.5px 10px;
      @include borderRadius;
      border: 1px solid $grey;
      &-btns {
        @include flexGap(15px, row);
        align-items: center;
        img {
          cursor: pointer;
        }
      }
    }
  }
  &-btnCreate {
    display: flex;
    justify-content: flex-end;
    padding-right: 120px;
  }
  &-notificationFrequency {
    display: flex;
    flex-direction: column;
    @include gapMin;

    &-title {
      color: $blue;
      @include FSbaseBold;
    }
    &-wrapper {
      display: flex;
      align-items: center;
      @include gapMin;
      @include _400 {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-checbox {
      display: flex;
      align-items: center;
      @include gapMin;
      &-text {
        @include FSbaseBold;
        white-space: nowrap;
      }
    }
  }
  &-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @include gapBig;
    @include _1024 {
      @include gapMin;
    }
    @include _768 {
      grid-template-columns: 1fr;
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-title {
        color: $blue;
        @include FSbaseBold;
      }
      &-wrapper {
        display: flex;
        align-items: center;
        @include gapMin;
      }
      &-checbox {
        display: flex;
        align-items: center;
        @include gapMin;
        &-text {
          @include FSbaseBold;
          white-space: nowrap;
        }
      }
    }
  }
  &-regularity {
    display: flex;
    align-items: center;
    @include gapMin;
    &-title {
      @include FSbaseBold;
    }
  }
}
