@import "../../styles/variables";

.listModal {
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    @include gapMin;
  }
  &__title {
    margin: 0;
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
    @include _1024 {
      @include FSbaseBoldM;
      color: $blue;
    }
  }
  &__select {
    width: 32%;
    // margin-bottom: 20px;
  }
  &__list {
    list-style-type: none;
    display: grid;
    // grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    @include borderRadius;
    // border: 1px solid $grey;
    background: white;
    background-color: $blue-m-bg;
    gap: 5px;
    min-height: 45px;
    padding: 10px;
    max-height: 199px;
    filter: none;
    align-items: flex-start;
    // }
  }
}
.listModalItem {
  display: flex;
  width: 100%;
  background-color: #fff;
  height: 30px;
  // border: 1px solid $grey;
  @include borderRadius;
  padding: 10px;
  justify-content: space-between;
  align-items: center;
  margin-right: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  cursor: pointer;
  @include HoverSelectRow;

  > span {
    margin: 0;
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseReg;
    line-height: 16px;
    color: black;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &:hover {
    color: $blue;
  }

  &__btns {
    display: flex;
    position: relative;
  }

  &__btn {
    background-color: #fff;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@keyframes spinner-border {
  100% {
    transform: rotate(360deg);
  }
}

.submit-spinner-lm {
  display: inline-block;
  position: absolute;
  vertical-align: -0.125em;
  border: 2px solid $main-color;
  border-right-color: transparent;
  border-radius: 50%;
  top: -10px;
  right: -10px;
  -webkit-animation: 0.75s linear infinite spinner-border;
  animation: 0.75s linear infinite spinner-border;
  // width: 24px;
  // height: 24px;
  // @include _2000 {
  width: 20px;
  height: 20px;
  // }
  // @include _1700 {
  //   width: 18px;
  //   height: 18px;
  // }
}

.listModalItem.disabled {
  //   border: 1px solid $main-color;
}
