@import "/src/styles/variables";
.modalSwiperPhoto {
  display: grid;
  grid-template-columns: 1fr 200px;
  @include gapBig;
  &-activePhoto {
    width: 100%;
  }
  @include _1024 {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 150px;
    height: 100%;
  }
  @include _768 {
    grid-template-rows: 1fr 120px;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    text-align: center;
    font-size: 18px;
    background: #fff;
    /* Center slide text vertically */
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    -webkit-align-items: center;
    align-items: center;
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
  }
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  body {
    background: #000;
    color: #000;
  }

  .swiper {
    width: 100%;
    height: 300px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper-slide {
    background-size: cover;
    background-position: center;
  }
  .modalSwiperPhoto-bigImg {
    height: 600px;
    width: 100%;
    position: relative;
  }
  .modalSwiperPhoto-bigImg-swiper {
    height: 600px;
    width: 100%;
    position: relative;
    @include _1024 {
      height: 100%;
    }
    .modalSwiperPhoto-img {
      width: 100%;
    }

    &-next,
    &-prev {
      position: absolute;
      top: 50%;
      z-index: 99;
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      @include borderRadius;
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
    }
    &-next {
      right: 10px;
      transform: rotate(180deg);
    }
    &-prev {
      left: 10px;
    }
  }

  .modalSwiperPhoto-panel-swiper {
    height: 600px;
    box-sizing: border-box;
    padding: 10px 0;
    @include _1024 {
      display: flex;
      height: 100%;
    }
  }

  .modalSwiperPhoto-panel-swiper .swiper-slide {
    // width: 25%;
    height: 100%;
    opacity: 0.4;
  }

  .modalSwiperPhoto-panel-swiper .swiper-slide-thumb-active {
    opacity: 1;
  }

  .swiper-slide .modalSwiperPhoto-panel-img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
}
