@import "src/styles/variables";
.planListCard {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  border: 1px solid $grey;
  @include borderRadius;
  position: relative;
  height: 50px;
  cursor: pointer;
  &-active {
    background-color: $blue;
    border: 1px solid $blue;
    span {
      color: #fff;
    }
  }
  @include _1700 {
    padding: 5px;
    height: 45px;
  }
  @include _1300 {
    width: 310px;
  }
  @include _1024 {
    width: 260px;
  }

  &-name {
    display: flex;
    flex-direction: column;
    width: 180px;
    @include gapMin;
    &-title {
      @include FSbaseBold;
      color: $blue;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-doc {
      @include FSxsReg;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    @include _1300 {
      width: 210px;
    }
  }
  @include HoverBtnLayer;

  &-btns {
    display: flex;
    gap: 5px;
  }
  .hrCol {
    background-color: $blue;
    @include borderRadius;
    height: 30px;
    width: 1px;
  }
  &:hover {
    .hrCol {
      background-color: #ffffff;
      height: 30px;
    }
  }
}

.planListCard.planListCard-active {
  background-color: $blue;
  background: linear-gradient($blue, $blue);
  border: 1px solid $blue;
  div {
    > span {
      color: white;
    }
  }
  .hrCol {
    background-color: #ffffff;
    @include borderRadius;
    height: 30px;
    width: 1px;
  }
  svg {
    fill: #ffffff;
  }
}
