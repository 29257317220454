@import "./src/styles/_variables.scss";
.navbar {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100vh;
  &-wrapper {
    width: 180px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    @include _1700 {
      width: 130px;
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 20px;
    }
  }
  &-logo {
    display: flex;
    justify-content: center;

    width: 54.03px;
    height: 31.78px;
    margin-bottom: 10px;
  }
  &-customeLogo {
    &-plug {
      // padding: 21px 39px;
      max-width: fit-content;
      max-height: fit-content;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      padding: 2px;
      span {
        font-family: "Montserrat";
        text-transform: uppercase;
        font-weight: 700;
        font-size: 18px;
      }
      cursor: pointer;
      width: 50px;
      height: 50px;
      @include _1200 {
        width: 40px;
        height: 40px;
      }
      img {
        background: #ffffff;
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-links {
    padding-left: 5px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    overflow-y: auto;
    overflow-x: clip;
    max-height: 670px;
  }
  &-footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    margin-bottom: 20px;
    &-date {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 8px;
      color: white;
    }
    @include _1300 {
      margin-bottom: 100px;
    }
    &-academyLogo-logo {
      display: flex;
      align-items: center;
      &-big {
        width: 80px;
        height: 20px;
      }
    }

    &-police {
      font-family: "Montserrat";
      @include FSxsBold;
      color: white;
      opacity: 0.8;
      white-space: nowrap;
    }
    &-logo {
      width: 40.03px;
      height: 31.78px;
      margin-bottom: 100px;
    }
  }
  @include _1200 {
    width: 60px;
    background-color: $background-color;
    transition: left 0.3s ease-in-out;
    overflow: hidden;
    &-wrapper {
      position: absolute;
      // overflow: hidden;
      // padding-top: 20px;
      background-color: $background-color;
      width: 60px;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 50;
      transition: width 0.3s ease-in-out;
    }
    &-customeLogo {
      margin-top: 20px;
      &-plug {
        // padding: 10px 16px;
        max-width: fit-content;
        margin: 0 auto;
        span {
          font-family: "Montserrat";
          text-transform: uppercase;
          @include FSxsBold;
        }
        cursor: pointer;
      }
    }
    &-logo {
      width: 40.03px;
      height: 31.78px;
      margin-bottom: 10px;
    }
    &-footer {
      gap: 5px;
      &-police {
        font-weight: 700;
        font-size: 4px;
        line-height: 5px;
      }
      &-academyLogo-logo {
        &-small {
          width: 20px;
          height: 20px;
        }
      }
    }
    &-links {
      padding-left: 10px;
      a {
        span {
          opacity: 0;
          transition: opacity 0.3s ease-in-out;
        }
      }
    }
  }
  @include _1100 {
    width: 50px;
    &-customeLogo {
      margin-top: 20px;
      &-plug {
        // padding: 4px 4px;
        max-width: fit-content;
        margin: 0 auto;
        span {
          font-family: "Montserrat";
          text-transform: uppercase;
          font-size: 10px;
        }
        cursor: pointer;
      }
    }
    &-wrapper {
      position: absolute;
      // overflow: hidden;
      // padding-top: 20px;
      background-color: $background-color;
      width: 50px;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 50;
      transition: width 0.3s ease-in-out;
    }
    &-logo {
      width: 40.03px;
      height: 31.78px;
      margin-bottom: 10px;
    }
    &-footer {
      &-police {
        font-weight: 700;
        font-size: 4px;
        line-height: 5px;
        word-wrap: break-word;
        opacity: 0;
      }
    }
    &-links {
      padding-left: 5px;
    }
  }
}
@include _1200 {
  .navbar-wrapper.navbar-active-wrapper {
    width: 130px;
    overflow: hidden;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    .navbar-footer-police {
      opacity: 1;
    }
    .navbar-links {
      a {
        span {
          opacity: 1;
        }
      }
    }
    @include _1300 {
      width: 150px;
      .navbar-footer-roboweb-text {
        display: block;
      }
    }
  }
}
