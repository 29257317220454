@import "src/styles/variables";

.checkWorkEmail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Montserrat";
  border-top: 2px solid $main-color;
  padding-top: 20px;
  &-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @include _600 {
      display: flex;
      flex-direction: column;
    }
  }
  &-person {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-avatar {
      @include XSavatar;
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-info {
      display: flex;
      gap: 10px;
      &-wrapper {
        display: flex;
        gap: 10px;
        flex-direction: column;
      }
      &-name {
        @include FSbaseBold;
      }
      &-position {
        @include FSbaseReg;
      }
    }
  }
  &-project {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-name {
      @include FSbaseBold;
    }
  }
  &-center {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    @include _600 {
      display: flex;
      flex-direction: column;
    }
  }
  &-DocItem {
    display: flex;
    flex-direction: column;
    gap: 10px;
    > div {
      max-width: calc(50% - 20px) !important;
    }
  }
  &-btns {
    display: flex;
    gap: 20px;
  }
}
