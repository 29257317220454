@import "./src/styles/variables";

.modalEditDepartment {
  &-form {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @include gapBig;

    &__wrapper {
      display: grid;
      grid-template-columns: 6fr 1fr;
      @include gapBig;
      @include _768 {
        display: flex;
        flex-direction: column-reverse;
      }
    }
  }
  a {
    text-decoration: none;
  }

  &-content {
    padding-bottom: 20px;
    @include _1024 {
      padding-bottom: 10;
    }
    &:not(:last-child) {
      // border-bottom: 2px solid #7bc7f1;
    }
    &:last-child {
      padding-top: 20px;
      @include _1700 {
        padding-top: 10px;
      }
      @include _1024 {
        padding-bottom: 10;
      }
    }
    &__title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
      @include gapMin;
      width: 100%;
      justify-content: space-between;

      @include _1024 {
        grid-template-columns: repeat(3, 1fr);
        @include gapBig;
      }
      @include _600 {
        display: flex;
        flex-direction: column;
      }
      &__item {
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _600 {
          flex-direction: row;
          @include gapMin;
        }
      }
      &-item {
        // &-position {
        //   grid-column-start: 2;
        //   grid-column-end: 4;
        // }
      }
      &__title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &__text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
        text-decoration: none;
      }
    }
    &__select {
      width: 32.5%;
      @include _1024 {
        width: 100%;
      }
    }
  }
  &-info {
    flex: 3;
  }
  &-avatar {
    flex: 1;
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _768 {
      align-items: center;
      width: 100%;
    }
  }
  &-avatar {
    &__wrapper {
      width: 326px;
      height: 326px;
      @include _2000 {
        width: 250px;
        height: 250px;
      }
      @include _1700 {
        width: 150px;
        height: 150px;
      }
    }
    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &__btn-wrapper {
    display: flex;
    justify-content: center;
  }
}
