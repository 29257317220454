@import "../../styles/variables";

.subtaskList {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  &-item {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-title {
      @include FSsubtitle;
    }
  }
  &-header {
    font-family: "Montserrat";
    list-style-type: none;
    width: 100%;
    margin: 0;
    display: grid;
    @include gapMin;
    @include headerRow;
    padding: 0 10px 0 10px;
    position: relative;
    grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 120px;
    @include _1900 {
      grid-template-columns: 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 95px;
    }
    @include _1455 {
      grid-template-columns: 0.5fr 3fr 1fr 1fr 1.2fr 1.2fr 1fr 95px;
    }
    &.edit {
      grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 180px 1fr 120px;
      @include _1900 {
        grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1fr 1.2fr 1.2fr 150px 1fr 95px;
      }
      @include _1455 {
        grid-template-columns: 20px 0.5fr 3fr 1fr 1fr 1.2fr 1.2fr 1fr 95px;
      }
    }
    &-item {
      display: flex;
      align-items: center;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      justify-content: center;
      > span {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        vertical-align: middle;
        height: auto;
        display: inline-block;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    &-item.numder-item {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      @include gapBig;
    }
  }
  &-list {
    display: flex;
    flex-direction: column;
    @include gapMin;
    list-style-type: none;
    width: 100%;
    &-task {
      @include cardRowWrapper;
      display: flex;
      justify-content: space-between;
      &-text {
        @include FSbaseBold;
      }
    }
  }
  &-gridMobile {
    @include cardColumnGridMobile;
  }
}
