@import "./src/styles/variables";

.documentationItemGrid {
  //   @include cardColumnWrapper;
  display: grid;
  grid-template-columns: 1fr 20px;
  // @include gapMin;

  background-color: #fff;
  @include borderRadius;
  padding: 10px 5px 10px 10px;
  height: fit-content;
  position: relative;
  border: 1px solid $grey;
  justify-self: center;
  // width: 170px;
  // height: 170px;
  width: 100%;

  &-header {
    // position: absolute;
    // top: 0;
    width: 100%;
  }
  &-wrapper {
    display: grid;
    grid-template-columns: 60px 1fr;
    @include gapMin;
  }
  &-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include _1024 {
      height: 60px;
      justify-content: center;
    }
    svg {
      width: 100%;
      // max-height: 150px;
      height: 60px;
      @include _1024 {
        height: 60px;
      }
    }
  }
  &-text {
    display: flex;
    flex-direction: column;
    @include gapMin;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
  }
  &-foto {
    width: 100%;
    height: 60px;
    @include borderRadius;
    object-fit: cover;
    cursor: pointer;
    @include _1024 {
      height: 60px;
    }
  }
  &-info {
    border-top: 2px solid #f6f6f6;
    list-style-type: none;
    margin: 0 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-item {
      display: flex;
      justify-content: space-between;
    }
    &-title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseReg;
      line-height: 16px;
      margin-right: 4px;
      max-width: 90%;
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
    }
    &-text {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
    }
  }
  &-title {
    @include FSbaseBold;
    @include nowrap;
  }
  &-btn-menu {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    z-index: 6;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      cursor: pointer;
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
