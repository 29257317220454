@import "../../styles/variables";

.modalProject {
  span {
    font-family: "Montserrat";
  }
  display: grid;
  grid-template-columns: 3fr 1fr;
  @include _768 {
    display: flex;
    flex-direction: column-reverse;
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
    max-width: 995px;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
    }
    &-item.desc {
      span:not(.title) {
        font-weight: 400;
      }
    }

    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
      }
      span.title {
        color: $blue;
      }
    }

    &-specifications {
      display: grid;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr;
      flex-wrap: wrap;
      @include gapBig;
      @include _600 {
        grid-template-columns: 1fr;
      }
      &-block {
        &-item {
          display: flex;
          flex-direction: column;
          @include gapMin;
          @include _600 {
            flex-direction: row;
          }
          span.title {
            color: $blue;
          }
        }
      }
    }
  }
  &-photos {
    div {
      // div {
      //   width: 250px;
      //   height: 250px;
      // }
    }
  }
}
