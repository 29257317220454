@import "./src/styles/_variables.scss";

.modalChooseAddStepWorkMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  &-item {
    span {
      @include FSbaseBoldM;
    }
  }
}
