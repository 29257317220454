@import "../../../../../../styles/variables";

.searchBox {
  // position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   transform: translate(-50%, 50%);
  background: $main-color;
  width: 40px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  transition: width 0.4s ease;
  position: relative;
  &-input {
    border: none;
    background: none;
    outline: none;
    float: left;
    padding: 0;
    color: dark;
    @include FSbaseBoldM;
    transition: all 0.4s ease;
    line-height: 40px;
    width: 0px;
    @include borderRadius;
    opacity: 0;
    &::placeholder {
      color: $grey;
    }
  }
  &-buttons-close {
    position: absolute;
    right: 40px;
    cursor: pointer;
    opacity: 0;
    width: 40px;
    height: 40px;
    @include borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    background: #fff0;
    transition: all 0.4s;
    &-active {
      opacity: 1;
      // @include HoverIcon;
    }
  }
  &-buttons-white {
    position: absolute;
    // top: 10px;
    right: 0;
    color: white;
    float: right;
    width: 40px;
    height: 40px;
    @include borderRadius;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #fff0;
    opacity: 1;
    transition: all 0.5s ease;
  }
  &-buttons-dark {
    position: absolute;
    // top: 10px;
    right: 0;
    // transform: translate(-50%, 50%);
    display: none;
    color: white;

    width: 40px;
    height: 40px;

    @include borderRadius;
    background-color: inherit;
    transition: all 0.5s ease;
    opacity: 0;
  }
  &.active {
    width: 100%;
  }
  &.active > .searchBox-input {
    width: 100%;
    padding: 0 6px 0 10px;
    background: white;
    width: 100%;
    opacity: 1;
    &::placeholder {
      color: $grey-dark;
    }
  }
  &.active > .searchBox-buttons-white {
    display: none;
    background: inherit;
    // color: #2f3640;
    opacity: 0;
  }
  &.active > .searchBox-buttons-dark {
    display: block;
    background: #fff0;
    opacity: 1;
    // background: white;
    // color: #2f3640;
    display: flex;
    justify-content: center;
    align-items: center;
    // background: inherit;
  }
}

// @media screen and (max-width: 620px) {
//   .searchBox:hover > .searchInput {
//     width: 150px;
//     padding: 0 6px;
//   }
// }
