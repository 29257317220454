@import "../../../../../../styles/variables";

.structuresListHeader {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $main-color;
  @include borderRadius;
  padding: 0 20px 0 10px;
  z-index: 3;
  position: relative;
  @include gapBig;
  @include _1024 {
    height: 50px;
    padding: 0 10px;
    @include gapMin;
  }
  &-avatar {
    width: 74px;
    height: 74px;
    border: 2px solid #fff;
    @include borderRadius;

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    @include _1024 {
      height: 48px;
      width: 48px;
    }
  }
  &__left {
    display: flex;
    align-items: center;
    @include gapBig;
    max-width: calc(100% - 40px);
    @include _1024 {
      @include gapMin;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include gapMin;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    @include _1024 {
      gap: 5px;
    }
  }
  &__title {
    font-style: normal;
    @include FSbaseBold;
    line-height: 16px;
    color: #ffffff;
    @include _1024 {
      @include FSbaseBoldM;
    }
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__text {
    font-style: normal;
    @include FSstrSub;
    line-height: 14px;
    color: #ffffff;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__btn {
    background-color: inherit;
    cursor: pointer;
    img {
      @include SmdBtn;
    }
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    right: 0;
    display: flex;
    flex-direction: column;
    width: 249px;
    z-index: 6;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
