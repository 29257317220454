@import "swiper/css";
@import "../../styles/variables";

.cardColumn {
  @include cardColumnWrapperProject;
  overflow: hidden;
  &.completed {
    opacity: 0.5;
  }
  &-content {
    padding: 10px 10px 10px 10px;
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-info {
      display: flex;
      flex-direction: column;
      @include gapMin;
      cursor: pointer;
      &-header {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
        padding-top: 10px;
        border-bottom: 1px solid $grey;
        @include _1024 {
          border-bottom: 1px solid $main-color;
          padding-bottom: 10px;
        }

        &-title {
          @include FSbaseBold;
        }
      }
      &-item {
        font-family: "Montserrat";

        display: flex;
        justify-content: space-between;
        gap: 5px;
        @include FSbaseBold;
        &-title {
          white-space: nowrap;
          color: $blue;
        }
        &-text {
          @include FSbaseBold;
          text-align: end;
          @include nowrap;
        }
      }
      &-item.title {
        font-weight: 700;
        @include FSbaseBold;
      }
    }
    &-tasks {
      display: grid;
      grid-template-columns: 1fr minmax(100px, 1fr);
      @include gapMin;
      // align-items: flex-start;
      &-desc {
        display: flex;
        flex-direction: column;
        gap: 5px;
        span {
          font-family: "Montserrat";
        }
        &-title {
          @include FSbaseBold;
        }
        &-li {
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;
          &-circle {
            border-radius: 50%;
            width: 10px;
            height: 10px;
          }
          &-title {
            @include FSmdMed;
            @include _1024 {
              @include FSxsRegM;
            }
          }
          &-count {
            @include FSmdMed;
            font-weight: 700;
            @include _1024 {
              @include FSxsBoldM;
            }
            &.overdue {
              color: $overdue;
            }
          }
        }
        &-li.completed {
          div {
            background-color: $light-green;
          }
          span {
            color: $light-green;
          }
        }
        &-li.inWork {
          div {
            background-color: $atWork;
          }
          span {
            color: $atWork;
          }
        }
        &-li.onVerification {
          div {
            background-color: $onVerification;
          }
          span {
            color: $onVerification;
          }
        }
        &-li.overdue {
          div {
            background-color: $overdue;
          }
          span {
            color: $overdue;
          }
        }
        &-li.rejected {
          div {
            background-color: $rejected;
          }
          span {
            color: $rejected;
          }
        }
        &-li.assigned {
          div {
            background-color: $assigned;
          }
          span {
            color: $assigned;
          }
        }
      }
      &-chart {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
      }
    }
    &-number {
      background-color: white;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 4px 33px;
      position: absolute;
      top: 170px;
      @include _2000 {
        top: 160px;
      }
      @include _1024 {
        top: 130px;
      }
      z-index: 10;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
      }
    }
    &-remove {
      position: absolute;
      top: 10px;
      right: 0px;
      padding: 0 10px 0 10px;
      z-index: 10;
      // background: white;

      cursor: pointer;
      background: rgba(255, 255, 255, 0.5);
      width: 40px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      position: absolute;
      border-radius: 50px 0 0 50px;
      @include _1024 {
        width: 40px;
        height: 40px;
      }
    }
    &-edit {
      background: rgba(255, 255, 255, 0.5);
      width: 40px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      position: absolute;
      top: 10px;
      left: 0px;
      z-index: 10;
      // background: white;
      padding: 0 10px 0 10px;
      border-radius: 0 50px 50px 0;
      cursor: pointer;
      @include _1024 {
        width: 40px;
        height: 40px;
      }
    }
    &-menu {
      // background: rgba(255, 255, 255, 0.5);
      z-index: 10;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 10px 10px 10px 0;
      width: 100%;
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &-swiper {
    height: 190px;
    @include _2000 {
      height: 170px;
    }
    @include _1700 {
      height: 120px;
    }
    @include _1024 {
      height: 140px;
    }

    img {
      width: 100%;
    }
    &-slideImg {
      height: 190px;
      @include _2000 {
        height: 170px;
      }
      @include _1700 {
        height: 120px;
      }
      @include _1024 {
        height: 140px;
      }
      background-size: cover;
      background-position: center;
      &.no-image {
        background-size: 50% auto;
        background-repeat: no-repeat;
      }
    }
    &-next,
    &-prev {
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      @include borderRadius;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      top: 20%;
      img {
        max-width: fit-content;
        @include SmdBtn;
      }
      @include _1024 {
        width: 40px;
        height: 40px;
        top: 15%;
        background: none;
      }
    }
    &-next {
      right: 5px;
      svg {
        transform: rotate(180deg);
      }
    }
    &-prev {
      left: 5px;
    }
  }
}
