@import "../../../../../styles/variables";

.participants {
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding-bottom: 60px;
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-cards {
    display: flex;
    @include gapBig;
    flex-wrap: wrap;
    flex-direction: column;
    &-content {
      display: flex;
      flex-direction: column;
      padding: 0 10px 0 0px;
      @include gapMin;
      &-columns {
        padding: 0 10px 0 10px;
        @include cardColumnGrid;
      }
    }
    &-header {
      font-family: "Montserrat";
      list-style-type: none;
      width: 100%;
      margin: 0;
      display: grid;
      gap: 5px;
      align-items: center;
      justify-content: space-between;
      grid-template-columns: 
      // 80px
        2fr 1.5fr 1.5fr 1.5fr 1.5fr 20px;
      @include headerWrapper;
      &-item {
        &:not(:nth-child(8)) {
          display: flex;
          text-align: center;
          white-space: nowrap;
          overflow: hidden;
          justify-content: center;
        }
        > span {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: $blue;
          vertical-align: middle;
          height: auto;
          display: inline-block;
          text-align: start;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      &-item.number {
        display: flex;
        align-items: center;
        gap: 20px;
        justify-content: flex-start;
      }
    }
  }
  &-cards.rows {
    padding: 0 10px 0 10px;
    display: flex;
    width: 100%;
    @include gapMin;
    flex-direction: column;
  }
  &-pagination {
    display: flex;
    justify-content: center;
  }
}
