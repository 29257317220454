@import "../../styles/variables";

.fileUploader {
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-title {
    font-family: "Montserrat";
    line-height: 18px;
    @include FSbaseBold;
    color: $blue;
    @include _1300 {
      line-height: 12px;
    }
  }
  &-dragZone {
    min-width: auto;
    border: dashed 2px #c4c4c4;
    padding: 34px 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    max-width: none;
    height: 158px;
    justify-content: center;
    .sc-iBkjds.eqhUFQ {
      display: flex;
      justify-content: center;
    }
    svg {
      display: none;
    }
    span {
      font-family: "Montserrat";
      font-weight: 700;
      color: #c4c4c4;
      text-align: center;
      font-size: 24px;
      @include FSbaseBold;
    }
  }
  &-button {
    background: #ffffff;
    border: 1px solid $grey;
    @include borderRadius;
    padding: 0 10px;
    font-family: "Montserrat";
    font-style: normal;
    @include FSbaseReg;
    display: flex;
    align-items: center;
    width: 100%;
    height: 45px;
    justify-content: space-between;
    @include _1024 {
      margin-top: 3px;
      height: 40px;
    }
  }
}
