@import "../../styles/variables";

.additionalFilterPanel {
  display: flex;
  @include gapMin;
  &-content {
    display: flex;
    align-items: center;
    border-radius: 50px;
    background-color: white;
    min-width: 50px;
    padding: 0px 15px;
    height: 50px;
    @include gapBig;
    @include _2000 {
      min-width: 45px;
      height: 45px;
      @include gapMin;
    }
    // @include _1455 {
    //   gap: 20px;
    // }
    @include _1700 {
      min-width: 40px;
      height: 35px;
    }
  }
  &-devs {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    background-color: white;
    // padding: 0px 20px;
    width: 55px;
    height: 50px;
    @include gapBig;
    @include _2000 {
      width: 45px;
      height: 45px;
      @include gapMin;
    }
    @include _1700 {
      width: 35px;
      height: 35px;
    }
  }
  &-item {
    cursor: pointer;
    span {
      font-family: "Montserrat";
      font-weight: 700;
      @include FSnav;
      color: black;
      white-space: nowrap;
    }
    @include min_1300 {
      @include HoverModalText;
    }

    @include _1300 {
      display: flex;
      align-items: center;
      gap: 5px;
      transition: all 1s linear;
      width: fit-content;
      max-width: 20px;
      transition: all 0.7s ease-in-out;
      overflow: hidden;
      &:hover {
        max-width: 300px;
      }
    }
    &-imports {
      position: relative;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      &-btn {
        display: flex;
        align-items: center;
        > svg {
          // width: 28px;
          @include SFilterPanelIcon;
          fill: #2c2c2c;
          transition: fill 0.5s;
          &:hover {
            fill: $main-color;
          }
        }
      }
      &-select {
        position: absolute;
        background: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        padding: 10px;
        white-space: nowrap;
        display: flex;
        flex-direction: column;
        top: 35px;
        @include gapMin;
        left: -50px;
        z-index: 5;
        @include _1700 {
          left: -40px;
        }
        span {
          font-family: "Montserrat";
          @include FSmdMed;
          color: #2c2c2c;
        }
        @include HoverModalText;
        // span:hover {
        //   color: $main-color;
        // }
      }
    }
    &-row-column {
      // padding-top: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      > svg {
        @include SFilterPanelIcon;
        // width: 25px;
        stroke: black;
        transition: stroke 0.5s;
        &:hover {
          stroke: $main-color;
        }
      }
    }
  }
  &-item.active {
    > span {
      color: $blue;
      background: $blue;
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      -webkit-text-fill-color: transparent;
    }
    @include _1300 {
      max-width: 300px;
      > span {
        color: black;
        background: black;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
