@import "./src/styles/variables";

.modalEditEmployee {
  &-content {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &:not(:last-child) {
      border-bottom: 2px solid #7bc7f1;
      // padding-bottom: 20px;
    }
    &-baseInfo {
      display: flex;
      @include gapBig;
      display: grid;
      grid-template-columns: 1fr auto;
      @include _900 {
        display: flex;
        flex-direction: column-reverse;
      }
      &-wrapper {
        display: flex;
        flex-direction: column;
        @include gapBig;
      }
      &-btn-wrapper {
        display: flex;
        @include gapMin;
      }
    }
    &-wrapper {
      display: grid;
      grid-template-columns: 1fr auto;
      @include gapBig;
    }
    &-img {
      display: flex;
      flex-direction: column;
      @include gapMin;
      @include _900 {
        width: 100%;
        align-items: center;
      }
    }
    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: repeat(4, minmax(150px, 1fr));
      @include gapBig;
      width: 100%;
      justify-content: space-between;

      &-mainAccount {
        @include flexGap(10px, col);
      }
      @include _1024 {
        grid-template-columns: repeat(3, 1fr);
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-company {
        width: 32%;
      }
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
      }

      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
      &-select {
        width: 32%;
      }
    }
    &-emploees,
    &-groups {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 10px;
      @include borderRadius;
      filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.2));
      background: white;
      margin-top: 20px;
      margin-bottom: 20px;
      &-item {
        margin-right: 20px;
        > span {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 32px;
          color: #000000;
        }
      }
    }
  }
  &-btn-wrapper {
    display: flex;
    @include gapMin;
    justify-content: center;
  }
}
