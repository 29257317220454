@import "./src/styles/variables";

.sectionLoader {
  width: 100%;
  min-height: fit-content;
  height: 100%;
  position: relative;
  &.permission {
    min-height: 150px;
  }
}
