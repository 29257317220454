@import "/src/styles/variables";

.listWorks {
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-title {
    @include FSbaseBold;
    color: $blue;
  }
  .bold.green {
    color: $light-green;
  }
  .bold.red {
    color: $red;
  }
  .bold.yellow {
    color: $orange;
  }
  &-works {
    display: flex;
    flex-direction: column;
  }
  &-header {
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 2fr 1fr 100px;
    gap: 5px;
    span {
      vertical-align: middle;
      height: auto;
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-name {
      text-align: left !important;
    }
  }
  &-list {
    list-style-type: none;
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include borderRadius;
    // margin-bottom: 20px;
    column-gap: 20px;
    row-gap: 10px;
    min-height: 45px;
    // margin-top: 10px;
    padding: 10px;
    max-height: 199px;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    background-color: $blue-m-bg;
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-item {
    @include cardRowWrapper;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    > span {
      vertical-align: middle;
      height: auto;
      display: inline-block;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-name {
      text-align: left !important;
    }
    &-btns {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      @include gapMin;
    }
  }
}
