@import "./src/styles/variables";
.modalMobileMenuItem {
  display: flex;
  gap: 10px;
  align-items: center;
  span {
    @include FSbaseBoldM;
    text-decoration: none;
    color: black;
  }
}
