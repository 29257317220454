@import "../../styles/variables";

.checkboxSwitch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  &__input {
    display: none;
  }
  &__slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
    &:before {
      position: absolute;
      content: "";
      height: 18px;
      width: 18px;
      left: 1px;
      bottom: 1px;
      background-color: white;
      -webkit-transition: 0.4s;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
  input:checked + .checkboxSwitch__slider {
    background-color: $main-color;
  }
  input:focus + .checkboxSwitch__slider {
    box-shadow: 0 0 1p $main-color;
  }
  input:checked + .checkboxSwitch__slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
}
