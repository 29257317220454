@import "./src/styles/variables";

.profileEditInfo {
  font-family: "Montserrat";
  font-style: normal;
  padding: 10px 10px 0px 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gapBig;
  @include _1024 {
    padding: 10px 0 0 0;
  }
  &-title {
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
  }
  &-form {
    display: flex;
    justify-content: space-between;
    @include gapBig;
    border-bottom: 2px solid $main-color-light;
    padding-bottom: 20px;
    width: 100%;
    @include _1455 {
      flex-direction: column-reverse;
    }
    &-user {
      display: flex;
      flex-direction: column;
      @include gapBig;
      width: 100%;
      &-wrapper {
        display: grid;
        grid-template-columns: 8fr 1fr;
        @include gapBig;
        @include _600 {
          display: flex;
          flex-direction: column-reverse;
        }
      }
      &-list {
        display: grid;
        @include gapBig;
        grid-template-columns: repeat(3, 1fr);
        list-style-type: none;
        // margin-bottom: 20px;
        height: fit-content;
        @include _900 {
          grid-template-columns: repeat(3, 1fr);
        }
        @include _768 {
          display: flex;
          flex-direction: column;
        }
      }
      &-item.item-text {
        display: flex;
        flex-direction: column;
        @include gapBig;
      }
      &-item {
        &-title {
          @include FSbaseBold;
          color: $blue;
        }
        &-text {
          @include FSbaseBold;
        }
      }
    }
    &-avatar {
      // flex: 1;
      display: flex;
      flex-direction: column;
      @include gapMin;
      align-items: center;
      &-title {
        @include FSbaseBold;
        line-height: 16px;
        color: $blue;
      }
    }
  }
}
