@import "src/styles/variables";
.navigationListMobile {
  &-btns {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    padding-top: 10px;
    &-btn {
      background-color: #ffffff;
      padding: 8px 10px;
      width: 100%;
      max-height: 34px;
      min-width: 180px;
      @include borderRadius;
      border: 1px solid $grey;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      //   @include HoverBtnSearch;
      &-title {
        font-family: "Montserrat";
        font-weight: 700;
        @include FSbaseBoldM;
        color: black;
        white-space: nowrap;
      }
      &-active {
        background-color: $main-color;
        background: linear-gradient($main-color, $main-color);
        border: 1px solid $main-color;
        > span {
          color: white;
        }
      }
      @include _2000 {
        min-width: 150px;
      }
    }
  }
  &-select {
    min-width: 240px;
    position: relative;
    padding-top: 10px;
    &-btn {
      position: relative;
      background-color: $main-color;
      padding: 8px 10px;
      width: 100%;
      max-height: 34px;
      @include borderRadius;
      border: 1px solid $main-color;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      cursor: pointer;
      &-title {
        @include FSbaseBoldM;
        color: #fff;
        font-family: "Montserrat";
      }
      &-arrow {
        position: absolute;
        // top: 50%;
        right: 10px;
        transform: rotate(0deg), translate(-50%, -50%);
        transition: transform 0.3s linear;
        &-open {
          transform: rotate(180deg);
          transition: transform 0.3s linear;
        }
        > svg {
          fill: #fff;
        }
      }
      //   @include HoverBtnDark;
      &:hover {
        div {
          svg {
            fill: #fff;
          }
        }
      }
    }
    &-list {
      padding: 10px;
      background-color: #fff;
      @include borderRadius;
      border: 1px solid $grey;
      position: absolute;
      // bottom: -70px;
      width: 100%;
      z-index: 10;
      display: none;
      flex-direction: column;
      gap: 10px;
      &-active {
        display: flex;
      }
      &-option {
        @include FSbaseBoldM;
        cursor: pointer;
        font-family: "Montserrat";
        // color: $blue;
      }
      //   @include HoverSearchPanelText;
    }
  }
}
