@import "../../../styles/variables";

.chat {
  display: flex;
  height: 100%;
  span {
    font-family: "Montserrat";
  }
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 60px;
    @include _1700 {
      padding-top: 10px;
    }
    &-main {
      @include flexGap(20px, col);
      padding: 3px 0;
      height: 100%;
      padding-bottom: 20px;
      height: calc(100vh - 290px);
      @include _2000 {
        height: calc(100vh - 290px);
      }
      @include _1700 {
        height: calc(100vh - 270px);
      }
    }
    &-filter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-clear {
        display: flex;
        align-items: center;
        border-radius: 50px 0 0 50px;
        background-color: white;
        min-width: 50px;
        padding: 0px 15px;
        height: 50px;
        @include gapBig;
        img {
          cursor: pointer;
        }
      }
    }
    &-searchPanel {
      padding: 0 20px 0 10px;
    }
    &-chatZone {
      height: 100%;
      display: grid;
      @include gapBig;
      grid-template-columns: 600px minmax(600px, 1fr);
      padding: 0 20px 0 10px;
      // height: 100%;
      @include _1455 {
        grid-template-columns: 500px minmax(600px, 1fr);
      }
      @include _1300 {
        grid-template-columns: 400px minmax(500px, 1fr);
      }
      height: calc(100vmin - 300px);
      // @include _1300 {
      //   height: calc(100vh - 260px);
      // }
    }
  }
  .title {
    color: $blue;
    font-weight: 700;
  }
  .bold {
    @include FSbaseBold;
  }

  .hr {
    background-color: $grey;
    height: 1px;
    width: 100%;
  }
}
