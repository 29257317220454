@import "/src/styles/variables";

.cardColumnPartic {
  @include cardColumnWrapper;
  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 10px 0 10px;
    &-content {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-direction: column;
      margin-bottom: 10px;
      align-items: center;
    }
    &-menu {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
    &-avatar {
      @include LGavatar;
    }
    &-title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseReg;
      line-height: 16px;
    }
    &-text,
    &-name {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
    }

    &-button {
      background-color: #fff;
      img {
        @include SmdBtn;
      }
    }
  }
  &-info {
    border-top: 2px solid #f6f6f6;
    list-style-type: none;
    margin: 0 10px;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-item {
      display: flex;
      justify-content: space-between;

      &-title {
        white-space: nowrap;
        @include FSbaseRegM;
        margin-right: 5px;
      }
      &-text {
        @include FSbaseBoldM;
        text-align: end;
      }
    }
    &-title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      margin-right: 4px;
      color: $blue;
    }
    &-text {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      @include nowrap;
    }
  }
  &-footer {
    list-style-type: none;
    background-color: $main-color;
    border-radius: 0px 0px 2px 2px;
    padding: 10px;
    &-item {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    &-img {
      margin-right: 10px;
    }
    &-text {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      color: #fff;
      text-decoration: none;
    }
    a {
      text-decoration: none;
    }
  }
  &-menu-window {
    position: absolute;
    top: 0px;
    left: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    background: #ffffff;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
    @include borderRadius;
    > span {
      font-family: "Montserrat";
      font-style: normal;
      @include FSmdMed;
      line-height: 14px;
      cursor: pointer;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }
    @include HoverModalText;
  }
}
