@import "src/styles/variables";

.breadcrumbs {
  display: flex;
  border-radius: 0 50px 50px 0;
  height: 50px;
  padding: 14px 20px 14px 30px;
  margin-left: -20px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include gapMin;
  width: fit-content;
  cursor: pointer;
  background-color: #fff;
  min-width: 70px;
  max-width: 100%;
  @include _2000 {
    border-radius: 0 45px 45px 0;
    height: 45px;
    padding: 10px 15px 10px 30px;
  }
  @include _1700 {
    border-radius: 0 40px 40px 0;
    height: 35px;
  }
  .swiper-breadcrumbs {
    .swiper-wrapper {
      align-items: center;
    }
  }
}

.swiper-breadcrumbs-item {
  width: fit-content;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
