@import "./src/styles/_variables.scss";

.projectWorkScheduleMobileItem {
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}
