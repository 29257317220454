@import "./src/styles/variables";

.tasksGridCardMobile {
  display: flex;
  flex-direction: column;
  //   justify-content: space-between;
  width: 100%;
  border: 1px solid $grey;
  @include borderRadius;
  min-height: 200px;
  background-color: #fff;
  padding: 10px;
  &-header {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
    border-bottom: 1px solid $main-color;
    padding-bottom: 10px;
    &-info {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }

    &-content {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 35px;
      @include gapMin;
      &-title {
        @include FSbaseBoldM;
        text-align: center;
        width: 100%;
        // height: 100%;
        @include FSbaseBold;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -moz-box;
        -moz-box-orient: vertical;
        display: -webkit-box;

        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        line-clamp: 2;
        box-orient: vertical;
      }
    }
  }
  &-commentsCount {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $main-color;
    color: #fff;
    min-width: 20px;
    height: 20px;
    padding: 2px;
    @include FSmdReg;
    border-radius: 50%;
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
    &-item {
      display: flex;
      justify-content: space-between;
      &-title {
        @include FSbaseBoldM;
        margin-right: 5px;
        white-space: nowrap;
        color: $blue;
      }

      &-text {
        @include FSbaseBoldM;
        text-align: end;
        &.executors {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          line-height: 1.3em;
          max-height: 2.8em;
        }
        &.overdue {
          color: $red;
        }
      }
    }
  }
}
