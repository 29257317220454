@import "../../styles/variables";

.slider {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  &-photos {
    display: flex;
    flex-direction: column;

    @include gapMin;
    @include _600 {
      width: 100%;
    }
    &-title {
      font-family: "Montserrat";
      @include FSbaseBold;
      color: $blue;
      text-align: center;
    }
    &-currentImg {
      width: 386px;
      height: 386px;
      background-size: cover;
      background-position: center;
      @include borderRadius;
      position: relative;
      border: 1px solid $grey;
      margin: 0 auto;
      &-img {
        object-fit: cover;
        background-size: cover;
        background-position: center;
        width: 386px;
        height: 386px;
        border: 1px solid $grey;
        @include borderRadius;
        @include _1024 {
          width: 320px;
          height: 320px;
        }
        @include _600 {
          width: 100%;
        }
      }
      &-btnRemove {
        position: absolute;
        bottom: 0;
        right: 0;
        background: white;
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px 0 0 0;
        padding: 6px 10px;
        cursor: pointer;
      }

      @include _1024 {
        width: 320px;
        height: 250px;
      }
      @include _350 {
        width: 100%;
        height: 300px;
      }
    }
    &-swiper {
      display: flex;
      align-items: center;
      @include gapMin;
      &-next-icon {
        transform: scaleX(-1);
      }
      &-slider {
        height: 94px;
        width: 368px;
        @include _1024 {
          width: 320px;
        }
        @include _900 {
          width: 100%;
          max-width: 400px;
        }
        @include _600 {
          max-width: 300px;
        }
        @include _350 {
          max-width: 200px;
        }
        img {
          width: 100%;
        }
        &-slide {
          width: 90px;
          height: 90px;
          // @include _1024 {
          //   width: 70px;
          //   height: 70px;
          // }
          background-size: cover;
          background-position: center;
          @include borderRadius;
          cursor: pointer;
          position: relative;
          &-btnRemove {
            position: absolute;
            bottom: 0;
            right: 0;
            background: rgba(255, 255, 255, 0.5);
            box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
            border-radius: 10px 0 0 0;
            padding: 4px 8px;
            cursor: pointer;
            z-index: 10;
            // @include _1024 {
            //   bottom: 0px;
            //   right: 13px;
            //   padding: 2px 4px;
            // }
          }
        }
      }
    }
    &-btn {
      label {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      input {
        display: none;
      }
    }
  }
}
.slider.border {
  .slider-photos-currentImg {
    border: 1px solid $grey;
    @include borderRadius;
  }
}

.slider-photos-section {
  display: flex;
  flex-direction: column;
  @include gapBig;
  position: relative;
  @include _900 {
    max-width: 400px;
  }
  @include _600 {
    max-width: 300%;
  }
  @include _350 {
    max-width: clac(100% - 20);
  }
  &-panel {
    height: 94px;
    width: 368px;
    img {
      width: 100%;
    }
    @include _350 {
      width: clac(100% - 20);
    }
    &-slide {
      width: 94px;
      height: 94px;
      @include _1024 {
        width: 70px;
        height: 70px;
      }
      @include _350 {
        width: 50px;
        height: 50px;
      }
      background-size: cover;
      background-position: center;
      @include borderRadius;
      cursor: pointer;
      &-btnRemove {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(255, 255, 255, 0.5);
        box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
        border-radius: 10px 0 0 0;
        padding: 4px 8px;
        cursor: pointer;
        z-index: 10;
        @include _1024 {
          bottom: 0px;
          right: 13px;
          padding: 2px 4px;
        }
      }
    }
  }
  &-btns {
    &-next,
    &-prev {
      position: absolute;
      background: rgba(255, 255, 255, 0.5);
      @include borderRadius;
      width: 30px;
      height: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      z-index: 10;
      top: 30%;
      img {
        max-width: fit-content;
        @include SmdBtn;
      }
      @include _1024 {
        width: 40px;
        height: 40px;

        background: none;
      }
    }
    &-next {
      right: 5px;
      svg {
        transform: rotate(180deg);
      }
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    opacity: 0;
  }
}
.slider-photos-swiper-slider {
  // .swiper-slide {
  //   width: 80px !important;
  // }
}
