@import "../../../styles/variables.scss";

.tasks {
  display: flex;
  height: 100%;
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &__row {
      height: calc(100vh - 300px);
      padding-bottom: 20px;
      @include _2000 {
        height: calc(100vh - 290px);
      }
      @include _1700 {
        height: calc(100vh - 200px);
      }
    }
    &__filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }
    &__searchPanel {
      padding: 0 20px 0 10px;
    }
    &__pagination {
      display: flex;
      justify-content: center;
      padding-bottom: 20px;
    }
  }
  &-column {
    display: grid;
    padding: 10px 10px;
    background: #ffffff;
    /* тень */
    margin: 0 20px 0 10px;
    border: 1px solid $grey;
    @include borderRadius;
    list-style-type: none;
    height: 800px;
    gap: 5px;
    overflow-y: scroll;
    overflow-x: hidden;
    justify-content: space-between;
    grid-template-columns: repeat(6, 1fr);
    @include _1700 {
      padding: 5px;
    }
    &-header {
      width: 100%;
      padding: 10px 10px 12px 10px;
      background-color: $grey-dark;
      position: relative;
      border-radius: 10px 0 0 10px;
      clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
      @include _2000 {
        padding: 10px 8px 9px 8px;
      }
      @include _1700 {
        padding: 6px 8px 6px 8px;
      }

      &__title {
        font-family: "Montserrat";
        font-style: normal;
        // font-weight: 700;
        // font-size: 18px;
        @include FSnav;
        line-height: 21px;
        color: #fff;
        height: 100%;
      }
      &-atWork {
        background-color: $atWork;
      }
      &-onVerification {
        background-color: $onVerification;
      }
      &-completed {
        background-color: $completed;
      }
      &-overdue {
        background-color: $overdue;
      }
      &-rejected {
        background-color: $rejected;
      }
      &-assigned {
        background-color: $assigned;
      }
    }
    &__item {
      background: #f6f6f6;
      border: 1px solid $grey;
      @include borderRadius;
      height: 100%;
    }
    &__content {
      position: relative;
      overflow-y: auto;
      list-style: none;
      background: #f6f6f6;
      // overflow-y: scroll;
      padding: 10px;
      width: 100%;
      height: 730px;
      transition: 0.3s linear;
      overflow-x: hidden;
      @include _2000 {
        padding: 5px;
      }
    }
  }
}
