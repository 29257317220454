@import "../../../../styles/variables";

.companyBaseInfoEdit {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gapBig;
  padding: 0 20px 0 10px;
  &-row {
    display: flex;
    justify-content: flex-end;
  }
  &-content {
    display: flex;
    width: 100%;
    @include gapBig;
    @include _768 {
      display: flex;
      flex-direction: column-reverse;
    }
    &-info {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      width: 100%;
      justify-content: space-between;
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        width: 100%;
        display: flex;
        flex-direction: column;
        // margin-right: 20px;

        .inputSearch-title {
          color: $blue;
        }
        &.name-company {
          grid-column-start: 1;
          grid-column-end: -1;
        }
      }
      &-logo {
        display: flex;
        flex-direction: column;
        @include _768 {
          display: flex;
          flex-direction: column;
          width: 100%;
          align-items: center;
        }
        &-title {
          display: inline-block;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
          color: #04488e;
          margin-bottom: 10px;
        }
      }
    }
  }
}
