@import "./src/styles/variables";

.modalInfoGroup {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-content {
    // &:not(:last-child) {
    //   border-bottom: 2px solid #7bc7f1;
    // }
    // &:last-child {
    //   padding-top: 20px;
    // }
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-title {
      margin: 0;
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
      color: #000000;
    }
    &-info {
      list-style-type: none;
      width: 100%;
      display: grid;
      @include gapBig;
      grid-template-columns: repeat(3, 1fr);
      @include _1024 {
        grid-template-columns: repeat(2, 1fr);
      }
      @include _768 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        min-width: 20%;
        display: flex;
        flex-direction: column;
        @include gapMin;
        @include _768 {
          display: flex;
          flex-direction: row;
        }
      }
      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
      }
    }
    &-emploees {
      list-style-type: none;
      display: flex;
      flex-wrap: wrap;
      padding: 10px 10px;
      @include borderRadius;
      border: 1px solid $grey;
      background: white;
      &-item {
        margin-right: 20px;
        > span {
          margin: 0;
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 24px;
          color: #000000;
        }
      }
    }
  }
}
