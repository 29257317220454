@import "./src/styles/variables";

.documentationItemRow {
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include cardRowWrapper;

  &-content {
    display: grid;
    gap: 5px;
    align-items: center;
    width: 100%;
    grid-template-columns: 20px 40px 1fr 100px 100px 200px 1fr;
    &-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: flex;
      justify-content: center;
      width: 100%;
    }
    &-item.name {
      @include borderRadius;
      overflow: hidden;
      align-self: center;
      cursor: pointer;
      > span {
        display: inline-block;
        text-align: start;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-btns {
      display: flex;
      align-items: center;
      @include gapMin;
      justify-content: flex-end;
    }
    &-item.icon {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &-item {
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
