@import "/src/styles/variables";

.companyMobile {
  &-info {
    padding: 10px 0 60px 0;
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
  &-edit {
    padding: 20px 0 60px 0;
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-wrapper {
      display: flex;
      flex-direction: column;
      @include gapBig;
    }
  }

  &-logo {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
  &-content {
    display: flex;
    flex-direction: column-reverse;
    @include gapMin;
    @include min_600 {
      display: grid;
      grid-template-columns: 4fr 1fr;
    }
    &-list {
      display: flex;
      flex-direction: column;
      @include gapBig;
      @include min_600 {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
      @include min_768 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        height: fit-content;
      }
    }
    &-item {
      display: flex;
      @include gapMin;
      @include min_600 {
        flex-direction: column;
      }
    }
  }
  &-title {
    @include FSbaseBoldM;
    color: $blue;
  }
  &-text {
    @include FSbaseBoldM;
  }

  &-edit {
  }
}
