@import "/src/styles/variables";

.documentationMobile {
  width: 100%;
  height: auto;
  padding-bottom: 60px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-all {
      padding-top: 10px;
    }
    &-cards {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }
  }
  &-wrapper {
    padding-top: 10px;
  }
}
