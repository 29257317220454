@import "../../../styles/_variables.scss";

.projects {
  display: flex;
  height: 100%;
  &-content {
    @include flexGap(20px, col);
    background-color: $background-color-content;
    padding-top: 20px;
    width: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    &-main {
      @include flexGap(20px, col);
      padding-bottom: 20px;
      width: 100%;
      display: flex;
      flex-direction: column;
      @include gapBig;
      height: calc(100vh - 300px);
      @include _2000 {
        height: calc(100vh - 280px);
      }
      @include _1700 {
        height: calc(100vh - 200px);
      }
    }
    &-filter {
      display: flex;
      justify-content: space-between;
      &-set {
        display: flex;
        @include gapBig;
      }
    }
    &-searchPanel {
      padding: 0 20px 0 10px;
    }
    &-cards.rows {
      padding: 0 20px 0 10px;
      display: flex;
      @include gapBig;
      flex-wrap: wrap;

      &-header {
        display: grid;
        justify-content: space-between;
        grid-template-columns:
          minmax(auto, 91px)
          minmax(auto, 262px)
          minmax(auto, 154px)
          minmax(auto, 166px)
          minmax(auto, 155px)
          // minmax(auto, 100px)
          minmax(auto, 128px)
          minmax(auto, 336px)
          minmax(auto, 169px)
          minmax(100px, auto);
        padding: 0 20px 0 15px;
        &-item {
          text-align: center;
          overflow: hidden;
          text-overflow: ellipsis;
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        &-item.number {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
    &-cards.rows {
      flex-direction: column;
      padding: 0;
      gap: 10px;
      padding: 0 10px 0 5px;
    }
    &-cardCharts {
      padding: 0 10px 0 5px;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      &-header {
        display: grid;
        grid-template-columns:
          minmax(auto, 91px)
          minmax(auto, 258px)
          minmax(auto, 210px)
          minmax(auto, 218px)
          minmax(auto, 218px)
          minmax(auto, 114px)
          minmax(auto, 136px)
          minmax(auto, 147px)
          minmax(auto, 150px)
          minmax(100px, 100px);
        padding: 0 20px 0 15px;
        &-item {
          text-align: center;
          span {
            font-family: "Montserrat";
            @include FSbaseBold;
            color: $blue;
          }
        }
        &-item.number {
          display: flex;
          align-items: center;
          gap: 10px;
        }
      }
    }
    &-pagination {
      display: flex;
      justify-content: center;
    }
  }
}
