@import "../../styles/variables";

.calendarTable {
  @include flexGap(15px, col);
  padding: 10px;
  background: #ffffff;
  // border: 1px solid $grey;
  @include borderRadius;
  span {
    font-family: "Montserrat";
  }
  &-header {
    display: flex;
    justify-content: center;
    &-panel {
      display: flex;
      flex-direction: row;
      @include gapBig;
      align-items: center;
      @include _1300 {
        @include gapMin;
      }
      &-prev,
      &-next {
        @include flexAllCenter();
        background: rgba(255, 255, 255, 0.5);
        border: 1px solid #c0c0c0;
        @include borderRadius;
        width: 34px;
        height: 34px;
        cursor: pointer;
        @include _1300 {
          width: 30px;
          height: 30px;
        }
      }
      &-next svg {
        transform: rotate(180deg);
      }
      &-value {
        position: relative;
        min-width: 173px;
        height: 34px;
        span {
          @include FSbaseBold;
          color: white;
          height: 100%;
        }
        @include _1300 {
          min-width: 150px;
          width: 150px;
          height: 30px;
        }
        &-title {
          @include flexAllCenter();
          @include gapBig;
          background: #04488e;
          @include borderRadius;
          padding: 7px 10px;
          height: 100%;
          cursor: pointer;
        }
        &-menu {
          display: flex;
          justify-content: center;
          gap: 12px;
          position: absolute;
          bottom: -305px;
          left: 0;
          background: #04488e;
          @include borderRadius;
          padding: 8px 8px;
          min-width: 173px;
          width: 100%;
          height: 300px;
          z-index: 10;
          @include _1300 {
            min-width: 150px;
            width: 150px;
          }
          &-month,
          &-year {
            @include flexGap(5px, col);
            text-align: center;
            max-height: 300px;
            span {
              transition: all 0.3s;
              cursor: pointer;
              &:hover {
                color: $main-color;
              }
            }
            span.active {
              color: $main-color;
            }
            /* Скрываем scrollbar для Chrome, Safari и Opera */
            &::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none; /* IE и Edge */
            scrollbar-width: none;
          }
          &-year {
            padding-right: 5px;
          }
        }
      }
    }
  }
  &-table {
    background: #ffffff;
    border: 1px solid $grey;
    @include borderRadius;
    // overflow-y: scroll;

    &-header {
      display: grid;
      grid-template-columns: repeat(7, minmax(70px, 1fr));
      text-align: center;

      span {
        border-right: 1px solid #f6f6f6;
        @include FSbaseBold;
        color: #04488e;
        line-height: 38px;
      }
      span:last-child {
        border-right: none;
      }
    }
    &-margix {
      @include flexGap(0px, col);
      &-week {
        display: grid;
        grid-template-columns: repeat(7, minmax(70px, 1fr));
        height: 165px;
        &-rows {
          display: flex;
          flex-direction: column;
          min-height: 20px;
          height: fit-content;
        }
      }
      &-day {
        @include flexGap(10px, col);
        padding: 10px;
        border: 1px solid #f6f6f6;
        width: 100%;

        @include _1300 {
          padding: 5px;
        }
        &-title {
          // font-weight: 600;
          // font-size: 16px;
          // @include FSbaseReg;
          // @include _1200 {
          //   font-size: 12px;
          // }
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-variant-numeric: tabular-nums;
          -moz-font-feature-settings: "tnum";
          -webkit-font-feature-settings: "tnum";
          font-feature-settings: "tnum";

          @include FSbaseSemibold;
          padding: 5px;
          display: flex;
          align-items: center;
          &.evetsHeader {
            border: 2px solid #a9ddfa82;
            @include borderRadius;
            cursor: pointer;
            @include HoverModalText;
          }
        }
        &-title.noMonth {
          color: $grey;
        }
        &-eventList {
          @include flexGap(5px, col);
          height: 115px;
          position: relative;
          &-row {
            // min-height: 20px;
            height: fit-content;
            transition: none;
          }
          &-item {
            padding: 5px;
            height: 40px;
            width: 100%;
            border: 1px solid $main-color;
            // background: #f6f6f6;
            @include borderRadius;
            cursor: pointer;
            transition: all 0.2s;
            // @include _1024 {
            //   border: 2px solid #a9ddfa82;
            // }
            span {
              font-size: 12px;
              overflow-x: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            &-title {
              @include flexGap(0px, col);
            }
            @include HoverSelectRow;
            overflow: visible;
            // &:hover {
            //   transition: all 0.3s;
            //   border: 1px solid $main-color;
            //   // filter: drop-shadow(3px 6px 6px rgba(133, 132, 132, 0.281));
            // }
            @include _1024 {
              &:active {
                transition: all 0.3s;
                border: 1px solid $main-color;
              }
            }
            &-row {
              display: grid;
              grid-template-columns: 1fr 100px;
              align-items: center;
              @include _1024 {
                grid-template-columns: 1fr 20px;
              }
              &-btns {
                display: flex;
                justify-content: flex-end;
                @include gapMin;
              }
            }
          }
        }
      }
      &-day:last-child {
        border-right: none;
      }
      &-day:nth-child(n + 6) {
        background-color: #ffe8eb34;
        .calendarTable-table-margix-day-title {
          color: $red;
        }
      }
      &-day-today {
        border: 2px solid #a9ddfa82;
        @include borderRadius;
      }
    }
  }
  .color-red {
    color: $red;
  }
}
