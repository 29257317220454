@import "../../styles/variables";

.headerMobilePage {
  &-header {
    padding: 20px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $grey;
    min-height: 62px;
  }
  &-title {
    @include FStitleM;
    color: black;
    text-align: start;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &-btn-close {
    background-color: inherit;
  }
}
