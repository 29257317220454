@import "./src/styles/variables";

.tasksGridMobile {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-grid {
    @include cardColumnGridMobile;
  }
}
