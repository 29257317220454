@import "../../styles/variables";

.folderStructure {
  width: 100%;
  display: flex;
  flex-direction: column;
  @include gapMin;
  &-header {
    display: flex;
    width: 100%;
    &-title {
      @include FSbaseBold;
    }
  }

  &-content {
    background-color: $blue-m-bg;
    @include borderRadius;
    min-height: 100px;
    max-height: 600px;
    padding: 10px;
    &-wrapper {
      padding-left: 20px;
    }
  }
}
