@import "../../../../../../styles/variables";

.navbarMobile {
  position: fixed;
  top: -120%;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  height: 100%;
  z-index: 998;
  transition: top 0.4s cubic-bezier(1, 0, 0, 1);
  overflow-y: auto;
  // -webkit-transition: all 0.5s;
  // -moz-transition: all 0.5s;
  // animation: down 0.4s;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 10px 20px 10px;
  &-active {
    top: 0;
  }
  &-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    &-list {
      display: grid;
      grid-template-columns: minmax(100px, 167px) minmax(100px, 167px);
      gap: 10px;
      grid-template-rows: repeat(6, 70px);
    }
  }
  &-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid $grey;
    @include borderRadius;
    height: 70px;
    &-active {
      background-color: $main-color;
      border: 1px solid #fff;

      span {
        color: #fff;
      }
    }
    &-title {
      @include FSbaseBold;
    }
  }
}
