@import "../../styles/variables";
@keyframes animateClose {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.select {
  display: flex;
  flex-direction: column;
  font-family: "Montserrat";
  font-weight: 700;
  position: relative;
  @include gapMin;
  span {
    @include FSbaseBold;
  }
  &-title {
    @include FSbaseBold;
    color: $blue;
    // @include _1024 {
    color: $blue;
    @include FSbaseBoldM;
    // }
    &-red {
      color: red;
    }
  }

  &-validate {
    position: absolute;
    bottom: -12px;
    span {
      color: red;
      @include FSxsReg;
    }
  }
  > div {
    //  css-b62m3t-container
    // border: 1px solid red;
  }
  .react-select__singleValue {
    @include FSbaseBold;
  }
  .react-select__menu {
    @include FSbaseBold;
    z-index: 5;
    > div {
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
        @include borderRadius;
      }

      &::-webkit-scrollbar-thumb {
        @include borderRadius;
        background-color: $main-color;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        background-color: #f9f9fd;
      }
    }
  }
  .react-select__control {
    border: 1px solid $grey;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 5px 7.5px 10px;
    color: black;
    background: white;
    @include borderRadius;
    border-radius: 6px !important;
    min-height: 45px;
    @include _2000 {
      min-height: 40px;
      padding: 5px 5px 5px 10px;
    }
    @include _1700 {
      min-height: 35px;
      padding: 2px 2px 2px 8px;
    }
  }
}

.select.titleForInput {
  .select-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue;
  }
}

.select.forInput {
  display: flex;
  flex-direction: column;
  @include gapMin;
  .select-title {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: $blue !important;
  }

  .react-select__control {
    border: 1px solid $grey;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 5px 7.5px 10px;
    color: black;
    background: white;

    // border-radius: 10px !important;
    @include borderRadius;
    min-height: 45px;
    @include _2000 {
      min-height: 40px;
      padding: 5px 5px 5px 10px;
    }
    @include _1700 {
      min-height: 35px;
      padding: 2px 2px 2px 8px;
    }
  }
}
.react-select {
  &-close-icon {
    animation: animateClose 2s linear;
    opacity: 0.5;
  }
  &__input-container {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
    }
  }
  &__single-value {
    font-family: "Montserrat";
    @include FSbaseBold;
    color: black;
  }
  &__menu {
    @include FSbaseBold;
    > div {
      &::-webkit-scrollbar {
        width: 5px;
        background-color: #f9f9fd;
        @include borderRadius;
      }

      &::-webkit-scrollbar-thumb {
        @include borderRadius;
        background-color: $main-color;
      }

      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.2);
        @include borderRadius;
        background-color: #f9f9fd;
      }
    }
  }
  &__indicator-separator {
    background-color: #fff !important;
  }
  &__input-container {
    @include FSbaseBold;
  }
  &__value-container {
    padding: 0 !important;
    @include FSbaseReg;
  }
  &__control {
    transition: all 0.2s;
    width: 100%;
    font-family: "Montserrat";
    @include FSbaseReg;
    padding: 7.5px 5px 7.5px 10px;
    color: black;
    // background: white;
    @include borderRadius;
    // border-radius: 10px !important;
    @include _2000 {
      min-height: 40px;
      padding: 5px 5px 5px 10px;
    }
    @include _1700 {
      min-height: 35px;
      padding: 2px 2px 2px 8px;
    }
    &:hover {
      border: 1px solid $grey;
    }
    // @include _1024 {
    background-color: $blue-m-bg !important;
    border: 1px solid $blue-m-bg !important;
    // }
  }
  &__input {
    input {
      font-family: "Montserrat";
      @include FSbaseReg;
      color: black;
    }
  }
  &__clear-indicator {
    padding: 0 !important;
  }
  input {
    font-family: "Montserrat";
    @include FSbaseReg;
    color: black;
  }
}
.select-placholder {
  @include FSbaseReg;
}
.react-select__indicator-separator {
  display: none;
}
.dropdownIndicator {
  height: 12px;
  width: 20px;
  transform: rotate(0deg);
  transition: transform 0.3s linear;
  &-open {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
  }
}

.select.validate {
  .react-select__control {
    border: 1px solid $orange !important;
  }
}
.select-placholder {
  color: $blue-m-text;
}
.select.isDisabled {
  .react-select__control {
    opacity: 0.5;
  }
}
