@import "/src/styles/variables";

.companyProject {
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-search {
    padding: 0 10px 0px 10px;
  }
}
