@import "/src/styles/variables";

.projectWorkScheduleColListHeader {
  background: white;

  border-radius: 0 50px 50px 0;

  padding: 12px 12px 12px 40px;
  transform: translateX(-10px);
  position: relative;
  width: calc(100% + 10px);
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  img {
    @include SmdBtn;
    cursor: pointer;
  }
  .title {
    @include FSbaseBold;
    padding-left: 10px;
  }
  &::after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    background-color: #c4c4c4;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 10px;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .title {
      position: relative;
      vertical-align: baseline;
      &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: -22px;
        height: 3px;
        width: 20px;
        background-color: #c4c4c4;
        content: "";
      }
    }
    &-btns {
      display: flex;
      gap: 10px;
    }
  }
}
