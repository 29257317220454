@import "../../styles/variables";

.modalInfoEditFolder {
  display: flex;
  flex-direction: column;
  width: 100%;

  position: relative;

  span {
    font-family: "Montserrat";
  }
  &.edit {
    @include _1024 {
      padding-bottom: 60px;
    }
  }
  &-main {
    display: flex;
    flex-direction: column;
    @include gapBig;
    position: relative;
    height: 100%;
    &-title {
      color: black;
      @include FSsubtitle;
    }
    &-grid-list {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      max-width: 1360px;
      @include _1024 {
        @include gapMin;
        grid-template-columns: 1fr 1fr;
      }
      @include _600 {
        grid-template-columns: 1fr;
      }
    }
    &-grid-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
    }
  }
  &-info {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &-title {
      color: black;
      @include FSbaseBold;
      font-family: "Montserrat";
    }
    &-name {
      display: flex;
      flex-direction: column;
      @include gapMin;

      &-title {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: #04488e;
      }
      &-text {
        display: inline-block;
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
        color: #000000;
        word-wrap: break-word;
        max-width: 99%;
      }
    }
  }
}
