@import "./src/styles/variables";
.modalInfoUser {
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-wrapper {
    display: grid;
    grid-template-columns: 1fr 160px;
    @include gapBig;
    @include _768 {
      display: flex;
      flex-direction: column-reverse;
    }
  }
  &-title {
    margin: 0;
    font-family: "Montserrat";
    font-style: normal;
    @include FSsubtitle;
    line-height: 18px;
    color: #000000;
  }
  &-list {
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    @include gapBig;
    width: 100%;
    justify-content: space-between;
    @include _1024 {
      grid-template-columns: repeat(3, 1fr);
    }
    @include _600 {
      display: flex;
      flex-direction: column;
    }
  }
  &-item {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include gapMin;
    @include _600 {
      flex-direction: row;
    }
    &-title {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: #04488e;
    }
    &-text {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      color: #000000;
    }
  }
  &-avatar {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-img {
      @include ProfileAvatar;
      @include borderRadius;
      border: 1px solid $grey;
    }
    @include _768 {
      align-items: center;
    }
  }
  &-profiles {
    display: flex;
    flex-direction: column;
    @include gapBig;
  }
}
.infoUserProfile {
  border-top: 1px solid $blue-m-bg;
  padding-top: 20px;
  @include _1700 {
    padding-top: 10px;
  }
  display: flex;
  flex-direction: column;
  @include gapBig;
  &-projects {
    display: flex;
    flex-direction: column;
    @include gapMin;
  }
}
