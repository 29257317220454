@import "../../styles/variables";
@include animate_pulse;

.pagination {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include gapBig;
  @include _768 {
    flex-direction: column;
  }
  &-wrapper {
    background: white;
    @include borderRadius;
    padding: 10px 25px;
    @include _2000 {
      padding: 7px 15px;
    }
    @include _1700 {
      padding: 5px 10px;
    }
    @include _1024 {
      // border: 1px solid $grey;
      // height: 40px;
      // padding: 5px 10px;
      padding: 0;
      background-color: inherit;
    }
    z-index: 0;
  }
  ul {
    display: flex;
    align-items: center;
    gap: 5px;
    li {
      list-style-type: none;
      font-family: "Montserrat";
      @include FSbaseBold;
      color: $main-color;
      position: relative;
      z-index: 10;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      width: 30px;
      height: 30px;
      @include _2000 {
        width: 25px;
        height: 25px;
      }
      @include _1024 {
        width: 40px;
        height: 40px;
        border: 1px solid $grey;
        color: black;
        background: white;
      }
      transition: all 0.5s;
      &:hover {
        // время, которое элемент должен ждать после наведения курсора
        background-color: #80cbf38f;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        color: $blue;
        @include _2000 {
          width: 25px;
          height: 25px;
        }
        @include _1024 {
          width: 40px;
          height: 40px;
        }
      }
      &:active {
        border-radius: 50%;
        animation: animate_pulse 0.3s;
      }
      > a {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    li.selected {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: no-drop;
      @include _2000 {
        width: 25px;
        height: 25px;
      }
      @include _1024 {
        width: 40px;
        height: 40px;
        color: $main-color;
        border: 1px solid $main-color;
      }
      &::after {
        content: "";
        cursor: no-drop;
        position: absolute;
        background-color: #80cbf38f;
        border-radius: 50%;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: -1;
        width: 30px;
        height: 30px;
        @include _2000 {
          width: 25px;
          height: 25px;
        }
        @include _1024 {
          width: 40px;
          height: 40px;
        }
      }
      &:hover {
        @include FSbaseBold;
        color: $main-color;
        position: relative;
        z-index: 10;
        background-color: #fff;
      }
    }
    li.previous,
    li.next {
      &:hover {
        background-color: #fff;
      }
    }
    li.previous.disabled {
      display: none;
    }
    li.next.disabled {
      display: none;
    }
  }
  .previous,
  .next {
    @include _1024 {
      width: 60px;
      @include borderRadius;
      background-color: #fff;
      border: 1px solid $main-color;
    }
    @include _400 {
      width: 40px;
    }
  }
  &-countElement {
    background: white;
    @include borderRadius;
    padding: 10px 25px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $main-color;
    @include FSbaseBold;
    @include _2000 {
      padding: 7px 15px;
    }
    @include _1700 {
      padding: 5px 10px;
    }
    @include _1024 {
      // border: 1px solid $grey;
      // height: 40px;
      // padding: 5px 10px;

      background-color: inherit;
    }
    ul {
      display: flex;
      align-items: center;
      gap: 5px;
      li {
        list-style-type: none;
        font-family: "Montserrat";
        @include FSbaseBold;
        color: $main-color;
        position: relative;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        width: 30px;
        height: 30px;
        @include _2000 {
          width: 25px;
          height: 25px;
        }
        @include _1024 {
          width: 40px;
          height: 40px;
          border: 1px solid $grey;
          color: black;
          background: white;
        }
        transition: all 0.5s;
        &:hover {
          // время, которое элемент должен ждать после наведения курсора
          background-color: #80cbf38f;
          border-radius: 50%;
          width: 30px;
          height: 30px;
          color: $blue;
          @include _2000 {
            width: 25px;
            height: 25px;
          }
          @include _1024 {
            width: 40px;
            height: 40px;
          }
        }
        &:active {
          border-radius: 50%;
          animation: animate_pulse 0.3s;
        }
        > a {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
    li.selected {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      cursor: no-drop;
      @include _2000 {
        width: 25px;
        height: 25px;
      }
      @include _1024 {
        width: 40px;
        height: 40px;
        color: $main-color;
        border: 1px solid $main-color;
      }
      &::after {
        content: "";
        cursor: no-drop;
        position: absolute;
        background-color: #80cbf38f;
        border-radius: 50%;
        top: 50%;
        right: 50%;
        transform: translate(50%, -50%);
        z-index: -1;
        width: 30px;
        height: 30px;
        @include _2000 {
          width: 25px;
          height: 25px;
        }
        @include _1024 {
          width: 40px;
          height: 40px;
        }
      }
      &:hover {
        @include FSbaseBold;
        color: $main-color;
        position: relative;
        z-index: 10;
        background-color: #fff;
      }
    }
  }
}
