@import "./src/styles/_variables.scss";

.referenceList {
  padding: 0 20px 0 10px;
  height: 70vh;
  display: flex;
  flex-direction: column;
  @include gapMin;
  @include _1024 {
    padding: 0;
    padding-bottom: 60px;
    height: calc(100vh - 150px);
  }
}
