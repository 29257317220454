@import "src/styles/variables";

.listChart {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  background: white;
  border: 1px solid $grey;
  @include borderRadius;
  padding: 20px;
  height: 100%;
  font-family: "Montserrat";
  @include _1700 {
    padding: 10px;
  }

  &-title {
    font-family: "Montserrat";
    @include FSh1;
    color: #000;
    @include _1024 {
      @include FStitleM;
    }
  }
  &-header {
    display: flex;
    gap: 10px;
    width: 100%;
    justify-content: space-between;
    position: relative;
    gap: 20px;
    &-modal {
      background: white;
      box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
      @include borderRadius;
      padding: 14px 20px;
      width: 327px;
      position: absolute;
      top: 0px;
      right: 0;
      display: flex;
      flex-direction: column;
      gap: 10px;
      z-index: 1;
      &-btns {
        display: flex;
        gap: 10px;
      }
    }
  }
  &-content {
    max-height: 484px;
    display: flex;
    gap: 20px;
    @include _1024 {
      padding: 0px;
      max-height: 100%;
    }
    @include _768 {
      flex-direction: column;
    }
    span {
      font-family: "Montserrat";
      font-size: 12px;
      @include FSxsReg;
    }
    &-list {
      padding-top: 50px;
      @include flexGap(0px, col);
      span {
        font-size: 14px;
        padding: 10px;
        white-space: nowrap;
        @include FSbaseBold;
      }
      span.active {
        transition: all 0.3s;
        color: $blue;
      }
      @include _768 {
        padding-top: 0;
        display: grid;
        padding-left: 70px;
        grid-template-columns: repeat(10, 1fr);
        @include _480 {
          grid-template-columns: repeat(8, 1fr);
        }
        @include _400 {
          grid-template-columns: repeat(5, 1fr);
        }
        span {
          writing-mode: vertical-rl;
          transform: rotate(180deg);
          transform-origin: center center;
        }
      }
    }
    &-table {
      display: flex;
      flex-direction: column;
      gap: 10px;
      width: 100%;
      @include _768 {
        flex-direction: row;
      }
      &-header {
        display: grid;
        background: #ffffff;
        padding: 10px;
        @include borderRadius;
        border: 1px solid $grey;
        max-width: 709px;
        width: 100%;
        gap: 10px;
        grid-template-columns: repeat(6, 1fr);
        @include _768 {
          display: flex;
          flex-direction: column;
          width: 60px;
          justify-content: space-between;
        }
        &-item {
          display: flex;
          gap: 10px;
          align-items: center;
          justify-content: center;
          // max-width: 150px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          display: flex;
          justify-content: center;
          cursor: pointer;
          span {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            @include FSbaseBold;
            color: $blue;
          }
        }
      }
      &-header.full {
        max-width: none;
        grid-template-columns: repeat(5, 1fr);
        span {
          text-align: center;
        }
      }
      &-main {
        display: flex;
        @include _768 {
          flex-direction: column;
          width: 100%;
        }
        &-data {
          @include borderRadius;
          border: 1px solid $grey;
          max-width: 709px;
          width: 100%;
          padding: 1px;
          @include _768 {
            display: grid;
            grid-template-columns: repeat(10, 1fr);
          }
          @include _480 {
            grid-template-columns: repeat(8, 1fr);
          }
          @include _400 {
            grid-template-columns: repeat(5, 1fr);
          }
          &-item {
            display: grid;
            background: #ffffff;
            padding: 5px;
            span {
              @include borderRadius;
              text-align: center;
              padding: 5px;
              @include FSbaseReg;
              transition: all 0.3s;
              cursor: pointer;
              &:hover {
                background: $blue-m-bg;
              }
            }
          }
        }
        &-data {
          max-width: none;
          &-item {
            grid-template-columns: repeat(5, 1fr);
            gap: 10px;
            span {
              @include FSbaseReg;
            }
            @include _768 {
              display: flex;
              flex-direction: column;
              &:not(:last-child) {
                border-right: 1px solid $blue-light-opacity;
              }
            }
          }
        }
      }
    }
  }
}
