@import "../../../styles/_variables.scss";

.chatMobile {
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 60px;
  display: grid;
  grid-template-columns: 300px 1fr;
  padding-top: 20px;
  @include _768 {
    display: flex;
    flex-direction: column;
    padding-top: 0;
  }
}
