@import "src/styles/variables";

.answerEngineer {
  //   &-info {
  //     width: 100%;
  //     &-form {
  //       &__FileUploader {
  //         width: 48.5%;
  //         margin-bottom: 20px;
  //       }
  //       &__DocItem {
  //         margin-bottom: 20px;
  //         width: 100%;
  //         > span {
  //           display: inline-block;
  //           font-family: "Montserrat";
  //           font-style: normal;
  //           @include FSbaseBold;
  //           line-height: 14px;
  //           color: $blue;
  //           margin-bottom: 10px;
  //         }
  //         > div {
  //           margin-bottom: 10px;
  //           max-width: 48.5%;
  //         }
  //       }
  //     }
  //   }
  &-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include gapBig;
    &__title {
      font-family: "Montserrat";
      font-style: normal;
      @include FSsubtitle;
      line-height: 18px;
    }
    &__span {
      display: inline-block;
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 14px;
      color: $blue;
    }
    &__text {
      font-family: "Montserrat";
      font-style: normal;
      @include FSbaseBold;
      line-height: 16px;
      &-description {
        font-weight: 400;
        line-height: 20px;
      }
      &-position {
        font-weight: 400;
      }
    }
    &-row {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      @include gapBig;
      @include _768 {
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
      }
      @include _600 {
        display: flex;
        flex-direction: column;
      }
      &-item {
        display: flex;
        flex-direction: column;
        @include gapMin;
        &-engineer {
          display: flex;
          &-wrapper {
            display: flex;
            @include gapMin;
          }
          &-info {
            display: flex;
            flex-direction: column;
            @include gapMin;
          }
        }
        &-comment {
          grid-column-start: 1;
          grid-column-end: 4;
        }
      }
    }
    &__avatar {
      @include XSavatar;
    }
  }
  &-edit {
    display: flex;
    flex-direction: column;
    @include gapBig;
    &__list {
      list-style-type: none;
      display: grid;
      grid-template-columns: 1fr 1fr;
      @include gapBig;
      @include _600 {
        display: flex;
        flex-direction: column;
      }
    }
    &-item {
      display: flex;
      flex-direction: column;
      @include gapMin;
      &-span {
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        font-family: "Montserrat";
      }
      &-info {
        display: flex;
        flex-direction: column;
        @include gapMin;
      }
      &-wrapper {
        display: flex;
        @include gapMin;
      }
      &-text {
        @include FSbaseBold;
        font-family: "Montserrat";
        &-position {
          @include FSbaseReg;
        }
      }
      &-avatar {
        @include XSavatar;
      }
      &__DocItem {
        display: flex;
        flex-direction: column;
        @include gapMin;
        grid-column-start: 1;
        grid-column-end: 2;
        > div {
          max-width: 100%;
        }
        margin-bottom: 20px;
      }
    }
  }
}

.checkWorkEmail {
  display: flex;
  flex-direction: column;
  gap: 20px;
  font-family: "Montserrat";
  border-top: 2px solid $main-color;
  padding-top: 20px;
  @include _1700 {
    padding-top: 10px;
  }
  &-top {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
  }
  &-person {
    display: flex;
    flex-direction: column;
    @include gapMin;

    &-avatar {
      @include XSavatar;
    }
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-info {
      display: flex;
      @include gapMin;
      &-wrapper {
        display: flex;
        @include gapMin;
        flex-direction: column;
      }
      &-name {
        @include FSbaseBold;
      }
      &-position {
        @include FSbaseReg;
      }
    }
  }
  &-project {
    display: flex;
    flex-direction: column;
    @include gapMin;
    &-title {
      @include FSbaseBold;
      color: $blue;
    }
    &-name {
      @include FSbaseBold;
    }
  }
  &-center {
    list-style-type: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    @include gapBig;
  }
  &-DocItem {
    display: flex;
    flex-direction: column;
    @include gapMin;
    > div {
      max-width: calc(50% - 20px) !important;
    }
  }
  &-btns {
    display: flex;
    @include gapBig;
  }
}
