@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../assets/fonts/Montserrat/Montserrat-Regular.woff") format("woff"),
    url("../assets/fonts/Montserrat/Montserrat-Regular.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../assets/fonts/Montserrat/Montserrat-Medium.woff") format("woff"),
    url("../assets/fonts/Montserrat/Montserrat-Medium.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../assets/fonts/Montserrat/Montserrat-SemiBold.woff") format("woff"),
    url("../assets/fonts/Montserrat/Montserrat-SemiBold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../assets/fonts/Montserrat/Montserrat-Bold.woff") format("woff"),
    url("../assets/fonts/Montserrat/Montserrat-Bold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Montserrat";
  font-display: swap;
  src: url("../assets/fonts/Montserrat/Montserrat-ExtraBold.woff") format("woff"),
    url("../assets/fonts/Montserrat/Montserrat-ExtraBold.woff2") format("woff2"),
    url("../assets/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

// @font-face {
//   font-family: "Roboto";
//   font-display: swap;
//   src: url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype"),
//     url("../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
//     url("../assets/fonts/Roboto/Roboto-Regular.ttf") format("truetype");
//   font-weight: 400;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Roboto";
//   font-display: swap;
//   src: url("../assets/fonts/Roboto/Roboto-Medium.woff") format("woff"),
//     url("../assets/fonts/Roboto/Roboto-Medium.woff2") format("woff2"),
//     url("../assets/fonts/Roboto/Roboto-Medium.ttf") format("truetype");
//   font-weight: 500;
//   font-style: normal;
// }

// @font-face {
//   font-family: "Roboto";
//   font-display: swap;
//   src: url("../assets/fonts/Roboto/Roboto-Bold.woff") format("woff"),
//     url("../assets/fonts/Roboto/Roboto-Regular.woff2") format("woff2"),
//     url("../assets/fonts/Roboto/Roboto-Bold.ttf") format("truetype");
//   font-weight: 700;
//   font-style: normal;
// }
