@import "../../../../../styles/variables";

.modalDeliveryWorkEmail {
  &-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    &-btns {
      display: flex;
      gap: 20px;
    }
    &-top {
      display: flex;
      padding: 20px 0;
    }
    &-person {
      list-style: none;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      gap: 20px;
      &-item {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }
      &-info {
        display: flex;
      }
      &-title {
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
        margin-right: 5px;
      }
      &-avatar {
        @include XSavatar;
        margin-right: 10px;
      }
      &-info {
        &-wrapper {
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
        &-name {
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseBold;
          line-height: 14px;
        }
        &-position {
          font-family: "Montserrat";
          font-style: normal;
          @include FSbaseReg;
          line-height: 16px;
        }
      }
    }
    &-project {
      display: flex;
      flex-direction: column;
      gap: 10px;
      &-title {
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 14px;
        color: $blue;
      }
      &-name {
        font-family: "Montserrat";
        font-style: normal;
        @include FSbaseBold;
        line-height: 16px;
      }
    }
    &-center {
      list-style-type: none;
      display: grid;
      gap: 20px;
      width: 100%;
      justify-content: space-between;
      grid-template-columns: 1fr 1fr 1fr;
    }
    &-bottom {
      display: flex;
      flex-direction: column;
      align-items: center;
      :not(:last-child) {
        > div {
          margin-bottom: 20px;
        }
      }
    }
  }
}
