@import "../../../../../../styles/_variables";

.companyResponsibleRow {
  @include borderRadius;
  gap: 10px;
  @include cardRowWrapper;
  @include gapBig;
  display: grid;
  grid-template-columns: 2fr 3fr 20px;
  @include _1700 {
    grid-template-columns: 2fr 3fr 20px;
  }
  &-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    justify-content: center;
    span {
      font-family: "Montserrat";
      @include FSbaseBold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-name {
      @include borderRadius;
      width: 100%;
      > span {
        overflow: hidden;
        align-self: center;
        @include cardRowPaddingSpan;
        color: $blue-active;
      }
      @include HoverBtnRow;
    }
    &-btns {
      display: flex;
      @include gapBig;
    }
  }
}
