@import "../../../styles/_variables.scss";

.referenceMobile {
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  gap: 10px;
  &-content {
    &-wrapper {
      height: calc(100vh - 300px);
      overflow-y: scroll;
      overflow-x: hidden;
      display: flex;
      flex-direction: column;
      gap: 20px;
      @include _1024 {
        height: calc(100vh - 30px);
      }
    }
  }
}
