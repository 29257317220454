@import "../../../styles/variables";

.documentation {
  display: flex;
  height: 100%;
  &-content {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    @include gapBig;
    background-color: $background-color-content;
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 50px;
    @include _1700 {
      padding-top: 10px;
    }
    span {
      font-family: "Montserrat";
    }
    &-main {
      max-height: calc(100vh - 220px);
      @include flexGap(20px, col);
      padding-bottom: 20px;
      @include gapBig;
      @include _1700 {
        max-height: calc(100vh - 140px);
      }
    }
    &-filter {
      display: flex;
      align-items: center;
      justify-content: space-between;
      justify-content: space-between;
      width: 100%;
      &-set {
        display: flex;
        @include gapBig;
        justify-content: flex-end;
      }
    }
  }
  &-searchPanel {
    padding: 0 20px 0 10px;
  }
  &-panel {
    padding: 0 20px 0 10px;
    display: flex;
    @include gapBig;
    height: 100%;
    height: calc(100vh - 220px);
    &-menuFolder {
      @include flexGap(10px, col);
      padding: 10px;
      background: #ffffff;
      border: 1px solid $grey;
      @include borderRadius;
      width: 100%;
      height: 100%;
      max-width: 264px;
      height: fit-content;
      span {
        font-family: "Montserrat";
        @include FSbaseBold;
      }

      &-select {
        .css-1s2u09g-control,
        .css-1pahdxg-control {
          @include flexAllCenter();
          width: 100%;
          padding: 11px 0px;
          @include borderRadius;
          border: 1px solid $grey;
          @include borderRadius;
          cursor: pointer;
          position: relative;
          .css-14el2xx-placeholder,
          .css-qc6sy-singleValue {
            font-family: "Montserrat";
            text-align: center;
            transform: translateX(15px);
            // font-size: 14px;
            @include FSbaseBold;
          }
        }
      }
    }
    &-cards {
      @include flexGap(10px, col);
      width: 100%;
      &-item {
        @include flexConter(space-between);
        align-items: center;
        width: 100%;
        padding: 14.5px 10px;
        background: #ffffff;
        border: 1px solid $grey;
        @include borderRadius;
        span {
          font-family: "Montserrat";
          font-size: 16px;
          @include FSbaseReg;
        }
        &-info {
          @include flexGap(10px, row);
          span.size {
            color: #c4c4c4;
            font-weight: 400px;
            @include FSbaseReg;
          }
        }
        &-btns {
          @include flexGap(10px, row);
        }
      }
    }
  }
  &-pagination {
    @include flexConter(center);
  }
  &-wrapper {
    padding: 0 20px 0 20px;
  }
}
