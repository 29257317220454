@import "src/styles/variables";

.chatListItem {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include borderRadius;
  position: relative;
  min-height: 40px;
  border: 1px solid white;
  @include _1024 {
    width: 100%;
  }
  &-info {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 100%;
    overflow: hidden;
    padding: 4px 4px 4px 6px;
    &-title {
      display: flex;
      flex-direction: column;
      gap: 1px;
      width: 80%;
      &-wrapper {
        max-width: 100%;
        @include nowrap;
      }
      &-name {
        font-family: "Montserrat";
        @include FSbaseBold;
        color: black;
        text-align: start;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        &-sub {
          color: $grey;
          @include FSbaseReg;
        }
      }
      &-lastMassage {
        max-width: 100%;
        @include FSmdReg;
        @include nowrap;
      }
    }

    &-logo {
      &-list {
        display: flex;
        align-items: center;
        div:not(:first-child) {
          margin-left: -20px;
        }
        &-countMembers {
          @include XSavatar;
          border-radius: 50%;
          border: 1px solid #8c9ea5;
          background-color: #f5f9fb;
          @include flexAllCenter();
          span {
            @include FSbaseMed;
            color: #8c9ea5;
          }
        }
      }
      &-img {
        @include XSavatar;
        border-radius: 50%;
        background-size: cover;
        background-position: center;
      }
    }
  }
  &-newMassageCount {
    position: absolute;
    right: 10px;
    width: 16px;
    height: 16px;
    @include flexAllCenter();
    background: #109cf1;
    @include borderRadius;
    span {
      font-weight: 700;
      font-size: 9px;
      color: white;
    }
  }
  @include HoverSelectRow;
  &:hover {
    transition: all 0.3s;
    // box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.2);
  }
  .submit-spinner-lm {
    position: absolute;
    top: 30%;
    right: 10px;
  }
}
.chatListItem.active {
  border: 1px solid $main-color;

  // padding: 4px;
  transition: all 0.3s;
  .chatListItem-info {
    background-color: #fff;
    padding-left: 5px;
    @include borderRadius;
    height: 100%;
    padding: 4px 0 4px 4px;
    @include HoverSelectRow;
  }
  // .chatListItem-info-title {
  //   span {
  //     color: $main-color;
  //   }
  // }
}
